export class Ordonnancement {
    mt_ordonnnance: any;
    code: string;
    libelle: string;
    date_ordonnance: any;
    reste: any;
    montant_engage: any;
    pta_sous_activite_id:any
  
    private errorMessage: string;
  
    constructor() {
      this.errorMessage = "";
    }
  
    // Get error message
    getErrorMessage(): string {
      return this.errorMessage;
    }
  
    // Check for save
    canSave(): boolean {
      // Validation pour le montant d'ordonnance
      if (this.mt_ordonnnance === undefined || this.mt_ordonnnance === null) {
        this.errorMessage = "Veuillez saisir le montant d'ordonnance";
        return false;
      }
    
      // Validation pour la date d'ordonnancement
      if (this.date_ordonnance === undefined || this.date_ordonnance === null) {
        this.errorMessage = "Veuillez saisir la date d'ordonnancement";
        return false;
      }
    
      // Conversion de la date d'ordonnancement en objet Date pour la comparaison
      const dateOrdonnancement = new Date(this.date_ordonnance);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Réinitialiser les heures pour la comparaison de dates
    
      dateOrdonnancement.setHours(0, 0, 0, 0); // Réinitialiser les heures pour la date d'ordonnancement

      // Vérifier si la date d'ordonnancement est supérieure à la date du jour
      if (dateOrdonnancement > today) {
        this.errorMessage = "La date d'ordonnancement ne peut pas être supérieure à la date du jour";
        return false;
      }
    
      // Validation pour le montant engagé
      if (this.montant_engage === undefined || this.montant_engage === null) {
        this.errorMessage = "Veuillez saisir le total engagé";
        return false;
      }
    
      // Validation pour le reste
      if (this.reste === undefined || this.reste === null) {
        this.errorMessage = "Veuillez saisir le reste";
        return false;
      }
    
      // Si toutes les validations passent
      this.errorMessage = "";
      return true;
    }
    
  }
  