export class Tache {
    code: string;
    libelle: string;
    poids: any;
    date_enregistrement: any;
    date_realisation: any;
    observation: any;
    preuve_realisation: File;
    direction_id: string;
    reference_user: any;
    reference: string;

    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    // Get error message
    getErrorMessage(): string {
        return this.errorMessage;
    }

    // Check for save
    canSave(): boolean {
        if (this.code === undefined || this.code === null || this.code.trim() === "") {
            this.errorMessage = "Veuillez saisir le code";
            return false;
        }

        if (this.libelle === undefined || this.libelle === null || this.libelle.trim() === "") {
            this.errorMessage = "Veuillez saisir le libellé";
            return false;
        }

        if (this.poids === undefined || this.poids === null) {
            this.errorMessage = "Veuillez saisir le poids";
            return false;
        }

        if (this.date_enregistrement === undefined || this.date_enregistrement === null) {
            this.errorMessage = "Veuillez saisir la date d'enregistrement";
            return false;
        }
            // Conversion de la date d'ordonnancement en objet Date pour la comparaison
            const dateRealisation = new Date(this.date_realisation);
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Réinitialiser les heures pour la comparaison de dates

            dateRealisation.setHours(0, 0, 0, 0); // Réinitialiser les heures pour la date d'ordonnancement

            // Vérifier si la date d'ordonnancement est supérieure à la date du jour
            if (dateRealisation > today) {
                this.errorMessage = "La date de Réalisation ne peut pas être supérieure à la date du jour";
                return false;
            }

        if (this.date_realisation === undefined || this.date_realisation === null) {
            this.errorMessage = "Veuillez saisir la date de réalisation";
            return false;
        }

        if (this.observation === undefined || this.observation === null || this.observation.trim() === "") {
            this.errorMessage = "Veuillez saisir une observation";
            return false;
        }

        // if (this.preuve_realisation === undefined || this.preuve_realisation === null) {
        //     this.errorMessage = "Veuillez ajouter une preuve de réalisation";
        //     return false;
        // }

        // if (this.direction_id === undefined || this.direction_id === null || this.direction_id.trim() === "") {
        //     this.errorMessage = "Veuillez sélectionner une direction";
        //     return false;
        // }

        // if (this.reference_user === undefined || this.reference_user === null) {
        //     this.errorMessage = "Veuillez saisir une référence utilisateur";
        //     return false;
        // }

        this.errorMessage = "";
        return true;
    }

    // Check for update
    canUpdateForRealisation(): boolean {


        if (this.poids === undefined || this.poids === null) {
            this.errorMessage = "Veuillez saisir le poids";
            return false;
        }

        if (this.date_enregistrement === undefined || this.date_enregistrement === null) {
            this.errorMessage = "Veuillez saisir la date d'enregistrement";
            return false;
        }

        if (this.date_realisation === undefined || this.date_realisation === null) {
            this.errorMessage = "Veuillez saisir la date de réalisation";
            return false;
        }

        // Conversion de la date d'ordonnancement en objet Date pour la comparaison
        const dateRealisation = new Date(this.date_realisation);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Réinitialiser les heures pour la comparaison de dates

        dateRealisation.setHours(0, 0, 0, 0); // Réinitialiser les heures pour la date d'ordonnancement

        // Vérifier si la date d'ordonnancement est supérieure à la date du jour
        if (dateRealisation > today) {
            this.errorMessage = "La date de Réalisation ne peut pas être supérieure à la date du jour";
            return false;
        }

        // if (this.observation === undefined || this.observation === null || this.observation.trim() === "") {
        //     this.errorMessage = "Veuillez saisir une observation";
        //     return false;
        // }

        // if (this.preuve_realisation === undefined || this.preuve_realisation === null) {
        //     this.errorMessage = "Veuillez ajouter une preuve de réalisation";
        //     return false;
        // }

        // if (this.direction_id === undefined || this.direction_id === null || this.direction_id.trim() === "") {
        //     this.errorMessage = "Veuillez sélectionner une direction";
        //     return false;
        // }

        // if (this.reference_user === undefined || this.reference_user === null) {
        //     this.errorMessage = "Veuillez saisir une référence utilisateur";
        //     return false;
        // }

        this.errorMessage = "";
        return true;
    }
}
