<div class="container-fluid">
  <app-pagetitle id="upper" title="TABLEAU DE BORD" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <!-- <div class="container mt-2"> -->
  <div class="row">

    <!-- statistiques tep -->
    <div class="row col-md-3">
      <div class="card">
        <div class="card-body pb-1">
          <div class="float-end d-none d-md-inline-block">
            <div class="btn-group mb-2">
            </div>
          </div>
          <h4 class="card-title mb-4">Taux d'Exécution Physique (TEP)</h4>
          <div class="row">
            <!-- Colonne des Clés -->
            <div class="col-4">
              <h5>Date début :</h5>
              <h5>Date fin :</h5>
            </div>

            <!-- Colonne des Valeurs -->
            <div class="col-4">
              <h5>{{date_debut | dateFormat :null}}</h5>
              <h5>{{date_fin | dateFormat :null}}</h5>
            </div>
          </div>


          <div>
            <apx-chart dir="ltr" class="apex-charts" [series]="revenueChart?.series"
              [dataLabels]="revenueChart?.dataLabels" [chart]="revenueChart?.chart" [legend]="revenueChart?.legend"
              [colors]="revenueChart?.colors" [labels]="revenueChart?.labels" [stroke]="revenueChart?.stroke"
              [plotOptions]="revenueChart?.plotOptions">
            </apx-chart>
          </div>
        </div>
      </div>

    </div>

    <!-- statistiques ordonnancement et engagement -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-body pb-1">
          <div class="float-end d-none d-md-inline-block">
            <div class="btn-group mb-2">
            </div>
          </div>
          <h4 class="card-title mb-4">Taux d’exécution financière</h4>
          <div class="row">
            <!-- Colonne des Clés -->
            <div class="col-4">
              <h5>Date début :</h5>
              <h5>Date fin :</h5>
            </div>

            <!-- Colonne des Valeurs -->
            <div class="col-4">
              <h5>{{date_debut | dateFormat :null}}</h5>
              <h5>{{date_fin | dateFormat :null}}</h5>
            </div>
          </div>


          <div>
            <apx-chart dir="ltr" class="apex-charts" [series]="revenueChartotal?.series"
              [dataLabels]="revenueChartotal?.dataLabels" [chart]="revenueChartotal?.chart"
              [legend]="revenueChartotal?.legend" [colors]="revenueChartotal?.colors"
              [labels]="revenueChartotal?.labels" [stroke]="revenueChartotal?.stroke"
              [plotOptions]="revenueChartotal?.plotOptions">
            </apx-chart>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body pb-1">
          <div class="float-end d-none d-md-inline-block">
            <div class="btn-group mb-2">
            </div>
          </div>
          <h4 class="card-title mb-4">Taux de décaissement</h4>
          <div class="row">
            <!-- Colonne des Clés -->
            <div class="col-4">
              <h5>Date début :</h5>
              <h5>Date fin :</h5>
            </div>

            <!-- Colonne des Valeurs -->
            <div class="col-4">
              <h5>{{date_debut | dateFormat :null}}</h5>
              <h5>{{date_fin | dateFormat :null}}</h5>
            </div>
          </div>


          <div>
            <apx-chart dir="ltr" class="apex-charts" [series]="revenueChartauxDecaissement?.series"
              [dataLabels]="revenueChartauxDecaissement?.dataLabels" [chart]="revenueChartauxDecaissement?.chart"
              [legend]="revenueChartauxDecaissement?.legend" [colors]="revenueChartauxDecaissement?.colors"
              [labels]="revenueChartauxDecaissement?.labels" [stroke]="revenueChartauxDecaissement?.stroke"
              [plotOptions]="revenueChartauxDecaissement?.plotOptions">
            </apx-chart>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4"> Tâches</h4>
          <ngx-simplebar style="height: 275px;">
            <ul class="list-unstyled activity-wid">
              <!-- Tâches en retard (Rouge) -->
              <li class="activity-list">
                <div title="Cliquez pour consulter les tâches en retard" style="cursor: pointer;"
                  class="activity-icon avatar-xs">
                  <span (click)="openModalUpdateCible(statsEnretard?.taches_en_retard?.liste, 'bg-danger')"
                    class="avatar-title bg-primary-subtle text-primary rounded-circle">
                    <i class="ri-edit-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h6 id="dash" class="text-danger">
                      Vous avez {{ statsEnretard?.taches_en_retard?.nombres_taches }} tâche(s) en retard
                    </h6>
                  </div>
                </div>
              </li>

              <!-- Tâches urgentes (Orange) -->
              <li class="activity-list">
                <div title="Cliquez pour consulter les tâches urgentes" style="cursor: pointer;"
                  class="activity-icon avatar-xs">
                  <span (click)="openModalUpdateCible(statsEnretard?.taches_urgentes?.liste, 'bg-warning')"
                    class="avatar-title bg-primary-subtle text-primary rounded-circle">
                    <i class="ri-edit-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 id="dash" class="text-warning">
                      Vous avez {{ statsEnretard?.taches_urgentes?.nombres_taches }} tâche(s) urgente(s)
                    </h5>
                  </div>
                </div>
              </li>

              <!-- Tâches réalisées (Vert) -->
              <li class="activity-list">
                <div title="Cliquez pour consulter les tâches réalisées" style="cursor: pointer;"
                  class="activity-icon avatar-xs">
                  <span (click)="openModalUpdateCible(statsEnretard?.taches_realisees?.liste, 'bg-success')"
                    class="avatar-title bg-primary-subtle text-primary rounded-circle">
                    <i class="ri-edit-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 id="dash" class="text-success">
                      Vous avez {{ statsEnretard?.taches_realisees?.nombres_taches }} tâche(s) réalisée(s)
                    </h5>
                  </div>
                </div>
              </li>
            </ul>

          </ngx-simplebar>
        </div>
      </div>
    </div>

  </div>
  <!-- </div> -->


</div>




<!-- affichage des taches en ecran modal -->
<ng-template #detailModal let-modal>
  <div class="container-fluid">
    <br>
    <div class="d-flex justify-content-between align-items-center">
      <h4>Tâches ({{items.length}})</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Close click')"> </button>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive mt-3">
          <table class="table table-centered datatable dt-responsive nowrap" style="width: 100%;">
            <thead [ngClass]="headerClass">
              <tr>
                <th>Code</th>
                <th>Libellé</th>
                <th style="white-space: nowrap;">Date de début</th>
                <th>Date de fin</th>
                <th>Poids</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of paginatedItems">
                <td>{{item?.code}}</td>
                <td>{{item?.libelle}}</td>
                <td>{{item?.date_debut | dateFormat}}</td>
                <td>{{item?.date_fin | dateFormat}}</td>
                <td>{{item?.poids}}</td>
              </tr>
            </tbody>
          </table>

          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]">
          </p-paginator>
        </div>
      </div>
    </div>
  </div>
</ng-template>