<!-- <app-horizontaltopbar></app-horizontaltopbar><br><br><br><br><br> -->



<div class="d-flex justify-content-center">
  <div class="col-lg-10">
    <div class="card">
      <div class="card-body">
        <p-toast position="bottom-top" />

        <!--      
        <h4 class="card-title mb-4 text-center">Veuillez choisir un exercice</h4>
      
        <div class="table-responsive">
          <table class="table table-centered">
            <thead class="rwd-table">
              <tr>
                <th class="dtr-control">
                </th>
                <th>Année</th>
                <th colspan="5"></th> 
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of paginatedExercice" style="cursor: pointer;">
                <td class="dtr-control">
                </td>
                <td>{{item.annee}}</td>
                <td colspan="5"></td> 
                <td>
                  <div style="display: flex; justify-content: center;"> 
                    <a (click)="accessUser(item.annee)" href="javascript:void(0);" title="Cliquez ici pour accéder aux donnees de cette année" class="button button--default" id="acces">
                      Accéder <i class="pi pi-angle-right" style="font-size: 0.6rem;"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div> -->

        <div class="container text-center mt-5">
          <h3 class="mb-4">Veuillez choisir un exercice :</h3>
          
          <!-- Parent element to align items inline -->
          <div class="d-flex justify-content-center flex-wrap">
              <!-- Loop through paginatedExercice to display items inline -->
              <div class="col-4 col-md-2 me-3" *ngFor="let item of paginatedExercice">
                  <div class="year-btn" (click)="accessUser(item.annee)">
                      {{ item.annee }} 
                      <span title="Veuillez cliquer pour accéder à cet exercice" style="background-color: white; border-radius: 50%; padding: 10px; display: inline-block; color: #4655B4;">
                        <i class="pi pi-arrow-up-right" style="font-size: 1.5rem"></i>
                    </span>
                    
                  </div>
              </div>
          </div>
      
          <!-- Loader displayed during data loading -->
          <div class="d-flex justify-content-center" *ngIf="isLoading === true">
              <div class="container">
                  <div class="row">
                      <div class="text-center">
                          <h5 id="graycolor">Chargement en cours</h5>
                          <div class="loader4"></div>
                      </div>
                  </div>
              </div>
          </div>
      <br>
          <!-- Paginator for navigating pages -->
          <!-- <p-paginator (onPageChange)="onPageChangeProjets($event)" 
                       [first]="first" 
                       [rows]="rows" 
                       [totalRecords]="totalRecords" 
                       [rowsPerPageOptions]="[10, 20, 30]">
          </p-paginator> -->
      </div>

      <div class="d-flex justify-content-center mt-4">
        <a type="button" (click)="goBack()"  class="btn" style="background-color: transparent; border: 1px solid #ccc; color: #555; padding: 8px 16px; border-radius: 5px;">
          <i class="pi pi-arrow-left" style="font-size: 1.2rem; color: #555;"></i> Retour
        </a>
      </div>
      
      
      <div class="bottom-line"></div>

      </div>
    </div>
  </div>