<div class="container-fluid">

  <h4> Exécution Tâches ({{itemsArealiser.length}} sous-activités)</h4>


  <div class="d-flex justify-content-end">

    <div  class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <label class="d-inline-flex align-items-center">
          Projets:
          <select type="text" name="searchTerm" class="form-select ms-2"
            aria-controls="tickets-table" [(ngModel)]="selectedProjet" (change)="processSelectedProjet()"  >
            <option disabled value="Sélectionner une direction"></option>
            <option *ngFor="let item of projetItems" [ngValue]="item"> {{ item?.libelle}}</option>
          </select>
        </label>
      </div>
    </div>

    <div *ngIf="showFiltre === true" class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <label class="d-inline-flex align-items-center">
          Direction:
          <select  type="text" name="searchTerm" class="form-select ms-2"
            aria-controls="tickets-table" [(ngModel)]="selectedDirection"  (change)="filterDataByDirections()"   >
            <option value="Sélectionner une direction" >Sélectionner une direction</option>
            <option *ngFor="let item of directionItems" [ngValue]="item"> {{ item?.direction.libelle}}</option>
          </select>
        </label>
      </div>
    </div>
  
   
   

  </div>


  <p-tabView>

    <!-- taches a realiser pr les sous activites -->
    <p-tabPanel header="A réaliser ">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
                </div>
              </div>
              <div class="table-responsive mt-3">
                <table class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                  <thead class="thead-light  rwd-table">
                    <tr>
                      <th>Code</th>
                      <th>Libellé</th>
                      <th>Début</th>
                      <th>Fin</th>
                      <th>Poids(%) </th>
                      <th>Mt. Total (FCFA)</th>
                      <th style="width: 120px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of paginatedItemsArealiser; let index = index">

                      <td>{{item?.code_sous_activite}}</td>
                      <td>{{item?.sous_activite}}</td>
                      <td>{{item?.date_debut | date:'dd/MM/yyyy' }}</td>
                      <td>{{item?.date_fin | date:'dd/MM/yyyy'}}</td>
                      <td class="text-right">{{item?.poids}}</td>
                      <td class="text-right">{{item.mt_financement_ptf | formatMoney:null}}</td>
                      <td>
                        <button class="btn btn-success btn-sm" (click)="detailModal(item , 'arealiser')"
                          title="Cliquez ici pour voir les tâches">Tâches</button>
                      </td>
                    </tr>

                  </tbody>

                </table>

              </div>
              <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                <div class="container">
                  <div class="row">
                    <div class="text-center">
                      <h5 id="graycolor">Chargement en cours</h5>
                      <div class="loader4"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="paginatedItemsArealiser.length === 0 && isLoading === false" class="d-flex justify-content-center">
                <div class="container">
                  <div class="row">
                    <div class="text-center">
                      <h5 id="graycolor">Aucune donnée trouvée</h5>
                    </div>
                  </div>
                </div>

              </div>
              <p-paginator (onPageChange)="onPageChangeArealiser($event)" [first]="firstArealiser" [rows]="rowsArealiser"
                [totalRecords]="totalRecordsArealiser" [rowsPerPageOptions]="[10, 20, 30]">
              </p-paginator>
            </div>

          </div>

        </div>

      </div>
    </p-tabPanel>

  <!-- taches realisees pr les sous activites -->
    <p-tabPanel header="Réalisés">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
                </div>
              </div>
              <div class="table-responsive mt-3">
                <table class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                  <thead class="thead-light  rwd-table">
                    <tr>
                      <th>Code</th>
                      <th>Libellé</th>
                      <th>Date Début</th>
                      <th>Date Fin</th>
                      <th>Poids(%) </th>
                      <th>Mt. total (FCFA)</th>
                      <th>Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of paginatedItemsArealiser; let index = index">
                      <td>{{item?.code_sous_activite}}</td>
                      <td>{{item?.sous_activite}}</td>
                      <td>{{item?.date_debut | date:'dd/MM/yyyy' }}</td>
                      <td>{{item?.date_fin | date:'dd/MM/yyyy'}}</td>
                      <td>{{item?.poids}}</td>
                      <td class="text-right">{{item.mt_financement_ptf | formatMoney:null }}</td>
                      <td>
                        <button class="btn btn-success btn-sm" (click)="detailModal(item,'realise')"
                          title="Cliquez ici pour voir les tâches">Tâches</button>
                      </td>
                    </tr>

                  </tbody>

                </table>

              </div>
              <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                <div class="container">
                  <div class="row">
                    <div class="text-center">
                      <h5 id="graycolor">Chargement en cours</h5>
                      <div class="loader4"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="paginatedItemsArealiser.length === 0 && isLoading === false" class="d-flex justify-content-center">
                <div class="container">
                  <div class="row">
                    <div class="text-center">
                      <h5 id="graycolor">Aucune donnée trouvée</h5>
                    </div>
                  </div>
                </div>

              </div>
              <p-paginator (onPageChange)="onPageChangeArealiser($event)" [first]="firstArealiser" [rows]="rowsArealiser"
              [totalRecords]="totalRecordsArealiser" [rowsPerPageOptions]="[10, 20, 30]">
            </p-paginator>
           
            </div>

          </div>

        </div>

      </div>
    </p-tabPanel>
   
     <!-- taches en retard pr les sous activites -->
    <p-tabPanel header="En retard">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
                </div>
              </div>
              <div class="table-responsive mt-3">
                <table class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                  <thead class="thead-light  rwd-table">
                    <tr>
                      <th>Code</th>
                      <th>Libellé</th>
                      <th>Date Début</th>
                      <th>Date Fin</th>
                      <th>Poids(%) </th>
                      <th>Mt. total (FCFA)</th>
                      <th style="width: 120px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of paginatedItemsArealiser; let index = index">

                      <td>{{item?.code_sous_activite}}</td>
                      <td>{{item?.sous_activite}}</td>
                      <td>{{item?.date_debut | date:'dd/MM/yyyy' }}</td>
                      <td>{{item?.date_fin | date:'dd/MM/yyyy'}}</td>
                      <td>{{item?.poids}}</td>
                       <td class="text-right">{{item.mt_financement_ptf | formatMoney :null }}</td>
                       <td>
                        <button class="btn btn-success btn-sm" (click)="detailModal(item,'retard')"
                          title="Cliquez ici pour voir les tâches">Tâches</button>
                      </td>
                    </tr>

                  </tbody>

                </table>

              </div>
              <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                <div class="container">
                  <div class="row">
                    <div class="text-center">
                      <h5 id="graycolor">Chargement en cours</h5>
                      <div class="loader4"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="paginatedItemsArealiser.length === 0 && isLoading === false" class="d-flex justify-content-center">
                <div class="container">
                  <div class="row">
                    <div class="text-center">
                      <h5 id="graycolor">Aucune donnée trouvée</h5>
                    </div>
                  </div>
                </div>
              </div>

            <p-paginator (onPageChange)="onPageChangeArealiser($event)" [first]="firstArealiser" [rows]="rowsArealiser"
              [totalRecords]="totalRecordsArealiser" [rowsPerPageOptions]="[10, 20, 30]">
            </p-paginator>

            </div>

          </div>

        </div>

      </div>
    </p-tabPanel>


  </p-tabView>

</div>



<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>