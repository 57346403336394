import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';

import { PagesRoutingModule } from './pages-routing.module';

import { SimplebarAngularModule } from 'simplebar-angular';
import { NgbNavModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgPipesModule } from 'ngx-pipes';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';


import { LocalStorageService } from '../shared/service/storage/localstorage.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';

import { ToastModule } from 'primeng/toast';


import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FecthDataService } from '../shared/service/fetchservice/fetchservice';
import { ContactComponent } from '../public/page/contact/contact.component';
import { ExecutionModule } from './pages/suivi/execution/execution.module';
import { ProgrammeService } from './pages/suivi/plananalytique/programs/programme/programme.component';
import { ActionServiceService } from './pages/suivi/plananalytique/actions/action/action.component';
import { ProjetIndicateurService } from './pages/suivi/plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { IndicateurServiceService } from './pages/suivi/plananalytique/indicateurs/indicateur/indicateur.component';
import { LocalisationComponent } from './pages/recherche/ressource/localisation/localisation.component';
import { EditCentreComponent } from './pages/recherche/centres/edit-centre/edit-centre.component';
import { DirectionService } from './pages/suivi/plananalytique/directions/direction/direction.component';


@NgModule({
  declarations: [
  
     EditCentreComponent,
     ContactComponent 
    ], 
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    NgPipesModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgApexchartsModule,
    DndModule,
    FullCalendarModule,
    LeafletModule,
    WidgetModule,
    SimplebarAngularModule,
  
    ExecutionModule,
    ConfirmDialogModule,
    ToastModule,
    ButtonModule,
    NgxUiLoaderModule,

    //SharedModule,
    
    
  ],
  providers: [

    LocalStorageService,
    ConfirmationService,
    MessageService,
    ProgrammeService,
    ActionServiceService,
    ProjetIndicateurService,
    IndicateurServiceService,
    FecthDataService,
    DirectionService
  ],

  exports:[ 
  ]
})
export class PagesModule { }
