<div class="breadcrumbs overlay">
    <div class="container">
        <div class="bread-inner">
            <div class="row">
                <div class="col-12">
                    <h2 style="">CONTACT</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Contact Start -->
<div class="container-xxl py-5">
    <div class="container">
        <div class="row g-4" >
            <div class="col-lg-4">
                <div style="background-color: rgb(166 166 166 / 20%)"  class="h-100  rounded d-flex align-items-center p-5">
                    <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                        style="width: 55px; height: 55px;">
                        <i class="fa fa-map-marker-alt text-primary"></i>
                    </div>
                    <div class="ms-4">
                        <p class="mb-2">Address</p>
                        <h5 class="mb-0">Rue 858,Placodji 01 BP 249 Cotonou </h5>
                    </div>
                </div>   
            </div>
            <div class="col-lg-4">
                <div style="background-color: rgb(166 166 166 / 20%)" class="h-100  rounded d-flex align-items-center p-5">
                    <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                        style="width: 55px; height: 55px;">
                        <i class="fa fa-phone-alt text-primary"></i>
                    </div>
                    <div class="ms-4">
                        <p class="mb-2">Appelez-nous</p>
                        <h5 class="mb-0">+229 21 31 03 09</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div style="background-color: rgb(166 166 166 / 20%)" class="h-100  rounded d-flex align-items-center p-5">
                    <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                        style="width: 55px; height: 55px;">
                        <i class="fa fa-envelope-open text-primary"></i>
                    </div>
                    <div class="ms-4">
                        <p class="mb-2">Envoyez-nous un courriel maintenant</p>
                        <h5 class="mb-0">info&#64;example.com</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div style="background-color: rgb(166 166 166 / 20%)" class=" rounded p-5">
                    <p class="d-inline-block border rounded-pill py-1 px-4">Contactez-nous
                    </p>
                    <h1 style="" class="mb-4">
                        Avez-vous des questions? Veuillez nous contacter!</h1>
                    <p style="" class="mb-4">Nous
                        sommes là pour vous aider ! Que vous ayez des questions, des préoccupations ou que vous
                        souhaitiez en savoir plus sur nos services, n'hésitez pas à nous contacter. Notre équipe dédiée
                        est prête à vous fournir l'assistance dont vous avez besoin.</p>
                    <form>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input [(ngModel)]="item.name" name="nom" type="text" class="form-control" id="name"
                                        placeholder="Your Name">
                                    <label for="name">Votre nom</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input [(ngModel)]="item.email" name="email" type="email" class="form-control"
                                        id="email" placeholder="Your Email">
                                    <label for="email">Votre email</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <input [(ngModel)]="item.sujet" name="sujet" type="text" class="form-control"
                                        id="subject" placeholder="Subject">
                                    <label for="subject">sujet</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <textarea [(ngModel)]="item.message" name="message" class="form-control"
                                        placeholder="Leave a message here" id="message"
                                        style="height: 100px"></textarea>
                                    <label for="message">Message</label>
                                </div>
                            </div>
                            <div class="col-12 position-relative">
                                <button mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading"
                                    (click)="sendMessage()" class="btn btn-primary w-100 py-3" type="submit">
                                    <ng-container *ngIf="!loading">Envoyer</ng-container>
                                    <ng-container *ngIf="loading"></ng-container>
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="h-100" style="min-height: 400px;">
                    <iframe class="rounded w-100 h-100"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6667.086010738618!2d2.35370140372002!3d6.487064650540143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1024a934fad9ba0f%3A0xbfb91b75d36258b0!2sSalle%20d%E2%80%99assembl%C3%A9es%20des%20T%C3%A9moins%20de%20J%C3%A9hovah%20de%20Calavi!5e0!3m2!1sfr!2sbj!4v1726659269205!5m2!1sfr!2sbj"
                        frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact End -->

<p-toast position="top-right"></p-toast>