import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartographieComponent } from './cartographie/cartographie.component';
import { RealisationIndicateursComponent } from './realisation-indicateurs/realisation-indicateurs.component';
import { TacheComponent } from './taches/tache/tache.component';
import { EngagementComponent } from './engagements/engagement/engagement.component';
import { OrdonnancementComponent } from './ordonnancements/ordonnancement/ordonnancement.component';

const routes: Routes = [
  {
    path: 'tâche',
    component: TacheComponent
  },

  {
    path: 'cartographie',
    component: CartographieComponent
  },

  {
    path: 'realisation',
    component: RealisationIndicateursComponent
  },

  {
    path: 'engagement',
    component: EngagementComponent
  },

  {
    path: 'ordonnancement',
    component: OrdonnancementComponent
  },


];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExecutionRoutingModule { }
