export class PtaSousActivite {
  code_sous_activite: any;
  sous_activite: any;
  date_debut: Date;
  date_fin: Date;
  amputation_budgetaire: any;
  mode_execution: any;
  poids: any;
  mt_financement_etat: any;
  mt_financement_fond_propre: any;
  mt_financement_ptf: any;
  est_revue: any;
  pta_id: any;
  pta_activite_id: any;
  reference: any;
  projet_id: any;
  structures_associees: any;
  structure_responsable: any;
  total: number;
  total_engage: number;
  total_ordonnance: number;
  total_encaissement: number;
  total_decaisse: number;
  date_engagement: any;
  date_ordonnance: any;
  date_encaissement: any;
  date_decaissement: any;

  total_a_ordonnancer: number;
  total_a_encaisser: number;
  reste: number;


  private errorMessage: string;

  constructor() {
    this.errorMessage = "";
  }

  // Get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }

  // Check for save
  canOrdonnance(): boolean {
    // Validate required fields
    if (this.total_ordonnance === undefined || this.total_ordonnance === null) {
      this.errorMessage = "Veuillez saisir le montant ordonnancé";
      return false;
    }

    if (this.date_ordonnance === undefined || this.date_ordonnance === null) {
      this.errorMessage = "Veuillez saisir la date d'ordonnance";
      return false;
    }

    // Clear the error message if all validations pass
    this.errorMessage = "";
    return true;
  }//end canOrdonnance

  // Check for save
  canSaveEngagement(): boolean {

    if (this.total_engage === undefined || this.total_engage === null) {
      this.errorMessage = "Veuillez saisir le montant engagé";
      return false;
    }

    if (this.date_engagement === undefined || this.date_engagement === null) {
      this.errorMessage = "Veuillez saisir la date d'engagement";
      return false;
    }

    this.errorMessage = "";
    return true;
  }//end canSaveEngagement


  canEncaissement(): boolean {
    // Validate required fields
    if (this.total_a_encaisser === undefined || this.total_a_encaisser === null) {
      this.errorMessage = "Veuillez saisir le montant encaissé";
      return false;
    }

    if (this.date_encaissement === undefined || this.date_encaissement === null) {
      this.errorMessage = "Veuillez saisir la date d'encaissement";
      return false;
    }

    // Clear the error message if all validations pass
    this.errorMessage = "";
    return true;
  }

  canDecaissement(): boolean {
    // Validate required fields
    if (this.total_decaisse === undefined || this.total_decaisse === null) {
      this.errorMessage = "Veuillez saisir le montant decaissé";
      return false;
    }

    if (this.date_decaissement === undefined || this.date_decaissement === null) {
      this.errorMessage = "Veuillez saisir la date de decaissement";
      return false;
    }

    // Clear the error message if all validations pass
    this.errorMessage = "";
    return true;
  }
}
