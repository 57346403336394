export class ProjeToPta {
    code_activite: any;
    code: any;
    activite: any;
    code_sous_activite : any;
    sous_activite: any;
    projet_id: any;
    pta_sous_activite_id: any;
    date_debut: any;
    date_fin: any;
    poids: any;
    mt_financement_etat: any;
    montant_tache: any;
    libelle: any;
    pta_activite_id: any;
    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }

    canSave(): boolean {
        if (!this.code_activite || this.code_activite.trim() === '') {
            this.errorMessage = "Le  'code' est obligatoire.";
            return false;
        }
       

        if (!this.date_debut || this.date_debut.trim() === '') {
            this.errorMessage = "La date début est obligatoire.";
            return false;
        }

        if (!this.date_fin || this.date_fin.trim() === '') {
            this.errorMessage = "La date de fin est obligatoire.";
            return false;
        }

        if (!this.poids ) {
            this.errorMessage = "Le  poids est obligatoire.";
            return false;
        }

        if (!this.mt_financement_etat ) {
            this.errorMessage = "Le  montant est obligatoire.";
            return false;
        }

        
        // if (!this.libelle || this.libelle.trim() === '') {
        //     this.errorMessage = "Le  'libelle' est obligatoire.";
        //     return false;
        // }

        // if (!this.pta_activite_id || this.pta_activite_id.trim() === '') {
        //     this.errorMessage = "Le champ 'pta_activite_id' est obligatoire.";
        //     return false;
        // }

        this.errorMessage = ""; // Clear error message if all fields are valid
        return true;
    }
}
