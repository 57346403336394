import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { DetailPtaComponent } from '../detail-pta/detail-pta.component';
import { Constant } from 'src/app/shared/service/utils/contant';
import { OperationService } from 'src/app/shared/service/operation/operation.service';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-validation-pta',
  templateUrl: './validation-pta.component.html',
  styleUrl: './validation-pta.component.scss'
})
export class ValidationPtaComponent {

  validationData: any = [];
  @ViewChild('sousActivitesModal') sousActivitesContent: TemplateRef<any>;
  @ViewChild('activitesModal') activitesContent: TemplateRef<any>;
  @ViewChild('projetsModal') projetsContent: TemplateRef<any>;
  first: number = 0;

  validationPagianteStat: any = [];
  pojetItems: any = [];
  rows: number = 9;


  firstSousActivites = 0;
  rowsSousActivites = 10;
  paginatedSousActivites = [];
  totalRecords1: number = 0; // Nombre total d'éléments

  firstActivites = 0;
  rowsActivites = 10;
  paginatedActivites = [];
  totalRecords2: number = 0; // Nombre total d'éléments

  firstProjets = 0;
  rowsProjets = 10;
  paginatedProjets = [];
  totalRecords3: number = 0; // Nombre total d'éléments

  annee :any
  showTextCard :boolean = false;
  isLoading: boolean = false;

  projet_id:any
  constructor(
    private operationService: OperationService,
    private paramService: ParameterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private localstorage: LocalStorageService,
    private modalService: NgbModal,
  ) { }


  ngOnInit() {
    this.getProjet();
   // this.getNonValidatedData();
  }


  //get pending validation
  getNonValidatedData() {
    this.isLoading = true;
    this.operationService.getPendingValidationPta().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.validationData = response.data;
        //console.log("this.validationData", this.validationData);
        if (this.validationData.sous_activites.length > 0 &&
          this.validationData.activites.length > 0 &&
          this.validationData.projets.length > 0) {
            this.showTextCard = true;

        }
        this.updatePaginatedSousActivites();
        this.updatePaginatedActivites();
        this.updatePaginatedProjets();
        this.totalRecords1 = this.validationData.sous_activites.length;
        this.totalRecords2 = this.validationData.activites.length;
        this.totalRecords3 = this.validationData.projets.length;
        //verification

        this.annee = this.validationData.annee;
        if (this.validationData.sous_activites.length === 0 &&
          this.validationData.activites.length === 0 &&
          this.validationData.projets.length === 0
        ) {
          this.showTextCard = true
          if (this.showTextCard === true && this.annee != "" ) {
            this.validatePta();

          }
        }
      },
      (error) => {
        this.isLoading = false;
        //console.log("une erreur survenu", error);
        this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: "Impossible de récupérer les données non validées." });
      }
    )
  }//end getNonValidatedData


  //condition pour lancer la validation des pta
  validatePta() {

    //console.log("this.annee",this.annee);
    
    const requestData = {
      "annee":this.annee,
      "projet_id":this.projet_id.reference
    }
    this.operationService.validatePendingPta(requestData).subscribe(
    //get pending year
      (response: any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
        
      },
      (error) => {
        this.isLoading = false;
        //console.log("une erreur survenu", error);
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Impossible de récupérer les données non validées." });
      }
    )
  }//end validatePta
  updatePaginatedSousActivites() {
    const start = this.firstSousActivites * this.rowsSousActivites;
    const end = start + this.rowsSousActivites;
    this.paginatedSousActivites = this.validationData.sous_activites.slice(start, end);
    //console.log("this.paginatedSousActivites", this.paginatedSousActivites);
  }///end updatePaginatedSousActivites


  updatePaginatedActivites() {
    const start = this.firstActivites * this.rowsActivites;
    const end = start + this.rowsActivites;
    this.paginatedActivites = this.validationData.activites.slice(start, end);
  }//end updatePaginatedActivites


  //
  updatePaginatedProjets() {
    const start = this.firstProjets * this.rowsProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.validationData.projets.slice(start, end);
  }//end updatePaginatedProjets


  // Gestion des changements de page pour chaque type de donnée
  onPageChangeSousActivites(event) {
    this.firstSousActivites = event.first ;
    this.rowsSousActivites = event.rows ;
    this.updatePaginatedSousActivites();
  }

  onPageChangeActivites(event) {
    this.firstActivites =  event.first;
    this.rowsActivites =  event.rows;
    this.updatePaginatedActivites();
  }

  onPageChangeProjets(event) {
    this.firstProjets =  event.first;
    this.firstProjets =  event.first;
    this.updatePaginatedProjets();
  }

  //afficher la liste des details de sous activites
  openDetailsSousActivites() {
    this.modalService.open(this.sousActivitesContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }//end openDetailsSousActivites

  //afficher la liste des details des activites
  openDetailsActivites() {
    this.modalService.open(this.activitesContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }//end openDetailsActivites

  //afficher la liste des details des activites
  openDetailsProjets() {
    this.modalService.open(this.projetsContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }//end openDetailsProjets

  confirm1(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Êtes-vous sûr de vouloir continuer ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
      rejectLabel: 'Non', // Définir le libellé du bouton "Non"
      accept: () => {
        this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'Vous avez acepter' });

        this.operationService.getCentreByReference(event).subscribe(

          (response: any) => {

            //console.log("la reponse du webserice", response);

          },
          (error) => {

            //console.log("une erreur est survenu", error);

          },
        )
      },
      // reject: () => {
      //     this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: 'Vous avez rejeté', life: 3000 });
      // }
    });
  }


  openDetailValidationPta(validation: any) {
    this.localstorage.saveAction(validation)
    const modalRef = this.modalService.open(DetailPtaComponent, {
      backdrop: 'static',
      centered: true,
    },);
  }//end openDetailValidationPta

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }
  getProjet(){
    this.paramService.fetchDataProjet().subscribe(
      (response:any)=>{
        this.pojetItems = response.data;
        console.log("la reponse du webservice",this.pojetItems);
        
      },
      (error:any)=>{
        console.log("une erreur est survenu");
        
      }
    )
  }

}
