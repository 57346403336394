import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { EditProjetComponent } from '../../plananalytique/projet/edit-projet/edit-projet.component';
import { ProjetIndicateurService } from '../../plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-revue-budgetaire',
  templateUrl: './revue-budgetaire.component.html',
  styleUrl: './revue-budgetaire.component.scss'
})

export class RevueBudgetaireComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;
  filteredData: any[] = []; // Données filtrées
  searchTerm: string = ''; // Terme de recherche
  revuBudgetaire: any = [];
  modalRef: NgbModalRef | null = null;
  paginatedItems: any[] = [];
  first: number = 0;
  rows: number = 10;
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;

  itemToDelete!: any;

  constructor(
    private modalService: NgbModal,

    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private projetService: ProjetIndicateurService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {

    // this.projetService.projetCreated$.subscribe(() => {
    //   this.fetchDataAction();
    // });
  }

  ngOnInit(): void {



    this.fetchDataAction();
  }

  fetchDataAction() {
    this.isLoading = true;

    this.paramservice.fetchDataProjet().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.revuBudgetaire = response.data;
        this.filteredData = [...this.revuBudgetaire]; // Initialiser filteredData avec toutes les données
        this.totalRecords = this.filteredData.length;
        this.updatePagination();
        //console.log("this.actionData", this.revuBudgetaire);
      },
      (error) => {
        //console.log("une erreur est survenu", error);
      }
    );
  }


  onPageChange(event: any) {
    this.first = event.first ;
    this.rows = event.rows ;
    this.updatePagination();
  }

  updatePagination() {
    const start = this.first;
    const end = this.first + this.rows;
    this.paginatedItems = this.filteredData.slice(start, end);
  
    //console.log("this.updateRessourceStats",this.paginatedItems);
  }
  applyFilter(term: string) {
    this.searchTerm = term.trim().toLowerCase();

    if (this.searchTerm) {
      this.filteredData = this.revuBudgetaire.filter(item =>
        item.sigle.toLowerCase().includes(this.searchTerm)
      );
    } else {
      // Réinitialiser les données filtrées si la recherche est vide
      this.filteredData = [...this.revuBudgetaire];
    }

    this.totalRecords = this.filteredData.length;
    this.first = 0; // Revenir à la première page après le filtrage
    this.updatePagination();
  }

  confirmDeletion(project: any) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer ce projet ' + project.libelle + '?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.ngxService.start();
        this.paramservice.deleteProjet(project.reference).subscribe(
          (response: any) => {
            this.ngxService.stop();
            //console.log("la reponse du webserice", response);
            this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: response.message });
            this.fetchDataAction();
          },
          (error) => {
            this.ngxService.stop();
            //console.log("une erreur est survenu", error);
            this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });
          },
        )
      },
      reject: () => {
        //this.messageService.add({ severity: 'warn', summary: '', detail: 'Vous avez rejeté', life: 3000 });
      }
    });
  }

  EditModal(projetIndicateur: any) {
    this.localstorage.saveProjet(projetIndicateur)
    const modalRef = this.modalService.open(EditProjetComponent, {
      backdrop: 'static',
      centered: true,
      size: 'xl'
    },);
  }


  addProjetToActiviter(item: any) {

    //console.log("item");

    this.localstorage.saveProjetActivite(item);
    
    this.router.navigate(['/suivi/planification/projet-activite'])
  }
}
