import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkerDataService } from 'src/app/shared/service/makerservice/markerDataService';
import { OperationService } from 'src/app/shared/service/operation/operation.service';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrl: './resource-detail.component.scss'
})
export class ResourceDetailComponent {



  resource: any;
  reference: string;
  typeResourceId: any
  markers: { lat: number, lng: number, type: any, data: any}[] = [];

  titre: any
  pespective: any
  photo: any
source:any
  constructor(
    private route: ActivatedRoute,
    private operationService: OperationService,
    private localStorage: LocalStorageService,
    private markerDataService: MarkerDataService,
    private router: Router,

  ) {
    this.markerDataService.markers$.subscribe(markers => {
      this.markers = markers;
    });
  }

  ngOnInit(): void {
    this.typeResourceId = this.localStorage.getRessourceDetail();

    console.log(" this.typeResourceId", this.typeResourceId);
    this.getResourceDetails();
    this.source  = this.localStorage.getAccueil();
  }

  getResourceDetails(): void {
    this.operationService.getRessourceByReference(this.typeResourceId).subscribe(
      (response: any) => {
        // console.log("la reponse du websevice",response);
        this.resource = response.data[0];
        //  console.log("la reponse du websevice",this.resource.titre);    
        this.updateMarkersWithRessources( this.resource);  // Met à jour les marqueurs
    
      },
      (error) => {

        //  console.log("une erreur est survenu", error);

      }
    );
  }

  
  updateMarkersWithRessources(ressources: any) {
    
    // Créer un tableau pour stocker les localisations transformées
    console.log("ressources", ressources);

    const localisations = ressources.localisation_ressource.map((localisation: any) => {

      let data: any = {
        ressource: ressources.titre, 
        departement: localisation.arrondissement.commune.departement.libelle, 
        description: ressources.perspective, 
        type_ressource: ressources.type_ressource.libelle,
        logo: ressources.icone,
        type: "ressource",
        communes: localisation.arrondissement.commune.departement.libelle,
        localite: localisation.nom_localite,
        resume: localisation.resume_recherche,    
        rapport_recherche: localisation.rapport_recherche,
      };

      return {
        lat: localisation.latitude, 
        lng: localisation.longitude,
        type: "ressource",
        data: data
      };
    });
  
    // Met à jour les marqueurs via le service (à adapter en fonction de votre service)
    this.markerDataService.updateMarkers(localisations);
  }
  
  returnToSource(){
    if (this.source === "accueil") {
      this.router.navigate(['/']);
    }
    if (this.source ==="recherches") {
      this.router.navigate(['/recherches']);
    }
  }
}
