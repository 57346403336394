import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PostLoginComponent } from './account/auth/post-login/post-login.component';
import { AccessUserProjetComponent } from './account/auth/access-user-projet/access-user-projet.component';

;
;

const routes: Routes = [
   { path: 'projet', component: AccessUserProjetComponent }, 
  //   { path: 'dashboard-recherche', component: DashboardComponentRecherche }, 
    
  /// deuxieme menu


  //   {
  //     path: 'recherche/ressource',
  //     component: RessourcesComponent
  //   },

  //   {
  //     path: 'recherche/centre',
  //     component: CentreComponent
  //   },

  //   { 
  //     path: 'recherche/localisation',
  //     component: LocalisationComponent
 //   },
    //end
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
