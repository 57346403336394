import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgrammeComponent } from './plananalytique/programs/programme/programme.component';
import { ActionComponent } from './plananalytique/actions/action/action.component';
import { ProjetIndicateurComponent } from './plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { IndicateurComponent } from './plananalytique/indicateurs/indicateur/indicateur.component';
import { DirectionComponent } from './plananalytique/directions/direction/direction.component';
import { ServiceComponent } from './plananalytique/services/service/service.component';
import { GestionAccesComponent } from './plananalytique/gestion/gestion-acces/gestion-acces.component';
import { ImportationPtaComponent } from './planification/importation-pta/importation-pta.component';
import { ValidationPtaComponent } from './planification/validation/validation-pta/validation-pta.component';
import { RevueBudgetaireComponent } from './planification/revue-budgetaire/revue-budgetaire.component';
import { CibleIndicateursComponent } from './planification/cible-indicateurs/cible-indicateurs.component';
import { ActiviteHorsPtaComponent } from './planification/activite-hors-pta/activite-hors-pta.component';
import { TacheComponent } from './execution/taches/tache/tache.component';
import { CartographieComponent } from './execution/cartographie/cartographie.component';
import { RealisationIndicateursComponent } from './execution/realisation-indicateurs/realisation-indicateurs.component';
import { EngagementComponent } from './execution/engagements/engagement/engagement.component';
import { OrdonnancementComponent } from './execution/ordonnancements/ordonnancement/ordonnancement.component';
import { TacheEditionComponent } from './edition/tache-edition/tache-edition.component';
import { TepActivitesComponent } from './edition/tep-activites/tep-activites.component';
import { TepSousActivitesComponent } from './edition/tep-sous-activites/tep-sous-activites.component';
import { TepProjetComponent } from './edition/tep-projet/tep-projet.component';
import { TepActionComponent } from './edition/tep-action/tep-action.component';
import { TepProgrammmeComponent } from './edition/tep-programmme/tep-programmme.component';
import { TepGlobalObgrmComponent } from './edition/tep-global-obgrm/tep-global-obgrm.component';
import { TableaudBordProjetComponent } from './edition/tableaud-bord-projet/tableaud-bord-projet.component';
import { TableaudBordIndicateurComponent } from './edition/tableaud-bord-indicateur/tableaud-bord-indicateur.component';
import { BilanPtaComponent } from './edition/bilan-pta/bilan-pta.component';
import { RapportPerformanceComponent } from './edition/rapport-performance/rapport-performance.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailProjetIndicateurComponent } from './plananalytique/projet/detail-projet-indicateur/detail-projet-indicateur.component';
import { AddProjetToActiviteComponent } from './planification/add-projet-to-activite/add-projet-to-activite.component';
import { AddSousActivitToActiviteComponent } from './planification/add-sous-activit-to-activite/add-sous-activit-to-activite.component';
import { AddTacheSousActiviteComponent } from './planification/add-tache-sous-activite/add-tache-sous-activite.component';
import { ProjetdirectionComponent } from './plananalytique/projetdirection/projetdirection.component';
import { PlanificationComponent } from './plananalytique/indicateurs/planification/planification.component';
import { GraphiqueComponent } from './edition/graphique/graphique.component';
import { TauxExecutionComponent } from './edition/taux-execution/taux-execution.component';
import { IndicateurCleComponent } from './edition/indicateur-cle/indicateur-cle.component';

const routes: Routes = [


  { path: 'tableaux-de-bord', component: DashboardComponent },
  // plan
  {
    path: 'plan/programme',
    component: ProgrammeComponent
  },

  {
    path: 'plan/action',
    component: ActionComponent
  },
  {
    path: 'plan/projet-lien-indicateur',
    component: ProjetIndicateurComponent
  },

  {
    path: 'plan/indicateur',
    component: IndicateurComponent
  },
  {
    path: 'plan/planification-indicateur',
    component: PlanificationComponent
  },

  {
    path: 'plan/detail-projet',
    component: DetailProjetIndicateurComponent
  },

  {
    path: 'plan/direction',
    component: DirectionComponent
  },
  {
    path: 'plan/projet-direction',
    component: ProjetdirectionComponent
  },


  {
    path: 'plan/service',
    component: ServiceComponent
  },

  {
    path: 'plan/gestion-acces',
    component: GestionAccesComponent
  },

  //end

  //palinificatiion

  {
    path: 'planification/importation',
    component: ImportationPtaComponent
  },

  {
    path: 'planification/validation',
    component: ValidationPtaComponent
  },

  {
    path: 'planification/projet-activite',
    component: AddProjetToActiviteComponent
  },

  
  {
    path: 'planification/activite-sous-activite',
    component: AddSousActivitToActiviteComponent
  },

  {
    path: 'planification/sous-activite-tache',
    component: AddTacheSousActiviteComponent
  },

  {
    path: 'planification/budgetaire',
    component: RevueBudgetaireComponent
  },

  {
    path: 'planification/cible-indicateurs',
    component: CibleIndicateursComponent
  },

  {
    path: 'planification/activite-hors-pta',
    component: ActiviteHorsPtaComponent
  },
  //end
  //execution

  {
    path: 'execution/tâche',
    component: TacheComponent
  },
  {
    path: 'execution/cartographie',
    component: CartographieComponent
  },

  {
    path: 'execution/realisation',
    component: RealisationIndicateursComponent
  },

  {
    path: 'execution/engagement',
    component: EngagementComponent
  },

  {
    path: 'execution/ordonnancement',
    component: OrdonnancementComponent
  },


 


  //debut edition

  {
    path: 'edition/tache',
    component: TacheComponent
  },
  {
    path: 'edition/tep-activite',
    component: TepActivitesComponent
  },
  {
    path: 'edition/tep-sous-activite',
    component: TepSousActivitesComponent
  },

  {
    path: 'edition/tep-projet',
    component: TepProjetComponent
  },

  {
    path: 'edition/tep-action',
    component: TepActionComponent
  },

  {
    path: 'edition/tep-programme',
    component: TepProgrammmeComponent
  },

  {
    path: 'edition/tep-global-obgrm',
    component: TepGlobalObgrmComponent
  },

  {
    path: 'edition/tableaubord',
    component: TableaudBordProjetComponent
  },
  {
    path: 'edition/tableaubord-indicateur',
    component: TableaudBordIndicateurComponent
  },

  {
    path: 'edition/bilan',
    component: BilanPtaComponent
  },

  {
    path: 'edition/tep-by-projet',
    component: RapportPerformanceComponent
  },

  {
    path: 'edition/graphique',
    component: GraphiqueComponent
  },
  {
    path: 'edition/taux-execution',
    component: TauxExecutionComponent
  },
  {
    path: 'edition/indicateur-cle',
    component: IndicateurCleComponent
  },

 



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuiviRoutingModule { }
