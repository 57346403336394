import { Component, OnInit, Input } from '@angular/core';
import { ParameterService } from '../../service/params/param';
import { LocalStorageService } from '../../service/storage/localstorage.service';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {

  @Input() title: string;
  @Input() value: string;
  @Input() icon: string;
  userInfo: any;

  statData :any =[];
  statDataIcon :any =[];
  constructor(

    private paramService:ParameterService,
    private localstorage: LocalStorageService,

  ) { }

  ngOnInit(): void {
    this.userInfo = this.localstorage.getInfoUserToService();

    this.getStatsIndicateurs();
  }
  getStatsIndicateurs(){

   // console.log('appele');
    
    this.statDataIcon =  "ri-briefcase-4-line";
  
    this.paramService.getStatsIndicateur().subscribe(
      (response:any)=>{
       // console.log("la reponse du webservice",response.data);
        this.statData = response.data;
       // console.log(" this.statData", this.statData);
        
      },
      (error)=>{

       // console.log("une erreur est survenu",error);
        
      }
    )

  }

}
