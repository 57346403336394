import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-realisation',
  templateUrl: './realisation.component.html',
  styleUrl: './realisation.component.scss'
})


export class RealisationComponent {


  constructor(

    private router:Router
  ){
    
  }
  goToMission(){

    console.log("appelr");
    
    this.router.navigate(['/apropos'])
  }
}
