import { BehaviorSubject } from "rxjs";
import { MENU } from "src/app/admin/layouts/shared/horizontalnavbar/menu";
import { MenuItem } from "src/app/admin/layouts/shared/horizontalnavbar/menu.model";

export class FecthDataService {
    private ServiceCreatedSubject = new BehaviorSubject<boolean>(false);
    actionCreated$ = this.ServiceCreatedSubject.asObservable();
  
    notifySeriviceCreated() {
      this.ServiceCreatedSubject.next(true);
    }
  }

  export class MenuService {
    private menuItemsSubject = new BehaviorSubject<MenuItem[]>([]);
    public menuItems$ = this.menuItemsSubject.asObservable();
  
    constructor() {}
  
    updateMenu(userGroup: string, userDirection: string, accessExecution: any): void {
      // Filtrage des menus en fonction de l'utilisateur
      const filteredMenu = MENU.map(item => {
        // Si accessExecution est null, ne pas afficher les menus 'Exécution' et 'Edition'
        if (accessExecution === null && (item.label === 'Exécution' || item.label === 'Edition')) {
          return null; // Exclure cet item
        }
    
        if (item.roles && !item.roles.includes(userGroup)) {
          return null; // Exclure cet item
        }
    
        let newItem = { ...item }; // Créer une nouvelle référence pour l'item
    
        if (newItem.subItems) {
          newItem.subItems = newItem.subItems.filter(subItem => {
            if (userDirection === 'DAF' && subItem.rolesDirection) {
              return !subItem.rolesDirection.includes('DAF');
            }
            return true;
          }).map(subItem => ({ ...subItem })); // Créer une nouvelle référence pour chaque sous-item
        }
    
        return newItem;
      }).filter(item => item !== null); // Filtrer les items exclus
    
      this.menuItemsSubject.next(filteredMenu);
    }
    
    
  }