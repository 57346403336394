import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProjetIndicateurService } from '../../plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Constant } from 'src/app/shared/service/utils/contant';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-rapport-performance',
  templateUrl: './rapport-performance.component.html',
  styleUrl: './rapport-performance.component.scss'
})


export class RapportPerformanceComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  projetData: any = [] = [];
  annee: any;
  modalRef: NgbModalRef | null = null;
  isLoading: boolean = false;
  searchTerm: string = '';

  itemToDelete!: ProjetIndicateur;
  filteredtems: any = [];
  projetId: any
  pageSize: number = 10;
  pdfUrl: string;
  excelUrl: string;

  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  paginatedProjets: any = [];
  dateDebut: string;
  dateFin: string;
  projetItems: any = [];
  selectedProjet: any;
  directionId: any;
  showFiltre: boolean = false;
  directionItems: any = [];
  userInfo: any;

  chartData: any;
  chartOptions: any;
  constructor(
    private modalService: NgbModal,
    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private projetService: ProjetIndicateurService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    const currentYear = new Date().getFullYear();

    this.dateDebut = `${currentYear}-01-01`;
    this.dateFin = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {

    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();

    ////console.log(" this.annee", this.annee);

    //console.log("this.projetId", this.projetId);
    this.userInfo = this.localstorage.getInfoUserToService();

    //console.log("userInfo", this.userInfo);
    //console.log("this.userInfo?.data?.groupe ", this.userInfo?.data?.groupe);

    if (this.userInfo?.data?.groupe === "admin") {

      this.showFiltre = true;
      this.getDirection();
    }
    if (this.userInfo?.data?.groupe === "point_focal") {

      this.directionId = this.localstorage.getInfoUserToService()?.data?.direction_id;

      //console.log(" this.directionId ", this.directionId);

      this.getProjetByDirection(this.directionId);
    }
   // this.getStatistique();
   this.initChart();

  }

  initChart() {
    this.chartData = {
      labels: ['TEP Prévu', 'TEP Réalisé', 'Écart'],
      datasets: [
        {
          label: 'TEP Prévu',
          backgroundColor: '#42A5F5',
          data: [0, 0, 0] 
        },
        {
          label: 'TEP Réalisé',
          backgroundColor: '#66BB6A',
          data: [0, 0, 0] 
        },
        {
          label: 'Écart',
          backgroundColor: '#FF7043',
          data: [0, 0, 0] 
        }
      ]
    };
  
    this.chartOptions = {
      responsive: true,
      scales: {
        x: { // Replaced xAxes
          beginAtZero: true
        },
        y: { // Replaced yAxes
          beginAtZero: true
        }
      }
    };
  }
  
  getDirection() {

    this.paramservice.fetchDataDirection().subscribe(

      (response: any) => {

        //console.log("la reponse du webservice", response);

        this.directionItems = response.data;

      },
      (error) => {

        //console.log("une erreur est survenu", error);

      }
    )
  }
  getProjetByDirection(reference: any) {

    this.paramservice.getProjetByDirection(reference).subscribe(

      (response: any) => {

        this.projetItems = response.data;
        // this.getExercice();

        //console.log("la reponse du webservice", this.projetItems);
      },
      (error: any) => {
        //console.log("une erreur est survenu", error);

      }
    )

  }
  getStatistique() {
    //check
    if (this.dateDebut === null || this.dateDebut === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if (this.dateFin === null || this.dateFin === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
    let projectItem: any = {};
    if (this.selectedProjet === "DEFAULT") {
      projectItem = null;
    } else {
      projectItem = this.selectedProjet;
    }

    this.isLoading = true;
    this.ngxService.start();

    this.paramservice.getStatistiqueRapportPerformance(this.annee, projectItem, this.directionId, this.dateDebut, this.dateFin, this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.ngxService.stop();

        this.isLoading = false;
        this.projetData = response.data; // 
        //console.log('this.projetData', this.projetData);

        this.totalRecords = this.projetData.length;

        this.updatePaginatedProjets();
        this.updateChart(); // Mettre à jour l'histogramme avec les nouvelles données

      },
      (error) => {
        this.ngxService.stop();

        this.isLoading = false;
        //console.log('une erreur est survenue', error);
      }
    );
  }
  updateChart(): void {
    if (this.projetData.length > 0) {
      const projet = this.projetData[0]; 
  
      this.chartData = {
        labels: ['TEP Prévu', 'TEP Réalisé', 'Écart'], 
        datasets: [
          {
            label: 'TEP Prévu',
            backgroundColor: '#42A5F5',
            data: [projet.tep_prevu, 0, 0]
          },
          {
            label: 'TEP Réalisé',
            backgroundColor: '#66BB6A',
            data: [0, projet.tep_realise, 0] 
          },
          {
            label: 'Écart',
            backgroundColor: '#FF7043',
            data: [0, 0, projet.ecart] 
          }
        ]
      };
    }
  }
  

  
  onPageChangeProjets(event) {
    this.firstProjets = event.page * this.rowsProjets; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.projetData.slice(start, end);

  }
  exportToDocument(typeDoc: any) {
     //check
     if (this.dateDebut === null || this.dateDebut === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if (this.dateFin === null || this.dateFin === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
    let projectItem: any = {};
    if (this.selectedProjet === "DEFAULT") {
      projectItem = null;
    } else {
      projectItem = this.selectedProjet;
    }
    this.ngxService.start();
   

    this.paramservice.printStatistiqueRapportPerformanceAsFile(this.annee, projectItem, this.directionId, this.dateDebut, this.dateFin, typeDoc, this.userInfo?.data?.reference).subscribe(
      (response: Blob) => {
        const filename = 'Statistiques_Activite_Operationnelle.pdf';
        FileSaver.saveAs(response, filename);

        const url = URL.createObjectURL(response);
        window.open(url, '_blank');

        this.ngxService.stop();
        this.messageService.add({ severity: 'success', summary: 'Téléchargement réussi', detail: 'Le fichier PDF a été téléchargé avec succès', life: 3000 });
      },
      (error) => {
        //console.error("Une erreur est survenue", error);
        this.ngxService.stop();
      }
    );

  }







}