<div class="container-fluid">
    <h4 id="upper">Indicateurs Clés</h4>
    <div class="d-flex justify-content-end">
        <div class="me-3">
            <label class="d-inline-flex align-items-center">
                Début:
                <input type="date" class="form-control ms-2" [(ngModel)]="date_debut" name="dateDebut" required>
            </label>
        </div>
        <div class="me-3">
            <label class="d-inline-flex align-items-center">
                Fin:
                <input type="date" class="form-control ms-2" [(ngModel)]="date_fin" name="dateFin" required>
            </label>
        </div>
        <div class="me-3">
            <input (click)="getIndicateurCalcule()" type="button" value="Rechercher" class="btn btn-primary">
        </div>
    </div>

    <div class="card mt-4" *ngIf="isLoading">
        <div class="row justify-content-center">
            <div class="col-md-8 mb-4">
                <div class="card">
                    <div class="card-body text-center">
                        <h5 class="card-title">Taux d'Engagement</h5>
                        <p-chart type="bar" [data]="data" [options]="options"></p-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-4" *ngIf="!isLoading">
        <div class="row justify-content-center">

            <div class="col-md-8 mb-4">

                <div class="card"><br>
                    <h5 class="card-title text-center" >Taux d'Engagement</h5>

                    <div class="card-body text-center d-flex justify-content-center">
                        <p-skeleton width="5rem" height="20rem"  />&nbsp;
                        <p-skeleton width="5rem" height="10rem"  />&nbsp;
                        <p-skeleton width="5rem" height="15rem"  />&nbsp;
                        <p-skeleton width="5rem" height="20rem"  />&nbsp;
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>