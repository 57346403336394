<div class="breadcrumbs overlay">
    <div class="container">
        <div class="bread-inner">
            <div class="row">
                <div class="col-12">
                    <h2>Métiers</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<br>


<section class="why-choose section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                </div>
            </div>
        </div><br>
        <div class="row">
          
            <!-- Start Cards Section -->
            <div class="col-md-6">
                <div class=" choose-left row"><br>
                    <h3>Métiers</h3>

                   
                    <!-- Card 1: Recherches Minières -->
                    <div class="card col-md-12 card-custom">
                        <div class="card-body">
                            <h4 class="card-title ">RECHERCHES MINIÈRES</h4>
                            <ul>
                                <li>Géochimie sol, stream-sédiment et lithogéochimie</li>
                                <li>Cartographie géologique</li>
                                <li>Prospection géophysique</li>
                                <li>Sondage carotté</li>
                                <li>Sondage manuel par tubage de soutènement</li>
                            </ul>
                        </div>
                    </div>
                    <!-- Card 2: Laboratoire d'Analyse -->
                    <div class="card col-md-12 card-custom">
                        <div class="card-body">
                            <h4 class="card-title">LABORATOIRE D'ANALYSE</h4>
                            <ul>
                                <li>Etude pétrographique</li>
                                <li>Analyses géochimiques</li>
                                <li>Lames minces et sections polies</li>
                            </ul>
                        </div>
                    </div>
                    <!-- Card 3: Développement Minier -->
                    <div class="card col-md-12 card-custom">
                        <div class="card-body">
                            <h4 class="card-title">DÉVELOPPEMENT MINIER</h4>
                            <ul>
                                <li>Production pilote des matériaux locaux de construction</li>
                                <li>Épreuves des équipements sous pression</li>
                                <li>Poinçonnage des bijoux en or et contrôle des pierres précieuses et fines</li>
                                <li>Inspection des établissements classés dangereux</li>
                                <li>Prestations de service dans le cadre de la gestion des substances explosives à usage
                                    civil</li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start">
                        <a (click)="goToMission()" href="javascript: void(0);" 
                           class="btn btn-primary text-white" 
                           style="padding: 8px; font-size: 15px;">Réalisation</a>
                    </div>
                </div>
            </div>
            <!-- End Cards Section -->
             
            <div class="col-md-6 d-flex justify-content-center  mt-2">
                <iframe width="660" height="315" src="https://www.youtube.com/embed/-2FVYNxzkVI?si=DiNjlp0NCaGidfJM"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
      
    </div>
  
</section>
<br>