<div class="topnav" *ngIf="acces?.code === 'suivie'">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
      <div class="collapse navbar-collapse justify-content-center" id="topnav-menu-content">
        <ul class="navbar-nav">
          <li *ngFor="let item of filteredMenuItems; let i = $index" class="nav-item" [ngClass]="{'dropdown': hasItems(item)}">
            <a style="font-size: 16px; text-transform: uppercase; color: #E71E25; font-weight: bold;" class="nav-link" [ngClass]="{'dropdown-toggle': hasItems(item), 'arrow-none': true}" href="javascript: void(0);" [routerLink]="item.link" role="button" (click)="onMenuClick($event)">
              <i class="{{ item.icon }} me-2"></i>{{ item.label | translate }}
            </a>
            <div *ngIf="hasItems(item)" class="dropdown-menu row" aria-labelledby="topnav-dashboard" id="navmenu">
              <div *ngFor="let subitem of item.subItems; let j = $index">
                <div *ngIf="hasItems(subitem)" class="dropdown">
                  <a style="color: gray; font-weight: 500;text-transform: uppercase;" class="dropdown-item" (click)="onMenuClick($event)">{{ subitem.label | translate }}
                    <div class="arrow-down"></div>
                  </a>
                  <div class="dropdown-menu">
                    <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                      <a style="color: gray; font-weight: 500; text-transform: uppercase;" class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link" routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                    </ng-template>
                  </div>
                </div>
                <a *ngIf="!hasItems(subitem)" style="font-weight: 500; text-transform: uppercase;" class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link" routerLinkActive="active">{{ subitem.label | translate }}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>


<div class="topnav" *ngIf="acces?.code === 'recherche-valorisation'">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
      <div class="collapse navbar-collapse justify-content-center" id="topnav-menu-content">
        <ul class="navbar-nav">
          <li *ngFor="let item of menuItems; let i = $index" class="nav-item" [ngClass]="{'dropdown': hasItems(item)}">
            <a style="font-size: 16px;text-transform: uppercase; color: #E71E25; font-weight: bold;" class="nav-link" [ngClass]="{'dropdown-toggle': hasItems(item), 'arrow-none': true}" href="javascript: void(0);" [routerLink]="item.link" role="button" (click)="onMenuClick($event)">
              <i class="{{ item.icon }} me-2"></i>{{ item.label | translate }}
              <!-- <div *ngIf="hasItems(item) && i !== 0" class="arrow-down"></div> -->
            </a>
            <div *ngIf="hasItems(item) && i !== 0" class="dropdown-menu row" aria-labelledby="topnav-dashboard" id="navmenu">
              <div *ngFor="let subitem of item.subItems; let j = $index">
                <div *ngIf="hasItems(subitem)" class="dropdown">
                  <a style="color: gray; font-weight: 500; text-transform: uppercase;" class="dropdown-item" (click)="onMenuClick($event)">{{ subitem.label | translate }}
                    <div class="arrow-down"></div>
                  </a>
                  <div class="dropdown-menu">
                    <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                      <a style="color: gray; font-weight: 500; text-transform: uppercase;" class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link" routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                    </ng-template>
                  </div>
                </div>
                <a *ngIf="!hasItems(subitem)" style="color: rgb(68, 57, 57); font-weight: 500;" class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link" routerLinkActive="active">{{ subitem.label | translate }}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
