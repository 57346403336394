import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Service } from 'src/app/shared/models/service';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrl: './edit-service.component.scss'
})


export class EditServiceComponent {

  item: Service= new Service()

  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService

  ) { }


  ngOninit(){

    this.item = this.localstorage.getService();

  }

  close(){

    this.modalService.dismissAll();
  }


  Edit(){

    const item =this.localstorage.getService();
    this.paramService.editService(item.id,this.item).subscribe(
      (response:any)=>{
        //console.log("la reponse du webservice",response);
      },
      (error:any)=>{
        //console.log("une erreur est survenu",error);
        
      }
    )
  }
}