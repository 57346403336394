import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProgrammeService } from '../../../plananalytique/programs/programme/programme.component';

@Component({
  selector: 'app-details-engagement',
  templateUrl: './details-engagement.component.html',
  styleUrl: './details-engagement.component.scss'
})
export class DetailsEngagementComponent {


  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService,
    private programmeService:ProgrammeService,
    private messageService:MessageService
  ) { }


  ngOnInit(){
  }

 /*  close(){

    this.modalService.dismissAll();
  }

  engagementData: Engagement= new Engagement()

  EditProgramme(){

    console.log("appeler");
    
    const item =this.localstorage.getProgrames();
    this.paramService.editPrograme(item.id,this.engagementData).subscribe(
      (response:any)=>{

        this.programmeService.notifyProgrammeCreated();
        console.log("la reponse du webservice",response);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.message });

        this.modalService.dismissAll();
      },
      (error:any)=>{
        console.log("une erreur est survenu",error);
        this.programmeService.notifyProgrammeCreated();
        this.modalService.dismissAll();


        
      }
    )
  } */
}