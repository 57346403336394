import { Component } from '@angular/core';

@Component({
  selector: 'app-tache-edition',
  templateUrl: './tache-edition.component.html',
  styleUrl: './tache-edition.component.scss'
})
export class TacheEditionComponent {

}
