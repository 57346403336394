<div class="breadcrumbs overlay">
    <div class="container">
        <div class="bread-inner">
            <div class="row">
                <div class="col-12">
                    <h2>Métiers</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<br>


<section class="why-choose section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                </div>
            </div>
        </div><br>
        <div class="row">

            <div class="row mt-4">
              <div class="col-md-6">
                  <div class="card card-custom h-100">
                      <div class="card-body">
                        <h4 class="card-title" style="color: #415cc0;">RECHERCHES MINIÈRES</h4>
                        <ul>
                            <li>Géochimie sol, stream-sédiment et lithogéochimie</li>
                            <li>Cartographie géologique</li>
                            <li>Prospection géophysique</li>
                            <li>Sondage carotté</li>
                            <li>Sondage manuel par tubage de soutènement</li>
                        </ul>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="card card-custom h-100">
                      <div class="card-body">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/-2FVYNxzkVI?si=DiNjlp0NCaGidfJM"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                    </iframe>
                      </div>
                  </div>
              </div>
          </div>


            <!-- Deuxième ligne : Deux cartes côte à côte -->
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="card card-custom h-100">
                        <div class="card-body">
                            <h4 class="card-title" style="color: #415cc0;">LABORATOIRE D'ANALYSE</h4>
                            <ul>
                                <li>Étude pétrographique</li>
                                <li>Analyses géochimiques</li>
                                <li>Lames minces et sections polies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-custom h-100">
                        <div class="card-body">
                            <h4 class="card-title" style="color: #415cc0;">DÉVELOPPEMENT MINIER</h4>
                            <ul>
                                <li>Production pilote des matériaux locaux de construction</li>
                                <li>Poinçonnage des bijoux en or et contrôle des pierres précieuses et fines</li>
                                <li>Inspections des établissements classés dangereux, insalubres ou incommodes</li>
                                <li>Prestations de service dans le cadre de la gestion des substances explosives à usage civil</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bouton en dessous -->
            <div class="text-center mt-3">
                <a (click)="goToMission()" href="javascript:void(0);" class="btn btn-primary text-white" style="padding: 8px; font-size: 15px;">Réalisations</a>
            </div>



        </div>

        <div class="container-fluid blog  mt-4">
          <div class="container py-4">
            <h4 class="card-title text-uppercase mb-4">Epreuve et Réépreuve des équipements sous pression</h4>
              <div class="row g-4 justify-content-center">

                  <div class="col-md-6 col-lg-6 col-xl-4 wow fadeInUp">
                      <div class="blog-item rounded h-100 d-flex flex-column">
                          <div class="blog-img">
                              <img src="../../../../../assets/images/Cuvegaz.jpeg" class="img-fluid w-100" alt="Image"
                                  style="height: 250px; object-fit: cover;">
                          </div>
                          <div class="blog-centent p-4 d-flex flex-column text-center text-uppercase flex-grow-1">
                              <a href="#" class="h4" id="gras" style="font-size: 20px;">Cuve</a>

                          </div>
                      </div>

                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-4 wow fadeInUp">
                    <div class="blog-item rounded h-100 d-flex flex-column">
                        <div class="blog-img">
                            <img src="./../../../../../assets/images/Ballon d'air comprime.jpeg" class="img-fluid w-100" alt="Image"
                                style="height: 250px; object-fit: cover;">
                        </div>
                        <div class="blog-centent p-4 d-flex flex-column text-center text-uppercase flex-grow-1">
                            <a href="#" class="h4" id="gras" style="font-size: 20px;">Ballons d'air comprimé</a>

                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-6 col-xl-4 wow fadeInUp">
                  <div class="blog-item rounded h-100 d-flex flex-column">
                      <div class="blog-img">
                          <img src="./../../../../../assets/images/Bouteilles de gaz.jpeg" class="img-fluid w-100" alt="Image"
                              style="height: 250px; object-fit: cover;">
                      </div>
                      <div class="blog-centent p-4 d-flex flex-column text-center text-uppercase flex-grow-1">
                          <a href="#" class="h4" id="gras" style="font-size: 20px;">Bouteilles de gaz</a>

                      </div>
                  </div>

              </div>
              <div class="col-md-6 col-lg-6 col-xl-4 wow fadeInUp">
                <div class="blog-item rounded h-100 d-flex flex-column">
                    <div class="blog-img">
                        <img src="./../../../../../assets/images/Inspection e¦ütablissement classe¦ü.jpeg" class="img-fluid w-100" alt="Image"
                            style="height: 250px; object-fit: cover;">
                    </div>
                    <div class="blog-centent p-4 d-flex flex-column  text-center text-uppercase flex-grow-1">
                        <a href="#" class="h4" id="gras" style="font-size: 20px;">Flexibles</a>

                    </div>
                </div>

            </div>
            <div class="col-md-6 col-lg-6 col-xl-4 wow fadeInUp">
              <div class="blog-item rounded h-100 d-flex flex-column">
                  <div class="blog-img">
                      <img src="./../../../../../assets/images/chaud.jpg" class="img-fluid w-100" alt="Image"
                          style="height: 250px; object-fit: cover;">
                  </div>
                  <div class="blog-centent p-4 d-flex flex-column text-center text-uppercase flex-grow-1">
                      <a href="#" class="h4" id="gras" style="font-size: 20px;">Chaudières</a>

                  </div>
              </div>

          </div>
          <div class="col-md-6 col-lg-6 col-xl-4 wow fadeInUp">
            <div class="blog-item rounded h-100 d-flex flex-column">
                <div class="blog-img">
                    <img src="./../../../../../assets/images/camion_citerne.jpg" class="img-fluid w-100" alt="Image"
                        style="height: 250px; object-fit: cover;">
                </div>
                <div class="blog-centent p-4 d-flex flex-column text-center text-uppercase   flex-grow-1">
                    <a href="#" class="h4" id="gras" style="font-size: 20px;">Camions citernes</a>

                </div>
            </div>

        </div>
              </div>
          </div>
      </div>

    </div>

</section>
<br>
