<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Modifier Un Programme</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="name">Code</label>
                    <input [(ngModel)]="programmeData.code" name="code" type="text" class="form-control" id="name" placeholder=" un code">
    
                </div>
                <div class="form-group  col-md-6">
                    <label for="name">Libelle</label>
                    <input [(ngModel)]="programmeData.libelle" name="libelle" type="text" class="form-control" id="name" placeholder=" libelle ">
    
                </div>
            </div><br>
           

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="email">Charge de Programme</label>
                    <input [(ngModel)]="programmeData.chargedeprogramme" name="chargedeprogramme" class="form-control" id="email" placeholder=" Charge de Programme">
    
                </div>
    
                <div class="form-group col-md-6">
                    <label>Poids </label>
                    <input [(ngModel)]="programmeData.poids" name="poids" type="number" class="form-control" placeholder=" un Poids" minlength="10">
    
                </div>
            </div><br>
           

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label>Objectif gobal </label>
                    <input [(ngModel)]="programmeData.objectif_global" name="objectif_global" type="text" class="form-control" placeholder=" Objectif gobal ">
    
                </div>

                <div class="form-group col-md-6">
                    <label>observations </label>
                    <input [(ngModel)]="programmeData.observations" name="observations" type="text" class="form-control" placeholder=" Objectif gobal ">
    
                </div>
            </div>
           <br>

            

            <div class="text-end">
                <button  (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times" style="color: white"></i>Annuler</button> &nbsp;
                <button (click)="EditProgramme()" type="submit" class="btn btn-success"> <i class="pi pi-save" style="color: white"></i> Enregistrer</button>

            </div>
        </form>
    </div>
</div>

<p-toast />
<ngx-ui-loader></ngx-ui-loader>
