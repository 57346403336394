<div>
  <div class="container-fluid p-0">
    <div class="row g-0">
      <div class="col-lg-4">
        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">

                    <div>
                      <a routerLink="" class="authentication-logo"><img src="../../../../../assets/img/logo-obgrm.png"
                          height="60" alt="logo"></a>
                    </div>

                    <h4 class="font-size-18 mt-4">Bienvenue!</h4>
                    <p class="text-muted">Connectez-vous pour continuer</p>
                  </div>

                  <div class="p-2 mt-5">
                    <form class="form-horizontal">
                      <div class="form-group auth-form-group-custom mb-4">
                        <i class="ri-user-2-line auti-custom-input-icon"></i>
                        <label for="email">Identifiant</label>
                        <input [(ngModel)]="item.email_address" type="email" name="email" class="form-control"
                          maxlength="30" id="email" placeholder="Adresse email ">

                      </div>

                      <div class="form-group auth-form-group-custom mb-4 position-relative">
                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                        <label for="password">Mot de passe</label>
                        <input [(ngModel)]="item.password" [type]="showPassword ? 'text' : 'password'" name="password"
                          class="form-control" maxlength="30" id="password" placeholder="Mot de passe" />
                        <button style="border-style: none;" type="button"
                          class="btn btn-outline-secondary btn-sm position-absolute toggle-password-btn"
                          (click)="togglePasswordVisibility()">
                          <i [ngClass]="showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'"></i>
                        </button>
                      </div>

                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customControlInline">
                        <label class="form-check-label" for="customControlInline">Se souvenir de moi</label>
                      </div>
                      <div class="mt-4 text-center position-relative">
                        <button (click)="onSubmit()" class="btn btn-primary w-md waves-effect waves-light" type="submit"
                          [disabled]="loading">
                          <span *ngIf="!loading">Se connecter</span>
                          <div class="loader text-center" *ngIf="loading"></div>
                        </button>
                      </div>




                      <div class="mt-4 text-center">
                        <a routerLink="/reset-password" class="text-muted"><i class="mdi mdi-lock me-1"></i>
                          Mot de passe oublié?</a>
                      </div>
                      <div class="mt-4 text-center">
                        <a routerLink="/" class="text-muted"><i class="pi pi-arrow-circle-left
                          " style="font-size: 0.9rem"></i> Retour</a>
                      </div>
                    </form>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="authentication-bg">
          <div class="bg-overlay"></div>
        </div>
      </div>
    </div>
  </div>
  <p-toast position="bottom-left" />

</div>
<!-- end page -->