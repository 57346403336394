import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { PagesModule } from './admin/pages.module';
import { PublicHeaderComponent } from './public/public-header/public-header.component';
import { PublicLayoutComponent } from './public/public-layout/public-layout.component';
import { PublicFooterComponent } from './public/public-footer/public-footer.component';
import { PublicModule } from './public/public.module';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { FakeBackendInterceptor } from './admin/core/helpers/fake-backend';
import { LayoutsModule } from './admin/layouts/layouts.module';
import { JwtInterceptor } from './admin/core/helpers/jwt.interceptor';
import { ErrorInterceptor } from './admin/core/helpers/error.interceptor';
import { PublicDashboardComponent } from './public/public-dashboard/public-dashboard.component';
import { OperationService } from './shared/service/operation/operation.service';
import { SuiviModule } from './admin/pages/suivi/suivi.module';
import { CategorieRessourceComponent } from './public/page/categorie-ressource/categorie-ressource.component';
import { PaginatorModule } from 'primeng/paginator';
import { ValorisationComponent } from './public/page/valorisation/valorisation.component';
import { MapsComponent } from './maps/maps.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { RechercheComponent } from './public/page/recherche/recherche.component';
import { SkeletonModule } from 'primeng/skeleton';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TabViewModule } from 'primeng/tabview';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MarkerDataService } from './shared/service/makerservice/markerDataService';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from './shared/shared.module';
import { FormatMoneyPipe } from './shared/pipe/format-money/format-money.pipe';
import { MenuService } from './shared/service/fetchservice/fetchservice';
import { RouterModule } from '@angular/router';
import { ResourceDetailComponent } from './public/page/resource-detail/resource-detail.component';
import { CentreDetailComponent } from './public/page/centre-detail/centre-detail.component';
import { DashboardComponentRecherche } from './admin/pages/recherche/dashboard/dashboard.component';
import { LimitToPipe } from './shared/pipe/limit-to/limit-to.pipe';
import { LocalisationComponent } from './admin/pages/recherche/ressource/localisation/localisation.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthInterceptor } from './shared/service/intercepteur/intercepteur';
import { ProfilComponent } from './admin/account/auth/profil/profil/profil.component';
import { DropdownModule } from 'primeng/dropdown';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PublicHeaderComponent,
    PublicLayoutComponent,
    PublicFooterComponent,
    PublicDashboardComponent,
    CategorieRessourceComponent,
    ValorisationComponent,
    MapsComponent,
    RechercheComponent,
    ResourceDetailComponent,
    CentreDetailComponent,
    DashboardComponentRecherche,
    LocalisationComponent,
  ],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule, 
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    PublicModule,
    LayoutsModule,
    FormsModule,
    MdbCarouselModule,
    GoogleMapsModule,
    LeafletModule,
    SkeletonModule,
    PaginatorModule ,
    TabViewModule,
    MdbTabsModule,
    ToastModule,
    RouterModule,
    NgxUiLoaderModule,
    ConfirmDialogModule,
    DropdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader, 
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }), 
    SuiviModule,
    SharedModule,

    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    OperationService,
    MarkerDataService,
    // FormatMoneyPipe,
    // LimitToPipe,
    MenuService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { } 
