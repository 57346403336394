import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MarkerDataService } from 'src/app/shared/service/makerservice/markerDataService';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-public-dashboard',
  templateUrl: './public-dashboard.component.html',
  styleUrl: './public-dashboard.component.scss'
})
export class PublicDashboardComponent {
  centreItems: any = [];
  ressoureceItems1: any = [];
  ressoureceItems2: any = [];
  ressoureceItems3: any = [];

  typeResoureceId1 = "minerales";
  typeResoureceId2 = "orientales";
  typeResoureceId3 = "fines";
  markers: { lat: number, lng: number, type: any, data: any, }[] = [];

  constructor(

    private router: Router,
    private paramService: ParameterService,
    private localStorage: LocalStorageService,
    private markerDataService: MarkerDataService,
  ) {

    this.markerDataService.markers$.subscribe(markers => {
      this.markers = markers;
    });
  }


  ngOnInit() {

    this.getCentreData();
    this.getRessourceBySubstanceMinerales();
    this.getRessourceByornementales();
    this.getRessourceByoFine();
  //  this.getRessource();

  }

  goToResourceDetail(reference: any): void {
    this.localStorage.saveAccueil("accueil")
    this.router.navigate(['/resource', reference]);

    this.localStorage.SaveResourceDetail(reference)


  }


  goToCategorieRessource(reference: any): void {
    this.router.navigate(['/resource/categorie', reference]);

    this.localStorage.SaveResourceDetailByCategorie(reference)
    this.localStorage.saveAccueilCentre("accueil")

  }

  goToCentreDetail(reference: string, item: any): void {
    this.router.navigate(['/centre', reference]);

    this.localStorage.saveCentreDetail(item)
    this.localStorage.saveAccueilCentre("accueil")
  }

  getCentreData() {
    this.paramService.fetchDatacentre().subscribe(
      (response: any) => {
        this.centreItems = response.data;
        this.updateMarkersWithRessources(this.centreItems);  // Met à jour les marqueurs
      },
      (error) => {
      }

    )

  }

  getRessourceBySubstanceMinerales() {
    const requestdata = {
      "type_ressource_id": "minerales"
    }

    this.paramService.getSubstanceMinerales(requestdata).subscribe(

      (response: any) => {
        this.ressoureceItems1 = response.data
      },
      (error) => {

      }
    )

  }


  getRessourceByornementales() {
    const requestdata = {
      "type_ressource_id": "orientales"
    }
    this.paramService.getSubstanceMinerales(requestdata).subscribe(

      (response: any) => {

        this.ressoureceItems2 = response.data

      },
      (error) => {
      }
    )

  }


  getRessourceByoFine() {
    const requestdata = {
      "type_ressource_id": "fines"
    }
    this.paramService.getSubstanceMinerales(requestdata).subscribe(
      (response: any) => {
        this.ressoureceItems3 = response.data
      },
      (error) => {

      }
    )

  }

  routerAbout() {
    this.router.navigate(['/apropos']);
  }

  items: any = [];


  updateMarkersWithRessources(centres: any[]) {
    
    // Transformer les centres en tableaux de localisations
    const localisations = centres.map(centre => {
      let data: any = {
        nom: centre.nom,
        description: centre.description,
        items: centre,
        type: "valorisation",
      };
      return {
        lat: centre.latitude,
        lng: centre.longitude,
        type: "valorisation",
        data: data,
      };
    });
  
    // Met à jour les marqueurs via le service
    this.markerDataService.updateMarkers(localisations);
  }
  

}
