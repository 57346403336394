export class Profil {
    user_ref: any;
    email_address: any;
    password: any;
    confirm_password: any;
    name: any;
    surname: any;
    phone: any;

    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    canSend(): boolean {

        // Vérification du nom
        // if (this.name === undefined || this.name === null || this.name.trim() === "") {
        //     this.errorMessage = "Veuillez renseigner votre nom";
        //     return false;
        // }

        // // Vérification du prénom
        // if (this.surname === undefined || this.surname === null || this.surname.trim() === "") {
        //     this.errorMessage = "Veuillez renseigner votre prénom";
        //     return false;
        // }

        // // Vérification de l'adresse email
        // if (this.email_address === undefined || this.email_address === null || this.email_address.trim() === "") {
        //     this.errorMessage = "Veuillez renseigner votre adresse email";
        //     return false;
        // }

        // // Vérification du format de l'adresse email
        // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // if (!emailPattern.test(this.email_address)) {
        //     this.errorMessage = "Veuillez entrer une adresse email valide";
        //     return false;
        // }

        // Vérification du mot de passe
        if (this.password === undefined || this.password === null || this.password.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre mot de passe";
            return false;
        }

        // Vérification de la longueur du mot de passe
        if (this.password.trim().length <= 3) {
            this.errorMessage = "Votre mot de passe est trop court.";
            return false;
        }

        // Vérification de la confirmation du mot de passe
        if (this.confirm_password === undefined || this.confirm_password !== this.password) {
            this.errorMessage = "Les mots de passe ne correspondent pas";
            return false;
        }


        // Vérification du numéro de téléphone
        const phonePattern = /^[0-9]{8}$/;
        if (!phonePattern.test(this.phone)) {
            this.errorMessage = "Veuillez entrer un numéro de téléphone valide (10 chiffres)";
            return false;
        }

        // Si toutes les vérifications sont passées
        return true;
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
