<div class="container-fluid">

  <h4 id="upper">Réalisation Indicateurs</h4>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>

          </div>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">

            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light  rwd-table">
                <tr>
                  <th>Année</th>
                  <th>Valeur</th>
                  <th>Indicateur</th>
                  <th>Projet</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr  *ngFor="let item of paginatedItems; let index = index" [ngClass]="{
                  'ecart-positive': item.ecart > 0,
                  'ecart-negative': item.ecart < 0,
                  'ecart-neutral': item.ecart === 0
                }">
                  <td id="color">{{item?.annee}}</td>
                  <td id="color">{{item?.valeur_cible}}</td>
                  <td id="color">{{item?.indicateur?.libelle}}</td>
                  <td id="color">{{item?.indicateur.projet?.libelle}}</td>

                  <td>
                    <a (click)="openRealisation(item)" style="cursor: pointer;" class="me-3 text-primary"
                      v-b-tooltip.hover title="Réaliser">
                      <i class="pi pi-arrow-circle-right" style="font-size: 1rem;color: white;"></i>
                    </a>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="isLoading === true">
          <div class="container">
            <div class="row">
              <div class="text-center">
                <h5 id="graycolor">Chargement en cours</h5>
                <div class="loader4"></div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="paginatedItems.length === 0 && isLoading === false" class="d-flex justify-content-center">
          <div class="container">
            <div class="row">
              <div class="text-center">
                <h5 id="graycolor">Aucune donnée trouvée</h5>
              </div>
            </div>
          </div>

        </div>
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
          [rowsPerPageOptions]="[10, 20, 30]">
        </p-paginator>
      </div>
    </div>
  </div>
</div>
<ng-template #realisationModal let-modal>
  <div>
    <div class="">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h5 id="upper" class="modal-title">Réalisation Indicateurs</h5>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Close click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!-- Modal Body -->
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <!-- Indicateur Information -->
              <div class="col-md-8">
                <div class="card mb-4">
                  <div class="container mb-3 text-left">
                    <h6 id="upper" class="mb-4 text-center">Informations de l'indicateur</h6>
                    <div class="row mb-3">
                      <div class="col-md-6 text-left">
                        <h6 id="dash">Année :</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <h6 id="dash"><strong>{{items.annee}}</strong></h6>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6 text-left">
                        <h6 id="dash">Valeur cible
                          :</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <h6 id="dash"><strong>{{items.valeur_cible}}</strong></h6>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6 text-left">
                        <h6 id="dash">Ecart :</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <h6 id="dash" [ngStyle]="{'color': ecartColor}"><strong>{{ ecart }}</strong></h6>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6 text-left">
                        <h6 id="dash">Indicateur :</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <h6 id="dash"><strong>{{items.indicateur?.libelle}}</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Formulaire de Planification -->
              <div class="col-md-4">
                <h5 class="mb-4 text-center">Réaliser un Indicateur</h5>
                <form>
                  <div class="row">

                    <div class="col-md-12">
                      <div class="form-group mb-3">
                        <label for="valeur_cible">Valeur réalisée</label>
                        <input type="number" class="form-control" id="valeur_realisee"
                          [(ngModel)]="planification.valeur_realisee" name="valeur_realisee"
                          (ngModelChange)="calculerEcart()">

                      </div>
                    </div>
                  </div>
                  <div class="modal-footer ">
                    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')"><i
                        class="pi pi-times" style="color: white"></i> Annuler</button>
                    <button (click)="saveRealisation()" type="button" class="btn btn-primary"> <i class="pi pi-save"
                        style="color: white"></i> Realiser</button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>

        <!-- Modal Footer -->

      </div>
    </div>
  </div>
</ng-template>



<p-confirmDialog />
<p-toast />

<ngx-ui-loader>

</ngx-ui-loader>