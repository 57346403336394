<div class="container-fluid">
    
    <h5>Cartographie</h5>
    <div class="card">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.3209995568927!2d2.4196803999999994!3d6.352473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x102354f8a0150011%3A0x99980ad48916137c!2sAve%20Jean-Paul%20II%2C%20Cotonou!5e0!3m2!1sfr!2sbj!4v1703248256079!5m2!1sfr!2sbj" 
        width="100%" height="450"  frameborder="0" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
</div>
  

