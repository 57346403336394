import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Acteurs } from 'src/app/shared/models/acteurs';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-edit-acteurs',
  templateUrl: './edit-acteurs.component.html',
  styleUrl: './edit-acteurs.component.scss'
})

export class EditActeursComponent {

  item: Acteurs = new Acteurs();
  programmeData: any = [];
  photoPreview: string | ArrayBuffer | null = null;
  departement: any = [];
  commune: any = [];
  ressource: any = [];
  selectedDepartementId: any;
  selectedCommuneId: any;
  selectedArrondissement: any;
  @ViewChild('cibleModal') modalContent: TemplateRef<any>;
  items:any;
  constructor(
    private modalService: NgbModal,
    private paramService: ParameterService,
    private messageService: MessageService,
    private FecthDataService: FecthDataService,
    private localstorage: LocalStorageService,
    private ngxservice: NgxUiLoaderService,
    private confirmationService: ConfirmationService,
  ) {
  }

  ngOnInit() {
    this.items = this.localstorage.getActeur();
    console.log("this.items",this.items.ressources);

    this.item  =this.localstorage.getActeur();

    if (this.items?.annee_creation) {
      const date = new Date(this.items.annee_creation);
      const annee = date.getFullYear();
      const mois = String(date.getMonth() + 1).padStart(2, '0'); // Ajoute 1 car les mois commencent à 0
      const jour = String(date.getDate()).padStart(2, '0');
      
      this.item.annee_creation = `${annee}-${mois}-${jour}`; // Format ISO
    }
    this.getRessource();
  }

  close() {
    this.modalService.dismissAll();
  }

 
  
  updateActeurs() {
    // if (!this.item.canSave()) {
    //   this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: this.item.getErrorMessage() });
    //   return;
    // }

    const ressourcesSelectionnees = this.ressource.filter(item => item.ressource_checked);
  
    // Assurez-vous que vous envoyez ces ressources avec l'objet `item` si nécessaire
    this.item.ressource_exploite = ressourcesSelectionnees;
    this.item.acteur_id = this.items.reference;
      this.ngxservice.start();
      this.paramService.editActeur(this.item).subscribe(
        (response: any) => {
          this.ngxservice.stop();
          this.modalService.dismissAll();
          this.messageService.add({ severity: 'success', summary: Constant.rechercheiTitle, detail: response.message });

          this.FecthDataService.notifySeriviceCreated();

        },
        (error: any) => {
          this.ngxservice.stop();
          //console.log("une erreur est survenu", error);
          // this.modalService.dismissAll();
          this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });
          this.FecthDataService.notifySeriviceCreated();
          this.ngxservice.stop();


        }
      )
   // }

  }

  getRessource() {
    this.paramService.fetchDataRessource().subscribe(
      (response: any) => {
        this.ressource = response.data;
  
        // Comparer et marquer les ressources comme sélectionnées si elles existent dans les ressources de l'acteur
        this.ressource.forEach(item => {
          // Vérifier si l'élément existe déjà dans les ressources de l'acteur récupérées du localStorage
          const matchingResource = this.items?.ressources?.find(ressource => ressource.id === item.id);
          
          // Si l'élément est trouvé, marquer la case comme cochée
          if (matchingResource) {
            item.ressource_checked = true;
          } else {
            item.ressource_checked = false;
          }
        });
      },
      (error) => {
        // Gérer les erreurs
        console.error("Une erreur est survenue", error);
      }
    );
  }
  visible: boolean = false;

  position: string = 'center';

  showDialog(position: string) {
      this.position = position;
      this.visible = true;
  }
} 