import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Programme } from 'src/app/shared/models/programme';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProgrammeService } from '../programme/programme.component';
import { MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-edit-programe',
  templateUrl: './edit-programe.component.html',
  styleUrl: './edit-programe.component.scss'
})

export class EditProgrameComponent {


  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService,
    private programmeService:ProgrammeService,
    private messageService:MessageService,
   private ngxService:NgxUiLoaderService,

  ) { }


  ngOnInit(){

    this.programmeData = this.localstorage.getProgrames();
    //console.log(" this.programmeData ", this.programmeData);
    

  }

  close(){

    this.modalService.dismissAll();
  }

  programmeData: Programme= new Programme()

  EditProgramme(){

    //console.log("appeler");
    if (this.programmeData.canSave()) {
      this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: this.programmeData.getErrorMessage() });

    }else{

      this.ngxService.start();
      const item =this.localstorage.getProgrames();
      this.paramService.editPrograme(item.reference,this.programmeData).subscribe(
        (response:any)=>{
  
          this.programmeService.notifyProgrammeCreated();
          //console.log("la reponse du webservice",response);
          this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
  
          this.modalService.dismissAll();

          this.ngxService.stop();
        },
        (error:any)=>{
          //console.log("une erreur est survenu",error);
          this.programmeService.notifyProgrammeCreated();
         // this.modalService.dismissAll();
         this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail:"Une erreur est survenue. Veuillez réessayé" });

         this.ngxService.stop();

          
        }
      )
    }
   
  }
}