import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Tache } from 'src/app/shared/models/tache';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { request } from 'http';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-detail-tep-activite-op',
  templateUrl: './detail-tep-activite-op.component.html',
  styleUrl: './detail-tep-activite-op.component.scss'
})

export class DetailTepActiviteOpComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;
  submitted: boolean;
  tacheData: any = [];
  modalRef: NgbModalRef | null = null;
  @ViewChild('tacheModal') modalContent: TemplateRef<any>;
  activiteOp:any = {};
  paginatedItems: any[] = [];
  first: number = 0;
  rows: number = 10;
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;
  fitreTache:any;


  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxloader: NgxUiLoaderService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
     this.activiteOp =  this.localstorage.getActiviteBudgetaire();
     //this.updateList();
  }


  close() {
    this.modalService.dismissAll()
  }

  onPageChange(event: any) {
    this.first = event.first ;
    this.rows = event.rows;
    this.updateList();
  }

  updateList() {
    const start = this.first;
    const end = this.first + this.rows;

    this.paginatedItems = this.activiteOp.details.slice(start, end);
  }

 

}