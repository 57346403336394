<div class="container-fluid">

  <h4 id="upper">Validation PTA</h4>


  <!-- <div style="padding-top: 10px;" class="d-flex justify-content-center align-items-center" >
    <div class="container">
      <div class="row justify-content-center">
        <div class="card p-4">
         
        </div>
      </div>
    </div>
  </div> -->
  
  
  <!-- loading area -->
 
  <!-- end loading area -->

  <div class="container" *ngIf="isLoading === false">
    <div class="card" > <!-- Augmenter la hauteur de la carte -->
      <div class="d-flex justify-content-center align-items-center h-100 p-4">
        <div class="d-flex gap-3">
          <select class="form-select" name="projet" [(ngModel)]="projet_id" id="projet">
            <option disabled value="">Sélectionnez un projet</option>
            <option *ngFor="let item of pojetItems" [ngValue]="item">{{item.libelle}}</option>
          </select>
          <button (click)="getNonValidatedData()" class="btn btn-success">Valider</button>
        </div>
      </div>
      <div *ngIf="showTextCard === true" class="d-flex flex-column justify-content-center align-items-center text-center loading_area">
        <i class="pi pi-check-circle" style="font-size: 2rem; color: green;"></i>
        <h4 style="color: green;" class="mt-5">Les données ont validées avec succès</h4>
      </div>
    </div>
  </div>
    <!-- zone affichage du compte des operations en attente de validation -->
    <div class="row justify-content-center" *ngIf="isLoading ===false">

      <!-- sous activites area -->
      <div *ngIf="showTextCardSousactivite === true" class="col-md-4">
        <div class="card custom-card">
          <div class="card-header custom-card-header">
            <h5 class="">Vous avez des sous-activités en attente de validation</h5>
          </div>
          <div class="card-body">
            <div class="custom-card-number">{{validationData?.sous_activites?.length}}</div>
            <button (click)="openDetailsSousActivites()" class="btn btn-success custom-card-button">Détails</button>
          </div>
        </div>
      </div>
      <!-- end sous activites area -->
  
      <!-- activites area -->
      <div  *ngIf="showTextCardActivite === true" class="col-md-4">
        <div class="card custom-card">
          <div class="card-header custom-card-header">
            <h5 class="">Vous avez des activités en attente de validation</h5>
          </div>
          <div class="card-body">
            <div class="custom-card-number">{{validationData?.activites?.length}}</div>
            <button (click)="openDetailsActivites()" class="btn btn-success custom-card-button">Détails</button>
          </div>
        </div>
      </div>
      <!-- end activites area -->
  
      <!-- projets area -->
      <div  *ngIf="showTextCardProjet === true" class="col-md-4">
        <div class="card custom-card">
          <div class="card-header custom-card-header">
            <h5 class="">Vous avez des projets en attente de validation</h5>
          </div>
          <div class="card-body">
            <div class="custom-card-number">{{validationData?.projets?.length}}</div>
            <button (click)="openDetailsProjets()" class="btn btn-success custom-card-button">Détails</button>
          </div>
        </div>
      </div>
      <!-- end projets area -->
  
    </div>
    <!-- end zone -->
  
    <div class="d-flex justify-content-center" *ngIf="isLoading ===true">
      <div class="container">
        <div class="row">
          <div class="text-center loading_area mt-10">
            <img class="sized_image" src="/assets/images/loading_data.png">
            <h4 class="mt-4">Veuillez patienter...</h4>
            <div class="loader4"></div>
          </div>
        </div>
      </div>
    </div>
</div>


<!-- debut sous activites -->
<ng-template #sousActivitesModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Sous-activités({{validationData?.sous_activites?.length}})</h5>
    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table table-bordered table-striped">
        <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Libellé</th>
            <th>Struct. resp.</th>
            <th>Poids</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paginatedSousActivites">
            <td>{{ item.code }}</td>
            <td>{{ item.libelle }}</td>
            <td>{{ item.structure_responsable }}</td>
            <td>{{ item.total_poids }}</td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-center" *ngIf="isLoading === true">
        <div class="container">
          <div class="row">
            <div class="text-center">
              <h5 id="graycolor">Chargement en cours</h5>
              <div class="loader4"></div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="paginatedSousActivites.length === 0 && isLoading === false" class="d-flex justify-content-center">
        <div class="container">
          <div class="row">
            <div class="text-center">
              <h5 id="graycolor">Aucune donnée trouvée</h5>
            </div>
          </div>
        </div>

      </div>
      <p-paginator (onPageChange)="onPageChangeSousActivites($event)" [first]="firstSousActivites"
        [rows]="rowsSousActivites" [totalRecords]="totalRecords1" [rowsPerPageOptions]="[10, 20, 30]">
      </p-paginator>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Fermer</button>
  </div>
</ng-template>
<!-- fin sous activites -->


<!-- debut activites -->
<ng-template #activitesModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Activités({{validationData?.activites?.length}})</h5>
    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="table-responsive">
      <table class="table table-bordered table-striped">
        <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Libellé</th>
            <th>Struct. resp.</th>
            <th>Poids</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paginatedActivites">
            <td>{{ item.code }}</td>
            <td>{{ item.libelle }}</td>
            <td>{{ item.structure_responsable }}</td>
            <td>{{ item.total_poids }}</td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center" *ngIf="isLoading === true">
        <div class="container">
          <div class="row">
            <div class="text-center">
              <h5 id="graycolor">Chargement en cours</h5>
              <div class="loader4"></div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="paginatedActivites.length === 0 && isLoading === false" class="d-flex justify-content-center">
        <div class="container">
          <div class="row">
            <div class="text-center">
              <h5 id="graycolor">Aucune donnée trouvée</h5>
            </div>
          </div>
        </div>

      </div>
      <p-paginator (onPageChange)="onPageChangeActivites($event)" [first]="firstActivites" [rows]="rowsActivites"
        [totalRecords]="totalRecords2" [rowsPerPageOptions]="[10, 20, 30]">
      </p-paginator>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Fermer</button>
  </div>
</ng-template>
<!-- fin activites -->

<!-- debut projets -->
<ng-template #projetsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Projets({{validationData?.projets?.length}})</h5>
    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="table-responsive">
      <table class="table table-bordered table-striped">
        <thead class="thead-light">
          <tr>
            <th>Code</th>
            <th>Libellé</th>
            <th>Struct. resp.</th>
            <th>Poids</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paginatedProjets">
            <td>{{ item.code }}</td>
            <td>{{ item.libelle }}</td>
            <td>{{ item.structure_responsable }}</td>
            <td>{{ item.total_poids }}</td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center" *ngIf="isLoading === true">
        <div class="container">
          <div class="row">
            <div class="text-center">
              <h5 id="graycolor">Chargement en cours</h5>
              <div class="loader4"></div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="paginatedProjets.length === 0 && isLoading === false" class="d-flex justify-content-center">
        <div class="container">
          <div class="row">
            <div class="text-center">
              <h5 id="graycolor">Aucune donnée trouvée</h5>
            </div>
          </div>
        </div>

      </div>
      <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
        [totalRecords]="totalRecords3" [rowsPerPageOptions]="[10, 20, 30]">
      </p-paginator>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Fermer</button>
  </div>
</ng-template>
<!-- fin projets -->



<p-confirmDialog />
<p-toast />