export class Engagement {
    code: any;
    libelle: any;
    montant_engage: string;
    date_engagement: Date;
    pta_sous_activite_id: any;
  
    private errorMessage: string;
  
    constructor() {
      this.errorMessage = "";
    }
  
    // Get error message
    getErrorMessage(): string {
      return this.errorMessage;
    }
  
    // Check for save
    canSave(): boolean {
      // if (this.code === undefined || this.code === null || this.code.trim() === "") {
      //   this.errorMessage = "Veuillez saisir le code";
      //   return false;
      // }
  
      // if (this.libelle === undefined || this.libelle === null || this.libelle.trim() === "") {
      //   this.errorMessage = "Veuillez saisir le libellé";
      //   return false;
      // }
  
      if (this.montant_engage === undefined || this.montant_engage === null) {
        this.errorMessage = "Veuillez saisir le montant engagé";
        return false;
      }
  
      if (this.date_engagement === undefined || this.date_engagement === null) {
        this.errorMessage = "Veuillez saisir la date d'engagement";
        return false;
      }
  
      this.errorMessage = "";
      return true;
    }
  }
  