import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { request } from 'http';
import { OperationService } from 'src/app/shared/service/operation/operation.service';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-categorie-ressource',
  templateUrl: './categorie-ressource.component.html',
  styleUrl: './categorie-ressource.component.scss'
})
export class CategorieRessourceComponent {



  resource: any;
  ressorueceItems: any =[];

  paginatedItems: any[] = [];
  fiteredRessource: any[] = [];

  searchTerm: string = '';

  reference: string;
  first: number = 0;

  rows: number = 10;
  totalRecord :any
  titre:any
  ressourceId:any;
  constructor(
    private route: ActivatedRoute,
    private paramService: ParameterService,
    private localStorage : LocalStorageService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.ressourceId= this.localStorage.getRessourceDetailByCategorie();

    //console.log("ressourceId",this.ressourceId);
    
    this.reference = this.route.snapshot.paramMap.get('reference');
    this.getCategorieByTypeRessource();

    if (this.ressourceId === "minerales" ) {
      
      this.titre ="Substances minérales";
    }

    
    if (this.ressourceId === "orientales" ) {
      
      this.titre ="Pierres ornementales";
    }

    if (this.ressourceId === "fines" ) {
      
      this.titre ="Pierres fines";
    }

  }


  getCategorieByTypeRessource() {

    this.ressourceId= this.localStorage.getRessourceDetailByCategorie();

    const requestData ={
      "type_ressource_id":this.ressourceId
    }
    this.paramService.getSubstanceMinerales(requestData).subscribe(

      (response: any) => {

        this.ressorueceItems = response.data
        this.totalRecord=this.ressorueceItems.length;
        //console.log("la reponse du webservice des deresoource", response);

        //console.log("this.ressorueceItems",  this.ressorueceItems);
        
        this.updateRessourceStats();
      },
      (error) => {

        //console.log("une erreur est survenu", error);

      }
    )

}

goToResourceDetail(reference: string): void {
  this.router.navigate(['suivi/resource', reference]);

  this.localStorage.SaveResourceDetail(reference)


}



onPageChange(event: any) {
  this.first = event.first;
  this.rows = event.rows;
  this.updateRessourceStats();
}

updateRessourceStats() {
  const start = this.first;
  const end = this.first + this.rows;
  this.fiteredRessource = this.ressorueceItems.slice(start, end);

  //console.log("this.updateRessourceStats",this.fiteredRessource);
  
}

fiterRessource() {
  const lowerSearchTerm = this.searchTerm.toLowerCase();
  this.fiteredRessource = this.ressorueceItems.filter((item: any) => {
      // Vérifier que item et ses propriétés existent avant d'accéder à toLowerCase()
      const matchesTerm = 
          (item.perspective && item.perspective.toLowerCase().includes(lowerSearchTerm)) || 
          (item.titre && item.titre.toLowerCase().includes(lowerSearchTerm)) || 
          (item.type_ressource && item.type_ressource.libelle && item.type_ressource.libelle.toLowerCase().includes(lowerSearchTerm)) || 
          (item.code && item.code.toLowerCase().includes(lowerSearchTerm));

          //console.log("this.fiteredRessource",this.fiteredRessource);
          
      return matchesTerm;
  });
}


}