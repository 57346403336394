export class User {
    email_address: string;
    password: string;
   

    private errorMessage: string;

    constructor() {

        this.errorMessage = "";
    }

    canSend(): boolean {
        // Vérification de l'adresse email
        if (this.email_address === undefined || this.email_address === null || this.email_address.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre adresse email.";
            return false;
        }
    
        // Vérification du format de l'adresse email
        // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // if (!emailPattern.test(this.email_address.trim())) {
        //     this.errorMessage = "Veuillez saisir une adresse email valide.";
        //     return false;
        // }
    
        // Vérification du mot de passe
        if (this.password === undefined || this.password === null || this.password.trim() === "") {
            this.errorMessage = "Veuillez renseigner votre mot de passe.";
            return false;
        }
    
        // Vérification de la longueur du mot de passe
        if (this.password.trim().length <= 3) {
            this.errorMessage = "Votre mot de passe est trop court.";
            return false;
        }
    
        // Toutes les vérifications sont passées
        return true;
    }
    
    

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
