export class Acteurs {
    nom: any;
    prenoms: any;
    ifu: any;
    rrcm: any;
    annee_creation: any;
    description: any;
    secteur_activite: any;
    numero_autorisation_exploitation: any;
    ressource_exploite: any;
    ressource_checked: any;
    acteur_id: any;

    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }

    canSave(): boolean {
        if (!this.nom || this.nom.trim() === "") {
            this.errorMessage = "Le nom est obligatoire.";
            return false;
        }

        // if (!this.prenoms || this.prenoms.trim() === "") {
        //     this.errorMessage = "Les prénoms sont obligatoires.";
        //     return false;
        // }

        if (!this.ifu || this.ifu.trim() === "") {
            this.errorMessage = "L'IFU est obligatoire.";
            return false;
        }

        if (!this.annee_creation) {
            this.errorMessage = "L'année de création est obligatoire et doit être un nombre valide.";
            return false;
        }

        if (!this.secteur_activite || this.secteur_activite.trim() === "") {
            this.errorMessage = "Le secteur d'activité est obligatoire.";
            return false;
        }

        // Si des ressources doivent être sélectionnées
        // if (this.ressource_checked && this.ressource_checked.length === 0) {
        //     this.errorMessage = "Au moins une ressource exploitée doit être sélectionnée.";
        //     return false;
        // }

        // Si tout est valide
        this.errorMessage = "";
        return true;
    }
}

