import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MarkerDataService } from 'src/app/shared/service/makerservice/markerDataService';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-valorisation',
  templateUrl: './valorisation.component.html',
  styleUrl: './valorisation.component.scss'
})
export class ValorisationComponent {


  constructor(
    private router:Router,
    private paramService:ParameterService,
    private markerDataService: MarkerDataService,
    private localStorage: LocalStorageService,
  ){
    this.markerDataService.markers$.subscribe(markers => {
      this.markers = markers;
    });
  }

  centreItems :any[]=[];
  markers: { lat: number, lng: number, type: any, data: any }[] = [];

  goToCentreDetail(reference: string): void {
    this.router.navigate(['/centre', reference]);
    this.localStorage.saveAccueilCentre("centredetail")
  }

  ngOnInit(){
    this.getCentreData();
  
  }

  getCentreData() {


    this.paramService.fetchDatacentre().subscribe(

      (response: any) => {

        //console.log("la reposne du webservice", response.data);

        this.centreItems = response.data;
        this.updateMarkersWithRessources(this.centreItems);  // Met à jour les marqueurs

        //console.log(" this.centreItems", this.centreItems);


      },
      (error) => {

        //console.log("une erreur est survenu");

      }

    )

  }
  updateMarkersWithRessources(centres: any[]) {
    console.log("ressources", centres);
    
    // Transformer les centres en tableaux de localisations
    const localisations = centres.map(centre => {
      let data: any = {
        nom: centre.nom,
        items: centre,
        description: centre.description,
        type: "valorisation",
      };

      return {
        lat: centre.latitude,
        lng: centre.longitude,
        type: "valorisation",
        data: data
      };
    });
  
    // Met à jour les marqueurs via le service
    this.markerDataService.updateMarkers(localisations);
  }
}
