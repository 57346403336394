import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiModule } from './ui/ui.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ExecutionModule } from '../admin/pages/suivi/execution/execution.module';
import { SimplebarAngularModule } from 'simplebar-angular';
import { WidgetModule } from './widget/widget.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';
import { PagesRoutingModule } from '../admin/pages-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatMoneyPipe } from './pipe/format-money/format-money.pipe';
import { MapsComponent } from '../maps/maps.component';
import { LimitToPipe } from './pipe/limit-to/limit-to.pipe';

@NgModule({
  declarations: [
    
    FormatMoneyPipe,
    LimitToPipe,
    
   ],
  imports: [ 
    
   
  ], 
  exports: [
    FormatMoneyPipe,
    LimitToPipe,
  ]
}) 
export class SharedModule { }
