import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { IndicateurProjet } from 'src/app/shared/models/projet-indicateur';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { AddIndicateurComponent } from '../../indicateurs/add-indicateur/add-indicateur.component';
import { EditIndicateurComponent } from '../../indicateurs/edit-indicateur/edit-indicateur.component';
import { IndicateurServiceService } from '../../indicateurs/indicateur/indicateur.component';

@Component({
  selector: 'app-detail-projet-indicateur',
  templateUrl: './detail-projet-indicateur.component.html',
  styleUrl: './detail-projet-indicateur.component.scss'
})

export class DetailProjetIndicateurComponent  {

  // breadcrumb items
  breadCrumbItems: Array<{}>;
  submitted: boolean;
  indicateurData: any =[];
  modalRef: NgbModalRef | null = null;
  itemToDelete!: any;
  searchTerm: string;
  filteredtems: any = [];
  pageSize: number = 10; 

  item: ProjetIndicateur = new ProjetIndicateur();

  constructor(
      private modalService: NgbModal,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private ngxService: NgxUiLoaderService,
      private indcateurService: IndicateurServiceService
  ) {

    this.indcateurService.actionCreated$.subscribe(() => {
      // Met à jour la liste après la création réussie d'un glossaire
      this.fetchDataIndicateur();
    });
   }

  ngOnInit(): void {
    this.item = this.localstorage.getProjet();
    
    //console.log(" this.item", this.item);
  }


  
  addIndicateur() {
    this.modalService.open(AddIndicateurComponent, {
      centered: true,
      backdrop: 'static', 
      size: 'xl'
    });
  }

  EditIndicateur(projetIndicateur: any) {
    this.localstorage.saveIndicateur(projetIndicateur)
    const modalRef = this.modalService.open(EditIndicateurComponent, {
      backdrop: 'static',
      centered: true,
      size: 'xl'
    },);
  }

   fetchDataIndicateur() {
    this.paramservice.fetchDataIndicateur().subscribe(
      (response:any)=>{
        this.indicateurData = response.data;
        this.filteredtems = this.indicateurData
        //console.log("this.actionData",this.indicateurData);
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
      }
      
    ) ;
  }


  confirmDeletion(indicateur: any) {
    this.confirmationService.confirm({
        //target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir supprimer cet indicateur ' + indicateur.libelle + ' ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
        rejectLabel: 'Non', // Définir le libellé du bouton "Non" 
        accept: () => {
          this.ngxService.start();

            this.paramservice.deleteIndicateur(indicateur.reference).subscribe(
              (response:any)=>{
                this.ngxService.stop();
                //console.log("la reponse du webserice",response);
                this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
                this.fetchDataIndicateur();
              },
              (error)=>{
                this.ngxService.stop();
                //console.log("une erreur est survenu",error);
                this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });
              },
            )
          },
        reject: () => {
            //this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: 'Vous avez rejeté', life: 3000 });
        }
    });//end confirmDeletion
}

 

filterData() {
  const lowerSearchTerm = this.searchTerm.toLowerCase();
  this.filteredtems = this.indicateurData.filter((item: any) => {
      // Vérifier que les propriétés existent avant de filtrer
      const matchesLibelle = item.libelle && item.libelle.toLowerCase().includes(lowerSearchTerm);
      const matchesValeurReference = item.valeur_reference && item.valeur_reference.toLowerCase().includes(lowerSearchTerm);
      const matchesSourceVerification = item.source_verification && item.source_verification.toLowerCase().includes(lowerSearchTerm);
      const matchesUnite = item.unite && item.unite.toLowerCase().includes(lowerSearchTerm);
      const matchesModeCalcul = item.mode_calcul && item.mode_calcul.toLowerCase().includes(lowerSearchTerm);
      
      const projet = item.projet || {};
      const matchesProjetCode = projet.code && projet.code.toLowerCase().includes(lowerSearchTerm);
      const matchesProjetLibelle = projet.libelle && projet.libelle.toLowerCase().includes(lowerSearchTerm);

      const typeIndicateur = item.type_indicateur || {};
      const matchesTypeIndicateurLibelle = typeIndicateur.libelle && typeIndicateur.libelle.toLowerCase().includes(lowerSearchTerm);

      return matchesLibelle || matchesValeurReference || matchesSourceVerification ||
          matchesUnite || matchesModeCalcul || matchesProjetCode || matchesProjetLibelle ||
          matchesTypeIndicateurLibelle;
  });
}

changePageSize() {
  // Met à jour les données filtrées en fonction de la nouvelle taille de page sélectionnée
  this.filteredtems = this.filteredtems.slice(0, this.pageSize);
}
}

