<div class="breadcrumbs overlay">
    <div class="container">
        <div class="bread-inner">
            <div class="row">
                <div class="col-12">
                    <h2>MISSION</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<br>

<!-- Start Mission Section -->
<section class="why-choose section">
    <div class="container">
        <div class="row d-flex align-items-start"> <br><!-- Align items at the top -->
            <!-- Start Text Section -->
            <div class="col-md-6">
                <div class="choose-left"><br>
                    <h3>MISSION</h3>
                    <p class="mission-text">
                        L'Office Béninois de Recherches Géologiques et Minières est chargé de gérer et d'assurer les
                        missions de service public dans les domaines de la géologie et des mines.
                    </p>

                    <div class="compresser">
                        <ul class="mission-list">
                            <li>Contribuer à la définition de la politique du Gouvernement dans le secteur</li>
                            <li>Etablir les cartes géologiques, minières, géochimiques, géophysiques sur toute l'étendue
                                du territoire national</li>
                            <li>Réaliser, seul ou en partenariat, les travaux de recherches géologiques et minières</li>
                            <li>Réaliser les sondages miniers</li>
                            <li>Publier les cartes géologiques, minières, géochimiques, géophysiques</li>
                            <li>Etablir l'inventaire des potentialités minières nationales et l'évaluation des gisements
                                et en assurer leur promotion</li>
                            <li>Prendre des participations dans des activités minières attribuées aux acteurs privés
                            </li>
                            <li>Fournir de l'assistance technique dans le cadre de l'exploration minière</li>
                            <li>Réaliser les prestations de services</li>
                            <li>Fournir, en collaboration avec les Forces Armées Nationales, de l'assistance aux
                                carrières pour la gestion des substances explosives à usage civil et le transport des
                                produits dangereux.</li>
                        </ul>
                    </div>

                    <div class="justify-content-end mt-4"> <!-- Add margin-top for spacing -->
                        <a (click)="goToMission()" href="javascript: void(0);" class="btn btn-primary"
                            rel="noopener">Métiers</a>
                    </div><br>
                </div>
            </div>
            <!-- End Text Section -->

            <!-- Start Video Section --><br>
            <div class="col-md-6 d-flex justify-content-center align-items-center  mt-4 mr-4"><br>

                <iframe width="660" height="315" src="https://www.youtube.com/embed/-2FVYNxzkVI?si=DiNjlp0NCaGidfJM"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <!-- End Video Section -->


        </div>
    </div>
</section>
<br><br>
<!--/ End Mission Section -->