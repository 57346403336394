<div class="container-fluid">
    <h4 id="upper">TABLEAU DE BORD</h4>

  <!-- <div class="d-flex justify-content-end">
    <div class="text-center ">
      <a routerLink="/projet" href="javascript: void(0);" class="btn btn-primary btn-sm">Choisir un projet</a>
    </div>  
  </div> -->
  <div class="container mt-4">
    <div class="row gx-4 gy-4">

      <div class="col-md-8">
        <div class="card">
          <div class="card-body pb-1">
            <div class="float-end d-none d-md-inline-block">
              <div class="btn-group mb-2">
                <!-- <button type="button" class="btn btn-sm btn-light">Today</button>
                <button type="button" class="btn btn-sm btn-light active">Weekly</button>
                <button type="button" class="btn btn-sm btn-light">Monthly</button> -->
              </div>
            </div>
            <h4 class="card-title mb-4"></h4>
            <div>
              <div class="row">
                <div class="col-md-4 mb-1" >
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex">
                        <div class="flex-1 overflow-hidden">
                          <p class="text-truncate  mb-2">Ressources</p>
                          <h4 class="mb-0">{{ressource}}</h4>
                        </div>
                        <div class="text-primary ms-auto">
                          <i class="ri-stack-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
              
                    <div class="card-body border-top py-3">
                      <div class="text-truncate">
                      </div>
                    </div>
                  </div>
                </div>
        
                <div class="col-md-4 mb-1" >
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex">
                        <div class="flex-1 overflow-hidden">
                          <p class="text-truncate  mb-2">Centres</p>
                          <h4 class="mb-0">{{centre}}</h4>
                        </div>
                        <div class="text-primary ms-auto">
                          <i class="ri-stack-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
              
                    <div class="card-body border-top py-3">
                      <div class="text-truncate">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
  
        
        </div>
        <div class="row">
          <div class="col-md-4 mb-1" >
            <div class="card">
              <div class="card-body">
                <div class="d-flex">
                  <div class="flex-1 overflow-hidden">
                    <p class="text-truncate  mb-2">Substance minérales</p>
                    <h4 class="mb-0">{{typeRessourece1  }}</h4>
                  </div>
                  <div class="text-primary ms-auto">
                    <i class="ri-stack-line font-size-24"></i>
                  </div>
                </div>
              </div>
        
              <div class="card-body border-top py-3">
                <div class="text-truncate">
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-md-4 mb-1" >
            <div class="card">
              <div class="card-body">
                <div class="d-flex">
                  <div class="flex-1 overflow-hidden">
                    <p class="text-truncate  mb-2">Pierres ornementale </p>
                    <h4 class="mb-0">{{typeRessourece2}}</h4>
                  </div>
                  <div class="text-primary ms-auto">
                    <i class="ri-stack-line font-size-24"></i>
                  </div>
                </div>
              </div>
        
              <div class="card-body border-top py-3">
                <div class="text-truncate">
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 mb-1" >
            <div class="card">
              <div class="card-body">
                <div class="d-flex">
                  <div class="flex-1 overflow-hidden">
                    <p class="text-truncate  mb-2">Pierre fines</p>
                    <h4 class="mb-0">{{typeRessourece3}}</h4>
                  </div>
                  <div class="text-primary ms-auto">
                    <i class="ri-stack-line font-size-24"></i>
                  </div>
                </div>
              </div>
        
              <div class="card-body border-top py-3">
                <div class="text-truncate">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4"> Carte du Bénin</h4>

            <div class="d-flex justify-content-center">
              <app-maps [markers]="markers" class="w-100 h-100"></app-maps>

            </div>
          </div>
        </div>
      </div>
     
    
    </div>

    
  </div>

</div>
