import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { Direction } from 'src/app/shared/models/direction';
import { ParameterService } from 'src/app/shared/service/params/param';
import { DirectionService } from '../direction/direction.component';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-add-direction',
  templateUrl: './add-direction.component.html',
  styleUrl: './add-direction.component.scss'
})

export class AddDirectionComponent {


  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private ngxService: NgxUiLoaderService,
    private messageService:MessageService,
    private DirectionService: DirectionService
  ) { }


  //fermer
  close(){
    this.modalService.dismissAll();
  }

  item: Direction= new Direction()

  // ajout direction
  addDirection(){
    if (!this.item.libelle || !this.item.sigle) {
      
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuiller saisir le libellé et le sigle" });

    }
    else{
      this.ngxService.start();
      this.paramService.addDirection(this.item).subscribe(
        (response:any)=>{
          //console.log("la reponse du webservice",response);
          this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
  
          this.modalService.dismissAll();
          this.ngxService.stop();
          this.DirectionService.notifyProgrammeCreated();
        },
        (error:any)=>{
          //console.log("une erreur est survenu",error);
          //this.modalService.dismissAll();
          this.ngxService.stop();
          this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail:"Une erreur est survenue. Veuillez réessayer"});
          this.DirectionService.notifyProgrammeCreated();
        }
      )
    }
  }//end addDirection

}