import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProjetIndicateurService } from '../../plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { DetailTepProjetComponent } from '../detail-tep-projet/detail-tep-projet.component';

@Component({
  selector: 'app-tep-projet',
  templateUrl: './tep-projet.component.html',
  styleUrl: './tep-projet.component.scss'
})



export class TepProjetComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  projetData: any = [] = [];
  annee: any;
  modalRef: NgbModalRef | null = null;
  isLoading: boolean = false;
  searchTerm: string = '';
  dateDebut: string;
  dateFin: string;
  itemToDelete!: ProjetIndicateur;
  filteredtems: any = [];
  projetId: any
  pageSize: number = 10;
  pdfUrl: string;
  excelUrl: string;

  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  paginatedProjets: any = [];

  projetItems: any = [];
  selectedProjet: any;
  directionId: any;
  showFiltre: boolean = false;
  directionItems: any = [];
  userInfo: any
  
  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private projetService: ProjetIndicateurService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    const currentYear = new Date().getFullYear();
    this.dateDebut = `${currentYear}-01-01`;
    this.dateFin = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {

    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();

    this.userInfo = this.localstorage.getInfoUserToService();

    if (this.userInfo?.data?.groupe === "admin") {

      this.showFiltre = true;
      this.getDirection();
    }
    if (this.userInfo?.data?.groupe === "point_focal") {
      this.directionId = this.localstorage.getInfoUserToService()?.data?.direction_id;
      this.getProjetByDirection(this.directionId);
    }
    //statistiques par defaut

    this.getStatistique();
  }

  getDirection() {
    this.paramservice.fetchDataDirection().subscribe(
      (response: any) => {
        this.directionItems = response.data
      },
      (error) => {
        //console.log("une erreur est survenu", error);
      }
    )
  }
  getProjetByDirection(reference: any) {
    this.paramservice.getProjetByDirection(reference).subscribe(
      (response: any) => {
        this.projetItems = response.data;
        // this.getExercice();
      },
      (error: any) => {
        //console.log("une erreur est survenu", error);
      }
    )

  }

  //recuperation des statistiques
  getStatistique() {
    //check
    if (this.dateDebut === null || this.dateDebut === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if (this.dateFin === null || this.dateFin === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
  
    const savedate={

      "date_debut":this.dateDebut,
      "date_fin":this.dateFin
    }

    this.localstorage.saveDateToDetail(savedate)

  //  console.log("fff");
    
    //
    this.isLoading = true;
    this.ngxService.start();
    this.paramservice.getStatistiqueTepProjet(this.annee, this.dateDebut, this.dateFin, this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.ngxService.stop();
        this.isLoading = false;
        this.projetData = response.data;
        this.totalRecords = this.projetData.length;
        this.updatePaginatedProjets();
      },
      (error) => {
        this.ngxService.stop();
        this.messageService.add({ severity: 'error', summary: 'TEP', detail: error, life: 3000 });
        this.isLoading = false;
      }
    );
  }//end getStatistique


  onPageChangeProjets(event) {
    this.firstProjets = event.page * this.rowsProjets; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.projetData.slice(start, end);
  }//end updatePaginatedProjets


  //imprime en pdf et excel
  exportToDocument(typeDoc: any) {
    //check
    if (this.dateDebut === null || this.dateDebut === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if (this.dateFin === null || this.dateFin === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
    this.ngxService.start();

    // save date debut et fin

    
    //end
    this.paramservice.printStatistiqueTepProjetAsFile(this.annee, this.directionId, this.dateDebut, this.dateFin, typeDoc, this.userInfo?.data?.reference).subscribe(
      (response: Blob) => {
        const filename = 'Statistiques_Activite_Projet.pdf';
        FileSaver.saveAs(response, filename);

        const url = URL.createObjectURL(response);
        window.open(url, '_blank');

        this.ngxService.stop();
        this.messageService.add({ severity: 'success', summary: 'Téléchargement réussi', detail: 'Le fichier PDF a été téléchargé avec succès', life: 3000 });
      },
      (error) => {
        //console.error("Une erreur est survenue", error);
        this.ngxService.stop();
      }
    );
  }//end exportToDocument

  roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }


  //detaila ctivite budgetaire
  detailsProjet(item: any){

  this.localstorage.saveTepProjet(item);

  const modalRef = this.modalService.open(DetailTepProjetComponent, {
    backdrop: 'static',
    centered: true,
    size: 'xl'
  },);
}//end detailsProjet


}