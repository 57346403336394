<!-- Modal -->
<div>
  <div class="">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h5 id="upper" class="modal-title">Planification Indicateurs</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <!-- Indicateur Information -->
            <div class="col-md-8">
              <div class="card mb-4">
                <div class="container mb-3 text-left">
                  <h6 id="upper" class="mb-4 text-center">Informations de l'indicateur</h6>
                  <div class="row mb-3">
                    <div class="col-md-6 text-left">
                      <h6 id="dash">Libellé :</h6>
                    </div>
                    <div class="col-md-6 text-right">
                      <h6 id="dash"><strong>{{items.libelle}}</strong></h6>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6 text-left">
                      <h6 id="dash">Valeur de référence :</h6>
                    </div>
                    <div class="col-md-6 text-right">
                      <h6 id="dash"><strong>{{items.valeur_reference}}</strong></h6>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6 text-left">
                      <h6 id="dash">Source de vérification :</h6>
                    </div>
                    <div class="col-md-6 text-right">
                      <h6 id="dash"><strong>{{items.source_verification}}</strong></h6>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6 text-left">
                      <h6 id="dash">Projet :</h6>
                    </div>
                    <div class="col-md-6 text-right">
                      <h6 id="dash"><strong>{{items.projet.libelle}}</strong></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Formulaire de Planification -->
            <div class="col-md-4">
              <h5 class="mb-4 text-center">Planifier un Indicateur</h5>
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group mb-3">
                      <label for="annee">Année</label>
                      <select name="annee" [(ngModel)]="planification.annee" id="annee" class="form-select">
                        <option *ngFor="let annee of annees" [value]="annee">{{annee}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-3">
                      <label for="valeur_cible">Valeur cible</label>
                      <input type="number" class="form-control" id="valeur_cible"
                        [(ngModel)]="planification.valeur_cible" name="valeur_cible">
                    </div>
                  </div>
                </div>
                <div class="modal-footer ">
                  <button type="button" class="btn btn-secondary" (click)="closeModal()"><i class="pi pi-times"
                      style="color: white"></i> Annuler</button>
                  <button type="button" class="btn btn-primary" (click)="savePlanification()"> <i class="pi pi-save"
                      style="color: white"></i> Planifier</button>
                </div>
              </form>
            </div>
          </div>
         
        </div>
      </div>

      <!-- Modal Footer -->
    
    </div>
  </div>
</div>