import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Programme } from 'src/app/shared/models/programme';
import { AddProgrameComponent } from '../add-programe/add-programe.component';
import { EditProgrameComponent } from '../edit-programe/edit-programe.component';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-programme',
  templateUrl: './programme.component.html',
  styleUrl: './programme.component.scss'
})


export class ProgrammeComponent  {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  programmeData: any =[];
  validationform: UntypedFormGroup;
  modalRef: NgbModalRef | null = null;

  itemToDelete!: Programme; 

  constructor(
      private modalService: NgbModal,
      public formBuilder: UntypedFormBuilder,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private ProgrammeService:ProgrammeService,
  ) { 

    this.ProgrammeService.programmeCreated$.subscribe(() => {
      // Met à jour la liste après la création réussie d'un glossaire
      this.fetchDataPrograme();
    });
  }

  ngOnInit(): void {

   

   this.fetchDataPrograme();
  }

   fetchDataPrograme() {
    
    
    this.paramservice.fetchDataPrograme().subscribe(

      (response:any)=>{

        this.programmeData = response.data;

        //console.log("this.programmeData",this.programmeData);
        
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }
  /**
   * Modal Open
   * @param content modal content
   */
  openModal() {
    this.modalService.open(AddProgrameComponent, {
       centered: true,
       backdrop: 'static', 


       });
  }

  EditModal(programme:any) {
    this.localstorage.saveProgramme(programme)
      const modalRef = this.modalService.open(EditProgrameComponent,{
        backdrop: 'static', 
        centered: true,
      }, );
  }

  confirm1(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir continuer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
        rejectLabel: 'Non', // Définir le libellé du bouton "Non"
        accept: () => {

            this.paramservice.deleteProgramme(event).subscribe(

              (response:any)=>{

                //console.log("la reponse du webserice",response);
                this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });

                this.fetchDataPrograme();
                
              },
              (error)=>{

                //console.log("une erreur est survenu",error);
                this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: error.message });

              },
            )
          },
        // reject: () => {
        //     this.messageService.add({ severity: 'warn', summary: Constant.suiviTitle, detail: 'La suppression du programme est annulée.', life: 3000 });
        // }
    });
}

 

}

export class ProgrammeService {
  private ProgrammeCreatedSubject = new BehaviorSubject<boolean>(false);
  programmeCreated$ = this.ProgrammeCreatedSubject.asObservable();

  notifyProgrammeCreated() {
    this.ProgrammeCreatedSubject.next(true);
  }
}

