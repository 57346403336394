export class ProjetIndicateur {
    code: any;
    libelle: any;
    objectif_global: any;
    contexte: any;
    sigle: any;
    programme_id: any;
    objectifs_specifiques: any;
    resultat_projet: any;
    description_projet: string;
    zones_intervention: any;
    beneficiaires: string;
    cout: number;
    poids: number;
    responsable_projet: any;


    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    // get error message
    getErrorMessage(): string {
        return this.errorMessage;
    }

    // check for save
    canSave(): boolean {
        if (this.code === undefined || this.code === null || this.code.trim() === "") {
            this.errorMessage = "Veuillez saisir le code";
            return false;
        }

        if (this.libelle === undefined || this.libelle === null || this.libelle.trim() === "") {
            this.errorMessage = "Veuillez saisir le libellé";
            return false;
        }
        if (this.sigle === undefined || this.sigle === null || this.sigle.trim() === "") {
            this.errorMessage = "Veuillez saisir le sigle";
            return false;
        }
        if (this.contexte === undefined || this.contexte === null || this.contexte.trim() === "") {
            this.errorMessage = "Veuillez saisir le contexte";
            return false;
        }

        if (this.objectifs_specifiques === undefined || this.objectifs_specifiques === null || this.objectifs_specifiques.trim() === "") {
            this.errorMessage = "Veuillez saisir les objectifs spécifiques";
            return false;
        }
       

        if (this.resultat_projet === undefined || this.resultat_projet === null || this.resultat_projet.trim() === "") {
            this.errorMessage = "Veuillez saisir le résultat du projet";
            return false;
        }

        if (this.description_projet === undefined || this.description_projet === null || this.description_projet.trim() === "") {
            this.errorMessage = "Veuillez saisir la description du projet";
            return false;
        }

        if (this.zones_intervention === undefined || this.zones_intervention === null || this.zones_intervention.trim() === "") {
            this.errorMessage = "Veuillez saisir les zones d'intervention";
            return false;
        }

        if (this.beneficiaires === undefined || this.beneficiaires === null || this.beneficiaires.trim() === "") {
            this.errorMessage = "Veuillez saisir les bénéficiaires";
            return false;
        }

        if (this.cout === undefined || this.cout === null || this.cout === 0) {
            this.errorMessage = "Veuillez saisir le coût";
            return false;
        }

        if (this.poids === undefined || this.poids === null || this.poids === 0) {
            this.errorMessage = "Veuillez saisir le poids";
            return false;
        }

        if (this.responsable_projet === undefined || this.responsable_projet === null || this.responsable_projet.trim() === "") {
            this.errorMessage = "Veuillez saisir le responsable du projet";
            return false;
        }
        if (this.objectif_global === undefined || this.objectif_global === null || this.objectif_global.trim() === "") {
            this.errorMessage = "Veuillez saisir l'objectif global";
            return false;
        }
        return true;
    }
}
