export class Indicateur {
    libelle: any;
    valeur_reference: any;
    date_valeur_reference: any;
    source_verification: any;
    unite: any;
    mode_calcul: any;
    projet_id: any;
    type_indicateur_id: any;

    private errorMessage: string;


    getErrorMessage(): string {
        return this.errorMessage;
    }

    constructor() {
        this.errorMessage = "";
    
    }
    // check for save
    canSave(): boolean {
        if (this.libelle === undefined || this.libelle === null || this.libelle.trim() === "") {
            this.errorMessage = "Veuillez saisir le libellé";
            return false;
        }
        if (this.valeur_reference === undefined || this.valeur_reference === null ) {
            this.errorMessage = "Veuillez saisir la valeur de référence";
            return false;
        }

        if (this.source_verification === undefined || this.source_verification === null || this.source_verification.trim() === "") {
            this.errorMessage = "Veuillez saisir la source de vérification";
            return false;
        }
        if (this.unite === undefined || this.unite === null || this.unite.trim() === "") {
            this.errorMessage = "Veuillez saisir l'unité";
            return false;
        }

        if (this.type_indicateur_id === undefined || this.type_indicateur_id === null || this.type_indicateur_id.trim() === "") {
            this.errorMessage = "Veuillez sélectionner le type d'indicateur";
            return false;
        }
        if (this.projet_id === undefined || this.projet_id === null || this.projet_id.trim() === "") {
            this.errorMessage = "Veuillez sélectionner le projet";
            return false;
        }
        
        if (this.mode_calcul === undefined || this.mode_calcul === null || this.mode_calcul.trim() === "") {
            this.errorMessage = "Veuillez saisir le mode de calcul";
            return false;
        }
        if (this.date_valeur_reference === undefined || this.date_valeur_reference === null || this.date_valeur_reference.trim() === "") {
            this.errorMessage = "Veuillez saisir la date de la valeur de référence";
            return false;
        }

        return true;
    }//end canSave
}
