
<div>
  <div class="container-fluid p-0">
    <div class="row g-0">
      <div class="col-lg-4">
        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">
                    <div>
                      <a routerLink="" class="logo"><img src="../../../../../assets/img/logo-obgrm.png" height="70" alt="logo"></a>
                    </div>

                    <h4 class="font-size-18 mt-4">Réinitialiser le mot de passe</h4>
                  </div>

                  <div class="p-2 mt-5">
                    <div class="alert alert-success mb-4" role="alert">
                      Entrez votre email et les instructions vous seront envoyées!
                    
                    
                    </div>
                    <form class="form-horizontal">

                      <div class="form-group auth-form-group-custom mb-4">
                        <i class="ri-mail-line auti-custom-input-icon"></i>
                        <label for="email">Email</label>
                        <input [(ngModel)]="item" name="item" type="email" class="form-control"  id="email" placeholder=" Adresse email">
                       
                      </div>

                      <div class="mt-4 text-center">
                        <button (click)="onSubmit()" class="btn btn-primary w-md waves-effect waves-light" type="submit">Réinitialiser</button>
                      </div>
                    </form>
                    <div class="mt-4 text-center">
                      <a routerLink="/login" class="text-muted"><i class="mdi mdi-lock me-1"></i>
                        Se connecter ?</a>
                    </div>
                  </div>


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="authentication-bg">
          <div class="bg-overlay"></div>
        </div>
      </div>
    </div>
  </div>
  <p-toast position="bottom-left" />

</div>
<!-- end page -->

