import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { Localisation } from 'src/app/shared/models/localisation';
import { MarkerDataService } from 'src/app/shared/service/makerservice/markerDataService';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrl: './recherche.component.scss'
})
export class RechercheComponent {

  ressourceItems: any = [];
  departementItems: any = [];
  filteredLocalisations: any = [];
  first: number = 0;
  totalRecords: number = 0; 
  ressourcePagianteStat: any[] = []
  paginatedLocalisations: any[] = []
  rows: number = 9;
  selectedDepartement: any;
  selectedRessource: any;
  markers: { lat: number, lng: number, type: any, data: any } [] = [];
  allMarkers: { lat: number, lng: number, type: any, data: any,  } [] = [];
  allLocalisations: any[] = [];
  
  loading: boolean = false;

  constructor(
    private paramService: ParameterService,
    private markerDataService: MarkerDataService,
    private ngxloader: NgxUiLoaderService,
    private messageService: MessageService,
    private localStorage: LocalStorageService,
    private router: Router,
  ) {
    this.markerDataService.markers$.subscribe(markers => {
      this.markers = markers;
    });
  }

  ngOnInit() {
    this.selectedDepartement = null;
    this.selectedRessource = null;
    this.getDepartementsList();
    this.getRessourceList();
  }

  //liste des ressources
  getRessourceList() {
    this.paramService.fetchDataRessource().subscribe(
      (response: any) => {
        this.ressourceItems = response.data;

        //list of localisations
        this.ressourceItems.forEach(ressource => {
          ressource.localisation_ressource.forEach(localisation => {
            localisation["ressource"] = ressource;
            this.allLocalisations.push(localisation);
          });
        })
        this.filterLocalisationResults();
      },
      (error) => {
      }
    )
  }//end getRessourceList

  // Met à jour les marqueurs avec les données des ressources
  updateMarkersWithRessources(ressources: any[]) {
    console.log("ressources",ressources);
    
    const newMarkers = [];
    ressources.forEach(ressource => {
      ressource.localisation_ressource.forEach(localisation => {
        newMarkers.push({
          lat: localisation.latitude,
          lng: localisation.longitude,
          ressource: ressource.titre,
          type: ressource.type_ressource.libelle,
          description: ressource.perspective,
          departement: localisation.arrondissement.commune.departement.libelle
        });
      });
    });

    // Met à jour les marqueurs via le service
    this.markerDataService.updateMarkers(newMarkers);
  }//end updateMarkersWithRessources

  //recherche des donnees
  filterLocalisationResults(){

    //filtered data
    this.filteredLocalisations = this.allLocalisations;

    console.log("thisresource",this.filteredLocalisations);
    console.log("selectedDepartement",this.selectedDepartement);
    console.log("selectedRessource",this.selectedRessource);
    

    //if departement
    if(this.selectedDepartement !== null){
      this.filteredLocalisations = this.filteredLocalisations.filter(x => x.arrondissement.commune.departement_id === this.selectedDepartement?.id);
    }

    //if ressource
    if(this.selectedRessource !== null){
      this.filteredLocalisations = this.filteredLocalisations.filter(x => x.ressource_id === this.selectedRessource?.id);
    }

    // Met à jour les marqueurs via le service
    if (this.filteredLocalisations.length === 0) {
      this.messageService.add({ severity: 'info', summary: "OBGRM", detail: "Aucune ressource trouvée dans cette catégorie" });  

    }
    this.markers = [];
    this.filteredLocalisations.forEach(localisation => {

      let data: any = { ressource: localisation.ressource.titre,
        type_ressource: localisation.ressource.type_ressource.libelle,
        description: localisation.ressource.perspective,
        departement: localisation.arrondissement.commune.departement.libelle,
        communes: localisation.arrondissement.commune.libelle,
        localite: localisation.nom_localite,
        resume: localisation.resume_recherche,
        rapport_recherche: localisation.rapport_recherche,
        type: "ressource", 
        logo: localisation.ressource.icone,
      };

      this.markers.push({
        lat: localisation.latitude,
        lng: localisation.longitude,
        type: "ressource",
        data: data
        
      });
    })

    //for listing
    this.updateRessourceStats();

    this.markerDataService.updateMarkers(this.markers);

  }//end filterLocalisationResults

  //liste des departements
  getDepartementsList() {
    this.paramService.getStatsDepartement().subscribe(
      (response: any) => {
        this.departementItems = response.data;
      },
      (error) => {
        //console.log("une errer est survenu", error);
      }
    )
  }//end getDepartementsList


  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.updateRessourceStats();
  }

  updateRessourceStats() {
    const start = this.first;
    const end = this.first + this.rows;
    this.paginatedLocalisations = this.filteredLocalisations.slice(start, end);
  }

  goToResourceDetail(reference: any): void {
    this.router.navigate(['/resource', reference]);
    this.localStorage.SaveResourceDetail(reference);
    this.localStorage.saveAccueil("recherches");
  }

}
