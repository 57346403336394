export class GestionUser {
    name: string;
    surname: string;
    phone: string;
    email_address: string;
    identifiant: string;
    direction: any;
    password: string;
    groupe: any;
    acces_valoriation: boolean =false;
    acces_suivi_evaluation: boolean =false;
    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }

    canSave(): boolean {
        if (!this.name || this.name.trim() === '') {
            this.errorMessage = "Le  nom  est obligatoire";
            return false;
        }

        if (!this.surname || this.surname.trim() === '') {
            this.errorMessage = "Le  prénom est obligatoire";
            return false;
        }

        if (!this.phone ) {
            this.errorMessage = "Le  téléphone est obligatoire";
            return false;
        }

        if (!this.email_address || this.email_address.trim() === '') {
            this.errorMessage = "L addresse email est obligatoire";
            return false;
        }

        if (!this.identifiant || this.identifiant.trim() === '') {
            this.errorMessage = "L' identifiant est obligatoire";
            return false;
        }

        if (!this.password || this.password.trim() === '') {
            this.errorMessage = "Le  mot de passe est obligatoire";
            return false;
        }

        if (!this.groupe) {
            this.errorMessage = "Le  groupe est obligatoire";
            return false;
        }

        this.errorMessage = ""; // Clear error message if all fields are valid
        return true;
    }
}
