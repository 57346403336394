import { Component } from '@angular/core';
import { Action } from '@fullcalendar/core/internal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-activite-hors-pta',
  templateUrl: './activite-hors-pta.component.html',
  styleUrl: './activite-hors-pta.component.scss'
})

export class ActiviteHorsPtaComponent  {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  actionData: any =[];
  modalRef: NgbModalRef | null = null;

  itemToDelete!: Action; 

  constructor(
      private modalService: NgbModal,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService
  ) { }

  ngOnInit(): void {

    const questions = document.querySelectorAll(".question");

    questions.forEach(question => {
      question.addEventListener("click", event => {
        const active = document.querySelector(".question.active") as HTMLElement;
        if (active && active !== question) {
          active.classList.toggle("active");
          const nextSibling = active.nextElementSibling as HTMLElement;
          if (nextSibling) {
            nextSibling.style.maxHeight = "0";
          }
        }
        question.classList.toggle("active");
        const answer = question.nextElementSibling as HTMLElement;
        if (question.classList.contains("active") && answer) {
          answer.style.maxHeight = answer.scrollHeight + "px";
        } else if (answer) {
          answer.style.maxHeight = "0";
        }
      });
    });

    this.fetchDataAction();
  }

   fetchDataAction() {
    
    
    this.paramservice.fetchDataAction().subscribe(

      (response:any)=>{

        this.actionData = response.data;

        //console.log("this.actionData",this.actionData);
        
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }


  confirm1(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir continuer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
        rejectLabel: 'Non', // Définir le libellé du bouton "Non"
        accept: () => {
            this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'Vous avez acepter' });

            this.paramservice.deleteAction(event).subscribe(

              (response:any)=>{

                //console.log("la reponse du webserice",response);
                
              },
              (error)=>{

                //console.log("une erreur est survenu",error);
                
              },
            )
          },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: 'Vous avez rejeté', life: 3000 });
        }
    });
}

 

}
