import { Component, OnInit } from '@angular/core';
import { ParameterService } from 'src/app/shared/service/params/param';
import { ApexOptions } from 'ng-apexcharts';
import { MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as FileSaver from 'file-saver';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-graphique',
  templateUrl: './graphique.component.html',
  styleUrls: ['./graphique.component.scss'],
})
export class GraphiqueComponent implements OnInit {
  projets: any[] = [];
  selectedProjet: any;
  userInfo: any;
  date_fin: string;
  date_debut: string;
  isLoading: boolean = false;
  annee: boolean = false;
  totalFinancementEtat: number = 0;
  totalFinancementFondPropre: number = 0;
  totalGeneral: number = 0;
  // ApexCharts configuration
  simplePieChart: any;

  constructor(
    private messageService: MessageService,
    private parameterService: ParameterService,
    private ngxService: NgxUiLoaderService,
    private localstorage: LocalStorageService,


  ) {
    const currentYear = new Date().getFullYear();
    this.date_debut = `${currentYear}-01-01`;
    this.date_fin = new Date().toISOString().split('T')[0];
    this.userInfo = this.localstorage.getInfoUserToService();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();

  }

  ngOnInit(): void {
    this.getMontantByProjet();
    this.calculerTotaux()

  }
  getMontantByProjet() {
    const requestData = {
      date_debut: this.date_debut,
      date_fin: this.date_fin,
    };
    this.isLoading = false;
  
    this.parameterService.getMontantByProjet(requestData).subscribe(
      (response: any) => {
        this.isLoading = true;
  
        const projetsData = response.data;
        let total = 0;
  
        projetsData.forEach((item: any) => {
          total += Number(item.total);
        });
  
        this.projets = projetsData
          .map((item: any) => {
            return {
              ...item,
              pourcentage: ((item.total / total) * 100).toFixed(1), 
            };
          })
          .sort((a: any, b: any) => b.total - a.total); 
  
        console.log('this.projets', this.projets);
  
        if (this.projets.length > 0) {
          this.selectedProjet = this.projets[0];
          this.updateChart(); 
        }
  
        this.calculerTotaux();
      },
      (error) => {
        console.log('une erreur est survenue', error);
      }
    );
  }

  calculerTotaux() {
    this.totalFinancementEtat = this.projets.reduce((sum, p) => sum + p.mt_financement_etat, 0);
    this.totalFinancementFondPropre = this.projets.reduce((sum, p) => sum + p.mt_financement_fond_propre, 0);
    this.totalGeneral = this.projets.reduce((sum, p) => sum + p.total, 0);
  }
  updateChart() {
    const totalMontants = this.projets.map((projet) => projet.total);
    const labels = this.projets.map((projet) => projet.nom_projet);
    const colors = ['#42A5F5', '#66BB6A', '#FFA726', '#FF7043', '#AB47BC'];

    this.simplePieChart = {
      series: totalMontants, // Amount data
      chart: {
        type: 'pie',
        width: 600, 
      },
      labels: labels, 
      legend: {
        position: 'top',
        horizontalAlign: 'center',
      },
      colors: colors.slice(0, this.projets.length),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 900, 
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  onRowSelect(event: any) {
    this.selectedProjet = event.data;
    this.updateChart();
  }

  isNaN(value: any): boolean {
    return isNaN(value);
  }
}
