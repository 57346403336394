import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Simplebar
import { SimplebarAngularModule } from 'simplebar-angular';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { LanguageService } from '../../core/services/language.service';
import { TranslateModule } from '@ngx-translate/core';

import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { HorizontaltopbarComponent } from './horizontaltopbar/horizontaltopbar.component';
import { HorizontalnavbarComponent } from './horizontalnavbar/horizontalnavbar.component';
import { PostLoginComponent } from '../../account/auth/post-login/post-login.component';
import { AccessUserProjetComponent } from '../../account/auth/access-user-projet/access-user-projet.component';
import { ToastModule } from 'primeng/toast';
import { PaginatorModule } from 'primeng/paginator';
import { ProfilComponent } from '../../account/auth/profil/profil/profil.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({ 
  // tslint:disable-next-line: max-line-length
  declarations: [TopbarComponent, FooterComponent, SidebarComponent, RightsidebarComponent, HorizontaltopbarComponent, HorizontalnavbarComponent,PostLoginComponent,AccessUserProjetComponent,ProfilComponent],
  imports: [
  CommonModule,
    TranslateModule,
    SimplebarAngularModule,
    NgbDropdownModule,
    ClickOutsideModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    PaginatorModule,
    NgxUiLoaderModule

  ],
  exports: [TopbarComponent, FooterComponent, SidebarComponent, RightsidebarComponent, HorizontaltopbarComponent, HorizontalnavbarComponent],
  providers: [LanguageService]
})
export class SharedModule { }
