export class Centre {
    nom: string;
    description: string;
    arrondissement_id: any;
    longitude: string;
    latitude: string;
    photo: any;

    constructor() {
        this.nom = "";
        this.description = "";
        this.arrondissement_id = null; // Modifié pour correspondre à la définition du type
        this.longitude = "";
        this.latitude = "";
        this.photo = null; // Modifié pour correspondre à la définition du type
    }
}
