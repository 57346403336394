import { HttpHeaders } from '@angular/common/http';
import { Injectable ,Inject  } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export  class Constant {

    static  suiviTitle: string = "OBRGM Suivi";
    static  rechercheiTitle: string = "OBRGM Recherche";


}