import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Tache } from 'src/app/shared/models/tache';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { request } from 'http';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-detail-tache',
  templateUrl: './detail-tache.component.html',
  styleUrl: './detail-tache.component.scss'
})

export class DetailTacheComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;
  submitted: boolean;
  tacheData: any = [];
  modalRef: NgbModalRef | null = null;
  @ViewChild('tacheModal') modalContent: TemplateRef<any>;
  itemToDelete!: any;
  itemTache: Tache = new Tache();
  sousActivite:any = {};
  paginatedItems: any[] = [];
  first: number = 0;
  rows: number = 10;
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;
  fitreTache:any;
  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxloader: NgxUiLoaderService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
     this.sousActivite =  this.localstorage.getSousActiviteItem();
     this.fetchTachesList();
  }


fetchTachesList() {
  this.fitreTache = this.localstorage.getFilteredTache();
  
  this.isLoading = true;

  this.paramservice.getTacheListBySousActiviteItem(this.sousActivite.reference).subscribe(
    (response: any) => {
      this.isLoading = false;
      let tacheData = response.data;
      
      // Application du filtre en fonction du type
      if (this.fitreTache === 'arealiser') {
        tacheData = tacheData.filter(item => item.est_realise === false);
        
      } else if (this.fitreTache === 'realise') {
        tacheData = tacheData.filter(item => item.est_realise === true);

      } else if (this.fitreTache === 'retard') {
        tacheData = tacheData.filter(item => !item.est_realise && new Date(item.date_fin) < new Date());

      }

      this.tacheData = tacheData;
      this.totalRecords = this.tacheData.length;
      this.updateTache();
    },
    (error) => {
      this.isLoading = false;
    }
  );
}

  //detail modal
  detailModal(tacheItem: Tache) {
    this.itemTache = new Tache(); 
    this.itemTache.code = tacheItem.code; 
    this.itemTache.libelle = tacheItem.libelle; 
    this.itemTache.direction_id = tacheItem.direction_id; 
    this.itemTache.reference = tacheItem.reference; 
    this.itemTache.reference_user = tacheItem.reference_user; 
    this.itemTache.poids = tacheItem.poids; 

    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    this.itemTache.date_enregistrement = `${day}/${month}/${year}`;
    this.localstorage.saveTacheItem(tacheItem);
    
    
    const modalRef = this.modalService.open(this.modalContent, {
      backdrop: 'static',
      centered: true,
      size: 'lg'
    },);
  }//end detailModal

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.itemTache.preuve_realisation = file;
    }
  }

  //enregistre sous activite
  saveTacheToSousActiviter(modal:any) {
    if (!this.itemTache.canUpdateForRealisation()) {
      this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: this.itemTache.getErrorMessage() });
      return;
    } 

      let savedTacheItem = this.localstorage.getTacheItem();

      const formData = new FormData();
      this.ngxloader.start();
      formData.append('reference', savedTacheItem.reference);
      formData.append('code', savedTacheItem.code);
      formData.append('libelle', savedTacheItem.libelle);
      formData.append('poids', this.itemTache.poids);
      formData.append('date_enregistrement', this.itemTache.date_enregistrement);
      formData.append('date_realisation', this.itemTache.date_realisation);
      formData.append('observation', this.itemTache.observation);
      formData.append('pta_sous_activite_id', this.sousActivite.reference);

      if (this.fitreTache ==="arealiser") {

        savedTacheItem.est_realise = true;
        formData.append('est_realise', savedTacheItem.est_realise);

      }
      if (this.fitreTache ==="realise") {
        savedTacheItem.est_realise = false;
        formData.append('est_realise', savedTacheItem.est_realise);

      }

      if (this.fitreTache ==="retard") {
        savedTacheItem.est_realise = true;
        formData.append('est_realise', savedTacheItem.est_realise);

      }

      // check file 
      if (this.itemTache.preuve_realisation) {
        formData.append('file', this.itemTache.preuve_realisation);
      }

      this.paramservice.saveSousActiviteDirection(this.itemTache.reference,formData).subscribe(
        (response: any) => {
          this.ngxloader.stop();
          this.fetchTachesList(); 
          this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
          modal.close(); // Ferme le modal spécifique
          return;
        },
        (error: any) => {
          this.ngxloader.stop();
          this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: error });
        }
      );
  }

  close() {
    this.modalService.dismissAll()
  }



  onPageChange(event: any) {
    this.first = event.first ;
    this.rows = event.rows;

    this.updateTache();
  }

  updateTache() {
    const start = this.first;
    const end = this.first + this.rows;
    this.paginatedItems = this.tacheData.slice(start, end);
  }

  //annuler tache
  annulerTacheRealiser(event: any) {
     this.confirmationService.confirm({
       target: event.target as EventTarget,
       message: 'Êtes-vous sûr de vouloir annuler cette tâche :' + event.libelle + '?',
       header: 'Confirmation',
       icon: 'pi pi-exclamation-triangle',
       acceptIcon: 'pi pi-check',
       rejectIcon: 'pi pi-times',
       rejectButtonStyleClass: 'p-button-text',
       acceptLabel: 'Oui', 
       rejectLabel: 'Non', 
       accept: () => {
         const request = {
           "tache_id": event.reference,
         }
         this.ngxloader.start();
         this.paramservice.annulerTache(request).subscribe(
           (response: any) => {
            this.ngxloader.stop();
             this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
             this.fetchTachesList();
             this.ngxloader.stop();
           },
           (error) => {
             this.ngxloader.stop();
           },
         )
       },
     });
   }//end annulerTacheRealiser

}