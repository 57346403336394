import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Indicateur } from 'src/app/shared/models/indicateurs';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { reference } from '@popperjs/core';
import { ValeurCible } from 'src/app/shared/models/valeurcible';

@Component({
  selector: 'app-cible-indicateurs',
  templateUrl: './cible-indicateurs.component.html',
  styleUrl: './cible-indicateurs.component.scss'
})


export class CibleIndicateursComponent {
  submitted: boolean;
  items: any;
  itemsCible: any = [];
  modalRef: NgbModalRef | null = null;
  itemToDelete!: Indicateur;
  @ViewChild('cibleModal') modalContent: TemplateRef<any>;
  cible: ValeurCible = new ValeurCible();
  annees: number[] = [];

  paginatedItems = [];
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;
  searchTerm: string = '';
  filteredData: any[] = []; // Données filtrées
  accesData: any = [];

  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxService: NgxUiLoaderService,

  ) {

    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 5; i++) {
      this.annees.push(currentYear + i);
    }
  }

  ngOnInit(): void {
    this.getCibleIndicateurs();
  }

  getCibleIndicateurs() {
    this.isLoading = true;

    this.paramservice.getCibleIndicateurs().subscribe(

      (response: any) => {
        this.itemsCible = response.data;
        this.filteredData = [...this.itemsCible]; // Initialiser filteredData avec toutes les données

        this.totalRecords = this.itemsCible.length;
        this.isLoading = false;

        this.updatePaginatedProjets(); // Initial pagination

        //console.log("this.actionData", this.itemsCible);
      },
      (error) => {
        //console.log("une erreur est survenu", error);
      }
    );
  }

  
  onPageChangeProjets(event) {
    this.firstProjets =  event.first; // Mise à jour de la page actuelle
    this.rowsProjets =  event.rows ; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }
  
  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedItems = this.filteredData.slice(start, end);
  }

  applyFilter(term: string) {
    this.searchTerm = term.trim().toLowerCase();

    //console.log("this.searchTerm",this.searchTerm);
    
    if (this.searchTerm) {
      this.filteredData = this.itemsCible.filter(years =>
        years.annee.toLowerCase().includes(this.searchTerm)
      );
      //console.log("filteredData",this.filteredData);
      
    } else {
      // Réinitialiser les données filtrées si la recherche est vide
      this.filteredData = [...this.accesData];
    }

    this.totalRecords = this.filteredData.length;
    this.firstProjets = 0; // Revenir à la première page après le filtrage
    this.updatePaginatedProjets();
  }

  confirmationSuppression(item: any) {
    this.confirmationService.confirm({
      //message: 'Êtes-vous sûr de vouloir supprimer  ' + item.libelle + '?',
      message: 'Êtes-vous sûr de vouloir supprimer ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
      rejectLabel: 'Non', // Définir le libellé du bouton "Non"
      accept: () => {
        this.ngxService.start();
        this.paramservice.deleteCibleIndicateur(item.reference).subscribe(
          (response: any) => {
            //console.log("la reponse du webserice", response);
            this.ngxService.stop();
            this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: response.message });
            this.getCibleIndicateurs();
          },
          (error) => {
            //console.log("une erreur est survenu", error);
            this.ngxService.stop();

          },
        )
      },

    });
  }


  openModalUpdateCible(item) {
    this.items = item;
    this.cible =this.items

    this.cible.valeur_cible_id= this.items.reference
    //console.log("this.items", this.items);

    this.modalService.open(this.modalContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  updateValeurCible() {
    this.ngxService.start();
    this.paramservice.updateCible(this.items.reference, this.cible).subscribe(
      (response: any) => {
        //console.log("la reponse du webservice", response);
        this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
        this.ngxService.stop();
      },
      (error: any) => {
        //console.log("une erreur est survenu", error);
        // this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });

        this.ngxService.stop();

      })
  }


}
