export class Email {
    name: string;
    email: string;
    sujet: string;
    message: string;
    private errorMessage: string;

    constructor() {

        this.errorMessage = "";
    }

    canSend(): boolean {
        if (this.name === undefined || this.name === null || this.name.trim() === "") {
            this.errorMessage = "Veuillez renseigner un nom.";
            return false;
        }

        if (this.email === undefined || this.email === null || this.email.trim() === "") {
            this.errorMessage = "Veuillez renseigner un email.";
            return false;
        }

        if (!this.isValidEmail(this.email)) {
            this.errorMessage = "Veuillez renseigner un email valide.";
            return false;
        }

        if (this.sujet === undefined || this.sujet === null || this.sujet.trim() === "") {
            this.errorMessage = "Veuillez renseigner un sujet.";
            return false;
        }

        if (this.message === undefined || this.message === null || this.message.trim() === "") {
            this.errorMessage = "Veuillez renseigner un message.";
            return false;
        }

        return true;
    }

    private isValidEmail(email: string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
