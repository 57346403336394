import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PublicLayoutComponent } from './public/public-layout/public-layout.component';
import { LayoutComponent } from './admin/layouts/layout/layout.component';
import { LoginComponent } from './admin/account/auth/login/login.component';
import { AccessUserProjetComponent } from './admin/account/auth/access-user-projet/access-user-projet.component';
import { PostLoginComponent } from './admin/account/auth/post-login/post-login.component';
import { PasswordresetComponent } from './admin/account/auth/passwordreset/passwordreset.component';
import { PublicDashboardComponent } from './public/public-dashboard/public-dashboard.component';
import { AuthGuard } from './shared/service/auth/auth.guard';
import { ProfilComponent } from './admin/account/auth/profil/profil/profil.component';

const routes: Routes = [

   { path: 'login', component: LoginComponent },
   {
    path: 'reset-password',
    component: PasswordresetComponent
},
   {
    
    path: 'projet',
    component: AccessUserProjetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'service',
    component: PostLoginComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'profil',
    component: ProfilComponent,
    canActivate: [AuthGuard]
  },
  { path: '',  component: PublicDashboardComponent }, 


  { path: 'account', loadChildren: () => import('./admin/account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'page', component: LayoutComponent, canActivate: [AuthGuard] ,loadChildren: () => import('./admin/pages.module').then(m => m.PagesModule) },
  { path: 'suivi', component: LayoutComponent,canActivate: [AuthGuard], loadChildren: () => import('./admin/pages/suivi/suivi.module').then(m => m.SuiviModule) },
  { path: 'recherche', component: LayoutComponent,canActivate: [AuthGuard], loadChildren: () => import('./admin/pages/recherche/recherche.module').then(m => m.RechercheModule) },


    
    {
      path: '',
      redirectTo: '',
      pathMatch: 'full',
    },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [ 
      {
        path: '',
        loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
      }]
  },

  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {  }
