import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProjetIndicateurService } from '../../plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { request } from 'http';
import { Constant } from 'src/app/shared/service/utils/contant';
import * as FileSaver from 'file-saver';
import { DetailTacheComponent } from '../../execution/taches/detail-tache/detail-tache.component';
import { DetailTepActiviteOpComponent } from '../detail-tep-activite/detail-tep-activite-op.component';

@Component({
  selector: 'app-tep-activites',
  templateUrl: './tep-activites.component.html',
  styleUrl: './tep-activites.component.scss'
})


export class TepActivitesComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  projetData: any = [] = [];
  annee: any;
  modalRef: NgbModalRef | null = null;
  isLoading: boolean = false;
  searchTerm: string = '';

  itemToDelete!: ProjetIndicateur;
  filteredtems: any = [];
  projetId: any
  pageSize: number = 10;
  pdfUrl: string;
  excelUrl: string;

  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  paginatedProjets: any = [];

  projetItems: any = [];
  selectedProjet: any;
  directionId: any;
  showFiltre: boolean = false;
  directionItems: any = [];
  userInfo: any
  dateDebut: string; 
  dateFin: string;

  constructor(
    private modalService: NgbModal,
    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private projetService: ProjetIndicateurService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
        const currentYear = new Date().getFullYear();
        this.dateDebut = `${currentYear}-01-01`; 
        this.dateFin = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {

    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();
    this.userInfo = this.localstorage.getInfoUserToService();
    
    if (this.userInfo?.data?.groupe === "admin") {

      this.showFiltre = true;
      this.getDirection();
      console.log("this.userInfo",this.userInfo);

    }
    if (this.userInfo?.data?.groupe === "point_focal") {

      this.directionId = this.localstorage.getInfoUserToService()?.data?.direction_id;
      this.getProjetByDirection(this.directionId);
    }

    //
    this.getStatistiqueBudgetaires();
  }


  getDirection() {

    this.paramservice.fetchDataDirection().subscribe(

      (response: any) => {

        //console.log("la reponse du webservice", response);

        this.directionItems = response.data;

      },
      (error) => {

        //console.log("une erreur est survenu", error);

      }
    )
  }
  getProjetByDirection(reference: any) {
    this.paramservice.getProjetByDirection(reference).subscribe(
      (response: any) => {
        this.projetItems = response.data;
        // this.getExercice();
        ////console.log("la reponse du webservice", this.projetItems);
      },
      (error: any) => {
        //console.log("une erreur est survenu", error);
      }
    )
  }//end getProjetByDirection

  //statistiques activites budgetaires
  getStatistiqueBudgetaires() {
       //check
       if(this.dateDebut === null || this.dateDebut === undefined){
        this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
        return;
      }
      if(this.dateFin === null || this.dateFin === undefined){
        this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
        return;
      }
      let projectItem: any = {};
      if(this.selectedProjet === "DEFAULT"){
        projectItem = null;
       }else{
        projectItem = this.selectedProjet;
       }

    this.isLoading = true;
    this.ngxService.start();
    this.paramservice.getStatistiqueTepActiviteBudgetaire(this.annee, projectItem, this.directionId, this.dateDebut, this.dateFin, this.userInfo?.data?.reference ).subscribe(
      (response: any) => {
        this.ngxService.stop();
        this.isLoading = false;
        this.projetData = response.data;
        this.totalRecords = this.projetData.length;
        this.updatePaginatedProjets();
      },
      (error) => {
        this.ngxService.stop();
        this.isLoading = false;
        this.messageService.add({ severity: 'error', summary: 'TEP', detail: error, life: 3000 });
        //console.log('une erreur est survenue', error);
      }
    );
  }//end getStatistiqueBudgetaires

  onPageChangeProjets(event) {
    this.firstProjets = event.page * this.rowsProjets; 
    this.updatePaginatedProjets();
  }

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.projetData.slice(start, end);
  }

      //export as document
  exportToDocument(typeDoc: any) {
    //check
    if(this.dateDebut === null || this.dateDebut === undefined){
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if(this.dateFin === null || this.dateFin === undefined){
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
    let projectItem: any = {};
    if(this.selectedProjet === "DEFAULT"){
      projectItem = null;
    }else{
      projectItem = this.selectedProjet;
    }

this.ngxService.start();

this.paramservice.printStatistiqueTepBudgetaireAsFile(this.annee, projectItem, this.directionId, this.dateDebut, this.dateFin, typeDoc, this.userInfo?.data?.reference ).subscribe(
  (response: Blob) => {
    const filename = 'Statistiques_Activite_Operationnelle.pdf';
    FileSaver.saveAs(response, filename);

    const url = URL.createObjectURL(response);
    window.open(url, '_blank');

    this.ngxService.stop();
    this.messageService.add({ severity: 'success', summary: 'Téléchargement', detail: 'Le fichier a été téléchargé avec succès', life: 3000 });
  },
  (error) => {
    this.ngxService.stop();
    this.messageService.add({ severity: 'error', summary: 'Téléchargement', detail: error, life: 3000 });
  }
);
}//end export

//detaila ctivite budgetaire
detailActiviteBudgetaire(item: any){

  this.localstorage.saveActiviteBudgetaire(item);

  const modalRef = this.modalService.open(DetailTepActiviteOpComponent, {
    backdrop: 'static',
    centered: true,
    size: 'xl'
  },);
}//end detailActiviteBudgetaire





}