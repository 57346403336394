import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { first } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-realisation-indicateurs',
  templateUrl: './realisation-indicateurs.component.html',
  styleUrl: './realisation-indicateurs.component.scss'
})


export class RealisationIndicateursComponent {

  @ViewChild('realisationModal') modalContent: TemplateRef<any>;

  submitted: boolean;

  cibleindicateurItems: any = [];

  modalRef: NgbModalRef | null = null;
  paginatedItems: any[] = [];
  first: number = 0;
  rows: number = 10;
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;
  planification: Planification = new Planification()
  items: any;
  annees: number[] = [];
  ecartColor: string = 'black'; // Couleur par défaut
  ecart: number = 0;

  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxService: NgxUiLoaderService,

  ) {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 5; i++) {
      this.annees.push(currentYear + i);
    }
  }

  ngOnInit(): void {
    this.fetchDataIndicateur();
  }

  fetchDataIndicateur() {
    this.isLoading = true;
    this.paramservice.getCibleIndicateurs().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.cibleindicateurItems = response.data;
        this.totalRecords = this.cibleindicateurItems.length
        this.updatePagination();

      },
      (error) => {

      }

    );
  }


  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.updatePagination();
  }

  updatePagination() {
    const start = this.first;
    const end = this.first + this.rows;
    this.paginatedItems = this.cibleindicateurItems.slice(start, end);
  }

  //
  confirm1(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Êtes-vous sûr de vouloir continuer ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'Vous avez acepter' });

        this.paramservice.deleteIndicateur(event).subscribe(

          (response: any) => {

          },
          (error) => {

          },
        )
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: 'Vous avez rejeté', life: 3000 });
      }
    });
  }


  openRealisation(item: any) {
    this.items = item;

    this.planification.valeur_cible_id = this.items.reference;
    this.planification.annee = this.items.annee;
    this.planification.indicateur_id=this.items.indicateur.reference;
    this.planification.valeur_realisee = this.items.valeur_realisee   ;

    //console.log(" this.planification.valeur_realisee",  this.planification.valeur_realisee);
    this.calculerEcart();
    this.modalService.open(this.modalContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }
  calculerEcart() {
    if (this.planification.valeur_realisee != null && this.items.valeur_cible != null) {
      this.ecart = this.planification.valeur_realisee - this.items.valeur_cible;

      if (this.ecart > 0) {
        this.ecartColor = 'green'; // Écart positif
      } else if (this.ecart < 0) {
        this.ecartColor = 'red'; // Écart négatif
      } else {
        this.ecartColor = 'blue'; // Écart nul
      }
    }
  }

  saveRealisation() {

    // if (!this.planification.annee) {
    //   this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez séléctionner l'année" });
    //   return;
    // }

    if (!this.planification.valeur_realisee) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez saisir la valeur cible" });
      return;
    }
    this.ngxService.start();
    this.paramservice.updateValeurcibleTorealiser(this.planification).subscribe(
      (response: any) => {
        //console.log("la réponse du webservice", response);
        this.ngxService.stop();
        this.modalService.dismissAll();
        this.fetchDataIndicateur();
      },
      (error) => {
        //console.log("une erreu et survenu", error);
        this.ngxService.stop();

      }
    )

  }
}
export class Planification {
  annee: any;
  valeur_realisee: any;
  valeur_cible_id: any
  indicateur_id: any


}