import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { AddProjetComponent } from '../add-projet/add-projet.component';
import { EditProjetComponent } from '../edit-projet/edit-projet.component';
import { BehaviorSubject } from 'rxjs';
import { Constant } from 'src/app/shared/service/utils/contant';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DetailProjetIndicateurComponent } from '../detail-projet-indicateur/detail-projet-indicateur.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projet-indicateur',
  templateUrl: './projet-indicateur.component.html',
  styleUrl: './projet-indicateur.component.scss'
})


export class ProjetIndicateurComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  projetData: any = [];
  validationform: UntypedFormGroup;
  modalRef: NgbModalRef | null = null;
  isLoading: boolean = false;
  searchTerm: string = '';
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  itemToDelete!: ProjetIndicateur;
  filteredtems: any = [];
  paginatedProjets = [];
  filteredProjetData: any[] = []; // Ajout d'une liste pour les données filtrées

  pageSize: number = 10;

  constructor(
      private modalService: NgbModal,
      public formBuilder: UntypedFormBuilder,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private projetService: ProjetIndicateurService,
      private ngxService: NgxUiLoaderService,
      private router :Router
  ) {
          this.projetService.projetCreated$.subscribe(() => {
            this.fetchDataProjet();
          });
  }

  ngOnInit(): void {
  }

  fetchDataProjet() {
    this.isLoading = true;
    this.paramservice.fetchDataProjet().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.projetData = response.data;
        this.filteredProjetData = [...this.projetData]; // Initialiser avec toutes les données
        this.totalRecords = this.projetData.length;
        this.updatePaginatedProjets(); // Initial pagination
      },
      (error) => {
        this.isLoading = false;
        console.log("une erreur est survenue", error);
      }
    );
  }//end fetchDataProjet
  
  onPageChangeProjets(event) {
    this.firstProjets =  event.first; // Mise à jour de la page actuelle
    this.rowsProjets =  event.rows ; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }
  
  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.projetData.slice(start, end);
  }
  filterProjet() {
    const lowerSearchTerm = this.searchTerm.toLowerCase();

    if (!lowerSearchTerm) {
      this.filteredProjetData = [...this.projetData]; // Réinitialiser avec toutes les données
    } else {
      this.filteredProjetData = this.projetData.filter((item: any) => {
        const matchesCode = item.code && item.code.toLowerCase().includes(lowerSearchTerm);
        const matchesLibelle = item.libelle && item.libelle.toLowerCase().includes(lowerSearchTerm);
        const matchesDescription = item.description_projet && item.description_projet.toLowerCase().includes(lowerSearchTerm);
        const matchesContexte = item.contexte && item.contexte.toLowerCase().includes(lowerSearchTerm);
        const matchesObjectifGlobal = item.objectif_global && item.objectif_global.toLowerCase().includes(lowerSearchTerm);
        const matchesObjectifsSpecifiques = item.objectifs_specifiques && item.objectifs_specifiques.toLowerCase().includes(lowerSearchTerm);
        const matchesResultatProjet = item.resultat_projet && item.resultat_projet.toLowerCase().includes(lowerSearchTerm);
        const matchesZonesIntervention = item.zones_intervention && item.zones_intervention.toLowerCase().includes(lowerSearchTerm);
        const matchesBeneficiaires = item.beneficiaires && item.beneficiaires.toLowerCase().includes(lowerSearchTerm);
        const matchesResponsableProjet = item.responsable_projet && item.responsable_projet.toLowerCase().includes(lowerSearchTerm);

        return matchesCode || matchesLibelle || matchesDescription || matchesContexte ||
          matchesObjectifGlobal || matchesObjectifsSpecifiques || matchesResultatProjet ||
          matchesZonesIntervention || matchesBeneficiaires || matchesResponsableProjet;
      });
    }

    this.totalRecords = this.filteredProjetData.length;
    this.firstProjets = 0; // Reset to the first page
    this.updatePaginatedProjets();
  }
  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Modal Open
   * @param content modal content
   */
  openModal() {
    this.modalService.open(AddProjetComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl'
    });
  }

  EditModal(projetIndicateur: any) {
    this.localstorage.saveProjet(projetIndicateur)
    const modalRef = this.modalService.open(EditProjetComponent, {
      backdrop: 'static',
      centered: true,
      size: 'xl'
    },);
  }

  DetailModal(projetIndicateur: any) {
    this.localstorage.saveProjet(projetIndicateur)
   this.router.navigate(['/suivi/plan/detail-projet'])
  }


  //methode de suppression
  confirmDeletion(project: any) {
    this.confirmationService.confirm({
        message: 'Êtes-vous sûr de vouloir supprimer ce projet ' + project.libelle + '?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', 
        rejectLabel: 'Non', 
        accept: () => {
          this.ngxService.start();
            this.paramservice.deleteProjet(project.reference).subscribe(
              (response:any)=>{
                this.ngxService.stop();
                console.log("la reponse du webserice",response);
                this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: response.message });
                this.fetchDataProjet();
              },
              (error)=>{
                this.ngxService.stop();
                console.log("une erreur est survenu",error);
                this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });  
              },
            )
          },
        reject: () => {
            //this.messageService.add({ severity: 'warn', summary: '', detail: 'Vous avez rejeté', life: 3000 });
        }
    });
  }

 

  
  changePageSize() {
    // Met à jour les données filtrées en fonction de la nouvelle taille de page sélectionnée
    this.filteredProjetData = this.filteredProjetData.slice(0, this.pageSize);
  }
}

export class ProjetIndicateurService {
  private ProjetCreatedSubject = new BehaviorSubject<boolean>(false);
  projetCreated$ = this.ProjetCreatedSubject.asObservable();
  notifyProgrammeCreated() {
    this.ProjetCreatedSubject.next(true);
  }
}