import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { GestionUser } from 'src/app/shared/models/gestionuser';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { ParameterService } from 'src/app/shared/service/params/param';

@Component({
  selector: 'app-add-gestion-acces',
  templateUrl: './add-gestion-acces.component.html',
  styleUrl: './add-gestion-acces.component.scss'
})

export class AddGestionAccesComponent {


  constructor(
    private modalService: NgbModal,
    private authService:AuthService,
    private paramService:ParameterService,
    private messageService:MessageService,
    private  fetchDataService :FecthDataService,
    private ngxService: NgxUiLoaderService,
    private fetchService:FecthDataService

  ) { 
    this.getGroupe();
    this.getDirection();
  }


  close(){

    this.modalService.dismissAll();
  }

  item: GestionUser= new GestionUser()
  groupItems:any =[];
  directionItems:any =[];
  isPasswordVisible: boolean = false;
   getGroupe(){

    this.paramService.getGroup().subscribe(

      (response:any)=>{

        //console.log("la reponse du webservice");
        
        this.groupItems = response.data;
      },
      (error)=>{

        //console.log("une erreur est survenu",error);
        
      }

    )
   }

   getDirection(){

    this.paramService.fetchDataDirection().subscribe(

      (response:any)=>{

        //console.log("la reponse du webservice");
        
        this.directionItems = response.data;
      },
      (error)=>{

        //console.log("une erreur est survenu",error);
        
      }

    )
   }
  addgestionUser(){

    if (!this.item.canSave()) {
      
      this.messageService.add({ severity: 'info', summary: '', detail: this.item.getErrorMessage() });

    }

    if (this.item.canSave()) {
      this.ngxService.start();
      this.authService.addGestionUser(this.item).subscribe(
        (response:any)=>{
          //console.log("la reponse du webservice",response);
          this.messageService.add({ severity: 'success', summary: '', detail: response.message });

          this.ngxService.stop();
          this.modalService.dismissAll();
          this.fetchDataService.notifySeriviceCreated();
        },
        (error:any)=>{
          //console.log("une erreur est survenu",error);
          
          this.messageService.add({ severity: 'error', summary: '', detail: "Une erreur est survenu" });
          this.ngxService.stop();
        }
      )
    }
   
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
