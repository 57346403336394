import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-detail-pta',
  templateUrl: './detail-pta.component.html',
  styleUrl: './detail-pta.component.scss'
})
export class DetailPtaComponent {

  actionData: any={}

  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService

  ) { }


  ngOninit(){

    this.actionData = this.localstorage.getValidationPta();

  }

  close(){

    this.modalService.dismissAll();
  }


  
}