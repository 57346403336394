<div class="container-fluid">

  <h4 id="upper">Revue Budgétaire</h4>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
           
          </div>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <!-- <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Afficher
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> Lignes</label></div> -->
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input [(ngModel)]="searchTerm" type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                    aria-controls="tickets-table" placeholder="Sigle"(keyup)="applyFilter($event.target.value)" (input)="applyFilter($event.target.value)" ></label></div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light rwd-table">
                <tr>
                  <th>Code </th>
                  <th>Libellé </th>
                  <th>Sigle </th>
                  <th>Objectif global </th>
                  <th>Résultat</th>
                  <th>Responsable </th>
                  <th style="width: 120px;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of paginatedItems ; let index = index">
                  <td>{{item?.code}}</td>
                  <td>{{item?.libelle}}</td>
                  <td>{{item?.sigle}}</td>

                  <td>{{item?.objectif_global}}</td>
                  <td>{{item?.resultat_projet}}</td>
                  <td>{{item?.responsable_projet}}</td>
                  <td>
                    <a (click)="EditModal(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover
                      title="Edit">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a (click)="confirmDeletion(item)" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>&nbsp;
                    <a (click)="addProjetToActiviter(item)" href="javascript:void(0);" class="me-3 text-primary" v-b-tooltip.hover title="Ajouter une  activité">
                      <i class="mdi mdi-plus font-size-18"></i>
                    </a>
                  </td>
                </tr>

              </tbody>
            </table>


          </div>
        </div>
       <div class="d-flex justify-content-center" *ngIf="isLoading === true">
            <div class="container">
              <div class="row">
                <div class="text-center">
                  <h5 id="graycolor">Chargement en cours</h5>
                  <div class="loader4"></div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="paginatedItems.length === 0 && isLoading === false" class="d-flex justify-content-center" >
            <div class="container">
              <div class="row">
                <div class="text-center">
                  <h5 id="graycolor">Aucune donnée trouvée</h5>
                </div>
              </div>
            </div>
            
          </div>
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
          [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
        </p-paginator>
      </div>

    </div>
  </div>
</div>



<p-confirmDialog />
<p-toast />