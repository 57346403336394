import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Direction } from 'src/app/shared/models/direction';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { DirectionService } from '../direction/direction.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-edit-direction',
  templateUrl: './edit-direction.component.html',
  styleUrl: './edit-direction.component.scss'
})

export class EditDirectionComponent {

  item: Direction= new Direction()

  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService,
    private DirectionService: DirectionService,
    private ngxService: NgxUiLoaderService,
    private messageService: MessageService


  ) { }


  ngOnInit(){

    this.item = this.localstorage.getDirection();

    //console.log("this.ite",this.item);
    
  }

  close(){

    this.modalService.dismissAll();
  }


  Edit(){

    this.ngxService.start();
    const item =this.localstorage.getDirection();
    this.paramService.editDirection(item.reference,this.item).subscribe(
      (response:any)=>{
        //console.log("la reponse du webservice",response);
        this.ngxService.stop();
        this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
        this.DirectionService.notifyProgrammeCreated();
      },
      (error:any)=>{
        //console.log("une erreur est survenu",error);
        this.ngxService.stop();
        this.DirectionService.notifyProgrammeCreated();
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: error });


      }
    )
  }
}