import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-importation-pta',
  templateUrl: './importation-pta.component.html',
  styleUrl: './importation-pta.component.scss'
})

export class ImportationPtaComponent implements OnInit {

  // breadcrumb items
  breadCrumbItems: Array<{}>;
  @ViewChild('fileInput') fileInput: any;
  @ViewChild('sousActivitesModal') modalContent: TemplateRef<any>;
  @ViewChild('tacheModal') modalContent2: TemplateRef<any>;

  excelData: any = {}
  projetsList: any[] = [];
  filteredSousActivitesList: any[] = [];
  showInformation: boolean = false;
  selectedFile: File | null = null;
  modalRef: NgbModalRef | null = null;

  firstProjets = 0;
  rowsProjets = 10;
  //
  firstActivite = 0;
  rowsActicite = 10;
  paginatedActivite: any = [];
  totalRecords1: number = 0; // Nombre total d'éléments
  paginatedTache: any = [] = [];
  totalRecords2: number = 0; // Nombre total d'éléments
  paginatedProjets = [];
  totalRecords: number = 0; // Nombre total d'éléments

  //tachepainateds
  firstTache = 0;
  rowsTache = 10;

  //end
  annee: any; projet: any;
  ptaActivites: any[] = [];
  selectedActivite: any;
  listSousActivites: any[] = [];
  selectedTache: any;
  isLoading: boolean = false;

  constructor(
    private paramservice: ParameterService,
    private ngxService: NgxUiLoaderService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.annee = this.localStorage.getExerciceToUserToSuivieEvaluation();
   // console.log("this.annee",this.annee);
    
    this.breadCrumbItems = [];

    this.getProjectList();
  }

  //get list of projects
  private getProjectList() {
    this.isLoading = true;
    this.paramservice.fetchDataProjet().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.projetsList = response.data;
        //console.log(this.projetsList);
      },
      (error) => {
        this.isLoading = false;
        //console.log("une erreur est survenue", error);
      }
    );
  }//end getProjectList

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }//end onFileSelected

  selectFile() {
    // Réinitialiser la valeur de l'élément input type="file"
    this.fileInput.nativeElement.value = '';
    // Ouvrir la boîte de dialogue de sélection de fichier
    this.fileInput.nativeElement.click();
  }

  demarrerImportation() {
    //test
    //console.log(this.projet);
    this.ptaActivites = [];

    //check projet
    if(!this.projet){
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez sélectionner un projet avant de continuer" });
      return;
    }

    //check annee
    if (!this.annee) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez saisir l'année" });
      return;
    }

    if (new String(this.annee).length < 4) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez saisir une année valide" });
      return;
    }

    //check fichier
    if (!this.selectedFile) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'Veuillez sélectionner un fichier' });
      return;
    }

    if (this.selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || this.selectedFile.type === 'application/vnd.ms-excel') {
      this.ngxService.start();
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('query', this.annee);
      formData.append('projet', this.projet.code);
      this.isLoading = true;

      this.paramservice.sendFile(formData).subscribe(
        (response: any) => {
          this.ngxService.stop();
          this.showInformation = true;
          this.isLoading = false;
          this.ngxService.stop();
          this.excelData = response?.data?.pta_activites;

          //list of sous activites
          this.listSousActivites = response?.data?.sous_activites;

          //console.log(" this.excelData", this.excelData.length);
        
          this.ptaActivites = this.excelData.flat();

          //.length
          //console.log("ptaActivites après flat:", this.ptaActivites.length);
          this.totalRecords = this.ptaActivites.length; //

          this.updatePaginatedProjets();
          this.updatePaginatedTache();

          this.messageService.add({ severity: 'success', summary: 'Succès', detail: response.message });
        },
        (error) => {
          this.ngxService.stop();

          //console.log(error);

          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: error });
        }
      );
    } else {
      this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Veuillez sélectionner un fichier Excel valide' });
    }
  }//end uploadFile

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    //console.log("Start:", start, "End:", end);
    //console.log("Total records:", this.totalRecords); // Devrait être 153

    this.paginatedProjets = this.ptaActivites.slice(start, end);
    //console.log("Paginated Projets:", this.paginatedProjets.length); // Devrait correspondre à rowsProjets (par ex. 10)
  }//end updatePaginatedProjets



  onPageChangeProjets(event) {
    this.firstProjets = event.first ;
    this.rowsProjets = event.rows ;
    //console.log("Page:", event.page, "First Projets:", this.firstProjets);

    this.updatePaginatedProjets();
  }//end onPageChangePro  jets

  openModal(activite: any) {
    this.selectedActivite = activite;


    this.filteredSousActivitesList = this.listSousActivites.filter(x => x.pta_activite_id === activite.id);

    // Ouvrir le modal en passant le template

    //console.log("this.selectedActivite", this.selectedActivite);
    this.totalRecords1 = this.selectedActivite?.pta_sous_activites?.length;

    this.updatePaginatedActivite();

    this.modalService.open(this.modalContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }//end openModal

  //
  updatePaginatedActivite() {
    //console.log("pta_sous_activites is array?", Array.isArray(this.selectedActivite?.pta_sous_activites));

    if (!this.selectedActivite || !Array.isArray(this.selectedActivite.pta_sous_activites)) {
      //console.error("selectedActivite ou pta_sous_activites n’est pas un tableau.");
      return;
    }
  
    //console.log("pta_sous_activites est un tableau?", Array.isArray(this.selectedActivite.pta_sous_activites));

    const start = this.firstActivite;
    const end = start + this.rowsActicite;
    //console.log("Start:", start, "End:", end, "Total Records:", this.selectedActivite?.pta_sous_activites?.length);
    this.paginatedActivite = this.selectedActivite?.pta_sous_activites.slice(start, end);
    //console.log("Paginated Activités:", this.paginatedActivite);
  }//end updatePaginatedActivite

  onPageChangeActivite(event) {
    this.firstActivite = event.first;
    this.firstActivite = event.rows ;
    this.updatePaginatedActivite();
  }
  openModalTache(sousActivite: any) {
    console.log("sousActivite",sousActivite);
    
    // Convertir sousActivite 
    this.selectedTache = Array.isArray(sousActivite) ? sousActivite : [sousActivite];

    //console.log("this.selectedTache", this.selectedTache);

    // Initialisation du nombre total d'enregistrements
    this.totalRecords2 = this.selectedTache.length;

    this.updatePaginatedTache();

    // Ouvrir le modal en passant le template
    this.modalService.open(this.modalContent2, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  updatePaginatedTache() {
    const start = this.firstTache;
    const end = start + this.rowsTache;

    // Vérifiez que selectedTache est un tableau avant d'essayer de le slicer
    if (Array.isArray(this.selectedTache)) {
      this.paginatedTache = this.selectedTache.slice(start, end);
      //console.log("Paginated Tache:", this.paginatedTache);
    } else {
      //console.error("Erreur: selectedTache n'est pas un tableau");
      this.paginatedTache = []; // Assurez-vous que paginatedTache est un tableau même en cas d'erreur
    }
  }


  onPageChangeTache(event) {
    this.firstTache =  event.first;
    this.rowsTache =  event.rows;
    this.updatePaginatedTache();
  }

}


