<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Ajouter Un Programme</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="code">Code</label>
                    <input [(ngModel)]="programmeData.code" name="code" type="text" class="form-control" id="code" placeholder="un code">
                </div>
                <div class="form-group col-md-6">
                    <label for="libelle">Libellé</label>
                    <input [(ngModel)]="programmeData.libelle" name="libelle" type="text" class="form-control" id="libelle" placeholder="libelle">
                </div>
            </div>

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="chargedeprogramme">Chargé de Programme</label>
                    <input [(ngModel)]="programmeData.chargedeprogramme" name="chargedeprogramme" class="form-control" id="chargedeprogramme" placeholder="Charge de Programme">
                </div>
                <div class="form-group col-md-6">
                    <label for="poids">Poids</label>
                    <input [(ngModel)]="programmeData.poids" name="poids" type="number" class="form-control" id="poids" placeholder="un Poids" minlength="10">
                </div>
            </div>

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="objectif_global">Objectif global</label>
                    <input [(ngModel)]="programmeData.objectif_global" name="objectif_global" type="text" class="form-control" id="objectif_global" placeholder="Objectif global">
                </div>

                <div class="form-group col-md-6">
                    <label for="objectif_global">Observation</label>
                    <input [(ngModel)]="programmeData.observations" name="objectif_global" type="text" class="form-control" id="objectif_global" placeholder="Objectif global">
                </div>
            </div>

            <div class="text-end">
                <button (click)="close()" type="button" class="btn btn-info ms-1 annu"><i class="pi pi-times" style="color: white"></i>
                    Annuler</button>&nbsp;

                <button (click)="addProgramme()" type="submit" class="btn btn-success"> <i class="pi pi-save" style="color: white"></i> Enregistrer</button>
            </div>
        </form>
    </div>
</div>


<p-toast />
<ngx-ui-loader></ngx-ui-loader>
