<div class="container-fluid">
    <h3 id="upper">Importation du fichier excel</h3>
    <div class="card">

        <div class="container mt-5">

            <!-- First Line: Projet -->
            <div class="row mb-3">
                <div class="col d-flex justify-content-center align-items-center">
                    <!-- Projet Selection -->
                    <div class="d-flex align-items-center">
                        <label for="projet" class="me-2">Projet:</label>
                        <select title="Veuillez sélectionner un projet" [(ngModel)]="projet" name="projet" class="form-control" id="projet" style="width: auto;">
                            <option *ngFor="let projet of projetsList" [ngValue]="projet">{{ projet.libelle }}</option>
                        </select>
                    </div>
                </div>
            </div>
        
            <!-- Second Line: Année and Fichier -->
            <div class="row mb-3">
                <div class="col d-flex justify-content-center align-items-center">
                    <!-- Année Input -->
                    <div class="d-flex align-items-center me-3">
                        <label for="annee" class="me-2">Année:</label>
                        <input title="Veuillez renseigner l'année" [(ngModel)]="annee" name="annee" type="number"
                            class="form-control" id="annee" style="width: auto;" placeholder="Veuillez saisir l'année">
                    </div>
                
                    <!-- File Input -->
                    <div>
                        <input title="Veuillez sélectionner le fichier"
                            style="border-radius: 5px; border-color: #30489e; cursor: pointer; border-style: none;" type="file"
                            (change)="onFileSelected($event)" accept=".xlsx, .xls" class="form-control-file" #fileInput>
                    </div>
                </div>
            </div>
        
            <!-- Third Line: Importer Button -->
            <div class="row">
                <div class="col d-flex justify-content-center align-items-center">
                    <!-- Import Button -->
                    <button title="Cliquez ici pour importer le fichier Excel" class="btn btn-success"
                        (click)="demarrerImportation()">
                        <i class="mdi mdi-cloud-upload me-2"></i> Importer
                    </button>
                </div>
            </div>

        </div>
        

        <br>


        <div class="d-flex justify-content-center" *ngIf="showInformation === false">
            <div class="container">
                <div class="row">
                    <div class="text-center">
                        <h4 class="text-success">Veuillez sélectionner un fichier pour voir les détails des PTA</h4>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="showInformation === true" class="container">

            <div class="row">
                <h5 class="modal-title">Activités ({{ptaActivites.length}})</h5>
                <!-- <h5 class="modal-title">Tâche ({{selectedTache.pta_tache.length}})</h5> -->

                <div class="table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead class="thead-light">
                            <tr>
                                <th>Code</th>
                                <th>Libellé</th>
                                <th>Début</th>
                                <th>Fin</th>
                                <th>Mode d'exécution</th>
                                <th>Financement de l'État</th>
                                <th>Financement propre</th>
                                <th>Financement PTF</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let activite of paginatedProjets">
                                <td>{{ activite.code_activite }}</td>
                                <td>{{ activite.activite }}</td>
                                <td>{{ activite.date_debut | dateFormat }}</td>
                                <td>{{ activite.date_fin | dateFormat }}</td>
                                <td>{{ activite.mode_execution }}</td>
                                <td class="text-end">{{ activite.mt_financement_etat  | formatMoney :null}}</td>
                                <td class="text-end">{{ activite.mt_financement_fond_propre | formatMoney :null }}</td>
                                <td class="text-end">{{ activite.mt_financement_ptf| formatMoney :null }}</td>
                                <td>
                                    <button (click)="openModal(activite)"
                                        title="Cliquez ici pour voir les sous-activités" class="btn btn-success btn-sm">
                                        <i class="mdi mdi-forward me-2"></i>Sous-activités
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                    <div class="container">
                        <div class="row">
                            <div class="text-center">
                                <h5 id="graycolor">Chargement en cours</h5>
                                <div class="loader4"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="paginatedProjets.length === 0 && isLoading === false" class="d-flex justify-content-center">
                    <div class="container">
                        <div class="row">
                            <div class="text-center">
                                <h5 id="graycolor">Aucune donnée trouvée</h5>
                                <div class="loader4"></div>
                            </div>
                        </div>
                    </div>

                </div>
                <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
                    [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
                </p-paginator>
            </div>

        </div>
    </div>

    <!-- Définition du modal avec ng-template -->
    <ng-template #sousActivitesModal let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Sous-activités({{ filteredSousActivitesList.length}})</h5>
            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div *ngIf="selectedActivite">
                <div class="row">
                    <div class="col-lg-4 col-md-6 mb-4"
                        *ngFor="let sousActivite of filteredSousActivitesList">

                        <div class="card h-100">
                            <div class="card-header card-green text-center">
                                <h5> {{ sousActivite.code_sous_activite }} - {{ sousActivite.sous_activite }}</h5>
                            </div>

                            <div class="card-body">
                                <div class="">
                                    <div class="flex-1">
                                        <h5 class="text-center">
                                            De {{ sousActivite.date_debut | dateFormat }} à &nbsp; {{
                                            sousActivite.date_fin
                                            | dateFormat }}
                                        </h5>

                                        <!-- Financial Table -->
                                        <table class="table">
                                            <thead class="data-header">
                                                <tr>
                                                    <th colspan="4" class="text-center data_section"> Financement </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><strong>État:</strong></td>
                                                    <td class="text-right">{{ sousActivite.mt_financement_etat  | formatMoney :null}}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Fonds propres:</strong></td>
                                                    <td class="text-right">{{ sousActivite.mt_financement_fond_propre | formatMoney :null }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>PTF:</strong></td>
                                                    <td class="text-right">{{ sousActivite.mt_financement_ptf | formatMoney :null }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- End Financial Table -->

                                        <!-- other data Table -->
                                        <table class="table">
                                            <thead class="data-header">
                                                <tr>
                                                    <th colspan="4" class="text-center data_section"> Autres
                                                        informations </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><strong>Poids:</strong></td>
                                                    <td class="text-right">{{ sousActivite.poids }}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Struct. resp:</strong></td>
                                                    <td class="text-right">{{ sousActivite.structure_responsable }}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Mode d'exécution:</strong></td>
                                                    <td class="text-right">{{ sousActivite.mode_execution }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- End other data Table -->
                                    </div>

                                    <div class="text-center">
                                        <button (click)="openModalTache(sousActivite)" class="btn btn-success btn-sm">
                                            <i class="mdi mdi-forward me-2"></i>Tâches
                                        </button>
                                    </div>
                                </div>

                                <div *ngIf="filteredSousActivitesList.length === 0 && isLoading === false"
                                    class="d-flex justify-content-center">
                                    <div class="container">
                                        <div class="row">
                                            <div class="text-center">
                                                <h5 id="graycolor">Aucune donnée trouvée</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                               
                            </div>
                        </div>

                    </div>
                    <p-paginator (onPageChange)="onPageChangeActivite($event)" [first]="firstActivite"
                    [rows]="rowsActicite" [totalRecords]="totalRecords1"
                    [rowsPerPageOptions]="[10, 20, 30]">
                </p-paginator>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Fermer</button>
        </div>
    </ng-template>

    <ng-template #tacheModal let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Tâche ({{selectedTache.length}})</h5>
            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div *ngIf="selectedActivite">
                <div class="row">
                    <div class="col-lg-4 col-md-6 mb-4" *ngFor="let tache of paginatedTache">

                        <div class="card h-100">
                            <div class="card-header card-green text-center">
                                <h5>
                                    Libellé: {{ tache.sous_activite }}</h5>
                            </div>

                            <div class="card-body">
                                <div class="">
                                    <div class="flex-1">
                                        <h5 class="text-center">
                                           Code &nbsp; {{tache.code_sous_activite }}
                                        </h5>
                                        <h5 class="text-center">
                                            De {{ tache.date_debut | dateFormat }} à &nbsp; {{tache.date_fin |
                                            dateFormat }}
                                        </h5>

                                        <!-- Financial Table -->
                                        <table class="table">
                                            <thead class="data-header">
                                                <tr>
                                                    <th colspan="4" class="text-center data_section"> Financement </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><strong>Montant tâche:</strong></td>
                                                    <td class="text-right"> {{ tache.mt_financement_etat | formatMoney :null}}
                                                    <td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <p-paginator (onPageChange)="onPageChangeTache($event)" [first]="firstTache" [rows]="rowsTache"
            [totalRecords]="totalRecords2" [rowsPerPageOptions]="[10, 20, 30]">
          </p-paginator>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Fermer</button>
        </div>
    </ng-template>


    <ngx-ui-loader></ngx-ui-loader>
    <p-toast />