<div class="breadcrumbs overlay">
    <div class="container">
        <div class="bread-inner">
            <div class="row">
                <div class="col-12">
                    <h2>Renforcement des Capacités de Gestion et de Valorisation des Ressources Minérales au Bénin</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<br>

<body>
    <main class="container my-4">
        <div class="row mb-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-body ">
                        <p class="text-center">Dans le cadre de la mise en œuvre du Projet d'Action du Gouvernement
                            (PAG), le pilier 2, qui vise à transformer structurellement l’économie béninoise, s’inscrit
                            comme un levier essentiel. L’Office Béninois de Recherches Géologiques et Minières (OBRGM)joue un rôle clé dans cette dynamique grâce à trois initiatives majeures :</p>
                        <ul class=" text-center align-content-center">
                            <p> <strong><i class="pi pi-graduation-cap" style="font-size: 1rem"></i>
                                    Le Projet de Recherche des Ressources Minérales du Bénin (PRRMB).</strong> </p>
                            <p><strong><i class="pi pi-graduation-cap" style="font-size: 1rem"></i>
                                    Le Projet de Valorisation des Ressources Minérales du Bénin (PVRMB).</strong></p>
                                    <p><strong><i class="pi pi-graduation-cap" style="font-size: 1rem"></i>
                                     Le   Projet Benin Lithium (PBL) .</strong></p>
                        </ul>
                        <p class="text-center">ces projets ambitionnent de renforcer les
                            capacités nationales de recherche, d’exploitation et de valorisation des ressources
                            minérales  pour contribuer au développement économique du pays.</p>

                    </div>
                </div>
            </div>
        </div>

        <!-- Sections avec des cartes uniformes -->
        <div class="row g-4">

            <!-- Section 2 -->
            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body">
                        <h5 class="card-title text-center">Un dépôt national pour une gestion sécurisée</h5>
                        <p>La construction du Dépôt National de Substances Explosives à usage civil dans
                            l’arrondissement de Dan, situé dans la commune de Djidja, marque une étape importante dans
                            le cadre du PVRMB. Cette infrastructure découle de la décision du Conseil des Ministres du
                            16 décembre 2015 et s'inscrit dans la stratégie gouvernementale visant à répondre aux
                            exigences de sécurité nationale.</p>

                    </div>
                </div>
            </div>

            <!-- Section 3 -->
            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body">
                        <h5 class="card-title text-center">Pourquoi Djidja ?</h5>
                        <p>La commune de Djidja abrite plus de 60 % des carrières de production de granulats au Bénin
                            , ce qui en fait un point stratégique pour la gestion des substances explosives à usage civil  utilisées
                            dans ces activités. Cette concentration a conduit à la nécessité de centraliser et sécuriser
                        </p>
                        <ul>
                            <li><strong>L'importation </strong> de substances explosives à usage civil,</li>
                            <li> <strong>Le stockage</strong> dans un lieu répondant aux normes  de sécurité en vigueur au Bénin,</li>
                            <li> <strong>La distribution et l’utilisation</strong> sur l’ensemble du territoire
                                national,</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Section 4 -->
            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body">
                        <h5 class="card-title text-center">Objectifs Stratégiques</h5>
                        <p>Ce projet vise plusieurs objectifs :</p>
                        <ul>
                            <li><strong>Renforcer la sécurité</strong> dans la chaîne d’approvisionnement et
                                d’utilisation des substances
                                explosives à usage civil .</li>
                            <li> <strong>Optimiser la logistique</strong> liée aux activités extractives pour une
                                exploitation plus
                                rentable et durable.</li>
                            <li> <strong>Promouvoir la valorisation locale</strong> des ressources minérales par une
                                meilleure gestion des
                                intrants nécessaires à l’extraction.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Section 5 -->
            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body">
                        <h5 class="card-title text-center">Impacts Économiques et Environnementaux</h5>
                        <p>La réalisation de ce dépôt contribuera à :</p>
                        <ul>
                            <li> <strong>Stimuler l’économie locale</strong>, notamment à Djidja, en soutenant les
                                acteurs des carrières
                                de granulats.</li>
                            <li> <strong>Réduire les risques environnementaux </strong> et <strong> sécuritaires</strong> liés au stockage
                                et à l’utilisation
                                anarchique des substances explosives à usage civil.</li>
                            <li> <strong>Attirer des Investisseurs nationaux et  étrangers</strong>, rassurés par un cadre structuré
                                et sécurisé.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Section 6 -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-center">Perspectives</h5>
                        <p>“L’OBRGM, à travers le PRRMB, le PVRMB et le PBL illustre l’engagement du gouvernement à exploiter durablement le potentiel minier du Bénin.
                            . La mise en service du Dépôt National
                            de substances explosives à usage civil représente une avancée majeure pour l’industrie
                            extractive nationale, consolidant ainsi le rôle du secteur minier comme moteur de croissance
                            économique.</p>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
</body>
<br>