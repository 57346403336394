import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { Centre } from 'src/app/shared/models/centre';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-edit-centre',
  templateUrl: './edit-centre.component.html',
  styleUrl: './edit-centre.component.scss'
})

export class EditCentreComponent {

  programmeData: any = [];
  photoPreview: string | ArrayBuffer | null = null;

  constructor(
    private modalService: NgbModal,
    private paramService: ParameterService,
    private localstorage: LocalStorageService,
    private messageService: MessageService,

    private fetchService: FecthDataService,
    private ngxservice: NgxUiLoaderService

  ) { }

  item: Centre = new Centre()
  departement: any = [];
  commune: any = [];
  arrondissement: any = [];
  selectedDepartementId: any;
  selectedCommuneId: any;
  selectedArrondissement: any;
  ngOnInit() {

    this.item = this.localstorage.getCentre();
    this.getDepartement();

    this.photoPreview = this.localstorage.getCentre()?.image_url;

  }

  close() {

    this.modalService.dismissAll();
  }


  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.item.photo = file;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.photoPreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  EditCentre() {

    if (!this.item.description || !this.item.nom || !this.item.longitude || !this.item.latitude || !this.item.arrondissement_id ) {

      this.messageService.add({ severity: 'warn', summary: Constant.rechercheiTitle, detail: "Veuillez renseigner tous les champs." });
      return;
    } else {
      const item = this.localstorage.getCentre();

      this.ngxservice.start();
      let formData: FormData = new FormData();
      if (!this.item.photo) {
        formData.append('nom', this.item.nom);
        formData.append('longitude', this.item.longitude);
        formData.append('latitude', this.item.latitude);
        formData.append('description', this.item.description);
        formData.append('arrondissement_id', this.item.arrondissement_id);
      }
      else if (this.item.photo) {
         formData.append('nom', this.item.nom);
        formData.append('longitude', this.item.longitude);
        formData.append('latitude', this.item.latitude);
        formData.append('description', this.item.description);
        formData.append('arrondissement_id', this.item.arrondissement_id);
        formData.append('photo', this.item.photo);
      }
  
      this.paramService.editCentre(item.reference, formData).subscribe(
        (response: any) => {
         // //console.log("la reponse du webservice", response);

          this.modalService.dismissAll();
          this.messageService.add({ severity: 'success', summary: Constant.rechercheiTitle, detail: response.message });
          this.fetchService.notifySeriviceCreated();
          this.ngxservice.stop();
        },
        (error: any) => {
          ////console.log("une erreur est survenu", error);
          this.messageService.add({ severity: 'erreur', summary: Constant.rechercheiTitle, detail: error });
          //  this.modalService.dismissAll();
          this.fetchService.notifySeriviceCreated();
          this.ngxservice.stop();

        }
      )
    }


  }


  getDepartement() {

    this.paramService.getStatsDepartement().subscribe(

      (response: any) => {

        this.departement = response.data;
        //console.log("this.departement", this.departement);

      },
      (error) => {

        //console.log("une errer est survenu", error);

      }
    )
  }



  onDepartementChange(id: any) {
    this.selectedDepartementId = id;
    this.getCommunesById(id);
  }

  oncommuneChange(id: any) {
    //console.log("id", id);

    this.selectedCommuneId = id;
    this.getArrondissementById(id);
  }

  getCommunesById(id: any) {
    this.paramService.getCommuneByDepartement(id).subscribe(
      (response: any) => {
        this.commune = response.data;
        //console.log("this.commune", this.commune);
      },
      (error) => {
        //console.log("une erreur est survenue", error);
      }
    );
  }

  getArrondissementById(id: any) {
    this.paramService.getArrondissementById(id).subscribe(
      (response: any) => {
        this.arrondissement = response.data;
        //console.log("this.arrondissement", this.arrondissement);
      },
      (error) => {
        //console.log("une erreur est survenue", error);
      }
    );
  }
}