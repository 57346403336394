import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Profil } from 'src/app/shared/models/profil';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrl: './profil.component.scss'
})
export class ProfilComponent {

  infoUser:any;
  item :Profil = new Profil();
  constructor(

    private authService:AuthService,
    private localStorage:LocalStorageService,
    private messageService:MessageService,
    private router:Router,
    private ngxUiLoader :NgxUiLoaderService

  ){}
  
  ngOnInit(){
    this.infoUser = this.localStorage.getInfoUserToService()?.data?.user;
    console.log("this.infoUser",this.infoUser);
    this.item.user_ref=this.infoUser.reference
    console.log(" this.item.user_ref", this.item.user_ref);
    
  }

  updateProfil(){

    if (!this.item.canSend()) { 
      this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: this.item.getErrorMessage() });
      return;
    }
    
    if (this.item) {
      
      this.ngxUiLoader.start();
      this.authService.updateProfil(this.item).subscribe(
        (response: any) => {
          this.ngxUiLoader.stop();

          this.messageService.add({ severity: 'success', summary: 'OBGRM', detail: response.message });

          this.router.navigate(['/login']);
          localStorage.clear();

        },
        (error) => {
          //console.log("une erreur est survenue", error);
          this.ngxUiLoader.stop();

          this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: error });

        }
      ).add(() => {
      });
    } 
  }
}
