import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { Ressource } from 'src/app/shared/models/ressource';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-edit-resource',
  templateUrl: './edit-resource.component.html',
  styleUrl: './edit-resource.component.scss'
})

export class EditResourceComponent {
  photoPreview: string | ArrayBuffer | null = null;
  photoPreviewValorisation: string | ArrayBuffer | null = null;
  photoPreviewIcone: string | ArrayBuffer | null = null;
  item: Ressource = new Ressource()
  typeRessourceData: any = [];

  constructor(
    private modalService: NgbModal,
    private paramService: ParameterService,
    private localstorage: LocalStorageService,
    private messageService: MessageService,
    private fetchService: FecthDataService,
    private ngxservice: NgxUiLoaderService
  ) { }


  ngOnInit() {
    this.item.perspective = this.localstorage.getRessource().perspective;
    this.item.titre = this.localstorage.getRessource().titre;
    this.item.type_ressource_id = this.localstorage.getRessource().type_ressource_id;
    this.item.description_valorisation = this.localstorage.getRessource().description_valorisation;

    console.log("type_ressource_id", this.item.type_ressource_id);

    this.photoPreview = this.localstorage.getRessource()?.photo;
    this.photoPreviewValorisation = this.localstorage.getRessource()?.photo_valorisation;
    this.photoPreviewIcone = this.localstorage.getRessource()?.icone;

    this.fetchTypeRessources();
  }

  close() {
    this.modalService.dismissAll();
  }


  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.item.photo = file;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.photoPreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }//end onFileSelected

  onPhotoValorisationSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.item.photo_valorisation = file;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.photoPreviewValorisation = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }//end onPhotoValorisationSelected
  onPhotoIconeSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.item.icone = file;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.photoPreviewIcone = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
  editRessource() {
    // if (!this.item.canSave()) {
    //   this.messageService.add({ severity: 'warn', summary: Constant.rechercheiTitle, detail:this.item.getErrorMessage() });
    //   return;
    // }
    //todo: corriger ceci, et faire un controle correct conformement a ce quon a lhabitude de faire. 
    // else {
    this.ngxservice.start();
    const item = this.localstorage.getRessource();
    let formData: FormData = new FormData();

    //data
    formData.append('type_ressource_id', this.item.type_ressource_id);
    formData.append('perspective', this.item.perspective);
    formData.append('titre', this.item.titre);
    formData.append('description_valorisation', this.item.description_valorisation);

    //photo
    if (this.item.photo) {
      formData.append('photo', this.item.photo);
    }
    //photo valorisation
    if (this.item.photo_valorisation) {
      formData.append('photo_valorisation', this.item.photo_valorisation);
    }
    if (this.item.icone) {
      formData.append('icone', this.item.icone);
    }

    this.paramService.editRessource(item.reference, formData).subscribe(
      (response: any) => {
        this.ngxservice.stop();
        this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.rechercheiTitle, detail: response.message });
        this.fetchService.notifySeriviceCreated();
        this.ngxservice.stop();
      },
      (error: any) => {
        this.messageService.add({ severity: 'erreur', summary: Constant.rechercheiTitle, detail: error });
        // this.modalService.dismissAll();
        this.fetchService.notifySeriviceCreated();
        this.ngxservice.stop();
      }
    )
    // }
  }//end editRessource

  //recup liste des types de ressources
  fetchTypeRessources() {
    this.paramService.fetchDataTypeResource().subscribe(
      (response: any) => {
        this.typeRessourceData = response.data;
        // console.log("this.programmeData",this.programmeData);
      },
      (error) => {
        // console.log("une erreur est survenu",error);
      }
    );
  }//end fetchTypeRessources
}