import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { GestionUser } from 'src/app/shared/models/gestionuser';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-edit-gestion-acces',
  templateUrl: './edit-gestion-acces.component.html',
  styleUrl: './edit-gestion-acces.component.scss'
})

export class EditGestionAccesComponent {

  item: GestionUser = new GestionUser()
  items:any = {};
  referenceUser: any;
  isPasswordVisible: boolean = false;
  ischeked:any;
  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private paramService: ParameterService,
    private localstorage: LocalStorageService,
    private fecthService: FecthDataService,
    private messageService: MessageService,
    private ngxService: NgxUiLoaderService,

  ) {
    
   }


  ngOnInit() {

   this.items = this.localstorage.getUsesModify();
   console.log(" this.items ", this.items );
   
   this.item.name = this.items.name;
   this.item.surname = this.items.surname;
   this.item.phone = this.items.phone;
   this.item.email_address = this.items.email;
   this.item.identifiant = this.items.identifiant;
   this.item.direction = this.items.direction.id;
   this.item.acces_suivi_evaluation = this.items.acces_suivi_evaluation;
   this.item.acces_valoriation = this.items.acces_valoriation;
   this.item.password = '';
    //.log("this user", this.item);
   // this.ischeked =this.localstorage.getUsesModify().acces_suivi_evaluation;
    //this.item.acces_suivie_evaluation = this.ischeked;
    //.log("this.ischeked",this.item.acces_suivi_evaluation);
    
    this.referenceUser = this.localstorage.getUsesModify()?.reference;
    //.log("  this.referenceUser ", this.referenceUser);
    this.item.email_address = this.localstorage.getUsesModify()?.email
    this.getGroupe();
    this.getDirection();

  }

  close() {

    this.modalService.dismissAll();
  }


  Edit() {
    if (!this.item.name || this.item.name.trim() === '') {
      this.messageService.add({ severity: 'info', summary: '', detail: 'Le nom est obligatoire' });
      return;
    }

    if (!this.item.surname || this.item.surname.trim() === '') {
      this.messageService.add({ severity: 'info', summary: '', detail: 'Le prénom est obligatoire' });
      return;
    }

    if (!this.item.phone) {
      this.messageService.add({ severity: 'info', summary: '', detail: 'Le téléphone est obligatoire' });
      return;
    }

    if (!this.item.email_address || this.item.email_address.trim() === '') {
      this.messageService.add({ severity: 'info', summary: '', detail: "L'adresse email est obligatoire" });
      return;
    }

    if (!this.item.identifiant || this.item.identifiant.trim() === '') {
      this.messageService.add({ severity: 'info', summary: '', detail: "L'identifiant est obligatoire" });
      return;
    }

    if (!this.item.password || this.item.password.trim() === '') {
      this.messageService.add({ severity: 'info', summary: '', detail: 'Le mot de passe est obligatoire' });
      return;
    }

    if (!this.item.groupe) {
      this.messageService.add({ severity: 'info', summary: '', detail: 'Le groupe est obligatoire' });
      return;
    }



    this.ngxService.start();
    const item = this.localstorage.getUsers();

    //.log("item", item);

    const itemUser = {

      ...this.item,
      "user": this.referenceUser
    };


    this.authService.editGestionUser(itemUser).subscribe(
      (response: any) => {
        //.log("la reponse du webservice", response);
        this.messageService.add({ severity: 'success', summary: 'OBGRM', detail: response.message });
        this.ngxService.stop();
        this.modalService.dismissAll();
        this.fecthService.notifySeriviceCreated();
      },
      (error: any) => {
        //.log("une erreur est survenu", error);
        this.ngxService.stop();

      }
    )
  }

  groupItems: any = [];
  directionItems: any = [];
  selectedGroup: any
  getGroupe() {

    this.paramService.getGroup().subscribe(

      (response: any) => {

        //.log("la reponse du webservice");

        this.groupItems = response.data;

        this.selectedGroup = this.groupItems.find(group => group.id === this.items?.groupe?.id);

        this.item.groupe = this.selectedGroup;
        //.log("this.item.groupe ", this.item.groupe);

      },
      (error) => {

        //.log("une erreur est survenu", error);

      }

    )
  }
  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
  getDirection() {

    this.paramService.fetchDataDirection().subscribe(

      (response: any) => {

        //.log("la reponse du webservice");

        this.directionItems = response.data;
      },
      (error) => {

        //.log("une erreur est survenu", error);

      }

    )
  }
}