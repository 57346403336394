<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Modifier Une Tache</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="d-flex">
                <div class="form-group col-md-6">
                    <label for="name">Libelle</label>
                    <input [(ngModel)]="actionData.libelle" name="libelle" type="text" class="form-control" id="name" placeholder=" un code">
    
                </div> &nbsp;
                <div class="form-group  col-md-6">
                    <label for="name">Programme</label>
                    <input [(ngModel)]="actionData.programme_id" name="programme_id" type="text" class="form-control" id="name" placeholder=" libelle ">
    
                </div>
            </div><br>
           

            <div class="d-flex">
                <div class="form-group col-md-6">
                    <label for="email">poids_action</label>
                    <input [(ngModel)]="actionData.poids_action" name="poids_action" class="form-control" id="email" placeholder=" Charge de Programme">
    
                </div>&nbsp;
    
                <div class="form-group col-md-6">
                    <label>Poids </label>
                    <input [(ngModel)]="actionData.responsable_action" name="responsable_action" type="number" class="form-control" placeholder=" un Poids" minlength="10">
    
                </div>
            </div><br>
           

           

            

            <div class="text-end">
                <button (click)="EditAction()" type="submit" class="btn btn-success">Enregistrer</button>
                <button  (click)="close()" type="button" class="btn btn-danger ms-1">Annuler</button>
            </div>
        </form>
    </div>
</div>