<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Modifier une Ressource</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="name">Nom</label>
                    <input [(ngModel)]="item.titre" name="nom" type="text" class="form-control" id="name">
                </div>

                <!-- Titre -->
                <div class="form-group col-md-6">
                    <label for="titre">Type de ressource</label>
                    <select name="type_ressource_id" id="type_ressource_id" class="form-select"
                        [(ngModel)]="item.type_ressource_id">
                        <option> Sélectionner un type de ressource</option>
                        <option *ngFor="let item of typeRessourceData" [value]="item.id"> {{item.libelle}}</option>
                    </select>
                </div>
            </div>

            <!-- Description (sur toute la ligne) -->
            <div class="row mb-3">
                <div class="form-group col-md-12">
                    <label for="description">Description</label>
                    <textarea [(ngModel)]="item.perspective" name="description" class="form-control"
                        id="description"></textarea>
                </div>
            </div>

            <!-- Description Valorisation (sur toute la ligne) -->
            <div class="row mb-3">
                <div class="form-group col-md-12">
                    <label for="description">Description Valorisation</label>
                    <textarea [(ngModel)]="item.description_valorisation" name="description_valorisation"
                        class="form-control" id="description_valorisation"></textarea>
                </div>
            </div>

            <div class=" d-flex row mb-2">
                <!-- Photo Valorisation -->
                <div class="col-md-4 mb-3">
                    <div class="form-group col-md-12">
                        <label for="photo">Photo Valorisation</label>
                        <input type="file" (change)="onPhotoValorisationSelected($event)" name="photo_valorisation"
                            class="form-control" id="photo_valorisation">
                    </div>
                </div>

                <!-- Photo -->
                <div class="col-md-4 mb-3">
                    <div class="form-group col-md-12">
                        <label for="photo">Photo</label>
                        <input type="file" (change)="onFileSelected($event)" name="photo" class="form-control"
                            id="photo">
                    </div>
                </div>
                <!-- Icone -->

                <div class="col-md-4 mb-3">
                    <div class="form-group col-md-12">
                        <label for="photo">Icône</label>
                        <input type="file" (change)="onPhotoIconeSelected($event)" name="photo" class="form-control"
                            id="photo">
                    </div>
                </div>
            </div>


            <div class="row" *ngIf="item">
                <!-- Aperçu image de valorisation -->
                <div class="col-md-4" *ngIf="photoPreviewValorisation">
                    <img [src]="photoPreviewValorisation" alt="Aperçu de l'image de valorisation"
                        style="width: 100%; height: 150px;">
                </div>

                <!-- Aperçu photo principale -->
                <div class="col-md-4" *ngIf="photoPreview">
                    <img [src]="photoPreview" alt="Aperçu de la photo" style="width: 100%; height: 150px;">
                </div>

                <!-- Aperçu icône -->
                <div class="col-md-4" *ngIf="photoPreviewIcone">
                    <img [src]="photoPreviewIcone" alt="Aperçu de l'icône" style="width: 100%; height: 150px;">
                </div>
            </div>
            <br>

            <div class="text-end">
                <button (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times"
                        style="color: white"></i> Annuler</button> &nbsp;
                <button (click)="editRessource()" type="submit" class="btn btn-success"> <i class="pi pi-save"
                        style="color: white"></i> Enregistrer</button>

            </div>
        </form>
    </div>
</div>

<p-toast />