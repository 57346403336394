import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProjetIndicateurService } from '../../plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Constant } from 'src/app/shared/service/utils/contant';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-tep-global-obgrm',
  templateUrl: './tep-global-obgrm.component.html',
  styleUrl: './tep-global-obgrm.component.scss'
})


export class TepGlobalObgrmComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  projetData: any = [] = [];
  annee: any;
  modalRef: NgbModalRef | null = null;
  isLoading: boolean = false;
  searchTerm: string = '';

  itemToDelete!: ProjetIndicateur;
  filteredtems: any = [];
  projetId: any
  pageSize: number = 10;
  pdfUrl: string;
  excelUrl: string;

  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  paginatedProjets: any = [];

  projetItems: any = [];
  selectedProjet: any;
  directionId: any;
  showFiltre: boolean = false;
  directionItems: any = [];
  userInfo: any
  dateDebut: string;
  dateFin: string;
  totalTepPrevu: any;
  totalTepRealise: any;
  pourcentagePTF: any;
  pourcentageFondsPropres: any;
  projetDataWithCalculations  : any;
  pourcentageEtat: any;
  totalEcart: any;
  totalMontantAlloue = 0;
  totalFinancementEtat = 0;
  totalFondsPropres = 0;
  totalMontantPTF = 0;
  projetDataWithRank = [];

  constructor(
    private modalService: NgbModal,
    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private projetService: ProjetIndicateurService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    const currentYear = new Date().getFullYear();
    this.dateDebut = `${currentYear}-01-01`;
    this.dateFin = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {

    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();

    //console.log(" this.annee", this.annee);

    //console.log("this.projetId", this.projetId);
    this.userInfo = this.localstorage.getInfoUserToService();

    //console.log("userInfo", this.userInfo);
    //console.log("this.userInfo?.data?.groupe ", this.userInfo?.data?.groupe);

    if (this.userInfo?.data?.groupe === "admin") {

      this.showFiltre = true;
      this.getDirection();
    }
    if (this.userInfo?.data?.groupe === "point_focal") {

      this.directionId = this.localstorage.getInfoUserToService()?.data?.direction_id;

      //console.log(" this.directionId ", this.directionId);

      this.getProjetByDirection(this.directionId);
    }
    this.getStatistique();
  }
  getDirection() {

    this.paramservice.fetchDataDirection().subscribe(

      (response: any) => {

        //console.log("la reponse du webservice", response);

        this.directionItems = response.data;

      },
      (error) => {

        //console.log("une erreur est survenu", error);

      }
    )
  }
  getProjetByDirection(reference: any) {

    this.paramservice.getProjetByDirection(reference).subscribe(

      (response: any) => {

        this.projetItems = response.data;
        // this.getExercice();

        //console.log("la reponse du webservice", this.projetItems);

      },
      (error: any) => {
        //console.log("une erreur est survenu", error);

      }
    )

  }

  getStatistique() {
    //check
    if (this.dateDebut === null || this.dateDebut === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if (this.dateFin === null || this.dateFin === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
  
    
    this.isLoading = true;
    this.ngxService.start();

    this.paramservice.getStatistiqueTepGlobalObgrm(this.annee, this.dateDebut, this.dateFin, this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.ngxService.stop();

        this.isLoading = false;
        this.projetData = response.data;
        this.totalRecords = this.projetData.length;

        this.totalTepPrevu =this.projetData.reduce((acc, projet) => acc + projet.tep_prevu, 0);
        this.totalTepRealise = this.projetData.reduce((acc, projet) => acc + projet.tep_realise, 0);
        this.totalEcart = this.projetData.reduce((acc, projet) => acc + projet.ecart, 0);
        this.calculateRanks();
        this.updatePaginatedProjets();
        this.calculateTotalsAndPercentages()
        //console.log('this.projetData', this.projetData);
      },
      (error) => {
        this.ngxService.stop();
        this.isLoading = false;
        //console.log('une erreur est survenue', error);
      }
    );
  }
  
  
  calculateTotalsAndPercentages() {
    // Calcul des totaux
    this.totalMontantAlloue = this.projetData.reduce((acc, projet) => acc + (projet.total || 0), 0);
    this.totalFinancementEtat = this.projetData.reduce((acc, projet) => acc + (projet.mt_financement_etat || 0), 0);
    this.totalFondsPropres = this.projetData.reduce((acc, projet) => acc + (projet.mt_financement_fond_propre || 0), 0);
    this.totalMontantPTF = this.projetData.reduce((acc, projet) => acc + (projet.mt_financement_ptf || 0), 0);
  
    // Calcul des pourcentages totaux
    this.pourcentageEtat = this.totalMontantAlloue > 0 ? (this.totalFinancementEtat / this.totalMontantAlloue) * 100 : 0;
    this.pourcentageFondsPropres = this.totalMontantAlloue > 0 ? (this.totalFondsPropres / this.totalMontantAlloue) * 100 : 0;
    this.pourcentagePTF = this.totalMontantAlloue > 0 ? (this.totalMontantPTF / this.totalMontantAlloue) * 100 : 0;
  
    // Calcul des rangs et des pourcentages par ligne
    this.calculateRanks();
  }
  
  calculateRanks() {
    const totalMontantAlloue = this.projetData.reduce((acc, projet) => acc + (projet.total || 0), 0);
  
    this.projetDataWithRank = this.projetData
      .map(projet => {
        const totalParLigne =
          (projet.mt_financement_etat || 0) +
          (projet.mt_financement_fond_propre || 0) +
          (projet.mt_financement_ptf || 0);
  
        const pourcentageParLigne = totalMontantAlloue > 0 ? (totalParLigne / totalMontantAlloue) * 100 : 0;
  
        return { 
          ...projet, 
          totalParLigne, 
          pourcentageParLigne: pourcentageParLigne.toFixed(2), 
        };
      })
      .sort((a, b) => b.totalParLigne - a.totalParLigne) // Trie par total décroissant
      .map((projet, index) => ({ 
        ...projet, 
        rang: `${index + 1}` // Ajout du rang
      }));
  }
  
  
  onPageChangeProjets(event) {
    this.firstProjets = event.page * this.rowsProjets;
    this.updatePaginatedProjets();
  }

// Méthode pour mettre à jour les données paginées des projets
updatePaginatedProjets() {
  const start = this.firstProjets;
  const end = start + this.rowsProjets;

  // Convertir projetData en tableau avant d'utiliser slice
  this.paginatedProjets = Array.isArray(this.projetData) 
    ? this.projetData.slice(start, end)
    : Array.from(this.projetData)?.slice(start, end);

    //console.log("paginatedProjets",this.paginatedProjets);
    
}

  exportToDocument(typeDoc: any) {
    //check
    if (this.dateDebut === null || this.dateDebut === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if (this.dateFin === null || this.dateFin === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
    let projectItem: any = {};
    if (this.selectedProjet === "DEFAULT") {
      projectItem = null;
    } else {
      projectItem = this.selectedProjet;
    }
    this.ngxService.start();
    this.paramservice.printStatistiqueTepGlobalObgrmAsFile(this.annee, projectItem, this.directionId, this.dateDebut, this.dateFin, typeDoc, this.userInfo?.data?.reference).subscribe(
      (response: Blob) => {
        const filename = 'Statistiques_Activite_Global.pdf';
        FileSaver.saveAs(response, filename);

        const url = URL.createObjectURL(response);
        window.open(url, '_blank');

        this.ngxService.stop();
        this.messageService.add({ severity: 'success', summary: 'Téléchargement réussi', detail: 'Le fichier PDF a été téléchargé avec succès', life: 3000 });
      },
      (error) => {
        console.error("Une erreur est survenue", error);
        this.ngxService.stop();
      }
    );

  }



  roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }


}