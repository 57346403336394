import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Service } from 'src/app/shared/models/service';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { AddGestionAccesComponent } from '../add-gestion-acces/add-gestion-acces.component';
import { EditGestionAccesComponent } from '../edit-gestion-acces/edit-gestion-acces.component';
import { GestionUser } from 'src/app/shared/models/gestionuser';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';

@Component({
  selector: 'app-gestion-acces',
  templateUrl: './gestion-acces.component.html',
  styleUrl: './gestion-acces.component.scss'
})
export class GestionAccesComponent  {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  accesData: any =[];

  modalRef: NgbModalRef | null = null;

  itemToDelete!: GestionUser; 
  paginatedProjets = [];
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;
  searchTerm: string = '';
  filteredData: any[] = []; // Données filtrées

  constructor(
      private modalService: NgbModal,
      private paramService:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private ngxService: NgxUiLoaderService,
      private authService: AuthService,
      private fetchService:FecthDataService

  ) { 

    this.fetchService.actionCreated$.subscribe(() => {
      this.fetchData();
    });
  }

  ngOnInit(): void {
  }

  fetchData() {
    this.isLoading = true;

    this.paramService.geListUsers().subscribe(
      (response: any) => {
        this.accesData = response.data;
        this.filteredData = [...this.accesData]; // Initialiser filteredData avec toutes les données
        this.totalRecords = this.filteredData.length;
        this.updatePaginatedProjets();
        this.isLoading = false;
      },
      (error) => {
        //console.log("une erreur est survenue", error);
        this.isLoading = false;
      }
    );
  }

  onPageChangeProjets(event) {
    this.firstProjets = event.first ; // Mise à jour de la page actuelle
    this.rowsProjets = event.rows ; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.filteredData.slice(start, end);
  }

  applyFilter(term: string) {
    this.searchTerm = term.trim().toLowerCase();

    if (this.searchTerm) {
      this.filteredData = this.accesData.filter(user =>
        user.name.toLowerCase().includes(this.searchTerm)
      );
    } else {
      // Réinitialiser les données filtrées si la recherche est vide
      this.filteredData = [...this.accesData];
    }

    this.totalRecords = this.filteredData.length;
    this.firstProjets = 0; // Revenir à la première page après le filtrage
    this.updatePaginatedProjets();
  }

  openModal() {
    this.modalService.open(AddGestionAccesComponent, {
       centered: true,
       backdrop: 'static', 
        size:"lg"

       });
  }

  EditModal(action:any) {
    this.localstorage.saveUsersModify(action)
      const modalRef = this.modalService.open(EditGestionAccesComponent,{
        backdrop: 'static', 
        centered: true,
        size:"lg"

      }, );
  }

  
   //methode de suppression
   confirmDeletion(project: any) {
    this.confirmationService.confirm({
        message: "Êtes-vous sûr de vouloir supprimer l'utilisateur " + project.name + '?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', 
        rejectLabel: 'Non', 
        accept: () => {
          this.ngxService.start();
            this.authService.deleteGestionUser(project.reference).subscribe(
              (response:any)=>{
                this.ngxService.stop();
                //console.log("la reponse du webserice",response);
                this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: response.message });
                this.fetchData();
              },
              (error)=>{
                this.ngxService.stop();
                //console.log("une erreur est survenu",error);
                this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });  
              },
            )
          },
        reject: () => {
            //this.messageService.add({ severity: 'warn', summary: '', detail: 'Vous avez rejeté', life: 3000 });
        }
    });
  }


}