import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-access-user-projet',
  templateUrl: './access-user-projet.component.html',
  styleUrl: './access-user-projet.component.scss'
})
export class AccessUserProjetComponent {


  userAccess: any;
  searchTerm: string = '';
  selectedYear: any;
  paginatedExercice: any = [];
  projetItems: any = [];
  ExerciceItems: any = []; 

  paginatedItems: any[] = [];
  first: number = 0;
  rows: number = 5;
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;

  referenceUser:any;

  constructor(

    private localStorage: LocalStorageService,
    private paramService: ParameterService,
    private router: Router,
    private messageService: MessageService,
    private location: Location
  ) { }


  ngOnInit() {

    this.referenceUser = this.localStorage.getInfoUserToService();

    this.userAccess = this.localStorage.getAcessUser()?.code;

    this.getExercice();
  }

  goBack(){
    this.location.back();
  }

  getExercice() {
    this.isLoading = true;

    this.paramService.getExercice().subscribe(

      (response: any) => {
        this.isLoading = false;

        this.ExerciceItems = response.data;
        this.paginatedExercice = this.ExerciceItems;
        this.totalRecords = this.ExerciceItems.length;
        this.updatePaginatedProjets(); // Initial filter
      },
      (error) => {


      }
    )
  }

  onPageChangeProjets(event) {
    this.first = event.page * this.rows; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }
  
  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.first;
    const end = start + this.rows;
    this.paginatedExercice = this.ExerciceItems.slice(start, end);
  }
  
  accessUser(item) {
   
        // Sauvegarder l'exercice sélectionné
        this.localStorage.saveExerciceToUserToSuivieEvaluation(item);
        
        // Redirection après avoir sauvegardé l'exercice
        this.router.navigate(['/suivi/tableaux-de-bord']);
    }


  

}
