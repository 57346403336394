import { BehaviorSubject } from "rxjs";

export class MarkerDataService {
    private markersSubject = new BehaviorSubject<{ lat: number, lng: number, type:any, data:any }[]>([]);
    markers$ = this.markersSubject.asObservable();
  
    constructor() { }
  
    updateMarkers(markers: { lat: number, lng: number, type:any, data:any, }[]): void {
      try {

      this.markersSubject.next(markers);
        
      } catch (error) {
        console.log(error);
        
      }
      
    }
  }