<app-horizontaltopbar></app-horizontaltopbar><br><br><br><br>
<div class="container mt-5">
    <div class="d-flex justify-content-end">
        <button routerLink="/service" style="width: 150px;" class="btn btn-secondary text-white">
             <i class="pi pi-arrow-left " style="font-size: 0.8rem"></i> Retour</button>
    </div><br>

    <div class="row justify-content-center">
        <div class="col-md-12">
            <!-- En-tête de profil -->
            <div class="profile-header text-center mb-4">
                <h1>Mon Profil</h1>
                <p style="color: #000;">Visualisez et modifiez vos informations personnelles</p>
            </div>

            <!-- Carte contenant les informations utilisateur et modification du mot de passe -->
            <div class="card profile-card p-4">
                <div class="row">
                    <!-- Colonne 1 : Informations personnelles -->
                    <div class="col-md-7">
                        <h4 class="mb-4 text-center">Informations Personnelles</h4>
                        <div class="row mb-3">
                            <label for="nom" class="col-md-6 col-form-label">Nom : </label>
                            <div class="col-md-6">
                                <input [(ngModel)]="infoUser.name"  type="text" class="form-control bordure" id="nom" readonly>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="structure" class="col-md-6 col-form-label">Prénom : </label>
                            <div class="col-md-6">
                                <input [(ngModel)]="infoUser.surname" type="text" class="form-control bordure" id="structure" readonly>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="structure" class="col-md-6 col-form-label">Téléphone : </label>
                            <div class="col-md-6">
                                <input [(ngModel)]="infoUser.phone" type="text" class="form-control bordure" id="structure" readonly>
                            </div>
                        </div>



                        <div class="row mb-3">
                            <label for="email" class="col-md-6 col-form-label">Email : </label>
                            <div class="col-md-6">
                                <input [(ngModel)]="infoUser.email" type="email" class="form-control bordure" id="email" readonly>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="structure" class="col-md-6 col-form-label">Structure : </label>
                            <div class="col-md-6">
                                <input [(ngModel)]="infoUser.direction.libelle" type="text" class="form-control bordure" id="structure" readonly>
                            </div>
                        </div>


                    </div>

                    <!-- Ligne verticale -->
                    <div class="col-md-1 d-flex align-items-center justify-content-center">
                        <div style="border-left: 2px solid #000; height: 100%;"></div> <!-- Ligne verticale -->
                    </div>

                    <!-- Colonne 2 : Modification du mot de passe -->
                    <div class="col-md-4">
                        <h4 class="mb-4 text-center">Modifier le Mot de Passe</h4>
                        <form>
                            <!-- Ligne pour Nom et Prénom -->
                            <!-- <div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="lastName" class="form-label">Nom</label>
                                    <input [(ngModel)]="item.name" type="text" class="form-control" id="lastName" name="lastName"
                                        placeholder="Nom" required>
                                </div>
                                <div class="col-md-6">
                                    <label for="firstName" class="form-label">Prénom</label>
                                    <input [(ngModel)]="item.surname" type="text" class="form-control" id="firstName" name="firstName"
                                        placeholder="Prénom" required>
                                </div>

                            </div> -->

                            <!-- Ligne pour Email et Téléphone -->
                            <!-- <div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="email" class="form-label">Email</label>
                                    <input [(ngModel)]="item.email_address" type="email" class="form-control" id="email" name="email" placeholder="Email"
                                        required>
                                </div>
                                <div class="col-md-6">
                                    <label for="phone" class="form-label">Téléphone</label>
                                    <input [(ngModel)]="item.phone" type="number" class="form-control" id="phone" name="phone"
                                        placeholder="Téléphone" required>
                                </div>
                            </div> -->

                            <!-- Ligne pour Nouveau mot de passe et Confirmer le mot de passe -->
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <label for="newPassword" class="form-label">Nouveau mot de passe</label>
                                    <input [(ngModel)]="item.password" type="password" class="form-control" id="newPassword" name="newPassword"
                                        placeholder="Nouveau mot de passe" required>
                                </div>
                                
                            </div>
                            <div class="row mb-3">
                               
                                <div class="col-md-12">
                                    <label for="confirmPassword" class="form-label">Confirmer mot de passe</label>
                                    <input [(ngModel)]="item.confirm_password"  type="password" class="form-control" id="confirmPassword"
                                        name="confirmPassword" placeholder="Confirmer mot de passe" required>
                                </div>
                            </div>
                            <!-- Bouton de sauvegarde, aligné à droite et avec une largeur limitée -->
                            <div class="text-end">
                                <button  (click)="updateProfil()" type="submit" class="btn btn-save"><i class="pi pi-save
                                    " style="font-size: 1rem"></i> Modifier</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="top-right" />
<ngx-ui-loader></ngx-ui-loader>