<div class="container-fluid"></div>

<h4 id="uppers">Tableau des Projets</h4>
<div class="d-flex justify-content-end">
 
</div>
<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive mt-3">
          <table class="table custom-table">
            <thead>
              <tr>
                <th class="text-center">  Dénomination / Sigle </th>
                <th class="text-center"> Poids </th>
                <th class="text-center"> Montant Fonds Propres</th>
                <th class="text-center"> Montant Budget Nationalt</th>
                <th class="text-center">Total</th>
                <th class="text-center">%</th>
                <th class="text-center">RANG</th>
              </tr>
            </thead>
            <tbody>
              <!-- Ligne des projets -->
              <tr *ngFor="let item of projets; let i = index">
                <td>{{ item.nom_projet }}</td>
                <td class="text-center">-</td>
                <td id="uppers" class="text-center">{{ item.mt_financement_fond_propre |  formatMoney:null }}</td>
                <td id="uppers"class="text-center">{{ item.mt_financement_etat | formatMoney:null }}</td>
                <td id="uppers" class="text-center">{{ item.total |  formatMoney:null }}</td>
                <td id="uppers" class="text-center">
                  {{ item.pourcentage && !isNaN(+item.pourcentage) ? (item.pourcentage | number: '1.0-2') : 0 }}%
                </td>
                                <td id="uppers"class="text-center">{{ i + 1 }}</td>
              </tr>

              <!-- Ligne des totaux -->
              <tr class="table-total">
                <td class="text-center fw-bold">TOTAL</td>
                <td class="text-center fw-bold">-</td>
                <td id="uppers"class="text-center fw-bold">{{ totalFinancementFondPropre |  formatMoney:null }}</td>
                <td id="uppers"class="text-center fw-bold">{{ totalFinancementEtat |  formatMoney:null }}</td>
                <td id="uppers"class="text-center fw-bold">{{ totalGeneral |  formatMoney:null }}</td>
                <td class="text-center fw-bold">-</td>
                <td class="text-center fw-bold">-</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <h2 class=" text-center" id="uppers">Répartition des Financements par Projet</h2>
    <div class="container mt-5 d-flex justify-content-center">
      <div class="row">
        <!-- Use col-md-8 to ensure it occupies 8 columns -->
        <div class="col-md-12 col-sm-6">
          <div class="card">
            <div class="card-body">
              <apx-chart
                class="apex-charts"
                dir="ltr"
                [series]="simplePieChart.series"
                [chart]="simplePieChart.chart"
                [labels]="simplePieChart.labels"
                [legend]="simplePieChart.legend"
                [colors]="simplePieChart.colors"
                [responsive]="simplePieChart.responsive"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    
    <div class="container mt-5 d-flex justify-content-center" *ngIf="!isLoading">
      <div class="mt-4 col-md-4">
        <p-skeleton shape="circle" size="25rem" />
      </div>

    </div>
  </div>

</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>