<div class="row justify-content-center">
  <div class="col-sm-12 col-md-6 col-lg-3 mb-3" *ngFor="let item of statData">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-1 overflow-hidden">
            <p class="text-truncate  mb-2">{{item?.libelle}}</p>
            <h4 class="d-flex mb-0 justify-content-center">{{item.valeur_reference}}</h4>
          </div>
          <div class="text-primary ms-auto">
            <i class="{{statDataIcon}} font-size-24"></i> 
          </div>  
        </div>
      </div>
    </div>  
  </div>
</div>
