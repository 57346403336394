import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProjetIndicateurService } from '../projet-indicateur/projet-indicateur.component';
import { MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';
import { AddIndicateurComponent } from '../../indicateurs/add-indicateur/add-indicateur.component';
import { EditIndicateurComponent } from '../../indicateurs/edit-indicateur/edit-indicateur.component';

@Component({
  selector: 'app-edit-projet',
  templateUrl: './edit-projet.component.html',
  styleUrl: './edit-projet.component.scss'
})

export class EditProjetComponent {

  item: ProjetIndicateur= new ProjetIndicateur()

  programmeData:any=[]
  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService,
    private ProjetService:ProjetIndicateurService,
    private messageService:MessageService,
    private ngxService: NgxUiLoaderService

  ) { }


  ngOnInit(){
    this.item = this.localstorage.getProjet();
  }

  close(){
    this.modalService.dismissAll();
  }


  Edit(){

    this.ngxService.start();

    const item =this.localstorage.getProjet();
    this.paramService.editProjet(item.reference,this.item).subscribe(
      (response:any)=>{
        //console.log("la reponse du webservice",response);
        this.ProjetService.notifyProgrammeCreated();

        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail:response.message });
        this.ngxService.stop();
        this.modalService.dismissAll();
      },
      (error:any)=>{
        //console.log("une erreur est survenu",error);
          this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });
        this.ngxService.stop();
      }
    )
  }

  // fetchDataPrograme() {
    
    
  //   this.paramService.fetchDataPrograme().subscribe(

  //     (response:any)=>{

  //       this.programmeData = response.data;

  //       //console.log("this.programmeData",this.programmeData);
        
  //     },
  //     (error)=>{
  //       //console.log("une erreur est survenu",error);
  //     //  this.modalService.dismissAll();
        
  //     }
      
  //   ) ;
  // }
}