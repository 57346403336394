<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Ajouter un centre</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="row mb-3">

                <div class="form-group col-md-6">
                    <label for="name">Nom</label>
                    <input name="nom" type="text" [(ngModel)]="item.nom"  class="form-control" id="name" maxlength="100" >
    
                </div> 
                <div class="form-group col-md-6">
                    <label for="name">Description</label>
                    <input name="description"   [(ngModel)]="item.description"type="text" class="form-control" id="description"  maxlength="150" >
    
                </div>
            </div>
           

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="email">Longitude </label>
                    <input [(ngModel)]="item.longitude" type="number" name="longitude" class="form-control" id="longitude">
    
                </div>
    
                <div class="form-group  col-md-6">
                    <label for="name">Latitude</label>

                    <input [(ngModel)]="item.latitude" name="latitude" type="number" class="form-control" id="latitude">

                </div>
            </div>
            <div class="row mb-3">
                <div class="form-group col-md-6">
                  <label for="departement">Département</label>
                  <select name="departement" id="departement" class="form-select" [(ngModel)]="selectedDepartementId" (change)="onDepartementChange($event.target.value)">
                    <option value="">Sélectionner un département</option>
                    <option *ngFor="let item of departement" [value]="item.id">{{ item.libelle }}</option>
                  </select>
                </div>
              
                <div class="form-group col-md-6">
                  <label for="commune">Commune</label>
                  <select name="commune" id="commune" class="form-select" [(ngModel)]="selectedCommuneId" (change)="oncommuneChange($event.target.value)">
                    <option value="">Sélectionner une commune</option>
                    <option *ngFor="let item of commune" [value]="item.id">{{ item.libelle }}</option>
                  </select>
                </div>
              </div>
              



            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="name">Arrondissement</label>
                    <select name="programme_id" id="programme_id" class="form-select"  [(ngModel)]="item.arrondissement_id" >
    
                        <option> Selectionner un arrondissement</option>
                        <option *ngFor="let item of arrondissement" [value]="item.id"> {{item.libelle}}</option>
                    </select>
                </div> 

                <div class="form-group  col-md-6">
                    <label for="name">Photo</label>

                    <input (change)="onFileSelected($event)"  name="photo" type="file" class="form-control" id="name">

                </div>
            </div>

            


            <div class="text-end">
                <button  (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times" style="color: white"></i>  Annuler</button> &nbsp;

                <button (click)="addCentre()" type="submit" class="btn btn-success"> <i class="pi pi-save" style="color: white"></i> Enregistrer</button>
            </div>
        </form>
    </div>
</div>

<p-toast />
