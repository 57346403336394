import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { AddServiceComponent } from '../add-service/add-service.component';
import { EditServiceComponent } from '../edit-service/edit-service.component';
import { Service } from 'src/app/shared/models/service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss'
})


export class ServiceComponent  {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  serviceData: any =[];

  modalRef: NgbModalRef | null = null;

  itemToDelete!: Service; 

  constructor(
      private modalService: NgbModal,
      public formBuilder: UntypedFormBuilder,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService
  ) { }

  ngOnInit(): void {

   

    this.fetchData();
  }

  fetchData() {
    
    
    this.paramservice.fetchDataService().subscribe(

      (response:any)=>{

        this.serviceData = response.data;

        //console.log("this.actionData",this.serviceData);
        
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }



  openModal() {
    this.modalService.open(AddServiceComponent, {
       centered: true,
       backdrop: 'static', 


       });
  }

  EditModal(action:any) {
    this.localstorage.saveService(action)
      const modalRef = this.modalService.open(EditServiceComponent,{
        backdrop: 'static', 
        centered: true,
      }, );
  }

  confirm1(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir continuer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
        rejectLabel: 'Non', // Définir le libellé du bouton "Non"
        accept: () => {
            this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'Vous avez acepter' });

            this.paramservice.deleteService(event).subscribe(

              (response:any)=>{

                //console.log("la reponse du webserice",response);
                
              },
              (error)=>{

                //console.log("une erreur est survenu",error);
                
              },
            )
          },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: 'Vous avez rejeté', life: 3000 });
        }
    });
}

 

}
