import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/admin/core/services/auth.service';
import { AuthfakeauthenticationService } from 'src/app/admin/core/services/authfake.service';
import { User } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  item : User = new User()
  loading = false;
  showPassword = false;

  constructor(
    private authService:AuthService,
    private messageService:MessageService,
    private router:Router,
    private localStorage:LocalStorageService
  ) { }

  ngOnInit() {
  }






  onSubmit() {
    if (!this.item.canSend()) { 
      this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: this.item.getErrorMessage() });
      return;
    }
    
    if (this.item) {
      
      this.loading = true;
      this.authService.login(this.item).subscribe(
        (response: any) => {
          this.messageService.add({ severity: 'success', summary: 'OBGRM', detail: response.message });

          this.localStorage.saveInfoUserToService(response)
          this.router.navigate(['/service']);
        },
        (error) => {
          //console.log("une erreur est survenue", error);
          this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: error });
        }
      ).add(() => {
        this.loading = false; 
      });
    }
  }//end onSubmit


  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

}
