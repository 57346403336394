<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Service</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="d-flex">
                <div class="form-group col-md-6">
                    <label for="name">Direction</label>
                    <select name="direction_id" id="direction_id" class="form-select" [(ngModel)]="item.direction_id">
                        <option *ngFor="let item of serviceData" [value]="item.id"> {{item.libelle}}</option>
                    </select>
                </div> &nbsp;

                <div class="form-group col-md-6">
                    <label for="name">Service</label>
                    <input  [(ngModel)]="item.libelle"  name="valeur_reference" type="text" class="form-control"
                        id="name">

                </div> &nbsp;
                
            </div><br>





            <div class="text-end">
                <button (click)="addItems()" type="submit" class="btn btn-success">Enregistrer</button>
                <button (click)="close()" type="button" class="btn btn-danger ms-1">Annuler</button>
            </div>
        </form>
    </div>
</div>