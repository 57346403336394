<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Modifier un projet</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>
            <div class="row">
                <!-- Premier groupe de trois champs -->
                <div class="col-md-4 mb-4">
                    <div class="form-group">
                        <label for="code">Code</label>
                        <input [(ngModel)]="item.code" name="code" type="text" class="form-control" id="code">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="libelle">Libellé</label>
                        <textarea [(ngModel)]="item.libelle" name="libelle" type="text" class="form-control" id="libelle"> </textarea>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="form-group">
                        <label for="sigle">Sigle</label>
                        <input [(ngModel)]="item.sigle" name="sigle" type="text" class="form-control" id="sigle">
                    </div>
                </div>
            </div>
        
            <div class="row">
                <!-- Deuxième groupe de trois champs -->
                <div class="col-md-4 mb-4">
                    <div class="form-group">
                        <label for="contexte">Contexte</label>
                        <textarea [(ngModel)]="item.contexte" name="contexte" type="text" class="form-control" id="contexte"></textarea>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="form-group">
                        <label for="objectifs_specifiques">Objectifs spécifiques</label>
                        <textarea [(ngModel)]="item.objectifs_specifiques" name="objectifs_specifiques" type="text" class="form-control" id="objectifs_specifiques"></textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="resultat_projet">Résultat projet</label>
                        <textarea [(ngModel)]="item.resultat_projet" name="resultat_projet" type="text" class="form-control" id="resultat_projet"></textarea>
                    </div>
                </div>
            </div>
        
            <div class="row">
                <!-- Troisième groupe de trois champs -->
                <div class="col-md-4 mb-4">
                    <div class="form-group">
                        <label for="description_projet">Description projet</label>
                        <textarea [(ngModel)]="item.description_projet" name="description_projet" type="text" class="form-control" id="description_projet"></textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="zones_intervention">Zones intervention</label>
                        <textarea [(ngModel)]="item.zones_intervention" name="zones_intervention" type="text" class="form-control" id="zones_intervention"></textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="beneficiaires">Bénéficiaires</label>
                        <textarea [(ngModel)]="item.beneficiaires" name="beneficiaires" type="text" class="form-control" id="beneficiaires"></textarea>
                    </div>
                </div>
            </div>
        
            <div class="row">
                <!-- Quatrième groupe de trois champs -->
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cout">Coût</label>
                        <input [(ngModel)]="item.cout" name="cout" type="number" class="form-control" id="cout">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="responsable_projet">Poids</label>
                        <input type="number" [(ngModel)]="item.poids" name="poids" type="text" class="form-control" id="poids">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="responsable_projet">Responsable projet</label>
                        <input [(ngModel)]="item.responsable_projet" name="responsable_projet" type="text" class="form-control" id="responsable_projet">
                    </div>
                </div>
               
            </div><br>
        
            <div class="row">
                <!-- Champ restant et boutons de soumission -->
                <div class="col-md-">
                    <div class="form-group">
                        <label for="objectif_global">Objectif global</label>
                        <textarea [(ngModel)]="item.objectif_global" name="objectif_global" type="text" class="form-control" id="objectif_global"> </textarea>
                    </div>
                </div>
                
            </div><br>
            <div class="col-md-12 d-flex justify-content-end">
                <button (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times" style="color: white"></i>Annuler</button> &nbsp;
                <button (click)="Edit()" type="submit" class="btn btn-success"><i class="pi pi-save" style="color: white"></i>Enregistrer</button>

            </div>
            
        </form>
        
        
    </div>
</div>
<p-toast />

