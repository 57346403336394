<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0">{{ title }}</h4>
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          @for(item of breadcrumbItems;track $index){
          @if(item.active){
          <li class="breadcrumb-item active">{{ item.label }}
          </li>
          }@else {
          <li class="breadcrumb-item"><a href="javascript: void(0);">{{ item.label }}</a>
          </li>
          }
          }
        </ol>
      </div>
    </div>
  </div>
</div>