  <div class="container-fluid">

    <h4 id="upper">Gestion des utilisateurs</h4>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!--  -->
              <a href="javascript:void(0);" class="btn btn-success mb-2" id="gras" (click)="openModal()">
                <i class="mdi mdi-plus me-2"></i> Ajouter un Utilisateur
              </a>
            </div>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
              
              </div>
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                    class="d-inline-flex align-items-center">Rechercher:
                    <input [(ngModel)]="searchTerm" type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                      aria-controls="tickets-table" (keyup)="applyFilter($event.target.value)" (input)="applyFilter($event.target.value)"></label></div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive mt-3">
              <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                <thead class="thead-light  rwd-table">
                  <tr>
                    <th style="width: 20px;">
                    </th>
                    <th>Nom</th>
                    <th>Prénoms</th>
                    <th>Télephone</th>
                    <th>Email</th>
                    <th>Identifiant</th>
                    <th> Direction</th>
                    <th>Groupe</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of paginatedProjets; let index = index">
                    <td>
                    </td>
                    <td>{{item?.name}}</td>
                    <td>{{item?.surname}}</td>
                    <td>{{item?.phone}}</td>
                    <td>{{item?.identifiant}}</td>
                    <td>{{item.direction?.libelle}}</td>
                    <td>{{item.groupe.designation}}</td>
                    <td>
                      <a (click)="EditModal(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover
                        title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a (click)="confirmDeletion(item)" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                        title="Delete">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>

                </tbody>

              </table>

            </div>
            <div class="d-flex justify-content-center" *ngIf="paginatedProjets.length === 0">
              <div class="container">
                <div class="row">
                  <div class="text-center">
                    <h6 id="graycolor">chargement en cours</h6>
                    <div class="loader4"></div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="paginatedProjets.length === 0 && isLoading === false" class="d-flex justify-content-center">
              <div class="container">
                <div class="row">
                  <div class="text-center">
                    <h5 id="graycolor">Aucune donnée trouvée</h5>
                  </div>
                </div>
              </div>

            </div>
            <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
            </p-paginator>
          </div>
        </div>

      </div>
    </div>

  </div>
  <p-confirmDialog />
  <p-toast />