import { Component, OnInit } from '@angular/core';
import { Graphique } from 'src/app/shared/models/graphique';
import { ParameterService } from 'src/app/shared/service/params/param';

@Component({
  selector: 'app-graphique',
  templateUrl: './graphique.component.html',
  styleUrl: './graphique.component.scss'
})
export class GraphiqueComponent implements OnInit {

  projets: any[] = [];
  selectedProjet: any;
  chartData: any;
  chartOptions: any;
  date_fin: string;
  date_debut: string;
  isLoading: boolean = false; 

  constructor(private parameterService: ParameterService) {
    const currentYear = new Date().getFullYear();
    this.date_debut = `${currentYear}-01-01`;
    this.date_fin = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this.getMontantByProjet();
  }

  getMontantByProjet() {
    const requestData = {
      "date_debut": this.date_debut,
      "date_fin": this.date_fin,
    };
    this.isLoading = false;
    this.parameterService.getMontantByProjet(requestData).subscribe(
      (response: any) => {
        this.isLoading = true;

        this.projets = response.data;

        console.log("this.projets", this.projets);

        if (this.projets.length > 0) {
          this.selectedProjet = this.projets[0];
          this.updateChart(); // Met à jour le graphique ici
        }
      },
      (error) => {
        console.log("une erreur est survenue", error);
      }
    );
  }

  updateChart() {
    // Récupérer le total de chaque projet
    const totalMontants = this.projets.map(projet => projet.total);

    this.chartData = {
      labels: this.projets.map(projet => projet.nom_projet), // Noms des projets comme étiquettes
      datasets: [
        {
          data: totalMontants, // Total de chaque projet
          backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'], // Couleurs pour chaque section
          hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D']
        }
      ]
    };
  }


  onRowSelect(event: any) {
    this.selectedProjet = event.data;
    this.updateChart();
  }
}