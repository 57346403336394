  
  <div class="breadcrumbs overlay">
    <div class="container">
        <div class="bread-inner">
            <div class="row">
                <div class="col-12">
                    <h2>REALISATIONS</h2>
                </div>
            </div>
        </div>
    </div>
</div><br>
  <!-- Start qui sommes nus choose -->
  <!-- Start qui sommes nus choose -->
  <section class="why-choose section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ">
                <!-- Start Choose Left -->
                <div class="choose-left"><br>
                    <h3>REALISATIONS</h3>
                   
                    <div class="card col-md-12 card-custom">
                        <div class="card-body">
                            <h4 class="card-title">LABORATOIRE D'ANALYSE</h4>
                            <ul>
                                <li>Carte géologique au 1 000 000 ème du Bénin
                                </li>
                                <li>Carte géologique au 500 000 ème du Bénin</li>
                                <li>Cartes géologiques au 200 000 ème couvrant tout le territoire du Bénin</li>
                                <li>Cartes du champ magnétique du Bénin</li>
                            </ul>
                        </div>
                    </div>

                        <div class="justify-content-end" >
                            <a (click)="goToMission()" href="javascript: void(0);" style=" font-family: '';font-size: 15px; justify-content: center;"  class="btn btn-primary text-white" rel="noopener" >A propos</a>
                        </div>
                </div>&nbsp;&nbsp;&nbsp;

              
                <!-- End Choose Left -->
            </div><br>
            <div class="col-md-6 d-flex justify-content-center align-items-center mt-4"> <!-- Utilisation de classes pour centrer -->
                <iframe width="660" height="315" src="https://www.youtube.com/embed/-2FVYNxzkVI?si=DiNjlp0NCaGidfJM"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</section><br>
<!--/ End  -->