import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { AddActionComponent } from '../add-action/add-action.component';
import { EditActionComponent } from '../edit-action/edit-action.component';
import { BehaviorSubject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrl: './action.component.scss'
})
export class ActionComponent  {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  actionData: any =[];
  validationform: UntypedFormGroup;
  modalRef: NgbModalRef | null = null;

  itemToDelete!: any; 

  constructor(
      private modalService: NgbModal,
      public formBuilder: UntypedFormBuilder,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private ActionServiceService: ActionServiceService,
      private ngxService:NgxUiLoaderService,

  ) {
    this.ActionServiceService.actionCreated$.subscribe(() => {
      // Met à jour la liste après la création réussie d'un glossaire
      this.fetchDataAction();
    });
   }

  ngOnInit(): void {

   

    this.fetchDataAction();


  }

   fetchDataAction() {
    
    
    this.paramservice.fetchDataAction().subscribe(

      (response:any)=>{

        this.actionData = response.data;

        //console.log("this.actionData",this.actionData);
        
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }
  /**
   * Modal Open
   * @param content modal content
   */
  openModal() {
    this.modalService.open(AddActionComponent, {
       centered: true,
       backdrop: 'static', 


       });
  }

  EditModal(action:any) {
    this.localstorage.saveAction(action)
      const modalRef = this.modalService.open(EditActionComponent,{
        backdrop: 'static', 
        centered: true,
      }, );
  }

  confirm1(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir continuer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
        rejectLabel: 'Non', // Définir le libellé du bouton "Non"
        accept: () => {

            this.paramservice.deleteAction(event).subscribe(

              (response:any)=>{

                //console.log("la reponse du webserice",response);
                this.messageService.add({ severity: 'succes', summary: Constant.suiviTitle, detail: response.message });

                this.fetchDataAction();
              },
              (error)=>{

                //console.log("une erreur est survenu",error);
                this.messageService.add({ severity: 'warn', summary: Constant.suiviTitle, detail: error });

                
              },
            )
          },
        // reject: () => {
        //     this.messageService.add({ severity: 'warn', summary: '', detail: 'Vous avez rejeté', life: 3000 });
        // }
    });
}

 

}
export class ActionServiceService {
  private ActionCreatedSubject = new BehaviorSubject<boolean>(false);
  actionCreated$ = this.ActionCreatedSubject.asObservable();

  notifyProgrammeCreated() {
    this.ActionCreatedSubject.next(true);
  }
}