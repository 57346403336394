<div class="container-fluid">
  <div class="d-flex justify-content-end">
    <button  routerLink="/suivi/planification/projet-activite" type="submit" class="btn btn-primary mt-3"><i class="pi pi-arrow-left " style="font-size: 0.9rem"></i>
       Retour</button>
  </div>
  <h5 id="upper" class="mb-4">Ajouter une sous-activité à un projet</h5>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-7">
              <div class="card mb-4">
                <div class="container mb-3 text-left"><br>
                  <h5 id="upper" class="mb-4 text-center">Informations de l'activité</h5>
                  <h5 class="mb-3">Libellé : <strong>{{item.activite}}</strong></h5>
                  <h5 class="mb-3">Date de Début : <strong>{{item.date_debut | dateFormat}}</strong></h5>
                  <h5 class="mb-3">Date de Fin : <strong>{{item.date_fin | dateFormat}}</strong></h5>
                  <h5 class="mb-3">Montant : <strong>{{item.mt_financement_etat | formatMoney:null }}</strong></h5>
                  <h5 class="mb-3">Poids : <strong>{{item.poids}}</strong></h5>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <h5  class="mb-4 text-center">Ajouter une Sous-activité</h5>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label for="code">Code</label>
                      <input type="text" class="form-control" id="code" [(ngModel)]="newItem.code_sous_activite"
                        name="code">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label for="libelle">Libellé</label>
                      <input type="text" class="form-control" id="libelle" [(ngModel)]="newItem.sous_activite"
                        name="sous_activite">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label for="date_debut">Date de Début</label>
                      <input type="date" class="form-control" id="date_debut" [(ngModel)]="newItem.date_debut"
                        name="date_debut">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label for="date_fin">Date de Fin</label>
                      <input type="date" class="form-control" id="date_fin" [(ngModel)]="newItem.date_fin"
                        name="date_fin">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label for="montant">Montant</label>
                      <input type="text" class="form-control" id="montant" [(ngModel)]="newItem.mt_financement_etat"
                        name="montant">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label for="poid">Poids</label>
                      <input type="number" class="form-control" id="poid" [(ngModel)]="newItem.poids" name="poid">
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button (click)="saveSousActiviteToActivite()" type="submit"
                    class="btn btn-primary mt-3">Enregistrer</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <h6 id="upper" class=" d-flex justify-content-center mt-3" style="text-transform: uppercase;">Liste des sous-activités
            associées aux projets</h6>
          <div class="card-body">
            <div class="table-responsive mt-3">
              <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                <thead class="thead-light rwd-table">
                  <tr>
                    <th>Code </th>
                    <th>Libellé </th>
                    <th>Date de Début </th>
                    <th>Date de Fin</th>
                    <th>Montant </th>
                    <th>Poids </th>
                    <th style="width: 120px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of paginatedProjets">
                    <td>{{item?.code_sous_activite}}</td>
                    <td>{{item?.sous_activite}}</td>

                    <td>{{item?.date_debut | dateFormat}}</td>
                    <td>{{item?.date_fin | dateFormat}}</td>
                    <td>{{item?.mt_financement_etat | formatMoney:null }}</td>
                    <td>{{item?.poids}}</td>
                    <td>
                      <a (click)="openModalTache(item)" style="cursor: pointer;" class="me-3 text-primary"
                        v-b-tooltip.hover title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a (click)="confirmDeletion(item)" href="javascript:void(0);" class="text-danger"
                        v-b-tooltip.hover title="Delete">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>&nbsp;
                      <a (click)="addSousTacheToSousActivite(item)" href="javascript:void(0);" class="me-3 text-primary"
                        v-b-tooltip.hover title="Ajouter une  sous-activités">
                        <i class="mdi mdi-plus font-size-18"></i>
                      </a>
                    </td>
                  </tr>

                </tbody>


              </table>
            </div>
          </div>
          <div class="d-flex justify-content-center" *ngIf="isLoading === true">
            <div class="container">
              <div class="row">
                <div class="text-center">
                  <h5 id="graycolor">Chargement en cours</h5>
                  <div class="loader4"></div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="paginatedProjets.length === 0 && isLoading === false" class="d-flex justify-content-center" >
            <div class="container">
              <div class="row">
                <div class="text-center">
                  <h5 id="graycolor">Aucune donnée trouvée</h5>
                </div>
              </div>
            </div>
            
          </div>
          <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
            [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
          </p-paginator>
        </div>

      </div>
    </div>
  </div>


  <ng-template #sousActiviteModal let-modal>
    <!-- Contacts model  -->
    <div class="modal-header">
      <h5 class="modal-title">Modifier une activité</h5>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form>

          <div class=" row mb-3">

            <div class="form-group col-md-6">
              <label for="name">Code</label>
              <input [(ngModel)]="updateItem.code_sous_activite" name="code" type="text" class="form-control" id="name">

            </div>
            <div class="form-group col-md-6">
              <label for="name">Libelle</label>
              <input [(ngModel)]="updateItem.sous_activite" name="updateItem" type="text" class="form-control"
                id="name">

            </div>
          </div><br>


          <div class=" row mb-3">
            <div class="form-group col-md-6">
              <label for="email">Date de Début</label>
              <input [(ngModel)]="updateItem.date_debut" type="date" name="date_debut" class="form-control"
                id="date_debut">

            </div>
            <div class="form-group col-md-6">
              <label for="name">Date de Fin</label>
              <input [(ngModel)]="updateItem.date_fin" name="date_debut" type="date" class="form-control" id="name">

            </div>

          </div><br>

          <div class=" row mb-3">
            <div class="form-group col-md-6">
              <label for="email">Montant</label>
              <input [(ngModel)]="updateItem.mt_financement_etat" type="number" name="mt_financement_etat"
                class="form-control" id="email">

            </div>
            <div class="form-group col-md-6">
              <label for="name">Poids</label>
              <input [(ngModel)]="updateItem.poids" name="poids" type="text" class="form-control" id="name">

            </div>

          </div>
          <div class="text-end">
            <button (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times"
                style="color: white"></i> Annuler</button>&nbsp;

            <button (click)="updateSousActiviteToActivite()" type="submit" class="btn btn-success"> <i
                class="pi pi-save" style="color: white"></i> Enregistrer</button>
          </div>
        </form>
      </div>
    </div>

  </ng-template>




  <p-confirmDialog></p-confirmDialog>
  <p-toast></p-toast>
  <ngx-ui-loader></ngx-ui-loader>
</div>