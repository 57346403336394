export class Programme {
    code: string;
    libelle: string;
    objectif_global: string;
    poids: string;
    observations: string;
    chargedeprogramme: string;

    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }
     // get error message
     getErrorMessage(): string {
        return this.errorMessage;
    }

    // check for save
    canSave(): boolean {
        if (this.code === undefined || this.code === null || this.code.trim() === "") {
            this.errorMessage = "Veuillez saisir le code";
            return false;
        }

        if (this.libelle === undefined || this.libelle === null || this.libelle.trim() === "") {
            this.errorMessage = "Veuillez saisir le libellé";
            return false;
        }

        if (this.objectif_global === undefined || this.objectif_global === null || this.objectif_global.trim() === "") {
            this.errorMessage = "Veuillez saisir l'objectif global";
            return false;
        }

        if (this.poids === undefined || this.poids === null ) {
            this.errorMessage = "Veuillez saisir le poids";
            return false;
        }

        if (this.observations === undefined || this.observations === null || this.observations.trim() === "") {
            this.errorMessage = "Veuillez saisir les observations";
            return false;
        }

        if (this.chargedeprogramme === undefined || this.chargedeprogramme === null || this.chargedeprogramme.trim() === "") {
            this.errorMessage = "Veuillez saisir le chargé de programme";
            return false;
        }

        return true;
    }
}
