import { Component } from '@angular/core';

@Component({
  selector: 'app-tep-programmme',
  templateUrl: './tep-programmme.component.html',
  styleUrl: './tep-programmme.component.scss'
})
export class TepProgrammmeComponent {

}
