<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <!-- <div class="col-sm-6">
        2023 © Nazox.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          Crafted with <i class="mdi mdi-heart text-danger"></i> by Themesdesign
        </div>
      </div> -->
    </div>
  </div>
</footer>
