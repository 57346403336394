import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkerDataService } from 'src/app/shared/service/makerservice/markerDataService';
import { OperationService } from 'src/app/shared/service/operation/operation.service';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-centre-detail',
  templateUrl: './centre-detail.component.html',
  styleUrl: './centre-detail.component.scss'
})

export class CentreDetailComponent {

  resource: any;
  reference: string;
  markers: { lat: number, lng: number, type: any, data: any}[] = [];
  source:any
  constructor(
    private route: ActivatedRoute,
    private operationService: OperationService,
    private localstorage:LocalStorageService,
    private markerDataService: MarkerDataService,
    private router: Router,


  ) { 
    this.markerDataService.markers$.subscribe(markers => {
      this.markers = markers;
    });
  }

  item:any ;
  centreData:any
  ngOnInit(): void {
    this.reference = this.route.snapshot.paramMap.get('reference');
    this.getCentreDetails();

   // this.item = this.localstorage.getCentreDetail();

    //console.log("this.item",this.item);
    this.source  = this.localstorage.getAccueilCentre();

  }

  getCentreDetails(): void {
    this.operationService.getCentreByReference(this.reference).subscribe(
           (response:any)=>{
        //console.log("la reponse du webservice",response);

        this.centreData = response.data[0];
        console.log("this.centreData",this.centreData);

        this.updateMarkersWithRessources(this.centreData);  // Met à jour les marqueurs    
      },
      (error)=>{

        //console.log("une erreur est survenu",error);
        
      }
    );
  }

  updateMarkersWithRessources(centres: any) {
    console.log("Type de centres:", Array.isArray(centres));
    console.log("Contenu de centres:", centres);
  
    // Si `centres` n'est pas un tableau, le convertir en un tableau
    const centresArray = Array.isArray(centres) ? centres : [centres];
  
    // Transformer les centres en tableaux de localisations
    const localisations = centresArray.map(centre => {
      let data: any = {
        nom: centre.nom,
        description: centre.description,

        items: centre,
        type: "valorisation",
      };

      return {
        lat: centre.latitude,
        lng: centre.longitude,
        type: "valorisation",
        data: data,
      };
    });
  
    // Met à jour les marqueurs via le service
    this.markerDataService.updateMarkers(localisations);
  }

  returnToSource(){
    console.log("aeppe",this.source);
    
    if (this.source === "accueil") {
      this.router.navigate(['/']);

    }
    if (this.source ==="centredetail") {
      this.router.navigate(['/valorisation']);

    }
  }  
}