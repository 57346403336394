import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { Programme } from 'src/app/shared/models/programme';
import { ParameterService } from 'src/app/shared/service/params/param';
import { ProgrammeService } from '../programme/programme.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-add-programe',
  templateUrl: './add-programe.component.html',
  styleUrl: './add-programe.component.scss'
})
export class AddProgrameComponent {


  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private messageService:MessageService,
    private programmeService:ProgrammeService,
    private ngxService:NgxUiLoaderService,

  ) { }


  close(){

    this.modalService.dismissAll();
  }

  programmeData: Programme= new Programme() 

  addProgramme(){

    if (!this.programmeData.canSave()) {
      
      this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: this.programmeData.getErrorMessage() });

    }
    else if (this.programmeData.canSave()) {

      this.ngxService.start();
      this.paramService.addProgramme(this.programmeData).subscribe(
        (response:any)=>{
          //console.log("la reponse du webservice",response);
  
  
          this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
  
          this.programmeService.notifyProgrammeCreated();
          this.modalService.dismissAll();
  
          this.ngxService.stop();

        },
        (error:any)=>{
          //console.log("une erreur est survenu",error);
         // this.modalService.dismissAll()
         this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });
         this.ngxService.stop();

        }
      )
    }

   
  }
}
