import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProjetIndicateurService } from '../../plananalytique/projet/projet-indicateur/projet-indicateur.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Constant } from 'src/app/shared/service/utils/contant';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-bilan-pta',
  templateUrl: './bilan-pta.component.html',
  styleUrl: './bilan-pta.component.scss'
})



export class BilanPtaComponent {



  projetData: any = [] = [];
  annee: any;
  modalRef: NgbModalRef | null = null;
  isLoading: boolean = false;
  searchTerm: string = '';

  itemToDelete!: ProjetIndicateur;
  filteredtems: any = [];
  projetId: any
  pageSize: number = 10;
  pdfUrl: string;
  excelUrl: string;

  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  paginatedProjets: any = [];

  projetItems: any = [];
  selectedProjet: any;
  directionId: any;
  showFiltre: boolean = false;
  directionItems: any = [];
  userInfo: any;
  dateDebut: string;
  dateFin: string;

  montantPrevu = 0;
  montantEngage = 0;
  montantOrdonnance = 0;
  tauxEngagement = 0;
  tauxOrdonnancement = 0;

  barChartData: any;
  barChartOptions: any;
  constructor(
    private modalService: NgbModal,
    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private projetService: ProjetIndicateurService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    const currentYear = new Date().getFullYear();
    this.dateDebut = `${currentYear}-01-01`;
    this.dateFin = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {

    this.barChartOptions = {
      scales: {
        y: { beginAtZero: true }
      }
    };

    this.projetId = this.localstorage.getProjetToUser();
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();

    //console.log(" this.annee", this.annee);

    //console.log("this.projetId", this.projetId);
    this.userInfo = this.localstorage.getInfoUserToService();

    //console.log("userInfo", this.userInfo);
    //console.log("this.userInfo?.data?.groupe ", this.userInfo?.data?.groupe);

    if (this.userInfo?.data?.groupe === "admin") {

      this.showFiltre = true;   
      
      this.getDirection();

    }

    
    if (this.userInfo?.data?.groupe === "point_focal") {

      this.directionId = this.localstorage.getInfoUserToService()?.data?.direction_id;

      //console.log(" this.directionId ", this.directionId);

      this.getProjetByDirection(this.directionId);
    }
   // this.getStatistique();
  }


  getDirection() {

    this.paramservice.fetchDataDirection().subscribe(

      (response: any) => {

        //console.log("la reponse du webservice", response);

        this.directionItems = response.data;

      },
      (error) => {

        //console.log("une erreur est survenu", error);

      }
    )
  }

  getProjetByDirection(reference: any) {

    this.paramservice.getProjetByDirection(reference).subscribe(

      (response: any) => {

        this.projetItems = response.data;
        // this.getExercice();

        //console.log("la reponse du webservice", this.projetItems);

      },
      (error: any) => {
        //console.log("une erreur est survenu", error);

      }
    )

  }
  getStatistique() {
    //check
    if (this.dateDebut === null || this.dateDebut === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if (this.dateFin === null || this.dateFin === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
    let projectItem: any = {};
    if (this.selectedProjet === "DEFAULT") {
      projectItem = null;
    } else {
      projectItem = this.selectedProjet;
    }
    this.isLoading = true;
    this.ngxService.start();

    this.paramservice.getStatistiqueTepBilanPta(this.annee, projectItem, this.directionId, this.dateDebut, this.dateFin, this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.ngxService.stop();

        this.isLoading = false;
        // Suppose response.data is an object; adjust if necessary
        this.projetData = response.data; // Wrap in an array if you need to iterate over it
        //console.log('this.projetData', this.projetData);
        this.totalRecords = this.projetData.length;



        //

        this.montantPrevu = this.projetData.montant_prevu,
        this.montantEngage = this.projetData.montant_engage,
        this.montantOrdonnance = this.projetData.montant_ordonnance
        //this.updatePaginatedProjets();
        this.updateBarChart();

      },
      (error) => {
        this.isLoading = false;
        //console.log('une erreur est survenue', error);
        this.ngxService.stop();

      }
    );
  }
  updateBarChart(): void {
    this.barChartData = {
      labels: ['Montant Prévu', 'Montant Engagé', 'Montant Ordonnancé'],
      datasets: [
        {
          label: 'Montant Prévu',
          backgroundColor: '#66bdcb', 

          data: [this.montantPrevu, 0, 0] 
        },
        {
          label: 'Montant Engagé',
          data: [0, this.montantEngage, 0] 
        },
        {
          label: 'Montant Ordonnancé',
          backgroundColor: '#FF7043', 
          data: [0, 0, this.montantOrdonnance] 
        },
       
      ]
    };
  }
  


  // onPageChangeProjets(event) {
  //   this.firstProjets = event.page * this.rowsProjets; // Mise à jour de la page actuelle
  //   this.updatePaginatedProjets();
  // }

  // // Méthode pour mettre à jour les données paginées des projets
  // updatePaginatedProjets() {
  //   const start = this.firstProjets;
  //   const end = start + this.rowsProjets;
  //   this.paginatedProjets = this.projetData.slice(start, end);

  // }
  exportToDocument(typeDoc: any) {
    this.ngxService.start();
    //check
    if (this.dateDebut === null || this.dateDebut === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de début', life: 3000 });
      return;
    }
    if (this.dateFin === null || this.dateFin === undefined) {
      this.messageService.add({ severity: 'error', summary: 'TEP', detail: 'Veuillez renseigner la date de fin', life: 3000 });
      return;
    }
    let projectItem: any = {};
    if (this.selectedProjet === "DEFAULT") {
      projectItem = null;
    } else {
      projectItem = this.selectedProjet;
    }

    this.paramservice.printStatistiqueBilanAsFile(this.annee, projectItem, this.directionId, this.dateDebut, this.dateFin, typeDoc, this.userInfo?.data?.reference).subscribe(
      (response: Blob) => {
        const filename = 'Statistiques_Activite_Bilan.pdf';
        FileSaver.saveAs(response, filename);

        const url = URL.createObjectURL(response);
        window.open(url, '_blank');

        this.ngxService.stop();
        this.messageService.add({ severity: 'success', summary: 'Téléchargement réussi', detail: 'Le fichier PDF a été téléchargé avec succès', life: 3000 });
      },
      (error) => {
        console.error("Une erreur est survenue", error);
        this.ngxService.stop();
      }
    );

  }


}