<div style="border-color: rgb(51, 50, 50); border-style: double;" class="container-fluid"><br>
  <div class="d-flex justify-content-between align-items-center">
    <h4>Activités budgétaires ({{activiteOp.details.length}}) - {{activiteOp.libelle}}</h4>

    <!-- Print Button -->
    <div class="dropdown d-inline-block " ngbDropdown>
      <button type="button" ngbDropdownToggle
          class="btn btn-primary dropdown-toggle" id="dropdownMenuButton" aria-haspopup="true"
          aria-expanded="false">
          Exporter <i class="pi pi-upload" style="font-size: 0.8rem"></i>

      </button>
      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu
          aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="javascript:void(0);" (click)="exportToDocument('pdf')">Au format
              PDF</a>
          <a class="dropdown-item" href="javascript:void(0);" (click)="exportToDocument('excel')">Au format
              Excel</a>
      </div>

  </div>
    <!-- <button type="button" class="btn btn-primary" >Imprimer</button> -->
    
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div>
        <div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light rwd-table">
                <tr>
                  <th>Code</th>
                  <th>Libellé</th>
                  <th>Poids</th>
                  <th>TEP Prévu</th>
                  <th>TEP Réalisé</th>
                  <th>Ecart</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of activiteOp.details; let index = index">
                  <td>{{item?.code}}</td>
                  <td>{{item?.libelle}}</td>
                  <td>{{item?.poids}}</td>
                  <td>{{item?.tep_prevu}}</td>
                  <td>{{item.tep_realise}}</td>
                  <td>{{ roundToTwoDecimals(item.tep_realise - item.tep_prevu) }}</td>
                </tr>
              </tbody>
            </table>

            <div class="d-flex justify-content-center" *ngIf="isLoading === true">
              <div class="container">
                <div class="row">
                  <div class="text-center">
                    <h5 id="graycolor">Chargement en cours...</h5>
                    <div class="loader4"></div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="activiteOp.details.length === 0 && isLoading === false" class="d-flex justify-content-center">
              <div class="container">
                <div class="row">
                  <div class="text-center">
                    <h5 id="graycolor">Aucune donnée trouvée</h5>
                  </div>
                </div>
              </div>
            </div>
            <!-- <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
            </p-paginator> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-toast />

