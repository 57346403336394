import { Component } from '@angular/core';

@Component({
  selector: 'app-tableaud-bord-projet',
  templateUrl: './tableaud-bord-projet.component.html',
  styleUrl: './tableaud-bord-projet.component.scss'
})
export class TableaudBordProjetComponent {

}
