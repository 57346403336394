import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { Indicateur } from 'src/app/shared/models/indicateurs';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { AddIndicateurComponent } from '../add-indicateur/add-indicateur.component';
import { EditIndicateurComponent } from '../edit-indicateur/edit-indicateur.component';
import { PlanificationComponent } from '../planification/planification.component';

@Component({
  selector: 'app-indicateur',
  templateUrl: './indicateur.component.html',
  styleUrl: './indicateur.component.scss'
})


export class IndicateurComponent {

  // breadcrumb items
  breadCrumbItems: Array<{}>;
  submitted: boolean;
  indicateurData: any = [];
  validationform: UntypedFormGroup;
  modalRef: NgbModalRef | null = null;
  itemToDelete!: Indicateur;
  searchTerm: string;
  filteredtems: any = [];
  pageSize: number = 10;
  paginatedProjets = [];
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  isLoading: boolean = false;
  userInfo :any
  showFiltre :boolean =false;
  directionItems :any =[];
  constructor(
    private modalService: NgbModal,
    public formBuilder: UntypedFormBuilder,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private indcateurService: IndicateurServiceService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {

    this.indcateurService.actionCreated$.subscribe(() => {
      // Met à jour la liste après la création réussie d'un glossaire
      this.fetchDataIndicateur();
    });
  }

  ngOnInit(): void {


    this.fetchDataIndicateur();
        
    this.userInfo =this.localstorage.getInfoUserToService();

    //console.log("userInfo", this.userInfo);
    //console.log("this.userInfo?.data?.groupe ",this.userInfo?.data?.groupe );

    if (this.userInfo?.data?.groupe === "admin") {
      
      this.showFiltre = true;
      this.getDirection();
    }
  }

  fetchDataIndicateur() {
    this.isLoading = true;

    this.paramservice.fetchDataIndicateur().subscribe(
      (response: any) => {
        this.isLoading = false;

        this.indicateurData = response.data;
        this.filteredtems = this.indicateurData
        //console.log("this.actionData", this.indicateurData);
        this.filteredtems = [...this.indicateurData]
        this.totalRecords = this.indicateurData.length; // Met à jour le total des enregistrements
        this.updatePaginatedProjets();

      },
      (error) => {
        //console.log("une erreur est survenu", error);
      }

    );
  }

  onPageChangeProjets(event) {
    this.firstProjets = event.first; // Mise à jour de la page actuelle
    this.rowsProjets = event.rows; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.filteredtems.slice(start, end);
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }
  /**
   * Modal Open
   * @param content add indicateur
   */
  addIndicateur() {
    this.modalService.open(AddIndicateurComponent, {
      centered: true,
      backdrop: 'static',
      size:"xl"

    });
  }//end addIndicateur

  EditModal(action: any) {
    //console.log("aaaa");
    
    this.localstorage.saveIndicateur(action)
    const modalRef = this.modalService.open(EditIndicateurComponent, {
      backdrop: 'static',
      centered: true,
      size:"xl"
    },);
  }

  //suppression
  confirmDeletion(indicateur: any) {
    this.confirmationService.confirm({
      //target: event.target as EventTarget,
      message: 'Êtes-vous sûr de vouloir supprimer cet indicateur ' + indicateur.libelle + ' ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
      rejectLabel: 'Non', // Définir le libellé du bouton "Non"
      accept: () => {
        this.ngxService.start();

        this.paramservice.deleteIndicateur(indicateur.reference).subscribe(
          (response: any) => {
            this.ngxService.stop();
            //console.log("la reponse du webserice", response);
            this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
            this.fetchDataIndicateur();
          },
          (error) => {
            this.ngxService.stop();
            //console.log("Une erreur est survenue", error);
            this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: error });
            //console.log("une erreur est survenu", error);
            this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });
          },
        )
      },
      reject: () => {
        //this.messageService.add({ severity: 'info', summary: 'OBGRM', detail: 'Vous avez rejeté', life: 3000 });
      }
    });//end confirmDeletion
  }



  filterData() {
    const lowerSearchTerm = this.searchTerm.toLowerCase();

    if (!lowerSearchTerm) {
      this.filteredtems = [...this.indicateurData]; // Réinitialiser avec toutes les données
    } else{
      this.filteredtems = this.indicateurData.filter((item: any) => {
        // Vérifier que les propriétés existent avant de filtrer
        const matchesLibelle = item.libelle && item.libelle.toLowerCase().includes(lowerSearchTerm);
        const matchesValeurReference = item.valeur_reference && item.valeur_reference.toLowerCase().includes(lowerSearchTerm);
        const matchesSourceVerification = item.source_verification && item.source_verification.toLowerCase().includes(lowerSearchTerm);
        const matchesUnite = item.unite && item.unite.toLowerCase().includes(lowerSearchTerm);
        const matchesModeCalcul = item.mode_calcul && item.mode_calcul.toLowerCase().includes(lowerSearchTerm);
  
        const projet = item.projet || {};
        const matchesProjetCode = projet.code && projet.code.toLowerCase().includes(lowerSearchTerm);
        const matchesProjetLibelle = projet.libelle && projet.libelle.toLowerCase().includes(lowerSearchTerm);
  
        const typeIndicateur = item.type_indicateur || {};
        const matchesTypeIndicateurLibelle = typeIndicateur.libelle && typeIndicateur.libelle.toLowerCase().includes(lowerSearchTerm);
  
        return matchesLibelle || matchesValeurReference || matchesSourceVerification ||
          matchesUnite || matchesModeCalcul || matchesProjetCode || matchesProjetLibelle ||
          matchesTypeIndicateurLibelle;
      });


    this.totalRecords = this.filteredtems.length;
    this.firstProjets = 0; // Reset to the first page
    this.updatePaginatedProjets();

    }


  }

  changePageSize() {
    // Met à jour les données filtrées en fonction de la nouvelle taille de page sélectionnée
    this.filteredtems = this.filteredtems.slice(0, this.pageSize);
  }


  getDirection(){

    this.paramservice.fetchDataDirection  ().subscribe(

      (response:any)=>{
        //console.log("la reponse du webservice",response);
        this.directionItems = response.data;
      },
      (error)=>{

        //console.log("une erreur est survenu",error);
        
      }
    )
  }


  
  redirectPlanification(item: any) {
    this.localstorage.savePlanification(item)
    const modalRef = this.modalService.open(PlanificationComponent, {
      backdrop: 'static',
      centered: true,
      size: 'xl'

    },);
  }

}

export class IndicateurServiceService {
  private IndicateurCreatedSubject = new BehaviorSubject<boolean>(false);
  actionCreated$ = this.IndicateurCreatedSubject.asObservable();

  notifyProgrammeCreated() {
    this.IndicateurCreatedSubject.next(true);
  }
}

