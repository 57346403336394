<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Direction</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="d-flex">
                <div class="form-group col-md-6">
                    <label for="name">Libellé</label>
                    <input [(ngModel)]="item.libelle" name="valeur_reference" type="text" class="form-control"
                        id="name">

                </div> &nbsp;

                <div class="form-group col-md-6">
                    <label for="name">Sigle</label>
                    <input [(ngModel)]="item.sigle" name="sigle" type="text" class="form-control"
                        id="name">

                </div> &nbsp;
                
            </div><br>



            <div class="text-end">
                <button (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times" style="color: white"></i> Annuler</button> &nbsp;
                <button (click)="Edit()" type="submit" class="btn btn-success"> <i class="pi pi-save" style="color: white"></i> Enregistrer</button>

            </div>

        
        </form>
    </div>
</div>


<p-confirmDialog />
  <p-toast />
  <ngx-ui-loader></ngx-ui-loader>