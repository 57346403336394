<!-- Carousel Start -->
<app-public-header></app-public-header>
<mdb-carousel [controls]="true" [indicators]="true" [animation]="'fade'" >
    <mdb-carousel-item style="z-index: -1;">
        <img src="../../../assets/img/banniere-img.jpg" height="540px" class="d-block w-100" alt="..." />

    </mdb-carousel-item>

    <mdb-carousel-item style="z-index: -1;">
        <img src="../../../assets/img/img2.jpg" height="540px" class="d-block w-100" alt="..." />
    </mdb-carousel-item>
    <div class="carousel-caption-container mb-40 ">
        <div class="carousel-caption-content">
            <h1 class="text-white ">
                Office Béninois de Recherches Géologiques et Minières
            </h1>
            <p class="mb-5 fs-5 text-white">
                L’Office Béninois de Recherches Géologiques et Minières est chargé de gérer <br> et d’assurer les
                missions de
                service public dans les domaines de la géologie et des mines. <br> Il est le correspondant des
                organismes
                sous-régionaux, régionaux et internationaux de recherches géologiques et minières.
            </p>
            <div class="text-left">
                <a (click)="routerAbout()" id="but" class="btn btn-primary rounded-pill text-white py-3 px-5">
                    En savoir +
                </a>
            </div>
        </div>
    </div>
</mdb-carousel>



<!-- Encart 1 minerales -->
<div class="container-fluid feature py-5">
    <div class="container">
        <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <h1 style="font-family: '" class=" mb-4"> <strong> Substances minérales  </strong></h1>
        </div>
        <div class="row g-4 justify-content-center">
            <div class="row justify-content-center" *ngIf="!ressoureceItems1 || ressoureceItems1.length === 0">

                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton  width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton  width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
      
              </div>
            <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp d-flex justify-content-center"
                *ngFor="let item of ressoureceItems1  | slice:0:3" data-wow-delay="0.1s">
                <div class="row-cols-1 feature-item p-4 ">
                    <div class="col-12 ">
                        <div class="feature-icon mb-4">
                            <div class="p-3 d-inline-flex bg-white rounded">

                                <img [src]="item.photo" height="100px" width="100px" alt="" srcset="">
                            </div>
                        </div>
                        <div class="feature-content d-flex flex-column">
                            <p id="gras" class="mb-4">{{item.titre}}</p>
                            <p class="mb-0">{{item.perspective |limitTo:100}}</p>
                            <button id="bordure" (click)="goToResourceDetail(item.reference)" class="btn btn-primary text-white"> En
                                savoir +</button>
                        </div>
                    </div>
                </div>
            </div>


        </div><br>

        <div class="d-flex justify-content-center">
            <button style="width: 150px;" (click)="goToCategorieRessource(typeResoureceId1)" class="btn btn-primary text-white"> Voir
                +</button>

        </div>
    </div>
</div>
<!-- Encart End -->

<!-- banne 1  Start -->
<section class="banner">
    <div class="banner-overlay"></div>
    <div class="container">
        <div class="row align-items-center banner-content">
            <div class="col-md-6 text-center text-md-start">
                <h2 style="color: white;">Qui
                    sommes-nous ?</h2>
                <p>Créé le 13 Août 1977 par décret N°77-29 sous la dénomination de l'Office ......</p>
                <a style="background-color: white; color: black;" href="javascript:void(0);" routerLink="/apropos"
                    class="btn btn-custom">En savoir +</a>
            </div>
            <div class="col-md-6 text-center text-md-start mt-4 mt-md-0">
                <h2 style="color: white;">Mission</h2>
                <p>L'Office Béninois de Recherches Géologiques et Minières est chargé de gérer......</p>
                <a style="background-color: white; color: black;" href="javascript:void(0);" routerLink="/mission"
                    class="btn btn-custom">En savoir +</a>
            </div>
        </div>
    </div>
</section>
<!-- banne End -->

<!-- Encart 2 pierres ornementales -->
<div class="container-fluid feature py-5">
    <div class="container">
        <div class="section-title mb-5 wow fadeInUp d-flex justify-content-center" data-wow-delay="0.1s">
            <h1 style="font-family: '" class=" mb-4"> <strong>Pierres ornementales</strong> </h1>
        </div>
        <div class="row g-4 justify-content-center">
            <div class="row justify-content-center" *ngIf="!ressoureceItems2 || ressoureceItems2.length === 0">

                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton  width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton  width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
      
              </div>
            <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp d-flex justify-content-center"
                *ngFor="let item of ressoureceItems2  | slice:0:3" data-wow-delay="0.1s">
                <div class="row-cols-1 feature-item p-4">
                    <div class="col-12">
                        <div class="feature-icon mb-4">
                            <div class="p-3 d-inline-flex bg-white rounded">
                                <img [src]="item.photo" height="100px" width="100px" alt="" srcset="">
                            </div>
                        </div>
                        <div class="feature-content d-flex flex-column">
                            <p  id="gras" class="mb-4">{{item.titre}}</p>
                            <p class="mb-0">{{item.perspective  |limitTo:100}}</p>
                            <button id="bordure" (click)="goToResourceDetail(item.reference)" class="btn btn-primary text-white"> En
                                savoir +</button>
                        </div>
                    </div>
                </div>
            </div>



        </div><br>
        <div class="d-flex justify-content-center">
            <button style="width: 150px;" (click)="goToCategorieRessource(typeResoureceId2)" class="btn btn-primary text-white"> Voir
                +</button>

        </div>
    </div>
</div>
<!-- Encart End -->

<!-- banne 2  Start -->
<section class="banner">
    <div class="banner-overlay"></div>
    <div class="container">
        <div class="row align-items-center banner-content">
            <div class="col-md-6 text-center text-md-start">
                <h2 style="color: white;">Nos métiers </h2>
                <p>Explorez les métiers variés et passionnants au cœur de notre institution....</p>
                <a style="background-color: white; color: black;" routerLink="/metiers" href="javascript:void(0);"
                    class="btn btn-custom">En savoir +</a>
            </div>
            <div class="col-md-6 text-center text-md-start mt-4 mt-md-0">
                <h2 style="color: white; font-family: Montserrat;">Nos réalisations </h2>
                <p>Découvrez également les réalisations remarquables qui façonnent notre institution au quotidien</p>
                <a id="bordure" style="background-color: white; color: black;" routerLink="/realisations" href="javascript:void(0);"
                    class="btn btn-custom">En savoir +</a>
            </div>
        </div>
    </div>
</section>
<!-- banne End -->

<!-- Encart 3 pierres fines -->
<div class="container-fluid feature py-5">
    <div class="container">
        <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <h1  class=" mb-4"><strong>Pierres fines</strong> </h1>
        </div>
        <div class="row g-4 justify-content-center">
            <div class="row justify-content-center" *ngIf="!ressoureceItems3 || ressoureceItems3.length === 0">

                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton  width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton  width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
      
              </div>
            <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp d-flex justify-content-center"
                *ngFor="let item of ressoureceItems3  | slice:0:3" data-wow-delay="0.1s">
                <div class="row-cols-1 feature-item p-4">
                    <div class="col-12">
                        <div class="feature-icon mb-4">
                            <div class="p-3 d-inline-flex bg-white rounded">
                                <img [src]="item.photo" height="100px" width="100px" alt="" srcset="">
                            </div>
                        </div>
                        <div class="feature-content d-flex flex-column">
                            <p  id="gras" class="mb-4"><strong>{{item.titre}} </strong></p>
                            <p class="mb-0">{{item.perspective |limitTo:100}}</p>
                            <button id="bordure" (click)="goToResourceDetail(item.reference)" class="btn btn-primary text-white"> En
                                savoir +</button>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="d-flex justify-content-center">
            <button style="width: 150px;" (click)="goToCategorieRessource(typeResoureceId3)" class="btn btn-primary text-white"> Voir
                +</button>

        </div>
    </div>
</div>
<!-- Encart End -->

<br><br>

<!-- Valorisation 1 Start -->
<div  style="background-color: rgb(222 224 219 / 46%);" class="container-fluid about " >
    <div class="container ">
        <div class="row g-5 align-items-center">
            <!-- Texte -->
            <div class="col-lg-7 order-lg-1 order-2 wow fadeInLeft" data-wow-delay="0.4s">
                <div class="section-title text-start mb-5">
                    <h1 class="mb-4"> <strong>Valorisation des centres</strong> </h1>
                    <p style="color: black;" class="mb-4">Le Bénin possède une variété de ressources minérales, bien que
                        celles-ci soient encore largement sous-exploitées. Parmi les ressources identifiées, on trouve
                        le calcaire, le marbre, l’or, le fer, et le phosphate. Ces ressources offrent un potentiel
                        important pour le développement de l’industrie minière, qui pourrait jouer un rôle crucial dans
                        la croissance économique du pays.</p>
                    <a routerLink="/valorisation" href="#" class="btn btn-primary rounded-pill text-white py-3 px-5">Filtrer</a>
                </div>
            </div>
            <!-- Image -->
            <div class=" justify-content-center align-items-center col-lg-5 col-12 order-lg-2 order-1 wow fadeInRight"
                data-wow-delay="0.2s">
                <div class="">
                    <app-maps [markers]="markers" class="w-100 h-100"></app-maps>

                </div>
            </div>

        </div>
    </div>
</div>
<!-- Valorisation End -->


<!-- Valorisation 2 Start -->
<div class="container-fluid blog ">
    <div class="container py-4">
        <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <h1  class="mb-4" > <strong>Centre de valorisation </strong> </h1>
        </div>
        <div class="row g-4 justify-content-center">
            <div class="row justify-content-center" *ngIf="!centreItems || centreItems.length === 0">

                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton  width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <p-skeleton  width="20rem" height="25rem"></p-skeleton>
                  </div>
                </div>
      
              </div>
            <div class="col-md-6 col-lg-6 col-xl-4 wow fadeInUp" *ngFor="let item of centreItems | slice:0:3"
                data-wow-delay="0.1s">
                <div class="blog-item rounded h-100 d-flex flex-column">
                    <div class="blog-img">
                        <img [src]="item.image_url" class="img-fluid w-100" alt="Image"
                            style="height: 250px; object-fit: cover;">
                    </div>
                    <div class="blog-centent p-4 d-flex flex-column flex-grow-1">
                        <a href="#" class="h4" id="gras" style="font-size: 20px;">{{item.nom}}</a>
                        <p class="my-4 flex-grow-1">{{item.description  |limitTo:40}}</p>
                        <a  id="bordure" (click)="goToCentreDetail(item.reference ,item)" href="javascript: void(0);"
                            class="btn btn-primary rounded-pill text-white py-2 px-4 mt-auto">En savoir +</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Valorisation End -->

<app-public-footer></app-public-footer>