import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/admin/core/services/auth.service';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})

/**
 * Reset-password component
 */
export class PasswordresetComponent implements OnInit, AfterViewInit {

  resetForm: UntypedFormGroup;
  submitted = false;
  error = '';
  success = '';
  loading = false;

  // set the currenr year
  year: number = new Date().getFullYear();

  constructor(
    private authService :AuthService,
    private messageService :MessageService,
    private router :Router
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }


  item:any
  onSubmit() {

    if (!this.item) {
      this.messageService.add({ severity: 'error', summary: 'OBGRM', detail:"Veuillez fournir une adresse mail valide" });
    }
    this.authService.reset(this.item).subscribe(

      (response:any)=>{

        //console.log("la reponse du werbservice",response);
        this.messageService.add({ severity: 'success', summary: 'OBGRM', detail: response.message });

        this.router.navigate(['/login'])
      },
      (error)=>{

        //console.log("une erreur est survenu ", error);
        this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: error });

      }
    )
  }
}
