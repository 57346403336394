import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrl: './post-login.component.scss'
})
export class PostLoginComponent {

  serviceName: string = "SERVICE";
  accessModule :any;
  servicesData: any = [];
  listenName: any

  constructor(
    private localStorage: LocalStorageService,
    private router: Router
  ) { }


  ngOnInit() {
    this.accessModule = this.localStorage.getInfoUserToService()?.data;
    this.listenName = this.localStorage.getInfoUserToService()?.data;
    this.serviceName = this.listenName.direction.libelle;

    //console.log(" this.listenName", this.listenName);
   
    this.filterServices();

  }

  filterServices() {
    // Réinitialiser la liste des services
    this.servicesData = [];

    if (this.accessModule.acces_valoriation  && this.accessModule.acces_suivi_evaluation) {
      // Les deux accès sont true, ajouter les deux services
      this.servicesData.push(
        { code: "recherche-valorisation", libelle: "Recherche et valorisation" },
        { code: "suivie", libelle: "Suivi - évaluation" }
      );
    } else if (this.accessModule.acces_valoriation) {
      // Seul acces_valoriation est true
      this.servicesData.push(
        { code: "recherche-valorisation", libelle: "Recherche et valorisation" }
      );
    } else if (this.accessModule.acces_suivi_evaluation) {
      // Seul acces_suivi_evaluation est true
      this.servicesData.push(
        { code: "suivie", libelle: "Suivi - évaluation" }
      );
    }
  }

  acessDashboard(item: any) {

    if (item.code == "suivie") {
      this.localStorage.saveAccessUser(item);
      this.router.navigate(['/page/projet'])
    }
    if (item.code == "recherche-valorisation") {
      this.localStorage.saveAccessUser(item);
      this.router.navigate(['/recherche/tableaux-de-bord'])
    }



  }
}
