<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Modifier un Indicateur</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="row mb-3">

                <div class="form-group  col-md-6">
                    <label for="name">Libelle</label>
                    <input [(ngModel)]="item.libelle" name="libelle" type="text"
                        class="form-control" id="name">

                </div>
                <div class="form-group col-md-6">
                    <label for="name">Date Valeur Référence </label>
                    <input [(ngModel)]="item.valeur_reference" name="valeur_reference" type="number" class="form-control"
                        id="name">

                </div> 
             
            </div><br>


            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="email">Source vérification</label>
                    <input [(ngModel)]="item.source_verification" name="source_verification" class="form-control"
                        id="email">

                </div>

                <div class="form-group col-md-6">
                    <label>Unité </label>
                    <input [(ngModel)]="item.unite" name="unite" type="text" class="form-control" minlength="10" maxlength="30">

                </div>
            </div><br>

            <div class="row mb-3">
                

                <div class="form-group  col-md-6">
                    <label>Type Indicateur </label>
                  <select class="form-select" name="type_indicateur_id" id="type_indicateur_id"  [(ngModel)]="item.type_indicateur_id">

                    <option value="">Sélectionner un Indicateur</option>
                    <option *ngFor="let item of typeIndicateurData " [value]="item.id">{{item.libelle}}</option>
                  </select>
    
                </div>
    

                <div class="form-group col-md-6">
                    <label>Projet </label>
                  <select name="projet_id" id="" class="form-select" [(ngModel)]="item.projet_id">

                    <option value="">Sélectionner un projet</option>
                    <option *ngFor="let item of projetData" [value]="item.id">{{item.libelle}}</option>
                  </select>
                </div>
            </div><br>

        

           <div class="row mb-3">
            <div class="form-group col-md-6">
                <label for="email">Mode de calcul</label>
                <input [(ngModel)]="item.mode_calcul" name="mode_calcul" class="form-control" id="email">

            </div>

            <div class="form-group  col-md-6">
                <label for="name">Date référence</label>
                <input name="date_valeur_reference"  [(ngModel)]="item.date_valeur_reference" type="date"
                    class="form-control" id="name">

            </div>

           </div> <br>

            <div class="text-end">
                <button (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times" style="color: white"></i>  Annuler</button> &nbsp;
                <button (click)="Edit()" type="submit" class="btn btn-success"> <i class="pi pi-save" style="color: white"></i>Enregistrer</button>

            </div>
        </form>
    </div>
</div>

<p-confirmDialog />
  <p-toast />

