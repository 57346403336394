import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { Ressource } from 'src/app/shared/models/ressource';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { ParameterService } from 'src/app/shared/service/params/param';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-add-resource',
  templateUrl: './add-resource.component.html',
  styleUrl: './add-resource.component.scss'
})

export class AddResourceComponent {


  item: Ressource= new Ressource()
  programmeData:any=[];
  photoPreview: string | ArrayBuffer | null = null;
  valorisationPreview: string | ArrayBuffer | null = null;
  iconePreview: string | ArrayBuffer | null = null;
  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private messageService:MessageService,
    private FecthDataService:FecthDataService,
    private ngxservice:NgxUiLoaderService
  ) {
   }

  ngOnInit(){
    this.fetchTypeRessource()
  }

  close(){
    this.modalService.dismissAll();
  }

  addRessource(){

    if (!this.item.canSave()) {
      this.messageService.add({ severity: 'warn', summary: Constant.rechercheiTitle, detail:this.item.getErrorMessage() });
      return;
    }
    else{
      this.ngxservice.start();
      let formData: FormData = new FormData();
      formData.append('type_ressource_id', this.item.type_ressource_id);
      formData.append('photo', this.item.photo); 
      formData.append('photo_valorisation', this.item.photo_valorisation); 
      formData.append('icone', this.item.icone); 
      formData.append('perspective', this.item.perspective);
      formData.append('titre', this.item.titre);
      formData.append('description_valorisation', this.item.description_valorisation);
  
  
      this.paramService.addRessource(formData).subscribe(
        (response:any)=>{
          this.ngxservice.stop();
  
          this.modalService.dismissAll();
          this.messageService.add({ severity: 'success', summary: Constant.rechercheiTitle, detail: response.message });
  
          this.FecthDataService.notifySeriviceCreated();
        },
        (error:any)=>{
        //  this.modalService.dismissAll();
          this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });
          this.FecthDataService.notifySeriviceCreated();
          this.ngxservice.stop();
        }
      )
    }
  }

  //pr selection de la photo
  onPhotoSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.item.photo = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.photoPreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }//end onPhotoSelected

  //onImageValorisationFileSelected
  onImageValorisationFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.item.photo_valorisation = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.valorisationPreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }//end onImageValorisationFileSelected

  // onIconeSelected
  onIconeSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.item.icone = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.iconePreview = e.target.result; // Met à jour l'aperçu de l'icône
      };
      reader.readAsDataURL(file);
    }
  }
  // end onIconeSelected
  //recupere le type de ressource
  fetchTypeRessource() {
    this.paramService.fetchDataTypeResource().subscribe(
      (response:any)=>{
        this.programmeData = response.data;
        //console.log("this.programmeData",this.programmeData);
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
      }
    ) ;
  }//end fetchTypeRessource

}