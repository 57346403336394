export class Localisation {
    ressource_id: string;
    arrondissement_id: any;
    donnee_geologique: string;
    donnee_geophysique: any;
    donnee_geochimique: string;
    longitude: string;
    latitude: any;
    perspective: string;
    date_recherche: string;
    nom_localite: string;
    reference: any;
    resume_recherche: string;
    rapport_recherche: any;

    private errorMessage: string;

    constructor() {
        this.errorMessage = "";
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }

    canSave(): boolean {

        if (!this.arrondissement_id || this.arrondissement_id.trim() === '') {
            this.errorMessage = "L'arrondissement est obligatoire";
            return false;
        }
        if (!this.nom_localite || this.nom_localite.trim() === '') {
            this.errorMessage = "Le nom de la localité est obligatoire";
            return false;
        }


        if (!this.longitude) {
            this.errorMessage = "La longitude est obligatoire";
            return false;
        }

        if (!this.latitude) {
            this.errorMessage = "La latitude est obligatoire";
            return false;
        }
        if (!this.donnee_geologique) {
            this.errorMessage = "La donnée géologique est obligatoire";
            return false;
        }
        if (!this.donnee_geophysique) {
            this.errorMessage = "La donnée géophysique est obligatoire";
            return false;
        }
        if (!this.donnee_geochimique || this.donnee_geochimique.trim() === '') {
            this.errorMessage = "La donnée géochimique est obligatoire";
            return false;
        }
        if (!this.perspective || this.perspective.trim() === '') {
            this.errorMessage = "La perspective  est obligatoire";
            return false;
        }

        if (!this.date_recherche || this.date_recherche.trim() === '') {
            this.errorMessage = "La date de recherche est obligatoire";
            return false;
        }

        if (!this.resume_recherche || this.resume_recherche.trim() === '') {
            this.errorMessage = "Le résumé  est obligatoire";
            return false;
        }

      /*   if (!this.rapport_recherche) {
            this.errorMessage = "Le rapport de recherche est obligatoire";
            return false;
        } */

        this.errorMessage = "";
        return true;
    }
}
