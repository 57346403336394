import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Email } from 'src/app/shared/models/message';
import { OperationService } from 'src/app/shared/service/operation/operation.service';
import { ParameterService } from 'src/app/shared/service/params/param';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {



  constructor(

    private operationService :OperationService,
    private messageService :MessageService

  ){}


  item:Email = new Email();
  loading = false;

  sendMessage() {
    if (!this.item.canSend()) {
      this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: this.item.getErrorMessage() });
      return;
    }
   
    if (this.item) {
      this.loading = true;
      
      this.operationService.sendMessage(this.item).subscribe(
        (response: any) => {
          this.loading = false;
          //console.log("la reponse du web service", response);
          this.messageService.add({ severity: 'success', summary: 'OBGRM', detail: response.message });
          this.item  = new Email();
        },
        (error: any) => {
          this.loading = false;
          //console.log("une erreur est survenu ", error);
          this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: error });
        }
      );
    }
  }
}
