import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from 'src/app/shared/service/params/param';
import { IndicateurServiceService } from '../indicateur/indicateur.component';
import { MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';
import { Indicateur } from 'src/app/shared/models/indicateurs';

@Component({
  selector: 'app-add-indicateur',
  templateUrl: './add-indicateur.component.html',
  styleUrl: './add-indicateur.component.scss'
})



export class AddIndicateurComponent {

  item: Indicateur= new Indicateur()
  projetData:any=[];
  typeIndicateurData:any=[];

  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private indicateurService:IndicateurServiceService,
    private messageService :MessageService,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit(){
    this.fetchDataProjet();
    this.getIndicateurProjet();
  }

  close(){
    this.modalService.dismissAll();
  }

  //ajout dindicateur
  addIndicateur(){
    if (!this.item.canSave()) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: this.item.getErrorMessage() });
    }
    if (this.item.canSave()) {
      this.ngxService.start();
      this.paramService.addIndicateurs(this.item).subscribe(
        (response:any)=>{
          //console.log("la reponse du webservice",response);
  
          this.modalService.dismissAll();
          this.indicateurService.notifyProgrammeCreated();
          this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message});
          this.ngxService.stop();
        },
        (error:any)=>{
          //console.log("une erreur est survenu",error);
        //  this.modalService.dismissAll();
          this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessaye" });
          this.ngxService.stop();
        }
      )
    }
  }//end addIndicateur

  //recup projet
  fetchDataProjet() {
    this.paramService.fetchDataProjet().subscribe(
      (response:any)=>{
        this.projetData = response.data;
        //console.log("this.projetData",this.projetData);
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
      }
    ) ;
  }//end fetchDataProjet

  //recup indicateur
  getIndicateurProjet() {
    this.paramService.fetchTypeIndicateur().subscribe(
      (response:any)=>{
        this.typeIndicateurData = response.data;
        //console.log("this.projetData",this.typeIndicateurData);
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
      }
    ) ;
  }//end getIndicateurProjet

}