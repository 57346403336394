import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apropos',
  templateUrl: './apropos.component.html',
  styleUrl: './apropos.component.scss'
})
export class AproposComponent {

  constructor(

    private router :Router
  ){}

  goToMission(){

  //  console.log("appelr");
    
    this.router.navigate(['/mission'])
  }
}
