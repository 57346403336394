<div class="container-fluid">

  <h4 id="upper">Sous activités ordonnancées</h4>
  <div class="d-flex justify-content-end">

    <div  class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <label class="d-inline-flex align-items-center">
          Projets:
          <select type="text" name="searchTerm" class="form-select ms-2"
            aria-controls="tickets-table" [(ngModel)]="selectedProjet" (change)="processSelectedProjet()"  >
            <option disabled value="Sélectionner une direction"></option>
            <option *ngFor="let item of projetItems" [ngValue]="item"> {{ item?.libelle}}</option>
          </select>
        </label>
      </div>
    </div>

    <div *ngIf="showFiltre === true" class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <label class="d-inline-flex align-items-center">
          Direction:
          <select  type="text" name="searchTerm" class="form-select ms-2"
            aria-controls="tickets-table" [(ngModel)]="selectedDirection"  (change)="filterDataByDirections()"   >
            <option value="Sélectionner une direction" >Sélectionner une direction</option>
            <option *ngFor="let item of directionItems" [ngValue]="item.reference"> {{ item?.direction?.libelle}}</option>
          </select>
        </label>
      </div>
    </div>
  
   
   

  </div>

  <p-tabView>

    <p-tabPanel header="Non Ordonnancés ({{itemNonOrdonancer ? itemNonOrdonancer.length :0}})">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
                </div>
              </div>

              <div class="table-responsive mt-3">
                <table class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                  <thead class="thead-light  rwd-table">
                    <tr>
                      <th>Code</th>
                      <th>Libellé</th>
                      <th> Montant planifié</th>
                      <th>Total engagé</th>
                      <th>Total Ordonnancé</th>
                      <th style="width: 120px;"></th>
                      <th style="width: 120px;"></th>
                    </tr>
                  </thead>
                  <tbody *ngIf="isLoading === false">
                    <tr *ngFor="let item of paginateNonOrdonnancement; let index = index">
                      <td>{{item.code_sous_activite}}</td>
                      <td>{{item.sous_activite}}</td>
                      <td>{{item.total | formatMoney:null }}</td>
                      <td>{{item.total_engage | formatMoney:null }}</td>
                      <td>{{item.total_ordonnance | formatMoney:null}}</td>
                      <td>
                        <button style="width: 150px;" (click)="openModal(item)" title="Cliquez ici pour ordonnancer"
                          class="btn btn-success btn-sm">
                          <i class="mdi mdi-check-all font-size-1"></i> Ordonnancer </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                  <div class="container">
                    <div class="row">
                      <div class="text-center">
                        <h5 id="graycolor">Chargement en cours</h5>
                        <div class="loader4"></div>
                      </div>
                    </div>
                  </div>

                </div>

                <div *ngIf="paginateNonOrdonnancement.length === 0 && isLoading === false"
                  class="d-flex justify-content-center">
                  <div class="container">
                    <div class="row">
                      <div class="text-center">
                        <h5 id="graycolor">Aucune donnée trouvée</h5>
                      </div>
                    </div>
                  </div>

                </div>
                <p-paginator (onPageChange)="onPageChangeNonOrdonnanement($event)" [first]="firstNonOrdonnanceemnt"
                  [rows]="rowsNonOrdonnancement" [totalRecords]="totalRecords2" [rowsPerPageOptions]="[10, 20, 30]">
                </p-paginator>
              </div>

            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Ordonnancés ({{items ? items.length : 0}}) ">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
            
                </div>
            
             
              </div>
              <div class="table-responsive mt-3">
                <table class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                  <thead class="thead-light  rwd-table">
                    <tr>
                      <th>Code</th>
                      <th>Libellé</th>
                      <th> Montant planifié</th>
                      <th>Total engagé</th>
                      <th>Total Ordonnancé</th>
                      <th style="width: 120px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of paginatedOrdonnancement; let index = index">
                      <td>{{item.code_sous_activite}}</td>
                      <td>{{item.sous_activite}}</td>
                      <td>{{item.total | formatMoney:null}}</td>
                      <td>{{item.total_engage | formatMoney:null}}</td>
                      <td>{{item.total_ordonnance | formatMoney:null}}</td>
                      <td>

                        <button (click)="cancelOrdonnancement(item)" title="Cliquez ici pour ordonnancer"
                          class="btn btn-success btn-sm">
                          <i class="mdi mdi-cancel font-size-18"></i> Annuler </button>

                      </td>
                    </tr>

                  </tbody>
                </table>
                <div class="d-flex justify-content-center" *ngIf="isLoading === true">
                  <div class="container">
                    <div class="row">
                      <div class="text-center">
                        <h5 id="graycolor">Chargement en cours</h5>
                        <div class="loader4"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="!paginatedOrdonnancement"
                  class="d-flex justify-content-center">
                  <div class="container">
                    <div class="row">
                      <div class="text-center">
                      </div>
                    </div>
                  </div>
                </div>
                <p-paginator (onPageChange)="onPageChangeOrdonnacnement($event)" [first]="firstOrdonnancement"
                  [rows]="rowsOrdonnancement" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
                </p-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>


  </p-tabView>
</div>



<ng-template #ordonnancementModal let-modal>

  <div class="modal-header">
    <h5 class="modal-title">Ordonnancement</h5>
    <button (click)="modal.dismiss('Close click')" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form>
        <div class="d-flex">
          <div class="form-group col-md-2">
            <label for="name">Code</label>
            <input style="background-color: aliceblue;" [(ngModel)]="sousActiviteItem.code_sous_activite" name="code" type="text"
              class="form-control" id="name" disabled placeholder="Code">
          </div> &nbsp;
          <div class="form-group  col-md-10">
            <label for="name">Libellé</label>
            <input style="background-color: aliceblue;" [(ngModel)]="sousActiviteItem.sous_activite" name="libelle" type="text"
              class="form-control" disabled placeholder="Libellé ">
          </div>
        </div><br>

        <!-- ligne de montants -->
        <div class="d-flex">

          <div class="form-group col-md-6">
            <label for="name">Montant engagé</label>
            <input style="background-color: aliceblue;"  [(ngModel)]="sousActiviteItem.total_engage" disabled name="total_engage" type="number" class="form-control" id="name"
              placeholder="Total engagé">
          </div> &nbsp;

          <div class="form-group  col-md-6">
            <label for="name">Total ordonnancé </label>
            <input  style="background-color: aliceblue;"  [(ngModel)]="sousActiviteItem.total_ordonnance" disabled name="total_ordonnance" type="number" class="form-control" id="name" placeholder="0 ">
  
          </div>
        </div><br>


           <!-- ligne de montants -->
           <div class="d-flex">
  
            <div class="form-group  col-md-6">
              <label for="name">Montant à ordonnancer</label>
              <input [(ngModel)]="sousActiviteItem.total_a_ordonnancer" name="montant_a_ordonnancer" type="number" class="form-control"
                id="name" placeholder=" Montant ordonnancé "  (ngModelChange)="calculateReste()">
  
            </div> &nbsp;
  
            <div class="form-group  col-md-6">
              <label for="name">Reste</label>
              <input  style="background-color: aliceblue;"  [(ngModel)]="sousActiviteItem.reste" disabled name="reste" type="number" class="form-control" id="name" placeholder="0 ">
    
            </div>
          </div><br>


        <div class="d-flex">
          <div class="form-group col-md-4">
            <label for="email">Date d'Ordonnancement</label>
            <input type="date" [(ngModel)]="sousActiviteItem.date_ordonnance"
              name="date_ordonnacement" class="form-control" id="date" placeholder="">
          </div>&nbsp;
        </div><br>

        <br>

        <div class="text-end">
          <div class="text-end">
            <button (click)="modal.dismiss('Close click')" type="button" class="btn btn-info ms-1"> <i
                class="pi pi-times" style="color: white"></i> Annuler</button> &nbsp;
            <button (click)="saveOrdonnancement()" type="submit" class="btn btn-success"> <i class="pi pi-save"
                style="color: white"></i>
              Ordonnancer</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>