<div class="container-fluid">

  <h4 id="upper">Point global OBRGM({{projetData.length}})</h4>
  <div class="d-flex justify-content-end">
    <!-- Ajout des champs de date -->
    <div class="me-3">
      <label class="d-inline-flex align-items-center">
        Début:
        <input disabled type="date" class="form-control ms-2" [(ngModel)]="dateDebut" name="dateDebut">
      </label>
    </div>

    <div class="me-3">
      <label class="d-inline-flex align-items-center">
        Fin:
        <input type="date" class="form-control ms-2" [(ngModel)]="dateFin" name="dateFin">
      </label>
    </div>
    <!-- <div *ngIf="showFiltre === true" class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <label class="d-inline-flex align-items-center">
          Direction:
          <select style="width: 200px;" type="text" name="searchTerm" class="form-select ms-2"
            aria-controls="tickets-table" [(ngModel)]="directionId" (change)="getProjetByDirection(directionId)">
            <option disabled value="Séléctionner une direction"></option>
            <option *ngFor="let item of directionItems" [value]="item.reference"> {{ item?.libelle}}</option>
          </select>
        </label>
      </div>
    </div>
    <div class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <label class="d-inline-flex align-items-center">
          Projet:
          <select type="text" name="searchTerm" class="form-select ms-2"
            aria-controls="tickets-table" [(ngModel)]="selectedProjet">
            <option [value]="'DEFAULT'" >Sélectionner un projet</option>
            <option *ngFor="let item of projetItems" [value]="item?.projet.reference"> {{ item?.projet?.libelle}}</option>
          </select>
        </label>
      </div>
    </div> -->
    <!-- Bouton Rechercher -->
    <div class="me-3">
      <div id="tickets-table_filter" class="dataTables_filter">
        <input (click)="getStatistique()" type="button" value="Rechercher" class="btn btn-primary">
      </div>
    </div>

    <!-- Bouton Exporter -->
    <!-- <div class="dropdown d-inline-block" ngbDropdown>
      <button type="button" ngbDropdownToggle class="btn btn-primary dropdown-toggle" id="dropdownMenuButton"
        aria-haspopup="true" aria-expanded="false">
        Exporter <i class="pi pi-upload" style="font-size: 0.8rem"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="javascript:void(0);" (click)="exportToDocument('pdf')">Au format PDF</a>
        <a class="dropdown-item" href="javascript:void(0);" (click)="exportToDocument('excel')">Au format Excel</a>
      </div>
    </div> -->
  </div>
  <div class="row">
    <div class="col-lg-7">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Montants par projet</h5>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light rwd-table">
                <tr>
                  <th>Libellé</th>
                  <th>Montant total alloué</th>
                  <th>Montant financement état</th>
                  <th>Montant fonds propres</th>
                  <th>Montant PTF</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let projet of projetData">
                  <td>{{ projet.nom_projet }}</td>
                  <td>{{ projet.total |formatMoney:null }}</td>
                  <td>{{ projet.mt_financement_etat|formatMoney:null }}</td>
                  <td>{{ projet.mt_financement_fond_propre|formatMoney:null }}</td>
                  <td>{{ projet.mt_financement_ptf |formatMoney:null}}</td>
                </tr>
                <tr *ngIf="projetData.length === 0 && !isLoading">
                  <td colspan="4">
                    <div class="text-center">
                      <h5 id="graycolor">Aucune donnée trouvée</h5>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-5">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Résumé par projet</h5>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light rwd-table">
                <tr>
                  <th>Taux d'exécution physique prévu</th>
                  <th>Taux d'exécution physique réalisé</th>
                  <th>Écart</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let projet of projetData">
                  <td>{{ projet.tep_prevu }}</td>
                  <td>{{ projet.tep_realise }}</td>
                  <td>{{ projet.ecart }}</td>
                </tr>
                <tr *ngIf="projetData.length === 0 && !isLoading">
                  <td colspan="3">
                    <div class="text-center">
                      <h5 id="graycolor">Aucune donnée trouvée</h5>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Section de chargement -->
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <div class="container">
      <div class="row">
        <div class="text-center">
          <h5 id="graycolor">Chargement en cours</h5>
          <div class="loader4"></div>
        </div>
      </div>
    </div>
  </div>

</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>