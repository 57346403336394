import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-metier',

  templateUrl: './metier.component.html',
  styleUrl: './metier.component.scss'
})

export class MetierComponent {


  constructor(

    private router:Router
  ){
    
  }
  goToMission(){

    //console.log("appelr");
    
    this.router.navigate(['/realisations'])
  }
}
