<div class="container-fluid"></div>

<h4 id="upper">Tableau des Projets</h4>
<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive mt-3">
          <table class="table table-centered datatable dt-responsive nowrap"
            style="border-collapse: collapse; border-spacing: 0; width: 100%;">
            <thead class="thead-light  rwd-table">
              <tr>

                <th>Nom du Projet</th>
                <th> Montant financement état</th>
                <th> Montant financement fond propre</th>
                <th> Montant financement ptf</th>
                <th>Total</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of projets">
                <td>{{ item.nom_projet }}</td>
                <td>{{ item.mt_financement_etat }}</td>
                <td>{{ item.mt_financement_fond_propre }}</td>
                <td>{{ item.mt_financement_ptf }}</td>
                <td>{{ item.total }}</td>
              </tr>



            </tbody>
          </table>
        </div>




      </div>
    </div>
    <h2 class=" text-center">Répartition des Financements par Projet</h2>
    <div class="container mt-5 d-flex justify-content-center" *ngIf="isLoading">
      <div class="mt-4 col-md-4">
        <p-chart type="pie" [data]="chartData" [options]="chartOptions"></p-chart>
      </div>

    </div>
    <div class="container mt-5 d-flex justify-content-center" *ngIf="!isLoading">
      <div class="mt-4 col-md-4">
        <p-skeleton shape="circle" size="25rem" />
      </div>

    </div>
  </div>

</div>

<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>