import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { AddResourceComponent } from '../add-resource/add-resource.component';
import { EditResourceComponent } from '../edit-resource/edit-resource.component';
import { BehaviorSubject } from 'rxjs';
import { Ressource } from 'src/app/shared/models/ressource';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { Router } from '@angular/router';
import { Constant } from 'src/app/shared/service/utils/contant';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-ressources',
  templateUrl: './ressources.component.html',
  styleUrl: './ressources.component.scss'
})

export class RessourcesComponent  {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  items: any =[];
  validationform: UntypedFormGroup;
  modalRef: NgbModalRef | null = null;

  itemToDelete!: Ressource; 
  searchTerm: string = '';

  pageSize: number = 10; 

  filteredtems: any = [];
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  paginatedProjets = [];

  constructor(
      private modalService: NgbModal,
      public formBuilder: UntypedFormBuilder,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private FecthDataService: FecthDataService,
      private router:Router,
      private ngxService:NgxUiLoaderService

  ) {
    this.FecthDataService.actionCreated$.subscribe(() => {
      // Met à jour la liste après la création réussie d'un glossaire
      this.fetchData();
    });
   }

  ngOnInit(): void {

   

    this.fetchData();


  }

   fetchData() {    
    this.paramservice.fetchDataRessource().subscribe(
      (response:any)=>{
        this.items = response.data;
        this.filteredtems = this.items;  
        this.totalRecords = this.filteredtems.length;
        this.updatePaginatedProjets(); // Initial pagination
        //("this.actionData",this.items);

      },
      (error)=>{
        //("une erreur est survenu",error);
        
      }
      
    ) ;
  }

  onPageChangeProjets(event) {
    this.firstProjets =  event.first; // Mise à jour de la page actuelle
    this.rowsProjets =  event.rows ; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }
  
  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.filteredtems.slice(start, end);
  }
  openModal() {
    this.modalService.open(AddResourceComponent, {
       centered: true,
       backdrop: 'static',
       size:"lg" 


       });
  }

  EditModal(action:any) {
    this.localstorage.saveResource(action)
      const modalRef = this.modalService.open(EditResourceComponent,{
        backdrop: 'static', 
        centered: true,
        size:"lg" 

      }, );
  }

  confirmDeletion(event: any) {
    this.confirmationService.confirm({
        //target: event.target as EventTarget,
        message: 'Êtes-vous sûr de vouloir supprimer cette ressources?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-text',
        acceptLabel: 'Oui', // Définir le libellé du bouton "Oui"
        rejectLabel: 'Non', // Définir le libellé du bouton "Non"
        accept: () => {
            this.ngxService.start();
            this.paramservice.deleteRessource(event).subscribe(

              (response:any)=>{

                //("la reponse du webserice",response);
                this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: response.message });
                this.ngxService.stop();

                this.fetchData();
              },
              (error)=>{

                //("une erreur est survenu",error);
                this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });
                this.ngxService.stop();

                
              },
            )
          },
        
    });
}

 

addLocalisation(item:any){

  this.localstorage.saveResource(item)
  this.router.navigate(['/recherche/localisation'])
}



fiterRessource() {
  
  const lowerSearchTerm = this.searchTerm.trim().toLowerCase();
  console.log("one one ",lowerSearchTerm);

  this.filteredtems = this.items.filter((item: any) => {
      // Vérifier que item et ses propriétés existent avant d'accéder à toLowerCase()
      const matchesTerm = 
          (item.perspective && item.perspective.toLowerCase().includes(lowerSearchTerm)) || 
          (item.titre && item.titre.toLowerCase().includes(lowerSearchTerm)) || 
          (item.type_ressource && item.type_ressource.libelle && item.type_ressource.libelle.toLowerCase().includes(lowerSearchTerm)) || 
          (item.code && item.code.toLowerCase().includes(lowerSearchTerm));

      return matchesTerm;
  });

  this.paginatedProjets = this.filteredtems;
}

changePageSize() {
  // Met à jour les données filtrées en fonction de la nouvelle taille de page sélectionnée
  this.filteredtems = this.items.slice(0, this.pageSize);
}
}


