import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ActionServiceService } from '../action/action.component';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-edit-action',
  templateUrl: './edit-action.component.html',
  styleUrl: './edit-action.component.scss'
})


export class EditActionComponent {

  ationData: any = {}
  programmeData :any=[];
  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService,
    private messageService:MessageService,
    private actionService:ActionServiceService

  ) { }


  ngOnInit(){

    this.ationData = this.localstorage.getAction();

    this.fetchDataPrograme();

  }

  close(){

    this.modalService.dismissAll();
  }


  EditAction(){

    const item =this.localstorage.getAction();
    this.paramService.editAction(item.reference,this.ationData).subscribe(
      (response:any)=>{
        //console.log("la reponse du webservice",response);

        this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail:response.message });
        this.actionService.notifyProgrammeCreated();

      },
      (error:any)=>{
        //console.log("une erreur est survenu",error);
        this.messageService.add({ severity: 'erreur', summary: Constant.suiviTitle, detail: error });
        this.modalService.dismissAll();
        
      }
    )
  }

  fetchDataPrograme() {
    
    
    this.paramService.fetchDataPrograme().subscribe(

      (response:any)=>{

        this.programmeData = response.data;

        //console.log("this.programmeData",this.programmeData);
        
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }
}