import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { IndicateurServiceService } from '../indicateur/indicateur.component';
import { MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';
import { Indicateur } from 'src/app/shared/models/indicateurs';

@Component({
  selector: 'app-edit-indicateur',
  templateUrl: './edit-indicateur.component.html',
  styleUrl: './edit-indicateur.component.scss'
})
export class EditIndicateurComponent {

  item: Indicateur = new Indicateur()
  typeIndicateurData: any = [];
  projetData: any = [];
  constructor(
    private modalService: NgbModal,
    private paramService: ParameterService,
    private localstorage: LocalStorageService,
    private indicateurService: IndicateurServiceService,
    private messageService: MessageService,
    private ngxService: NgxUiLoaderService


  ) { }


  ngOnInit() {

    this.item = this.localstorage.getIndicateur();
    //console.log(" this.item", this.item?.libelle);
    const dateValeur = this.localstorage.getIndicateur()?.date_valeur_reference;
    if (dateValeur) {
      const date = new Date(dateValeur);
      // Formater la date au format 'YYYY-MM-DD' pour l'input de type 'date'
      this.item.date_valeur_reference = date.toISOString().split('T')[0];
    }
    /////console.log("this.item.date_valeur_reference", this.item.date_valeur_reference);


    this.fetchDataProjet();
    this.getIndicateurProjet();

  }

  close() {

    this.modalService.dismissAll();
  }


  Edit() {
    const item = this.localstorage.getIndicateur();
    this.ngxService.start();
    this.paramService.editIndicateurs(item.reference, this.item).subscribe(
      (response: any) => {
        //console.log("la reponse du webservice", response);
        this.indicateurService.notifyProgrammeCreated();
        this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
        this.ngxService.stop();
      },
      (error: any) => {
        //console.log("une erreur est survenu", error);
        // this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });

        this.ngxService.stop();

      }
    )
  }

  //liste des projets
  fetchDataProjet() {
    this.paramService.fetchDataProjet().subscribe(
      (response: any) => {
        this.projetData = response.data;
        //console.log("this.projetData", this.projetData);
      },
      (error) => {
        //console.log("une erreur est survenu", error);
      }
    );
  }//end fetchDataProjet

  //recuperation des indicateurs du projet
  getIndicateurProjet() {
    this.paramService.fetchTypeIndicateur().subscribe(
      (response: any) => {
        this.typeIndicateurData = response.data;
        //console.log("this.projetData", this.typeIndicateurData);
      },
      (error) => {
        //console.log("une erreur est survenu", error);
      }
    );
  }//end getIndicateurProjet
}