import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';

@Component({
  selector: 'app-edit-tache',
  templateUrl: './edit-tache.component.html',
  styleUrl: './edit-tache.component.scss'
})

export class EditTacheComponent {

  actionData: any = {}

  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService

  ) { }


  ngOninit(){
    this.actionData = this.localstorage.getAction();
  }

  close(){

    this.modalService.dismissAll();
  }


  EditAction(){

    const item =this.localstorage.getAction();
    this.paramService.editAction(item.id,this.actionData).subscribe(
      (response:any)=>{
        console.log("la reponse du webservice",response);
      },
      (error:any)=>{
        console.log("une erreur est survenu",error);
        
      }
    )
  }
}