<header id="page-topbar" style="background-color: #30489e;">
  <div class="navbar-header">
    <div class="d-flex">
      <button type="button" class="btn btn-sm px-3 font-size-24 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

    </div>

    <div class="d-flex">
      <div class="d-flex" *ngIf="afficherService?.code === 'suivie'">
        <!-- <div style="padding-right: 10px;" class="mt-4 ">
          <h6 id="upper" style="color: white;">
            <i class="pi pi-home " style="font-size: 0.9rem"></i> <a style="color: white;" routerLink="/"
              href="javascript: void(0);"> Accueil</a>
          </h6>
        </div> &nbsp; -->
        <!-- <div class="mt-4 " *ngIf="showProjet === true && showExercice && showExercice.annee !== ''">

          <h6 id="upper" style="color: white;">
            <i class="pi pi-clipboard " style="font-size: 0.9rem"></i> {{showExercice.annee}}
          </h6>
        </div>
        &nbsp;

        <div class="mt-4" *ngIf="showProjet === true && projetName && projetName.libelle !== ''">
          <h6 id="upper" style="color: white;"><i class="pi pi-receipt" style="font-size: 0.9rem"></i>
            {{projetName.sigle}}</h6>
        </div> -->
      </div>&nbsp;&nbsp;&nbsp;&nbsp;
      <div style="display: flex; align-items: center; gap: 10px;" class="mb-4">
        <div class="dropdown" ngbDropdown style="flex: 1;">
          <div class="mt-4" style="color: white; " class="text-center mt-4 mb-6">
            <a id="upper" style="color: white; display: flex; align-items: center;" routerLink="/"
              href="javascript: void(0);" class="">
              <i class="pi pi-home" style="font-size: 0.9rem; margin-right: 5px;"></i> Accueil
            </a>
          </div>
        </div>
        <!--        -->
        <!-- <div  style=" pointer-events: none; cursor: not-allowed;" aria-disabled="false" class="dropdown" ngbDropdown style="flex: 1;">
          <div class="mt-4" *ngIf="afficherService?.code === 'suivie'"
            style="color: white;"
            class="text-center mt-4 mb-6">
            <a  id="upper" style="color: white; display: flex; align-items: center;" href="javascript: void(0);" class="">
              {{projetName?.sigle}}
            </a>
          </div>
        </div> -->

        <div style=" pointer-events: none; cursor: not-allowed;" class="dropdown" ngbDropdown style="flex: 1;">
          <div class="mt-4" *ngIf="afficherService?.code === 'suivie'" style="color: white; "
            class="text-center mt-4 mb-6">
            <a id="upper" style="color: white; display: flex; align-items: center;" href="javascript: void(0);"
              class="">
              {{showExercice || ''}}
            </a>
          </div>
        </div>

        <!-- <div class="dropdown" ngbDropdown style="flex: 1;">
          <div class="mt-4" *ngIf="afficherService?.code === 'suivie'"
            style="top: 90px; color: white; border-style: double; height: 25px; width: 72px;"
            class="text-center mt-4 mb-6">
            <a id="upper" style="color: white; display: flex; align-items: center;" routerLink="/page/projet" href="javascript: void(0);" class="">
              Choisir
            </a>
          </div>
        </div> -->
      </div>
      <div class="dropdown d-none d-lg-inline-block ms-1 mt-2">
        <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen"
          (click)="fullscreen()">
          <i style="color: white;" class="ri-fullscreen-line"></i>
        </button>
      </div>
      <div class="dropdown d-inline-block user-dropdown mt-2" ngbDropdown>

        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" src="assets/images/users/profil.png" alt="Header Avatar">
          <span style="color: white;" class="d-none d-xl-inline-block ms-1">{{afficherNom?.nom}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a style="font-size: 18px;" class="dropdown-item" href="javascript: void(0);" (click)="profil()"><i
              class="ri-user-line align-middle me-1"></i>
            Profil</a>
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i class="ri-wallet-2-line align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a> -->
          <!-- <a style="font-size: 18px;" class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge badge-success float-end mt-1"></span><i
              class="ri-settings-2-line align-middle me-1"></i>Paramètres</a> -->
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i class="ri-lock-unlock-line align-middle me-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a> -->
          <div class="dropdown-divider"></div>
          <a style="font-size: 18px;" class="dropdown-item text-danger" href="javascript: void(0);"
            (click)="logout()"><i class="ri-shut-down-line align-middle me-1 text-danger"></i>
            Se déconnecter</a>
        </div>
      </div>
      <!-- 
      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect" (click)="onSettingsButtonClicked()">
          <i class="ri-settings-2-line"></i>
        </button>
      </div> -->

    </div>
  </div>
</header>