import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { Centre } from 'src/app/shared/models/centre';
import { Ressource } from 'src/app/shared/models/ressource';
import { FecthDataService } from 'src/app/shared/service/fetchservice/fetchservice';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-add-centre',
  templateUrl: './add-centre.component.html',
  styleUrl: './add-centre.component.scss'
})


export class AddCentreComponent {

  item: Centre = new Centre();
  programmeData: any = [];
  photoPreview: string | ArrayBuffer | null = null;
  departement: any = [];
  commune: any = [];
  arrondissement: any = [];
  selectedDepartementId: any;
  selectedCommuneId: any;
  selectedArrondissement: any;

  constructor(
    private modalService: NgbModal,
    private paramService: ParameterService,
    private messageService: MessageService,
    private FecthDataService: FecthDataService,
    private localstorage: LocalStorageService,
    private ngxservice: NgxUiLoaderService
  ) {
  }

  ngOnInit() {
    this.getDepartement();
  }

  close() {
    this.modalService.dismissAll();
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.item.photo = file;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.photoPreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
  
  addCentre() {
    if (!this.item.description || !this.item.nom || !this.item.longitude || !this.item.latitude || !this.item.arrondissement_id || !this.item.photo) {
      this.messageService.add({ severity: 'warn', summary: Constant.rechercheiTitle, detail: "Veuillez renseigner tous les champs." });
      return;
    } else {

      this.ngxservice.start();
      let formData: FormData = new FormData();
      formData.append('nom', this.item.nom);
      formData.append('longitude', this.item.longitude);
      formData.append('latitude', this.item.latitude);
      formData.append('description', this.item.description);
      formData.append('arrondissement_id', this.item.arrondissement_id);
      formData.append('photo', this.item.photo);

      this.ngxservice.start();
      this.paramService.addCentre(formData).subscribe(
        (response: any) => {
          this.ngxservice.stop();
          this.modalService.dismissAll();
          this.messageService.add({ severity: 'success', summary: Constant.rechercheiTitle, detail: response.message });

          this.FecthDataService.notifySeriviceCreated();

        },
        (error: any) => {
          this.ngxservice.stop();
          //console.log("une erreur est survenu", error);
          // this.modalService.dismissAll();
          this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });
          this.FecthDataService.notifySeriviceCreated();
          this.ngxservice.stop();


        }
      )
    }

  }

  getDepartement() {

    this.paramService.getStatsDepartement().subscribe(

      (response: any) => {

        this.departement = response.data;
        //console.log("this.departement", this.departement);

      },
      (error) => {

        //console.log("une errer est survenu", error);

      }
    )
  }



  onDepartementChange(id: any) {
    this.selectedDepartementId = id;
    this.getCommunesById(id);
  }

  oncommuneChange(id: any) {
    //console.log("id", id);

    this.selectedCommuneId = id;
    this.getArrondissementById(id);
  }

  getCommunesById(id: any) {
    this.paramService.getCommuneByDepartement(id).subscribe(
      (response: any) => {
        this.commune = response.data;
        //console.log("this.commune", this.commune);
      },
      (error) => {
        //console.log("une erreur est survenue", error);
      }
    );
  }

  getArrondissementById(id: any) {
    this.paramService.getArrondissementById(id).subscribe(
      (response: any) => {
        this.arrondissement = response.data;
        //console.log("this.arrondissement", this.arrondissement);
      },
      (error) => {
        //console.log("une erreur est survenue", error);
      }
    );
  }


} 