
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {


    public getUsers(): any {
        return this.readFromSession("PROJET_GESTION_USER_TO_OPERATION");
    };
    public saveUsers(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_GESTION_USER_TO_OPERATION", obj);
    }

    public getProgrames(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROGRAMME_TO_OPERATION");
    };
    public saveProgramme(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_PROGRAMME_TO_OPERATION", obj);
    }


    //action

    public getAction(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION");
    };
    public saveAction(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION", obj);
    }

    //projet

    public getProjet(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROJET_TO_OPERATION");
    };
    public saveProjet(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_PROJET_TO_OPERATION", obj);
    }

    //iNDICATEUR

    public getIndicateur(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_INDICATEUR_TO_OPERATION");
    };
    public saveIndicateur(obj: any): void {
       console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_INDICATEUR_TO_OPERATION", obj);
    }


    public getDirection(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_DRECTION_TO_OPERATION");
    };
    public saveDirection(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_DRECTION_TO_OPERATION", obj);
    }

    public getGestionUser(): any {
        return this.readFromSession("PROJETSAVE_SUIVIEVAL_USER_TO_OPERATION");
    };
    public saveGestionUser(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJETSAVE_SUIVIEVAL_USER_TO_OPERATION", obj);
    }



    public getService(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_SERVICE_TO_OPERATION");
    };
    public saveService(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_SERVICE_TO_OPERATION", obj);
    }



    // validation pta

    public getValidationPta(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_VALIDATION_PTA_TO_OPERATION");
    };
    public saveValidationPta(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_VALIDATION_PTA_TO_OPERATION", obj);
    }

//acces user

    public getAcessUser(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_USER");
    };
    public saveAccessUser(obj:any): void{
      // // //console.log("l'obejt",obj);
        
        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_USER", obj);
    }
    //end


    // saveProjetToUser

    public getProjetToUserToSuivieEvaluation(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_USER_TO_SUIVIE_EVALUATION");
    };
    public saveProjetToUserToSuivieEvaluation(obj:any): void{
      // // //console.log("l'obejt",obj);
        
        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_USER_TO_SUIVIE_EVALUATION", obj);
    }

    public getExerciceToUserToSuivieEvaluation(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_EXERCICE_TO_OPERATION_USER_TO_SUIVIE_EVALUATION");
    };
    public saveExerciceToUserToSuivieEvaluation(obj:any): void{
        this.saveToSession("PROJET_SUIVIEVAL_ACTION_EXERCICE_TO_OPERATION_USER_TO_SUIVIE_EVALUATION", obj);
    }

    //end
    public getRessource(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_RESOURCE");
    };
    public saveResource(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_RESOURCE", obj);
    }


    public getCentre(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_CENTRE");
    };
    public saveCentre(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_CENTRE", obj);
    }
    public getActeur(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_ACTEUR");
    };
    public saveActeur(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_ACTEUR", obj);
    }


    public getCentreDetail(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_CENTRE_DETAIL");
    };
    public saveCentreDetail(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_CENTRE_DETAIL", obj);
    }


    public getRessourceDetail(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_RESOURCE_DETAIL");
    };
    public SaveResourceDetail(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_RESOURCE_DETAIL", obj);
    }


    
    public getRessourceDetailByCategorie(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_RESOURCE_DETAIL_BY_CATEGORY");
    };
    public SaveResourceDetailByCategorie(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_RESOURCE_DETAIL_BY_CATEGORY", obj);
    }


    // projet-activité 

    public getProjetActivite(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROJET_ACTIVITE_TO_OPERATION");
    };
    public saveProjetActivite(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_PROJET_ACTIVITE_TO_OPERATION", obj);
    }


    public getSousActiviterToActivite(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROJET_SOUS_ACTIVITE_TO_ACTIVITE_TO_OPERATION");
    };
    public saveSousActiviteToActivité(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_PROJET_SOUS_ACTIVITE_TO_ACTIVITE_TO_OPERATION", obj);
    }


    
    public getTacheSousActivite(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROJET_TACHE_SOUS_ACTIVITE_TO_ACTIVITE_TO_OPERATION");
    };
    public saveTacheSousActiviteToActivité(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_PROJET_TACHE_SOUS_ACTIVITE_TO_ACTIVITE_TO_OPERATION", obj);
    }

    //
    public getProjetToUser(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROJET_TO_USER_OPERATION");
    };
    public saveProjetToUser(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_SUIVIEVAL_PROJET_TO_USER_OPERATION", obj);
    }
    //end


    private readFromSession(key: string): any {
        let result = null;
        try {
            const json: any = localStorage.getItem(key);
            if (json) {
                const decoded = decodeURIComponent(escape(atob(json)));
                result = JSON.parse(decoded);
            }
        } catch (e) {
            //console.error("Error", e);
        }
        return result;
    }

    private saveToSession(key: string, value: any): void {

        //console.log("j'appelle le localstorage");

        try {
            const stringified = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
            localStorage.setItem(key, stringified);

            //console.log("value",value);

        } catch (e) {
            //console.error("Error", e);
        }
    }


    public getInfoUserToService(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_USER_SERVICE");
    };
    public saveInfoUserToService(obj:any): void{
        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_OPERATION_USER_SERVICE", obj);
    }

    public getProjetTache(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTION_TO_PROJET_TACHE_OPERATION");
    };
    public saveProjetTache(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_ACTION_TO_PROJET_TACHE_OPERATION", obj);
    }


    //manage sous activite item
    public getSousActiviteItem(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_SOUS_ACTIVITE_ITEM");
    };
    public saveSousActiviteItem(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_SOUS_ACTIVITE_ITEM", obj);
    }

    //manage tache item
    public getTacheItem(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_TACHE_ITEM");
    };
    public saveTacheItem(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_TACHE_ITEM", obj);
    }



    public getUsesModify(): any {
        return this.readFromSession("PROJET_GESTION_USER_TO_OPERATION_MODIFY");
    };
    public saveUsersModify(obj: any): void {
        // // //console.log("l'obejt",obj);

        this.saveToSession("PROJET_GESTION_USER_TO_OPERATION_MODIFY", obj);
    }


    public getFilteredTache(): any {
        return this.readFromSession("PROJET_SUIVIEVALFITERED_SOUS_ACTIVITE_ITEM");
    };
    public saveFiletredTache(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVALFITERED_SOUS_ACTIVITE_ITEM", obj);
    }


    public getActiviteBudgetaire(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_ACTIVITE_OPERATIONNELLE");
    };
    public saveActiviteBudgetaire(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_ACTIVITE_OPERATIONNELLE", obj);
    }

    
    public getTepProjet(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROJET_TEP");
    };
    public saveTepProjet(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_PROJET_TEP", obj);
    }

    public getDateToDetail(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROJET_TEP_DETAILS");
    };
    public saveDateToDetail(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_PROJET_TEP_DETAILS", obj);
    }

    public getPlanification(): any {
        return this.readFromSession("PROJET_SUIVIEVAL_PROJET_INDICACTEUR_PLANIFICATION");
    };
    public savePlanification(obj: any): void {
        this.saveToSession("PROJET_SUIVIEVAL_PROJET_INDICACTEUR_PLANIFICATION", obj);
    }



    public getAccueil(): any {
        return this.readFromSession("PROJET_ACCUEIL_PLANIFICATION");
    };
    public saveAccueil(obj: any): void {
        this.saveToSession("PROJET_ACCUEIL_PLANIFICATION", obj);
    }

    public getAccueilCentre(): any {
        return this.readFromSession("PROJET_ACCUEILCENTRE_PLANIFICATION");
    };
    public saveAccueilCentre(obj: any): void {
        this.saveToSession("PROJET_ACCUEILCENTRE_PLANIFICATION", obj);
    }
}