<div class="container-fluid">

  <h4 id="upper">Directions</h4>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <!--  -->
            <a href="javascript:void(0);" class="btn btn-success mb-2" id="gras" (click)="openModal()">
              <i class="mdi mdi-plus me-2"></i> Ajouter une Direction
            </a>
          </div>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <!-- <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Afficher
                  <select name="tickets-table_length" [(ngModel)]="pageSize" aria-controls="tickets-table"
                    name="pageSize" class="custom-select custom-select-sm form-control form-control-sm mx-2"
                    (change)="changePageSize()">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> lignes</label></div> -->
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" [(ngModel)]="searchTerm" (keyup)="filterData()"
                    class="form-control form-control-sm ms-2" aria-controls="tickets-table"></label></div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light  rwd-table">
                <tr>
                  <th style="width: 20px;">

                  </th>
                  <th>Libellé</th>
                  <th>Sigle</th>
                  <th style="width: 120px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of paginatedProjets; let index = index">
                  <td>
                  </td>

                  <td>{{item.libelle}}</td>
                  <td>{{item.sigle}}</td>
                  <td>
                    <a (click)="EditModal(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover
                      title="Edit">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a (click)="confirmDeletion(item)" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                      title="Delete">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="paginatedProjets.length === 0 && isLoading === true">
          <div class="container">
            <div class="row">
              <div class="text-center">
                <h5 id="graycolor">Chargement en cours...</h5>
                <div class="loader4"></div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="paginatedProjets.length === 0 && isLoading === false" class="d-flex justify-content-center" >
          <div class="container">
            <div class="row">
              <div class="text-center">
                <h5 id="graycolor">Aucune donnée trouvée</h5>
              </div>
            </div>
          </div>
          
        </div>
        <p-paginator (onPageChange)="onPageChangeProjets($event)" [first]="firstProjets" [rows]="rowsProjets"
          [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
        </p-paginator>
      </div>
    </div>
  </div>
</div>



<p-confirmDialog />
<p-toast />
<ngx-ui-loader></ngx-ui-loader>