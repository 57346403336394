<div style=" border-color: rgb(51, 50, 50);border-style:double;" class="container-fluid"><br>
  <div class="d-flex justify-content-between align-items-center">
    <h4 *ngIf="fitreTache === 'arealiser'">Tâches à réaliser ({{tacheData.length}}) - {{sousActivite.sous_activite}}
    </h4>
    <h4 *ngIf="fitreTache === 'realise'">Tâches Réalisées ({{tacheData.length}}) - {{sousActivite.sous_activite}}</h4>
    <h4 *ngIf="fitreTache === 'retard'">Tâches En retard ({{tacheData.length}}) - {{sousActivite.sous_activite}}</h4>
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div>
        <div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light  rwd-table">
                <tr>
                  <th>Code</th>
                  <th>Libellé</th>
                  <th>Date de début</th>
                  <th>Date de fin</th>
                  <th>Poids</th>
                  <th style="width: 120px;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of paginatedItems; let index = index">
                  <td>{{item?.code}}</td>
                  <td>{{item?.libelle}}</td>
                  <td>{{item?.date_debut | dateFormat}}</td>
                  <td>{{item?.date_fin | dateFormat}}</td>
                  <td>{{item.poids}}</td>
                  <td>
                    <button *ngIf="item.est_realise===false" (click)="detailModal(item)"
                      title="Cliquez ici pour voir les réalisation" class="btn btn-success btn-sm">
                      <i class="mdi mdi-forward me-2"></i>Réalisation
                    </button>

                    <button *ngIf="fitreTache ==='realise'" (click)="annulerTacheRealiser(item)"
                      title="Cliquez ici pour Annuler " class="btn btn-secondary btn-sm">
                      <i class="pi pi-minus-circle me-1"></i>Annuler
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="d-flex justify-content-center" *ngIf="isLoading === true">
              <div class="container">
                <div class="row">
                  <div class="text-center">
                    <h5 id="graycolor">Chargement en cours...</h5>
                    <div class="loader4"></div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="paginatedItems.length === 0 && isLoading === false" class="d-flex justify-content-center">
              <div class="container">
                <div class="row">
                  <div class="text-center">
                    <h5 id="graycolor">Aucune donnée trouvée</h5>
                  </div>
                </div>
              </div>

            </div>
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]">
            </p-paginator>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>
<ng-template #tacheModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{itemTache.libelle}}</h5>
    <button (click)="modal.dismiss('Close click')" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form>

        <div class="d-flex">
          <div class="form-group col-md-3">
            <label for="name">Code</label>
            <input style="background-color: aliceblue; width: 190px;" [(ngModel)]="itemTache.code" name="code" disabled
              type="text" class="form-control" id="code" placeholder=" un code">

          </div> &nbsp;
          <div class="form-group  col-md-8">
            <label for="name">Libellé</label>
            <input style="background-color: aliceblue; width: 575px;" [(ngModel)]="itemTache.libelle" name="libelle"
              disabled type="text" class="form-control" id="name" placeholder=" libelle ">

          </div>
        </div><br>


        <div class="d-flex">
          <div class="form-group col-md-6">
            <label for="email">Poids</label>
            <input style="background-color: aliceblue;" type="number" disabled [(ngModel)]="itemTache.poids"
              name="poids_action" class="form-control" id="email" placeholder="Poids">
          </div>&nbsp;

          <div class="form-group col-md-6">
            <label>Date d'enregistrement </label>
            <input style="background-color: aliceblue;" disabled [(ngModel)]="itemTache.date_enregistrement"
              name="responsable_action" type="text" class="form-control">

          </div>
        </div><br>

        <div class="d-flex">
          <div class="form-group col-md-6">
            <label for="email">Date de réalisation </label>
            <input [(ngModel)]="itemTache.date_realisation" name="Date_de_realisation " type="date" class="form-control"
              id="text" placeholder=" Date de réalisation ">

          </div>&nbsp;

          <div class="form-group col-md-6">
            <label>Observations </label>
            <textarea [(ngModel)]="itemTache.observation" name="Observations" type="text" class="form-control"
              style="height: 30px;" placeholder=" Observations" minlength="10"> </textarea>

          </div>
        </div><br>

        <div class="form-group col-md-12 mb-2">
          <label>Preuve de réalisation </label>
          <input (change)="onFileSelected($event)" name="Observations" type="file" class="form-control"
            placeholder=" Preuve de réalisation">

        </div>

        <div class="text-end">
          <div class="text-end">
            <button (click)="modal.dismiss('Close click')" type="button" class="btn btn-info ms-1">
              <i class="pi pi-times" style="color: white"></i> Annuler
            </button> &nbsp;

            <button *ngIf="fitreTache === 'arealiser' || fitreTache === 'retard'"
              (click)="saveTacheToSousActiviter(modal)" type="submit" class="btn btn-success">
              <i class="pi pi-save" style="color: white"></i> Réaliser
            </button>

            <button *ngIf="fitreTache ==='realise'" (click)="saveTacheToSousActiviter(modal)" type="submit"
              class="btn btn-success">
              <i class="pi pi-save" style="color: white"></i> A réaliser
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<p-toast />