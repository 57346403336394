<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Ajouter une Action</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class=" row mb-3">

                <div class="form-group col-md-6">
                    <label for="name">Code</label>
                    <input [(ngModel)]="ationData.code" name="code" type="text" class="form-control" id="name" >
    
                </div> 
                <div class="form-group col-md-6">
                    <label for="name">Libelle</label>
                    <input [(ngModel)]="ationData.libelle" name="libelle" type="text" class="form-control" id="name" >
    
                </div> 
            </div><br>
           

            <div class=" row mb-3">
                <div class="form-group col-md-6">
                    <label for="email">Poids Action</label>
                    <input [(ngModel)]="ationData.poids_action" type="number" name="poids_action" class="form-control" id="email">
    
                </div>
    
                <div class="form-group  col-md-6">
                    <label for="name">Programme</label>

                    <select name="programme_id" id="programme_id" class="form-select"  [(ngModel)]="ationData.programme_id" >

                        <option> Selectionner un programme</option>
                        <option *ngFor="let item of programmeData" [value]="item.id"> {{item.libelle}}</option>
                    </select>
                </div>
            </div><br>
           

            <div class="form-group">
                <label for="name">Responsable action</label>
                <input [(ngModel)]="ationData.responsable_action" name="responsable_action" type="text" class="form-control" id="name">

            </div> <br>

            <div class="text-end">
                <button  (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times" style="color: white"></i> Annuler</button>&nbsp;

                <button (click)="addAction()" type="submit" class="btn btn-success"> <i class="pi pi-save" style="color: white"></i> Enregistrer</button>
            </div>
        </form>
    </div>
</div>

<p-toast />
<ngx-ui-loader></ngx-ui-loader>
