<div class="container-fluid">
    <h4 id="upper">Graphique du Taux d'Exécution Physique (TEP)</h4>
    <div class="d-flex justify-content-end">
        <div class="me-3">
            <label class="d-inline-flex align-items-center">
                Début:
                <input type="date" class="form-control ms-2" [(ngModel)]="startDate" name="dateDebut" required>
            </label>
        </div>
        <div class="me-3">
            <label class="d-inline-flex align-items-center">
                Fin:
                <input type="date" class="form-control ms-2" [(ngModel)]="endDate" name="dateFin" required>
            </label>
        </div>
        <div class="me-3">
            <input (click)="generateInitialData()" type="button" value="Afficher" class="btn btn-primary"> 
        </div>
    </div>

    <div class="card">

        <div class="row mt-4">
            <div class="row mt-4">
                <!-- Graphique à gauche -->
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <p-chart type="line" [data]="data" [options]="options"></p-chart>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div style="background-color: #ddedf1; height: 36px; width: 510px;"
                        class="d-flex justify-content-center align-items-center">
                        <h5 class="text-center mb-0">Légende des Mois</h5>
                    </div><br>

                    <div class="row">
                        <!-- Boucle pour afficher les légendes dans une grille de 3 colonnes -->
                        <div class="col-4" *ngFor="let item of legendItems">
                            <div class="d-flex align-items-center mb-2">
                                <span class="legend-color-box me-2" [style.backgroundColor]="item.color"
                                    style="width: 15px; height: 15px; display: inline-block;"></span>
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>