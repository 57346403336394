import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjetIndicateur } from 'src/app/shared/models/projet';
import { ParameterService } from 'src/app/shared/service/params/param';
import { ProjetIndicateurService } from '../projet-indicateur/projet-indicateur.component';
import { MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-add-projet',
  templateUrl: './add-projet.component.html',
  styleUrl: './add-projet.component.scss'
})
export class AddProjetComponent {

  projetData: ProjetIndicateur= new ProjetIndicateur()
  programmeData:any=[];

  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private ProjetIndicateurService:ProjetIndicateurService,
    private messageService:MessageService,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit(){
  }

  close(){
    this.modalService.dismissAll();
  }

  addProjet(){
    if (!this.projetData.canSave()) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: this.projetData.getErrorMessage() });
    }
    
    if (this.projetData.canSave()) {

      this.ngxService.start();
      this.paramService.addProjet(this.projetData).subscribe(
        (response:any)=>{
          console.log("la reponse du webservice",response);
  
          this.modalService.dismissAll();
          this.ProjetIndicateurService.notifyProgrammeCreated();
          this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
          this.ngxService.stop();
  
        },
        (error:any)=>{
          console.log("une erreur est survenu",error);
          //this.modalService.dismissAll();
          this.ngxService.stop();
          
          this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });
  
  
        }
      )
    }
  }

  // fetchDataPrograme() {
    
    
  //   this.paramService.fetchDataPrograme().subscribe(

  //     (response:any)=>{

  //       this.programmeData = response.data;

  //       console.log("this.programmeData",this.programmeData);
        
  //     },
  //     (error)=>{
  //       console.log("une erreur est survenu",error);
        
  //     }
      
  //   ) ;
  // }
}

