


<!-- vertical layout -->
@if(isVerticalLayoutRequested()){
<app-vertical></app-vertical>
}

<!-- horizontal layout -->
@if(isHorizontalLayoutRequested()){
<app-horizontal></app-horizontal>
}
