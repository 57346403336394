import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Service } from 'src/app/shared/models/service';
import { ParameterService } from 'src/app/shared/service/params/param';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrl: './add-service.component.scss'
})

export class AddServiceComponent {


  constructor(
    private modalService: NgbModal,

    private paramService:ParameterService

  ) { }


  ngOnInit(){
    this.fetchData();
  }
  close(){

    this.modalService.dismissAll();
  }

  item: Service= new Service()

  serviceData :any=[];
  addItems(){
    this.paramService.addService(this.item).subscribe(
      (response:any)=>{
        //console.log("la reponse du webservice",response);

        this.modalService.dismissAll();
      },
      (error:any)=>{
        //console.log("une erreur est survenu",error);
        this.modalService.dismissAll();

      }
    )
  }
  fetchData() {
    
    
    this.paramService.fetchDataService().subscribe(

      (response:any)=>{

        this.serviceData = response.data;

        //console.log("this.actionData",this.serviceData);
        
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }

}