import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Tableau de bord',
        icon: 'ri-dashboard-line',
        link: '/suivi/tableaux-de-bord',
        roles: ['admin', 'point_focal'],// Seuls 'admin'point_focal

    },
    {
        id: 5,
        label: 'Plan Analytique',
        icon: 'ri-store-2-line',
        roles: ['admin'], // Seuls 'admin'

        subItems: [
            {
                id: 8,
                label: 'Projets',
                link: '/suivi/plan/projet-lien-indicateur',
                parentId: 5
            },
            {
                id: 9,
                label: 'Indicateurs',
                link: '/suivi/plan/indicateur',
                parentId: 5
            },
            {
                id: 10,
                label: 'Directions',
                link: '/suivi/plan/direction',
                parentId: 5
            },
            {
                id: 11,
                label: 'Projet-Direction',
                link: '/suivi/plan/projet-direction',
                parentId: 5
            },
            {
                id: 12,
                label: 'Gestion Accès',
                link: '/suivi/plan/gestion-acces',
                parentId: 5
            },

        ]
    },
    {
        id: 13,
        label: 'Planification',
        icon: 'ri-mail-send-line',
        roles: ['admin'], // Seul 'admin' peut voir cet élément

        subItems: [
            {
                id: 14,
                label: 'Importation PTA',
                link: '/suivi/planification/importation',
                parentId: 14
            },
            {
                id: 15,
                label: 'Mise en corformité PTA',
                link: '/suivi/planification/validation',
                parentId: 14
            },
            {
                id: 16,
                label: 'Revue budgétaire',
                link: '/suivi/planification/budgetaire',
                parentId: 14
            }

            ,
            {
                id: 17,
                label: 'Cibles des Indicateurs',
                link: '/suivi/planification/cible-indicateurs',
                parentId: 14
            }

            // ,
            // {
            //     id: 18,
            //     label: 'Activités hors PTA',
            //     link: '/suivi/planification/activite-hors-pta',
            //     parentId: 14
            // }
        ]
    },

    {
        id: 23,
        label: 'Exécution',
        icon: 'ri-profile-line',
        roles: ['admin', 'point_focal'], // Rôles autorisés
        subItems: [
            {
                id: 24,
                label: 'Execution Tâches',
                link: '/suivi/execution/tâche',
                parentId: 23,
                
            },
            {
                id: 26,
                label: 'Réalisations Indicateurs',
                link: '/suivi/execution/realisation',
                parentId: 23
            },
            {
                id: 27,
                label: 'Engagements',
                link: '/suivi/execution/engagement',
                parentId: 23,
                rolesDirection: ['DAF'], // Seul 'DAF' ne peut voir ce sous-menu

            },
            {
                id: 28,
                label: 'Ordonnancements',
                link: '/suivi/execution/ordonnancement',
                parentId: 23,
                rolesDirection: ['DAF'], // Seul  ne peut 'DAF' peut voir ce sous-menu

            },

        ]
    },

    {
        id: 23,
        label: 'Edition',
        icon: 'ri-edit-fill',
        roles: ['admin', 'point_focal'], // Rôles autorisés

        subItems: [
            {
                id: 25,
                label: 'TEP Activités opérationnelles',
                link: '/suivi/edition/tep-sous-activite',
                parentId: 23
            },

            {
                id: 26,
                label: 'TEP Activités budgétaires',
                link: '/suivi/edition/tep-activite',
                parentId: 23
            },

            {
                id: 27,
                label: 'TEP Projets',
                link: '/suivi/edition/tep-projet',
                parentId: 23
            },
            {
                id: 29,
                label: 'Point global OBRGM',
                link: '/suivi/edition/tep-global-obgrm',
                parentId: 23
            },
            // {
            //     id: 30,
            //     label: 'Tableau Bord Projet',
            //     link: '/suivi/edition/tableaubord',
            //     parentId: 23
            // },
            {
                id: 31,
                label: 'Tableau Bord Indicateurs',
                link: '/suivi/edition/tableaubord-indicateur',
                parentId: 23
            },
            {
                id: 32,
                label: 'Point financier par projet',
                link: '/suivi/edition/bilan',
                parentId: 23
            },
            {
                id: 34,
                label: 'TEP par projet',
                link: '/suivi/edition/tep-by-projet',
                parentId: 23
            },
            {
                id: 36,
                label: "Taux d'Exécution Physique",
                link: '/suivi/edition/taux-execution',
                parentId: 23
            },
            {
                id: 37,
                label: 'Statistique Projet',
                link: '/suivi/edition/graphique',
                parentId: 23
            },
            {
                id: 38,
                label: 'Indicateur clé',
                link: '/suivi/edition/indicateur-cle',
                parentId: 23
            },
           
        ]
    },


    // {
    //     id: 18,
    //     label: 'MENUITEMS.AUTHENTICATION.TEXT',
    //     icon: 'ri-account-circle-line',
    //     subItems: [
    //         {
    //             id: 19,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
    //             link: '/suivis/login-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 20,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
    //             link: '/suivis/register-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 21,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
    //             link: '/suivis/recoverpwd-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 22,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
    //             link: '/suivis/lock-screen-1',
    //             parentId: 18
    //         }
    //     ]
    // },


    {
        id: 1,
        label: 'DECONNEXION',
        icon: 'ri-government-line',
        link: '/service'
    },
];


export const MENURECHERCHE: MenuItem[] = [
    {
        id: 1,
        label: 'TABLEAU DE BORD',
        icon: 'ri-dashboard-line',
        link: '/recherche/tableaux-de-bord'
    },
    {
        id: 5,
        label: 'Ressources',
        icon: 'ri-store-2-line',
        link: '/recherche/ressource'

    },
    {
        id: 5,
        label: 'Centres',
        icon: 'ri-bubble-chart-line',
        link: '/recherche/centre'

    },
    {
        id: 5,
        label: 'Acteurs',
        icon: ' ri-shield-user-line',
        link: '/recherche/acteurs'

    },

    {
        id: 1,
        label: 'DECONNEXION',
        icon: 'ri-government-line',
        link: '/service'
    },
];

