import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { ToastModule } from 'primeng/toast';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PublicRoutingModule,
  ]
})
export class PublicModule { }
