
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { reference } from '@popperjs/core';



@Injectable()
export class OperationService {

  constructor(private http: HttpClient) {
  }

  //getCours
  getRessourceByReference(item: any): Observable<any> {

    let reference = {
      reference: item
    }
    return this.http.post(`${environment.apiUrl + 'params/ressource-details'}`, reference

    );
  }

  getCentreByReference(item: any): Observable<any> {

    let reference = {
      reference: item
    }
    return this.http.post(`${environment.apiUrl + 'params/centre-details'}`, reference

    );
  }




  sendMessage(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}operation/contact`,item);
  }
  //recupere les donnees en attente de validation
  getPendingValidationPta(): Observable<any> {
    return this.http.get(`${environment.apiUrl + 'operation/pending-validation-pta'}`);
  }//end getPendingValidationPta

  //validate pending data as pta
  validatePendingPta(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}operation/validate-pta`,item);
  }
 //end validatePendingPta   

 getProjetToDirection(item: any): Observable<any> {
  return this.http.post(`${environment.apiUrl}operation/directions-projet`,item);
}
}

