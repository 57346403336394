import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Action } from '@fullcalendar/core/internal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { DetailTacheComponent } from '../detail-tache/detail-tache.component';
import { EditTacheComponent } from '../edit-tache/edit-tache.component';
import { Constant } from 'src/app/shared/service/utils/contant';
import { Tache } from 'src/app/shared/models/tache';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { reference } from '@popperjs/core';
import { request } from 'http';

@Component({
  selector: 'app-tache',
  templateUrl: './tache.component.html',
  styleUrl: './tache.component.scss'
})

export class TacheComponent {

  tacheData: any = [];
  modalRef: NgbModalRef | null = null;
  itemToDelete!: Action;
  directionItem: any;
  userInfo: any
  showFiltre: boolean = false;
  directionId: any = [];
  directionItems: any = [];
  selectedDirection: any = {};
  userRelatedProject: any = {};

  paginatedItemsArealiser: any[] = [];
  firstArealiser: number = 0;
  rowsArealiser: number = 10;
  totalRecordsArealiser: number = 0; // Nombre total d'éléments

  isLoading: boolean = false;
  ExerciceItems: any = [];
  projetItems: any = [];
  annee: any;
  selectedProjet: any;
  itemsArealiser: any[] = [];
  itemsListCopy: any[] = []; 
  item: Tache = new Tache();

  userReference: any;
  @ViewChild('tacheModal') modalContent: TemplateRef<any>;
  name: any
  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private ngxloader: NgxUiLoaderService

  ) { }

  ngOnInit(): void {
    this.annee = this.localstorage.getExerciceToUserToSuivieEvaluation();

    this.userInfo = this.localstorage.getInfoUserToService();

    //listing des projets
    this.getProjetsList();

    //dernieres taches en instance
    this.getTacheArealiserRealiserEnRetard();

    if (this.userInfo?.data?.groupe === "admin") {
      this.showFiltre = true;
    }

    if (this.userInfo?.data?.groupe === "point_focal") {

      this.directionId = this.localstorage.getInfoUserToService()?.data?.direction_id;

      //this.getProjetByDirection(this.directionId);
    }
  }//end ngOnInit




  //detail sous activite
  detailModal(itemSubActivite: any, type:any) {
    this.localstorage.saveSousActiviteItem(itemSubActivite)
    this.localstorage.saveFiletredTache(type)
    const modalRef = this.modalService.open(DetailTacheComponent, {
      backdrop: 'static',
      centered: true,
      size: 'xl'
    },);
  }//end detailModal



  editModal(action: any) {
    this.localstorage.saveAction(action)
    const modalRef = this.modalService.open(EditTacheComponent, {
      backdrop: 'static',
      centered: true,
    },);
  }

  //Get list of sous activites
  getListSousActivites(item: any) {
    this.isLoading = true;
    this.ngxloader.start();
    this.paramservice.getTacheArealiser(item).subscribe(
      (response: any) => {
        this.ngxloader.stop();
        this.isLoading = false;

        if (response.status === 'error') {
          this.itemsListCopy = []; 
          this.itemsArealiser = this.itemsListCopy; 
          this.totalRecordsArealiser = 0;
          this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: response.message, life: 3000 });
         
        } else {
          this.itemsListCopy = response.data || []; 
          this.itemsArealiser = this.itemsListCopy; 

          this.totalRecordsArealiser = this.itemsArealiser.length; 
        }
        this.updateTacheArealiser();
      },
      (error) => {
        this.ngxloader.stop();
        this.isLoading = false; 
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue lors de la récupération de la liste des tâches à réaliser", life: 3000 });
    
      }
    );
  }//end getListSousActivites


  onPageChangeArealiser(event: any) {
    
    this.firstArealiser = event.first;  
    this.rowsArealiser = event.rows; 
    this.updateTacheArealiser();
  }
  
  //
  updateTacheArealiser() {
    const start = this.firstArealiser;
    const end = this.firstArealiser + this.rowsArealiser;
    if (Array.isArray(this.itemsArealiser)) {
      this.paginatedItemsArealiser = this.itemsArealiser.slice(start, end);
    } else {
      this.paginatedItemsArealiser = []; 
    }
  }//end updateTacheArealiser


  //process selected Projet
  processSelectedProjet(){
    //get sous activites associated
    this.getTacheArealiserRealiserEnRetard();

    //get direction associated
    this.getDirectionByProjet(this.selectedProjet.reference);
  }

  //listing des projets
  getProjetsList() {
    this.paramservice.getListUserProjects(this.userInfo?.data?.reference).subscribe(
      (response: any) => {
        this.projetItems = response.data;

        console.log(this.projetItems);
      },
      (error) => {
      }
    )
  }//end getProjetsList

  //list direction par projet
  getDirectionByProjet(reference: any) {
    this.paramservice.getDirectionsByProjet(reference).subscribe(
      (response: any) => {
        this.directionItems = response.data;
      },
      (error: any) => {
      }
    )
  }//end getProjetByDirection

  //liste des taches en retard
  getTacheArealiserRealiserEnRetard() {

    // if (!this.selectedProjet) {
    //   this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: 'Le projet est requis' });
    //   return;
    // }
    const request = {
      "user_ref": this.userInfo?.data?.reference,
      "projet_id": this.selectedProjet ? this.selectedProjet.reference : null,
      "annee": this.annee,
    }
    
    this.getListSousActivites(request);
  }//end getTacheArealiserRealiserEnRetard

  //filtre les donnees par direction
  filterDataByDirections(){
    if(this.selectedDirection === null || this.selectedDirection === undefined || this.selectedDirection.direction_id === undefined) {
      this.itemsArealiser = this.itemsListCopy
    }else{
      this.itemsArealiser = this.itemsListCopy.filter(x => x.direction_id === this.selectedDirection.direction_id);
    }  
    this.updateTacheArealiser();
  }//en filterDataByDirections


    //Get list of user projects
    // getListUserProjects() {
    //   this.paramservice.getListUserProjects(this.userInfo?.data?.reference).subscribe(
    //     (response: any) => {

    //       console.log(response.data);
  
    //       if (response.status === 'error') {
    //         this.userRelatedProject = {};
    //       } else {
    //         this.userRelatedProject = response.data;
    //         this.selectedProjet = this.userRelatedProject;

    //         //get default sous activites
    //         this.getTacheArealiserRealiserEnRetard();
    //       }
    //     },
    //     (error) => {
    //       //this.ngxloader.stop();
    //       this.isLoading = false; 
    //       this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue lors de la récupération des données", life: 3000 });
    //     }
    //   );
    // }//end getListUserProjects

}