 <!-- Modal Header -->
 <div class="modal-header">
    <h5 class="modal-title" id="creationEntrepriseModalLabel">Acteurs</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button></div>

<!-- Modal Body -->
<div class="modal-body">
    <form>
        <div class="row">
            <!-- Nom de l’entreprise -->
            <div class="col-md-4 mb-3">
                <label for="nomEntreprise" class="form-label"> Raison sociale<span class="text-danger">*</span></label>
                <input [(ngModel)]="item.nom" name="nom" type="text" class="form-control" id="nomEntreprise" placeholder="Entrez le nom de l’entreprise" maxlength="255" required>
            </div>
            <div class="col-md-4 mb-3">
                <label for="nomEntreprise" class="form-label">Identité du promoteur</label>
                <input  [(ngModel)]="item.prenoms" name="prenoms"type="text" class="form-control" id="nomEntreprise" placeholder="Entrez le nom de l’entreprise" maxlength="255" required>
            </div>
            <!-- Identifiant Fiscal Unique (IFU) -->
            <div class="col-md-4 mb-3">
                <label for="ifu" class="form-label">Identifiant Fiscal Unique  <span class="text-danger">*</span></label>
                <input  [(ngModel)]="item.ifu" name="ifu" type="text" class="form-control" id="ifu" placeholder="Entrez l’IFU de l’entreprise" maxlength="20" required>
            </div>
            <!-- RRCM (Registre du Commerce et du Crédit Mobilier) -->
            <div class="col-md-4 mb-3">
                <label for="rrcm" class="form-label">RCCM </label>
                <input  [(ngModel)]="item.rrcm" name="rrcm" type="text" class="form-control" id="rrcm" placeholder="Entrez le numéro RRCM" maxlength="50">
            </div>

            <!-- Année de création -->
            <div class="col-md-4 mb-3">
                <label for="anneeCreation" class="form-label">Année de création <span class="text-danger">*</span></label>
                <input  [(ngModel)]="item.annee_creation" name="annee_creation" type="date" class="form-control" id="anneeCreation" placeholder="Entrez l’année de création" required>
            </div>

           <!-- Description -->
           <div class="col-md-4 mb-3">
            <label for="description" class="form-label">Description </label>
            <textarea  [(ngModel)]="item.description" name="description" class="form-control" id="description" placeholder="Ajoutez une description de l’entreprise" rows="2"></textarea>
        </div>
           

                  <!-- Secteur d’activité -->
        <div class="col-md-4 mb-3">
            <label for="secteurActivite" class="form-label">Secteur d’activité <span class="text-danger">*</span></label>
            <input  [(ngModel)]="item.secteur_activite" name="secteur_activite" type="text" class="form-control" id="secteurActivite" placeholder="Indiquez le secteur d’activité" maxlength="255" required>
        </div>

            <!-- Numéro d’autorisation d’exploitation -->
            <div class="col-md-4 mb-3">
                <label style="white-space: nowrap;" for="numAutorisation" class="form-label">Numéro d’autorisation </label>
                <input  [(ngModel)]="item.numero_autorisation_exploitation" name="numero_autorisation_exploitation" type="text" class="form-control" id="numAutorisation" placeholder="Entrez le numéro d’autorisation d’exploitation" maxlength="50">
            </div>

            <!-- Ressources exploitées -->
            <div class="col-md-4 mb-3">
                <label class="form-label">Ressources exploitées</label>
                <div (click)="showDialog('right')" class="text-center">
                   <button class="btn btn-outline-info"> Sélectionnez</button>
                </div>
            </div>

        </div>
    </form>
</div>

<!-- Modal Footer -->
<div class="modal-footer">
    <button  (click)="close()" type="button" class="btn btn-secondary " data-bs-dismiss="modal"><i class="pi pi-times" style="color: white"></i> Annuler</button>
    <button (click)="addActeurs()" type="submit" class="btn btn-primary"> <i class="pi pi-save" style="color: white"></i> Enregistrer</button>
</div>

<p-toast />

<p-dialog header="Ressources exploitées" [modal]="true" [(visible)]="visible" [position]="position" [style]="{ width: '30rem' }">
    <div class="row">
        <div *ngFor="let item of ressource" class="col-6 form-check">

            <input 
                [(ngModel)]="item.ressource_exploite" 
                class="form-check-input" 
                type="checkbox" 
                id="ressource{{item.id}}">
            <label  class="" for="ressource{{item.id}}">
                {{item.titre}}
            </label>
        </div>
    </div>
</p-dialog>
