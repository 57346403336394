import { Injectable } from '@angular/core';


import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

}

