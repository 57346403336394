import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { Ordonnancement } from 'src/app/shared/models/ordonnancement';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ProgrammeService } from '../../../plananalytique/programs/programme/programme.component';

@Component({
  selector: 'app-detai-ordonnancement',
  templateUrl: './detai-ordonnancement.component.html',
  styleUrl: './detai-ordonnancement.component.scss'
})

export class DetaiOrdonnancementComponent {


  constructor(
    private modalService: NgbModal,
    private paramService:ParameterService,
    private localstorage:LocalStorageService,
    private programmeService:ProgrammeService,
    private messageService:MessageService
  ) { }


  ngOnInit(){

    this.ordonncancementData = this.localstorage.getProgrames();
    //console.log(" this.programmeData ", this.ordonncancementData);
    

  }

  close(){

    this.modalService.dismissAll();
  }

  ordonncancementData: Ordonnancement= new Ordonnancement()

  EditProgramme(){

    //console.log("appeler");
    
    const item =this.localstorage.getProgrames();
    this.paramService.editPrograme(item.id,this.ordonncancementData).subscribe(
      (response:any)=>{

        this.programmeService.notifyProgrammeCreated();
        //console.log("la reponse du webservice",response);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.message });

        this.modalService.dismissAll();
      },
      (error:any)=>{
        //console.log("une erreur est survenu",error);
        this.programmeService.notifyProgrammeCreated();
        this.modalService.dismissAll();


        
      }
    )
  }
}