import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AddDirectionComponent } from '../add-direction/add-direction.component';
import { EditDirectionComponent } from '../edit-direction/edit-direction.component';
import { Direction } from 'src/app/shared/models/direction';
import { BehaviorSubject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-direction',
  templateUrl: './direction.component.html',
  styleUrl: './direction.component.scss'
})


export class DirectionComponent  {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  directionData: any =[];
  validationform: UntypedFormGroup;

  modalRef: NgbModalRef | null = null;

  itemToDelete!: Direction; 
  searchTerm: string;
  filteredtems: any = [];
  pageSize: number = 10; 
  isLoading: boolean = false;
  paginatedProjets = [];
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; // Nombre total d'éléments
  constructor(
      private modalService: NgbModal,
      public formBuilder: UntypedFormBuilder,
      private paramservice:ParameterService,
      private localstorage:LocalStorageService,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private DirectionService: DirectionService,
      private ngxService: NgxUiLoaderService,

  ) { 
    this.DirectionService.directionCreated$.subscribe(() => {
      this.fetchDataDirection();
    });
  }

  ngOnInit(): void {
    this.fetchDataDirection();
  }

  fetchDataDirection() {
    
    this.isLoading = true;

    this.paramservice.fetchDataDirection().subscribe(

      (response:any)=>{
        this.isLoading = false;

        this.directionData = response.data;
        this.filteredtems = [...this.directionData];
        //console.log("this.actionData",this.directionData);
        this.totalRecords = this.directionData.length; // Met à jour le total des enregistrements
      this.updatePaginatedProjets();

      },
      (error)=>{
        //console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }

  onPageChangeProjets(event) {
    this.firstProjets =  event.first; // Mise à jour de la page actuelle
    this.rowsProjets =  event.rows; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }
  
  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.filteredtems.slice(start, end);
  }

  
//filtre les donnees par un mot cle
filterData() {
  const lowerSearchTerm = this.searchTerm.toLowerCase();

  //console.log("lowerSearchTerm",lowerSearchTerm);
  
  if (lowerSearchTerm === "") {

    //console.log("ila");
    
    this.filteredtems = [...this.directionData]; // Réinitialiser avec toutes les données
  } else{
    const searchWords = lowerSearchTerm.split(' ').filter(word => word.length > 0); // Split by spaces and filter out empty strings

    this.filteredtems = this.directionData.filter((item: any) => {
      // Check that properties exist before filtering
      const matchesLibelle = item.libelle && searchWords.every(word => item.libelle.toLowerCase().includes(word));
      const matchesReference = item.reference && searchWords.every(word => item.reference.toLowerCase().includes(word));
  
      // Check the services
      const matchesService = item.service && item.service.some((service: any) => 
        service.libelle && searchWords.every(word => service.libelle.toLowerCase().includes(word))
      );
  
      return matchesLibelle || matchesReference || matchesService;
    });

    this.totalRecords = this.filteredtems.length;
    this.firstProjets = 0; // Reset to the first page
    this.updatePaginatedProjets();
  }
 
}


  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }
  /**
   * Modal Open
   * @param content modal content
   */
  openModal() {
    this.modalService.open(AddDirectionComponent, {
       centered: true,
       backdrop: 'static', 


       });
  }

  EditModal(action:any) {
    this.localstorage.saveDirection(action)
      const modalRef = this.modalService.open(EditDirectionComponent,{
        backdrop: 'static', 
        centered: true,
      }, );
  }

  //confirme la suppression
  confirmDeletion(direction: any) {
        this.confirmationService.confirm({
            message: 'Êtes-vous sûr de vouloir supprimer cette direction ' + direction.libelle +  ' ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: 'pi pi-check',
            rejectIcon: 'pi pi-times',
            rejectButtonStyleClass: 'p-button-text',
            acceptLabel: 'Oui', 
            rejectLabel: 'Non', 
            accept: () => {
                this.ngxService.start();
                this.paramservice.deleteDirection(direction.reference).subscribe(

                  (response:any)=>{
                    this.ngxService.stop();
                    //console.log("la reponse du webserice",response);
                    this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
                    this.fetchDataDirection();
                  },
                  (error)=>{
                    //console.log("une erreur est survenu",error);
                    this.ngxService.stop();
                    this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: error });
                
                  },
                )
              },
            reject: () => {
                //this.messageService.add({ severity: 'error', summary: 'OBGRM', detail: 'Vous avez rejeté', life: 3000 });
            }
        });
    }//end confirmDeletion

 
changePageSize() {
  // Met à jour les données filtrées en fonction de la nouvelle taille de page sélectionnée
  this.filteredtems = this.filteredtems.slice(0, this.pageSize);
}
}

export class DirectionService {
  private DirectionCreatedSubject = new BehaviorSubject<boolean>(false);
  directionCreated$ = this.DirectionCreatedSubject.asObservable();
  notifyProgrammeCreated() {
    this.DirectionCreatedSubject.next(true);
  }
}