<div class="container-fluid feature py-5">
    <div class="container">
        
        <div class="d-flex justify-content-between align-items-center mb-5">
         
            <div class="section-title wow fadeInUp" data-wow-delay="0.1s">
                <h1 style="text-transform: uppercase;" class="mb-0"> {{titre}}</h1>
            </div>
            <button routerLink="/" style="width: 150px;" class="btn btn-primary text-white"><i class="pi pi-arrow-left" style="font-size: 0.9rem"></i>
                Retour</button>

        </div>
        <div class="row">
            <!-- Search Section -->
            <div class="col-md-3">
                <div id="search">
                    <svg viewBox="0 0 420 60" xmlns="http://www.w3.org/2000/svg">
                        <rect class="bar" />
                        <g class="magnifier">
                            <circle class="glass" />
                            <line class="handle" x1="32" y1="32" x2="44" y2="44"></line>
                        </g>
                        <g class="sparks">
                            <circle class="spark" />
                            <circle class="spark" />
                            <circle class="spark" />
                        </g>
                        <g class="burst pattern-one">
                            <circle class="particle circle" />
                            <path class="particle triangle" />
                            <circle class="particle circle" />
                            <path class="particle plus" />
                            <rect class="particle rect" />
                            <path class="particle triangle" />
                        </g>
                        <g class="burst pattern-two">
                            <path class="particle plus" />
                            <circle class="particle circle" />
                            <path class="particle triangle" />
                            <rect class="particle rect" />
                            <circle class="particle circle" />
                            <path class="particle plus" />
                        </g>
                        <g class="burst pattern-three">
                            <circle class="particle circle" />
                            <rect class="particle rect" />
                            <path class="particle plus" />
                            <path class="particle triangle" />
                            <rect class="particle rect" />
                            <path class="particle plus" />
                        </g>
                    </svg>
                    <input type="search" name="searchTerm" [(ngModel)]="searchTerm" (keyup)="fiterRessource()"
                        aria-label="" />

                </div>
            </div>
        </div> <br>

        <div class="row justify-content-center" *ngIf="!fiteredRessource || fiteredRessource.length === 0">

            <div class="col-md-3">
                <div class="card">
                    <p-skeleton width="20rem" height="25rem"></p-skeleton>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <p-skeleton width="20rem" height="25rem"></p-skeleton>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <p-skeleton width="20rem" height="25rem"></p-skeleton>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card">
                    <p-skeleton width="20rem" height="25rem"></p-skeleton>
                </div>
            </div>


        </div>
        <div class="row g-4 justify-content-center">
            <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp d-flex justify-content-center"
                *ngFor="let item of fiteredRessource" data-wow-delay="0.1s">
                <div class="row-cols-1 feature-item p-4">
                    <div class="col-12">
                        <div class="feature-icon mb-4">
                            <div class="p-3 d-inline-flex bg-white rounded">
                                <img [src]="item.photo" height="100px" width="100px" alt="" srcset="">
                            </div>
                        </div>
                        <div class="feature-content d-flex flex-column">
                            <h5 class="mb-4">{{item.titre}}</h5>
                            <p class="mb-0">{{item.perspective}}</p>
                            <button (click)="goToResourceDetail(item.reference)" class="btn btn-primary text-white"> En
                                savoir +</button>

                        </div>
                    </div>

                </div>

            </div>


            <div class="col-md-12 card d-flex justify-content-center">
                <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecord"
                    [rowsPerPageOptions]="[10, 20, 30]" />
            </div>

        </div>
    </div>

</div>