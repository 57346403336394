<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Modifier un Utilisateur</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="name">Nom</label>
                    <input [(ngModel)]="item.name" name="name" type="text" class="form-control" id="name" >
    
                </div> 
                <div class="form-group  col-md-6">
                    <label for="name">Prénoms</label>
                    <input [(ngModel)]="item.surname" name="programme_id" type="text" class="form-control" id="name">
    
                </div>
            </div><br>
           

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="email">Téléphone</label>
                    <input [(ngModel)]="item.phone" name="phone" class="form-control" id="email">
    
                </div>
    
                <div class="form-group col-md-6">
                    <label>Email </label>
                    <input [(ngModel)]="item.email_address" name="email_address" type="text" class="form-control"minlength="10">
    
                </div>
            </div><br>

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label for="email">Identifiant</label>
                    <input [(ngModel)]="item.identifiant" name="identifiant" class="form-control" id="email">
    
                </div>
    
                <div class="form-group col-md-6">
                    <label>Mot de passe </label>
                    <input [(ngModel)]="item.password" name="password" type="password" class="form-control"minlength="16">
    
                </div>
            </div><br>

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label>Groupe </label>
                    <select [(ngModel)]="item.groupe" name="groupe" type="text" class="form-select">

                        <option *ngFor="let item of groupItems " [ngValue]="item.code">{{item.designation}}</option>
                    </select>


                </div>


                <div class="form-group col-md-6">
                    <label>Direction </label>
                    <select [(ngModel)]="item.direction" name="poids" type="text" class="form-select">

                        <option *ngFor="let item of directionItems " [ngValue]="item.reference">{{item.libelle}}</option>
                    </select>

                </div>
            </div>
           

            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <div class="form-check">
                        <input [(ngModel)]="item.acces_suivi_evaluation" name="acces_suivi_evaluation" type="checkbox" class="form-check-input" id="suiviEvaluation">
                        <label class="form-check-label" for="suiviEvaluation">Accès Suivi-Evaluation</label>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <div class="form-check">
                        <input [(ngModel)]="item.acces_valoriation" name="acces_valoriation" type="checkbox" class="form-check-input" id="valorisation">
                        <label class="form-check-label" for="valorisation">Accès Recherche et  Valorisation</label>
                    </div>
                </div>
            </div>
            <br>

            <div class="text-end">
                <button (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times"
                        style="color: white"></i> Annuler</button>&nbsp;
                <button (click)="Edit()" type="submit" class="btn btn-success"> <i class="pi pi-save"
                        style="color: white"></i> Enregistrer</button>

            </div>
        </form>
    </div>
</div>
<p-toast />
<ngx-ui-loader></ngx-ui-loader>