<div class="container-fluid">
    
    <h4 id="upper">Programmes</h4>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!--  -->
              <a href="javascript:void(0);"  class="btn btn-success mb-2 ajout"  id="gras" (click)="openModal()">
                <i class="mdi mdi-plus me-2"></i> <strong  class="fw-bold">Ajouter un Programme</strong>
              </a>
            </div>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <!-- <div class="dataTables_length" id="tickets-table_length"><label class="d-inline-flex align-items-center">Afficher
                    <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize" class="custom-select custom-select-sm form-control form-control-sm mx-2">
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select> lignes</label></div> -->
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label class="d-inline-flex align-items-center">Rechercher:
                    <input type="text" name="searchTerm" class="form-control form-control-sm ms-2" aria-controls="tickets-table"></label></div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive mt-3">
              <table class="table table-centered datatable dt-responsive nowrap " style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                <thead class="thead-light rwd-table">
                  <tr  class="">
                    <th style="width: 20px;">
                    </th>
                    
                    <th>Code</th>
                    <th>Programme</th>
                    <th>Objectif</th>
                    <th> Poids (%)</th>
                    <th> Chargé de programme</th>
                    <th></th>
                    <!-- <th style="width: 120px;">Action</th> -->

                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let item of programmeData; let index = index">
                    <td> </td>
                    <td>{{item?.code}}</td>
                    <td>{{item?.libelle}}</td>
                    <td>{{item?.objectif_global}}</td>
                    <td>{{item?.poids}}</td>
                    <td>{{item.chargedeprogramme}}</td>
                    <td>
                      <a (click)="EditModal(item)" style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a (click)="confirm1(item.reference)"  href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                  
                </tbody>

                
              </table>
            </div>


          </div>
          <div class="d-flex justify-content-center" *ngIf="programmeData.length === 0">
            <div class="container">
                <div class="row">
                    <div class="text-center">
                        <h6 id="graycolor">chargement en cours</h6>
                        <div class="loader4"></div>
                    </div>
                </div>
            </div>
      
        </div>
        </div>
        
      </div>
    </div>
   
  </div>
  


  <p-confirmDialog />
  <p-toast />