import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { ActionServiceService } from '../action/action.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-add-action',
  templateUrl: './add-action.component.html',
  styleUrl: './add-action.component.scss'
})


export class AddActionComponent {


  constructor(
    private modalService: NgbModal,

    private paramService:ParameterService,
    private messageService:MessageService,
    private ActionServiceService :ActionServiceService,
    private ngxService:NgxUiLoaderService,



  ) {
    
   }

  ngOnInit(){
    this.fetchDataPrograme()
  }

  close(){

    this.modalService.dismissAll();
  }

  ationData: any = {}
  programmeData:any=[]

  addAction(){

    if (!this.ationData.canSave()) {
      this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: this.ationData.getErrorMessage() });

    }else{

      this.ngxService.start();
      this.paramService.addAction(this.ationData).subscribe(
        (response:any)=>{
          //console.log("la reponse du webservice",response);
  
          this.modalService.dismissAll();
          this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
          this.ActionServiceService.notifyProgrammeCreated();
          this.ngxService.stop();
        },
        (error:any)=>{
          //console.log("une erreur est survenu",error);
        //  this.modalService.dismissAll();
          this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: error });
          this.ActionServiceService.notifyProgrammeCreated();
          this.ngxService.stop();

  
        }
      )
    }
  
  }

  fetchDataPrograme() {
    
    
    this.paramService.fetchDataPrograme().subscribe(

      (response:any)=>{

        this.programmeData = response.data;

        //console.log("this.programmeData",this.programmeData);
        
      },
      (error)=>{
        //console.log("une erreur est survenu",error);
        
      }
      
    ) ;
  }
}
