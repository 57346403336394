import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProjeToPta } from 'src/app/shared/models/ProjetToPta';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';

@Component({
  selector: 'app-add-projet-to-activite',
  templateUrl: './add-projet-to-activite.component.html',
  styleUrl: './add-projet-to-activite.component.scss',
})
export class AddProjetToActiviteComponent {

  item: any;
  activiteItems: any;
  isLoading: boolean = false;

  activiteItemsToProjet: any
  @ViewChild('activiteModal') modalContent: TemplateRef<any>;

  firstProjets = 0;
  rowsProjets = 10;
  paginatedProjets = [];
  totalRecords: number = 0; // Nombre total d'éléments

  newItem: ProjeToPta = new ProjeToPta();

  updateItem: ProjeToPta = new ProjeToPta();

  constructor(

    private localStorage: LocalStorageService,
    private paramService: ParameterService,
    private messageService: MessageService,
    private ngxService: NgxUiLoaderService,
    private confirmationService: ConfirmationService,
    private modalService: NgbModal,
    private router: Router,

  ) { }


  ngOnInit() {


    this.item = this.localStorage.getProjetActivite();

    //console.log("les item", this.item);

    this.getActiviterToProjet();


  }


  getActiviterToProjet() {

    //console.log("this.item", this.item.reference);
    this.isLoading = true;

    this.paramService.getActiviteToProjet(this.item.reference).subscribe(

      (response: any) => {
        this.isLoading = false;

        //console.log("la reponse du webservice", response);

        this.activiteItemsToProjet = response.data;
        this.updatePaginatedProjets();
        this.totalRecords = this.activiteItemsToProjet.length; // Met à jour le total des enregistrements

      },
      (error) => {

        //console.log("une erreur est survenu", error);

      }
    )
  }

  saveActiviteToprojet() {
    if (!this.newItem.canSave()) {
      
      this.messageService.add({ severity: 'info', summary: '', detail: this.newItem.getErrorMessage() });
      return
    }
    this.newItem.projet_id = this.item.reference;

    //console.log('this.newItem.projetId', this.newItem.projet_id);

    this.ngxService.start();
    this.paramService.saveActiviteToProjet(this.newItem).subscribe(

      (response: any) => {
        this.activiteItems = response.data;

        //console.log(" this.activiteItems", this.activiteItems);

        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
        
        this.newItem = new ProjeToPta();
        this.getActiviterToProjet();
        this.ngxService.stop();
      },
      (error) => {

        //console.log("une erreur est survenu", error);
        this.ngxService.stop();
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "une erreur est survenu" });


      }
    )
  }


  openModalTache(sousActivite: any) {
    //console.log("sousActivite", sousActivite);

    this.updateItem = sousActivite;

    // Convertir la date_debut au format 'YYYY-MM-DD'
    this.updateItem.date_debut = sousActivite.date_debut.split('T')[0];
    this.updateItem.date_fin = sousActivite.date_fin.split('T')[0];

    //console.log("this.updateItem.date_debut", this.updateItem.date_debut);

    // Ouvrir le modal en passant le template
    this.updateItem.pta_activite_id = sousActivite.reference;
    this.updateItem.projet_id = this.item.reference;

    //console.log("this.updateItem.pta_activite_id", this.updateItem.pta_activite_id);

    this.modalService.open(this.modalContent, {
      size: 'ml',
      backdrop: 'static',
      keyboard: false
    });
  }




  updateActiviterToProjet() {
    //console.log('this.newItem.projetId', this.updateItem);

    this.ngxService.start();
    this.paramService.UpdateActiviteToProjet(this.updateItem.pta_activite_id, this.updateItem).subscribe(

      (response: any) => {
        this.activiteItems = response.data;

        //console.log(" this.activiteItems", this.activiteItems);

        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
        this.modalService.dismissAll();
        this.ngxService.stop();
      },
      (error) => {

        //console.log("une erreur est survenu", error);
        this.ngxService.stop();
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "une erreur est survenu" });


      }
    )
  }


  confirmDeletion(project: any) {
    this.confirmationService.confirm({
      message: " 'Êtes-vous sûr de vouloir supprimer l'activiée" + ' ' + project.activite + '?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.ngxService.start();
        this.paramService.deleteActiviterToProjet(project.reference).subscribe(
          (response: any) => {
            this.ngxService.stop();
            //console.log("la reponse du webserice", response);
            this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message });
            this.getActiviterToProjet();
          },
          (error) => {
            this.ngxService.stop();
            //console.log("une erreur est survenu", error);
            this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: "Une erreur est survenue. Veuillez réessayé" });
          },
        )
      },
      reject: () => {
        //this.messageService.add({ severity: 'warn', summary: '', detail: 'Vous avez rejeté', life: 3000 });
      }
    });
  }

  close() {

    this.modalService.dismissAll();
  }




  addSousActiviteToSousActivite(item: any) {

    //console.log("item");

    this.localStorage.saveProjetActivite(item);

    this.router.navigate(['/suivi/planification/activite-sous-activite'])
  }




  onPageChangeProjets(event) {
    this.firstProjets = event.rows ; // Mise à jour de la page actuelle
    this.firstProjets = event.first ; // Mise à jour de la page actuelle
    this.updatePaginatedProjets();
  }

  // Méthode pour mettre à jour les données paginées des projets
  updatePaginatedProjets() {
    const start = this.firstProjets;
    const end = start + this.rowsProjets;
    this.paginatedProjets = this.activiteItemsToProjet.slice(start, end);
  }
}
