import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrl: './mission.component.scss'
})
export class MissionComponent {


  constructor(

    private router:Router
  ){
    
  }
  goToMission(){

   // console.log("appelr");
    
    this.router.navigate(['/metiers'])
  }
}
