<div class="container-fluid blog">
    <div class="container py-4">
        <div class="d-flex justify-content-between align-items-center mb-5">
         
            <div class="section-title wow fadeInUp" data-wow-delay="0.1s">
                <h1 style="text-transform: uppercase;" class="mb-0">{{resource?.titre}}</h1>
            </div>
            <button (click)="returnToSource()" style="width: 150px;" class="btn btn-primary text-white"><i class="pi pi-arrow-left" style="font-size: 0.9rem"></i>
                Retour</button>

        </div>
        

        <div class="row g-4 justify-content-center">
            <div class="col-12 wow fadeInUp" data-wow-delay="0.1s">
                <div class="description mb-4">
                    <h2>{{resource?.type_ressource?.libelle}}</h2>
                </div>
            </div>
            <div class="col-12 col-md-11 wow fadeInUp" data-wow-delay="0.1s">
                <div class="row">
                    <!-- Image Column -->
                    <div class="col-12 col-md-4">
                        <div class="blog-img" *ngIf="resource">
                            <img [src]="resource?.photo" class="img-fluid w-100" alt="Image">
                        </div>
                        <div class="blog-img" *ngIf="!resource || resource.length === 0">
                            <p-skeleton width="20rem" height="25rem"></p-skeleton>
                        </div>
                    </div>
                    <!-- Text Column -->
                    <div class="col-12 col-md-4">
                        <div class="blog-content p-4">
                            <a href="#" class="h4">{{resource?.titre}}</a>
                            <p class="my-4">{{resource?.perspective}}</p>
                        </div>
                    </div>
                    <!-- Map Column -->
                    <div class="col-12 col-md-4">
                        <div class="blog-content p-4">
                            <app-maps [markers]="markers" class="w-100 h-100"></app-maps>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>