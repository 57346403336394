import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Localisation } from 'src/app/shared/models/localisation';
import { Ressource } from 'src/app/shared/models/ressource';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { Constant } from 'src/app/shared/service/utils/contant';
import { reference } from '@popperjs/core';
import { MarkerDataService } from 'src/app/shared/service/makerservice/markerDataService';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-localisation',
  templateUrl: './localisation.component.html',
  styleUrl: './localisation.component.scss'
})
export class LocalisationComponent {

  ressourceItem: any;
  isLoading: boolean = false;
  selectedDepartementId: any;
  selectedCommuneId: any;
  selectedArrondissement: any;
  statutresource: any = [];
  arrondissement: any = [];
  localisationItems: any = [];
  localisation: Localisation = new Localisation();
  departement: any = [];
  commune: any = [];
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  isEditMode = false; // Flag pour mode édition
  localisationRessourceItem: any = {};
  markers: { lat: number, lng: number, type: any, data: any }[] = [];

  constructor(
    private localStorage: LocalStorageService,
    private paramService: ParameterService,
    private messageService: MessageService,
    private router: Router,
    private ngxservice: NgxUiLoaderService,
    private modalService: NgbModal,
    private confirmationService: ConfirmationService,
    private markerDataService: MarkerDataService,

  ) {
    this.markerDataService.markers$.subscribe(markers => {
      this.markers = markers;
    });
  }


  ngOnInit() {
    this.ressourceItem = this.localStorage.getRessource();
    this.getDepartement();
    this.getLocalisationByRessource();
  }

  getDepartement() {

    this.paramService.getStatsDepartement().subscribe(
      (response: any) => {
        this.departement = response.data;
      },
      (error) => {
        //console.log("une errer est survenu", error);
      }
    )
  }
  onDepartementChange(id: any) {
    this.selectedDepartementId = id;
    this.getCommunesById(id);
  }

  oncommuneChange(id: any) {
    this.selectedCommuneId = id;
    this.getArrondissementById(id);
  }

  getCommunesById(id: any) {
    this.paramService.getCommuneByDepartement(id).subscribe(
      (response: any) => {
        this.commune = response.data;
        //console.log("this.commune", this.commune);
      },
      (error) => {
        //console.log("une erreur est survenue", error);
      }
    );
  }

  getArrondissementById(id: any) {
    this.paramService.getArrondissementById(id).subscribe(
      (response: any) => {
        this.arrondissement = response.data;
      },
      (error) => {
        //console.log("une erreur est survenue", error);
      }
    );
  }


  getLocalisationByRessource() {
    this.isLoading = true;
    this.paramService.getLocalisationByRessource(this.ressourceItem?.reference).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.localisationItems = response.data;
        console.log("this.localisationItems", this.localisationItems);

        this.updateMarkersWithRessources(this.localisationItems);
      },
      (error) => {
        this.isLoading = false;
        //console.log("une errer est survenu", error);
      }
    )
  }

  // Met à jour les marqueurs avec les données des ressources
  updateMarkersWithRessources(ressources: any[]) {
    console.log("ressources", ressources);

    const newMarkers = [];
    // Parcourt la liste des ressources
    ressources.forEach(ressource => {
      let data: any = {
        ressource: ressource.ressource.titre,
        departement: ressource.arrondissement.commune.departement.libelle,
        type_ressource: ressource.ressource.type_ressource.libelle,
        description: ressource.ressource.perspective,
        type: "ressource",
        logo: ressource.ressource.icone,
        communes: ressource.arrondissement.commune.libelle,
        localite: ressource.nom_localite,
        resume: ressource.resume_recherche,
        rapport_recherche: ressource.rapport_recherche,
        reference: ressource.reference,
      };

      // Ajouter un marqueur pour chaque ressource
      newMarkers.push({
        lat: ressource.latitude,
        lng: ressource.longitude,
        type: "ressource",
        data: data
      });
    });
    // Met à jour les marqueurs via le service
    this.markerDataService.updateMarkers(newMarkers);
  }

  //
  saveLocalisation() {
    if (this.isEditMode) {
      // Logique de mise à jour
      this.updateLocalisation();
      return
    }
    if (!this.localisation.canSave()) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: this.localisation.getErrorMessage() });
      return;
    }

    this.ngxservice.start();

    // Créez un objet FormData
    const formData = new FormData();

    // Ajoutez les données de localisation
    formData.append('arrondissement_id', this.localisation.arrondissement_id);
    formData.append('nom_localite', this.localisation.nom_localite);
    formData.append('longitude', this.localisation.longitude.toString());
    formData.append('latitude', this.localisation.latitude.toString());
    formData.append('donnee_geologique', this.localisation.donnee_geologique);
    formData.append('donnee_geophysique', this.localisation.donnee_geophysique);
    formData.append('donnee_geochimique', this.localisation.donnee_geochimique);
    formData.append('perspective', this.localisation.perspective);
    formData.append('date_recherche', this.localisation.date_recherche);
    formData.append('resume_recherche', this.localisation.resume_recherche);

    // Ajoutez l'image s'il y en a une
    if (this.localisation.rapport_recherche) {
      formData.append('rapport_recherche', this.localisation.rapport_recherche);
    }

    // Ajoutez l'ID de la ressource associée
    formData.append('ressource_id', this.ressourceItem?.reference);

    // Envoyez les données
    this.paramService.saveLocalisation(formData).subscribe(
      (response: any) => {
        this.ngxservice.stop();
        this.statutresource = response.data;
        this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.rechercheiTitle, detail: response.message });
        this.getLocalisationByRessource();
        this.resetFileInput();

      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });
        this.ngxservice.stop();
      }
    );
  }
  // Méthode pour réinitialiser le champ de fichier
  resetFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Réinitialise le champ de fichier
    }
  }
  //end saveLocalisation
  updateLocalisation() {
    // if (!this.localisation.canSave()) {
    //   this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: this.localisation.getErrorMessage() });
    //   return;
    // }


    if (!this.localisation.arrondissement_id) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "L'arrondissement est obligatoire" });
      return;
    }
    if (!this.localisation.nom_localite) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "Le nom de la localité est obligatoire" });
      return
    }


    if (!this.localisation.longitude) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "La longitude est obligatoire" }); return;
    }

    if (!this.localisation.latitude) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "La latitude est obligatoire" }); return;
    }
    if (!this.localisation.donnee_geologique) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "La donnée géologique est obligatoire" }); return;
    }
    if (!this.localisation.donnee_geophysique) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "La donnée géophysique est obligatoire" }); return;
    }
    if (!this.localisation.donnee_geochimique) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "La donnée géochimique est obligatoire" }); return;
    }
    if (!this.localisation.perspective) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "La donnée perspective est obligatoire" }); return;
    }

    if (!this.localisation.date_recherche) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "La date de recherche est obligatoire" }); return;
    }

    if (!this.localisation.resume_recherche) {
      this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: "Le résumé est obligatoire" }); return;
    }


    this.ngxservice.start();

    // Créez un objet FormData
    const formData = new FormData();

    // Ajoutez les données de localisation
    formData.append('arrondissement_id', this.localisation.arrondissement_id);
    formData.append('nom_localite', this.localisation.nom_localite);
    formData.append('longitude', this.localisation.longitude.toString());
    formData.append('latitude', this.localisation.latitude.toString());
    formData.append('donnee_geologique', this.localisation.donnee_geologique);
    formData.append('donnee_geophysique', this.localisation.donnee_geophysique);
    formData.append('donnee_geochimique', this.localisation.donnee_geochimique);
    formData.append('perspective', this.localisation.perspective);
    formData.append('date_recherche', this.localisation.date_recherche);
    formData.append('resume_recherche', this.localisation.resume_recherche);
    formData.append('reference', this.localisation.reference);

    // Ajoutez l'image si elle existe
    if (this.localisation.rapport_recherche) {
      formData.append('rapport_recherche', this.localisation.rapport_recherche);
    }

    // Ajoutez l'ID de la ressource associée
    formData.append('ressource_id', this.ressourceItem?.reference);

    // Appel au service de mise à jour
    this.paramService.UpdateLocalisation(this.localisation.reference, formData).subscribe(
      (response: any) => {
        this.getLocalisationByRessource();
        this.modalService.dismissAll();
        this.ngxservice.stop();
        this.messageService.add({ severity: 'success', summary: Constant.rechercheiTitle, detail: response.message });
      },
      (error) => {
        console.error("Une erreur est survenue lors de la mise à jour", error);
        this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });
        this.ngxservice.stop();
      }
    );
  }


  //end updateLocalisation

  openModal() {
    this.isEditMode = false; // Mode ajout
    this.localisation = new Localisation();
    this.modalService.open(this.modalContent, { size: 'xl' });
  }

  EditModal(_localisationRessourceItem: any) {
    this.isEditMode = true;
    this.localisation = { ..._localisationRessourceItem };
    //this.reference = ressourceItem.reference;

    console.log("reference is", _localisationRessourceItem.reference);

    this.localisation.ressource_id = this.ressourceItem.reference;
    this.modalService.open(this.modalContent, { size: 'xl' });
  }//end EditModal
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      // Vérifier si le fichier est un PDF
      if (file.type !== 'application/pdf') {
        this.messageService.add({ severity: 'info', summary: 'Format de fichier incorrect', detail: 'Veuillez sélectionner un fichier PDF.' });
        this.resetFileInput();
        return;
      }

      // Stocker le fichier sélectionné dans la propriété 'rapport_recherche' de l'objet 'localisation'
      this.localisation.rapport_recherche = file;
    }
  }


  confirmDeletion(event: any) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir continuer ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'p-button-text',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.ngxservice.start();
        this.paramService.deleteLocalisation(event).subscribe(
          (response: any) => {
            this.messageService.add({ severity: 'info', summary: Constant.rechercheiTitle, detail: response.message });
            this.ngxservice.stop();
            this.getLocalisationByRessource();
          },
          (error) => {
            this.messageService.add({ severity: 'error', summary: Constant.rechercheiTitle, detail: error });
            this.ngxservice.stop();
          },
        )
      },

    });
  }//end confirmDeletion


  exportToDocument(item: any) {
    console.log("item",item);
    this.ngxservice.start();
    this.paramService.downloadPdfByLocalisationRessource(item?.reference).subscribe(
      (response: Blob) => {
        const filename = 'localisation_ressource.pdf';
        FileSaver.saveAs(response, filename);
        const url = URL.createObjectURL(response);
        window.open(url, '_blank');
        this.ngxservice.stop();
        this.messageService.add({ severity: 'success', summary: 'Téléchargement réussi', detail: 'Le fichier PDF a été téléchargé avec succès', life: 3000 });
      },
      (error) => {
        //console.error("Une erreur est survenue", error);
        this.ngxservice.stop();
      }
    );

  }


}

