import { MenuItem } from './menu.model';

export const MENUADMIN : MenuItem [] = [

    {
        id: 5,
        label: 'Plan Analytique',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 6,
                label: 'Programme',
                link: '/plan/programme',
                parentId: 5
            },
            {
                id: 7,
                label: 'Action',
                link: '/plan/action/',
                parentId: 5
            },
            {
                id: 8,
                label: 'Projets lien Indicateur',
                link: '/plan/projet-lien-indicateur',
                parentId: 5
            },
            {
                id: 9,
                label: 'Indicateurs',
                link: '/plan/indicateur',
                parentId: 5
            },
            {
                id: 10,
                label: 'Directions',
                link: '/plan/direction',
                parentId: 5
            },
            {
                id: 11,
                label: 'Services',
                link: '/plan/service',
                parentId: 5
            },
            {
                id: 12,
                label: 'Gestion Accès',
                link: '/plan/gestion-acces',
                parentId: 5
            },
           
        ]
    },
    {
        id: 13,
        label: 'Planification',
        icon: 'ri-mail-send-line',
        subItems: [
            {
                id: 14,
                label: 'Importation PTA (fichiers excel)',
                link: '/planification/importation',
                parentId: 14
            },
            {
                id: 15,
                label: 'Validation PTA',
                link: '/planification/validation',
                parentId: 14
            },
            {
                id: 16,
                label: 'Revue budgétaire',
                link: '/planification/budgetaire',
                parentId: 14
            }

            ,
            {
                id: 17,
                label: 'Cibles des Indicateurs',
                link: '/planification/cible-indicateurs',
                parentId: 14
            }

            ,
            {
                id: 18,
                label: 'Activités hors PTA',
                link: '/planification/activite-hors-pta',
                parentId: 14
            }
        ]
    },
]
export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'ri-dashboard-line',
        badge: {
            variant: 'success',
            text: 'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: '/page/dashboard'
    },
    // {
    //     id: 3,
    //     label: 'MENUITEMS.CALENDAR.TEXT',
    //     icon: 'ri-calendar-2-line',
    //     link: '/calendar'
    // },
    // {
    //     id: 4,
    //     label: 'MENUITEMS.CHAT.TEXT',
    //     icon: 'ri-chat-1-line',
    //     link: '/chat'
    // },
    {
        id: 5,
        label: 'Plan Analytique',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 6,
                label: 'Programme',
                link: '/plan/programme',
                parentId: 5
            },
            {
                id: 7,
                label: 'Action',
                link: '/plan/action/',
                parentId: 5
            },
            {
                id: 8,
                label: 'Projets lien Indicateur',
                link: '/plan/projet-lien-indicateur',
                parentId: 5
            },
            {
                id: 9,
                label: 'Indicateurs',
                link: '/plan/indicateur',
                parentId: 5
            },
            {
                id: 10,
                label: 'Directions',
                link: '/plan/direction',
                parentId: 5
            },
            {
                id: 11,
                label: 'Services',
                link: '/plan/service',
                parentId: 5
            },
            {
                id: 12,
                label: 'Gestion Accès',
                link: '/plan/gestion-acces',
                parentId: 5
            },
           
        ]
    },
    {
        id: 13,
        label: 'Planification',
        icon: 'ri-mail-send-line',
        subItems: [
            {
                id: 14,
                label: 'Importation PTA (fichiers excel)',
                link: '/planification/importation',
                parentId: 14
            },
            {
                id: 15,
                label: 'Validation PTA',
                link: '/planification/validation',
                parentId: 14
            },
            {
                id: 16,
                label: 'Revue budgétaire',
                link: '/planification/budgetaire',
                parentId: 14
            }

            ,
            {
                id: 17,
                label: 'Cibles des Indicateurs',
                link: '/planification/cible-indicateurs',
                parentId: 14
            }

            ,
            {
                id: 18,
                label: 'Activités hors PTA',
                link: '/planification/activite-hors-pta',
                parentId: 14
            }
        ]
    },
    
    {
        id: 23,
        label: 'Exécution',
        icon: 'ri-profile-line',
        subItems: [
            {
                id: 24,
                label: 'Tâches(en retard ,à réaliser, réalisées , annulation)',
                link: '/execution/tache',
                parentId: 23
            },
            {
                id: 25,
                label: 'Cartogrophie',
                link: '/execution/cartographie',
                parentId: 23
            },
            {
                id: 26,
                label: 'Réalisations Indicateurs',
                link: '/execution/realisation',
                parentId: 23
            },
            {
                id: 27,
                label: 'Engagements',
                link: '/execution/engagement',
                parentId: 23
            },
            {
                id: 28,
                label: 'Ordonnancement',
                link: '/execution/ordonnancement',
                parentId: 23
            },
           
        ]
    },

 
    {
        id: 18,
        label: 'MENUITEMS.AUTHENTICATION.TEXT',
        icon: 'ri-account-circle-line',
        subItems: [
            {
                id: 19,
                label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
                link: '/pages/login-1',
                parentId: 18
            },
            {
                id: 20,
                label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
                link: '/pages/register-1',
                parentId: 18
            },
            {
                id: 21,
                label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
                link: '/pages/recoverpwd-1',
                parentId: 18
            },
            {
                id: 22,
                label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
                link: '/pages/lock-screen-1',
                parentId: 18
            }
        ]
    },
  
   
  
];
