import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ParameterService } from 'src/app/shared/service/params/param';
import { LocalStorageService } from 'src/app/shared/service/storage/localstorage.service';
import { IndicateurServiceService } from '../indicateur/indicateur.component';
import { Router } from '@angular/router';
import { Constant } from 'src/app/shared/service/utils/contant';
import { reference } from '@popperjs/core';

@Component({
  selector: 'app-planification',
  templateUrl: './planification.component.html',
  styleUrl: './planification.component.scss'
})

export class PlanificationComponent {
  firstProjets: number = 0; // La page actuelle (commence à 0)
  rowsProjets: number = 10; // Nombre d'éléments par page
  totalRecords: number = 0; //
  paginatedProjets = [];
  items: any;
  planification: Planification = new Planification();


  annees: number[] = [];

  constructor(
    private modalService: NgbModal,
    private paramservice: ParameterService,
    private localstorage: LocalStorageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private indcateurService: IndicateurServiceService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 5; i++) {
      this.annees.push(currentYear + i);
    }

  }

  ngOnInit() {

    this.items = this.localstorage.getPlanification()
    //console.log("this.item", this.items);
    this.planification.indicateur_id = this.items.reference;
   // //console.log("this.planification.reference_indicateur",this.planification.indicateur_id);
    
  }

  savePlanification() {

    if (!this.planification.annee) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez séléctionner l'année"});
      return;
    }

    if (!this.planification.valeur_cible) {
      this.messageService.add({ severity: 'info', summary: Constant.suiviTitle, detail: "Veuillez saisir la valeur cible"});
      return;
    }
    this.ngxService.start();
    this.paramservice.savePlanificationToIndicateur(this.planification).subscribe(
      (response:any)=>{
       
        if (response.status === "error") {
          this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail: response.message});
          this.ngxService.stop();
          this.modalService.dismissAll();
          return;
        }
        //console.log("la réponse du wtebservice",response);
        this.ngxService.stop();
        this.modalService.dismissAll();
        this.messageService.add({ severity: 'success', summary: Constant.suiviTitle, detail: response.message});

      },
      (error)=>{
        //console.log("une erreu et survenu",error);
        this.ngxService.stop();
        this.messageService.add({ severity: 'error', summary: Constant.suiviTitle, detail:error.message});

      }
    )

  }
  closeModal() {
    this.modalService.dismissAll();
  }


}
export class Planification {
  annee: any;
  valeur_cible: any;
  indicateur_id:any


}
