import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }


  login(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/login', item).pipe(
      tap(response => {
        // Stocker le token dans le localStorage
        localStorage.setItem('access_token_obgrm', response.data.token);
      })
    );
  }

  updateProfil(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/update-user-data', item);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('access_token_obgrm');
  }


  checkEmail(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/identite', item);
  }



  register(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/register', item);
    // return this.http.post(environment.apiUrl + 'signin',item);
  }


  reset(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `auth/reset-password/`, item);
  }


  addGestionUser(item: any): Observable<any> {
    //console.log("webservice");

    return this.http.post(environment.apiUrl + 'auth/register', item);
  }
  editGestionUser(item: any): Observable<any> {
    return this.http.post(environment.apiUrl + `auth/update-user`, item);
  }

  deleteGestionUser(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + `auth/remove-user/${id}`);
  }

}
