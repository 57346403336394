<div class="container-fluid">

  <h4 id="upper">Activité hors Pta</h4>
  <div class="row">
    <div class="col-lg-12">
      <!-- <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label class="d-inline-flex align-items-center">Afficher
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize" class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> lignes</label></div>
            </div>
            Search
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ms-2" aria-controls="tickets-table"></label></div>
            </div>
          </div>
          <div class="centered-wrapper">
            <div class="wrapper d-flex justify-content-center">
              <div class="container">
                <div class="question">
                  Activité1
                </div>
                <div class="answercont">
                  <div class="answer">
                    <div class="table-responsive mt-3">
                      <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead class="thead-light">
                          <tr>
                           
                            <th>libelle</th>
                            <th>programme</th>
                            <th>Poids Action</th>
                            <th>responsable action</th>
                            <th style="width: 120px;">Action</th> 
                  </tr>
                        </thead>
                        <tbody>
                          <tr  *ngFor="let item of actionData; let index = index">
                            <td>{{item?.libelle}}</td>
                            <td>{{item?.programe?.libelle}}</td>
                            <td>{{item?.poids_action}}</td>
                            <td>{{item.responsable_action}}</td>
                            <td>
                              <a  style="cursor: pointer;" class="me-3 text-primary" v-b-tooltip.hover title="Executer">
                                <i class="mdi mdi-check-all font-size-18"></i>
                              </a>
                              <a   href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Annuler">
                                <i class=" mdi mdi-window-close font-size-18"></i>
                              </a>
                            </td>
                          </tr>
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            

            </div>
          </div>

         
        </div>
      </div> -->
    </div>
  </div>
</div>



<p-confirmDialog />
<p-toast />