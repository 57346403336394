<!-- Contacts model  -->
<div class="modal-header">
    <h5 class="modal-title">Ajouter un projet</h5>
    <button  type="button" class="close" aria-label="Close"  (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3">
    <div class="text-left">
        <form>
            <div class="row">
                <!-- Premier groupe de trois champs -->
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="code">Code</label>
                        <input [(ngModel)]="projetData.code" name="code" type="text"  maxlength="10" class="form-control" id="code">
                    </div>
                </div><br>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="libelle">Libellé</label>
                        <textarea [(ngModel)]="projetData.libelle" name="libelle" type="text"  maxlength="100" class="form-control" id="libelle">
                        
                        </textarea>
                    </div>
                </div><br>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="sigle">Sigle</label>
                        <input [(ngModel)]="projetData.sigle" name="sigle" type="text" maxlength="5"  class="form-control" id="sigle">
                    </div>
                </div>
            </div><br>
        
            <div class="row">
                <!-- Deuxième groupe de trois champs -->
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="contexte">Contexte</label>
                        <textarea [(ngModel)]="projetData.contexte" name="contexte"  maxlength="200" type="text" class="form-control" id="contexte"> </textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="objectifs_specifiques">Objectifs spécifiques</label>
                        <textarea [(ngModel)]="projetData.objectifs_specifiques" name="objectifs_specifiques" maxlength="200"  type="text" class="form-control" id="objectifs_specifiques"></textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="resultat_projet">Résultat projet</label>
                        <textarea [(ngModel)]="projetData.resultat_projet" name="resultat_projet" maxlength="200"  type="text" class="form-control" id="resultat_projet"> </textarea>
                    </div>
                </div>
            </div><br>
        
            <div class="row">
                <!-- Troisième groupe de trois champs -->
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="description_projet">Description projet</label>
                        <textarea [(ngModel)]="projetData.description_projet"  maxlength="200"  name="description_projet" type="text" class="form-control" id="description_projet"> </textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="zones_intervention">Zones intervention</label>
                        <textarea [(ngModel)]="projetData.zones_intervention"  maxlength="200" name="zones_intervention" type="text" class="form-control" id="zones_intervention"> </textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="beneficiaires">Bénéficiaires</label>
                        <textarea [(ngModel)]="projetData.beneficiaires"  maxlength="50"  name="beneficiaires" type="text" class="form-control" id="beneficiaires"></textarea>
                    </div>
                </div>
            </div><br>
        
            <div class="row">
                <!-- Quatrième groupe de trois champs -->
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cout">Coût (en FCFA)</label>
                        <input [(ngModel)]="projetData.cout" name="cout" type="number" class="form-control" id="cout">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="poids">Poids</label>
                        <input  type="number" [(ngModel)]="projetData.poids" name="poids"  maxlength="50"   class="form-control" id="poids">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="responsable_projet">Responsable projet</label>
                        <input [(ngModel)]="projetData.responsable_projet" name="responsable_projet"  maxlength="50"  type="text" class="form-control" id="responsable_projet">
                    </div>
                </div>
            </div><br>
        
            <div class="row">
                <!-- Champ restant et boutons de soumission -->
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="objectif_global">Objectif global</label>
                        <textarea [(ngModel)]="projetData.objectif_global" name="objectif_global" type="text" maxlength="50" class="form-control" id="objectif_global"></textarea>
                    </div>
                </div>
                
            </div><br>
            <div class="col-md-12 d-flex justify-content-end">
                <button (click)="close()" type="button" class="btn btn-info ms-1"> <i class="pi pi-times" style="color: white"></i> Annuler</button> &nbsp;
                <button (click)="addProjet()" type="submit" class="btn btn-success"> <i class="pi pi-save" style="color: white"></i> Enregistrer</button>

            </div>
            
        </form>
        
        
    </div>
</div>
<p-toast />

